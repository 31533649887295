(function () {
  'use strict';

  angular
    .module('fokus', [
      'ui.router', // Angular flexible routing
      'ngStorage', // ngStorage - LocalStorage
      'cgNotify', // Angular notify
      '19degrees.ngSweetAlert2', // Sweet Alert 2
      'ui.bootstrap', // AngularJS native directives for Bootstrap
      'ngMessages', // ngMessages - Brabao Validator
      'ngSanitize', // Angular-sanitize'
      'idf.br-filters', // Br Filters: http://the-darc.github.io/angular-br-filters/
      'ui.select', // AngularJS ui-select
      'ui.utils.masks', // Util Masks: https://github.com/assisrafael/angular-input-masks
      'ngNotify', // Notify da barra de tarefas grande: https://github.com/matowens/ng-notify // http://matowens.github.io/ng-notify/
      'uiSwitch',
      'ngImgCrop',
      'ngFileUpload',
      'ngMaterial',
      'dndLists',
      'ngAnimate',
      'ui.validate',
      'thatisuday.dropzone',
      'pdf',
      'toastr',
      'ng-morris-js',
      'btford.socket-io',
      'ui.mask',
      'angular-loading-bar',
      'plupload.directive',
      'highcharts-ng', // Hghchart
      'ngCookies'
    ])
    .run([
      '$transitions',
      '$uibModalStack',
      'LoadingScreenFactory',
      function ($transitions, $uibModalStack, LoadingScreenFactory) {
        // Fecha qualquer modal que esteja aberto para trocar de pagina.
        $transitions.onStart({}, function (trans) {
          $uibModalStack.dismissAll();
        });

        // SocketIO.on('NFSeStatusNotification', function (vm) {
        //   console.log('RODANDO!')
        //   console.log(vm)
        // })

        // // Fecha qualquer modal que esteja aberto para trocar de pagina.
        // LoadingScreenFactory.Show();
        // trans.promise.finally(LoadingScreenFactory.Hide());
      },
    ])
    .run([
      '$rootScope',
      '$state',
      '$stateParams',
      function ($rootScope, $state, $stateParams) {
        $state.MenuRecolhido = false;

        $state.TrocarDeMenu = function() {
          $state.MenuRecolhido = !$state.MenuRecolhido;

          if ($state.MenuRecolhido) {
            $(function () {
              $('#sidebar-content.recolhido [data-toggle="tooltip"]').tooltip({
                container: 'body'
              })
            })
          } else {
            $(function () {
              $('[data-toggle="tooltip"]').tooltip('destroy')
            })
          }
        }

        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;
      },
    ]);
})();

(function () {
  'use strict';

  angular.module('fokus').config(config);
  config.$inject = ['$httpProvider'];

  function config($httpProvider) {
    $httpProvider.interceptors.push('RedirectInterceptor');
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .config(configState)
    .run(run)
    .config(['$provide', configureTemplateFactory]);

  configState.$inject = [
    '$stateProvider',
    '$urlRouterProvider',
    '$compileProvider',
    '$qProvider',
  ];
  run.$inject = ['$rootScope', '$state', 'AuthFactory', '$templateCache'];

  function configState(
    $stateProvider,
    $urlRouterProvider,
    $compileProvider,
    $qProvider
  ) {
    //  console.log("route-config: configState");
    $qProvider.errorOnUnhandledRejections(false);

    // Optimize load start with remove binding information inside the DOM element
    //$compileProvider.debugInfoEnabled(true);

    // Set default state
    $urlRouterProvider.otherwise('/login');

    // Remove # from URL
    // $locationProvider.html5Mode(true);

    /**
     * Add This Condition o Web.Config to remove # on the URL
     *<rule name="Main Rule" stopProcessing="true">
        <match url=".*" />
          <conditions logicalGrouping="MatchAll">
          <add input="{REQUEST_FILENAME}" matchType="IsFile" negate="true" />
          <add input="{REQUEST_FILENAME}" matchType="IsDirectory" negate="true" />
          </conditions>
        <action type="Rewrite" url="/" />
      </rule>
     */

    var date = new Date().getTime().toString();
    var temCookieAutenticacao = false;
    

    if(window.location !== window.parent.location){
      temCookieAutenticacao = true;
    }

    

    function mostrarMenuSeTiverCookie(showMenuDefault) {
      return temCookieAutenticacao ? false : showMenuDefault;
    }

    $stateProvider
      .state('login', {
        url: '/login',
        cache: false,
        templateUrl: '/views/login.html' + '?tscache=' + date,
        controller: 'loginCtrl',
        controllerAs: 'ctrl',
        data: {
          pageTitle: 'Login',
          specialClass: 'framed main-scrollable',
          authorize: false,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(false),
        },
      })

      .state('logout', {
        url: '/logout' + '?tscache=' + date,
        cache: false,
        controller: 'logoutCtrl',
        controllerAs: 'ctrl',
        data: {
          pageTitle: 'Logout',
          authorize: false,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(false),
        },
      })

      .state('resetpassword', {
        url: '/auth/resetpassword/{token}' + '?tscache=' + date,
        cache: false,
        controller: 'recoveryCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/common/modals/login/redefinirsenha.html' + '?tscache=' + date,
        data: {
          pageTitle: 'Esqueci minha senha',
          authorize: false,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(false),
        },
      })

      .state('acesso-negado', {
        url: '/acesso-negado',
        cache: false,
        templateUrl: '/views/common/acesso-negado.html' + '?tscache=' + date,
        //controller: 'AcessoNegadoCtrl',
        //controllerAs: 'ctrl',
        data: {
          pageTitle: 'Acesso Negado',
          specialClass: 'framed main-scrollable',
          authorize: false,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(false),
        },
      })

      .state('mudarFoto', {
        url: '/pessoal/mudarfoto',
        cache: false,
        templateUrl: '/views/pessoal/mudarFoto.html' + '?tscache=' + date,
        controller: 'mudarFotoCtrl',
        controllerAs: 'ctrl',
        data: {
          pageTitle: 'Alterar Foto',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('dashboard', {
        url: '/dashboard',
        cache: false,
        templateUrl: '/views/dashboard/dashboard.html' + '?tscache=' + date,
        controller: 'dashBoardCtrl',
        controllerAs: 'ctrl',
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: 'Dashboard',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('controleNFSEs', {
        url: '/controleNFSEs',
        cache: false,
        templateUrl:
          '/views/controleNFSEs/controleNFSEs.html' + '?tscache=' + date,
        controller: 'controleNFSEsCtrl',
        controllerAs: 'ctrl',
        data: {
          pageTitle: 'Controle de Notas',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true), /// tenta acessar aqui e na de baixo
        },
      })

      .state('dashboardprocessamentos', {
        url: '/dashboardprocessamentos',
        cache: false,
        templateUrl:
          '/views/administrativo/dashboardprocessamentos.html' +
          '?tscache=' +
          date,
        controller: 'dashBoardProcessamentosCtrl',
        controllerAs: 'ctrl',
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: 'Dashboard - Processamento',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('emissor', {
        url: '/operacional/nfse/emitir',
        cache: false,
        templateUrl: '/views/operacional/nfse.emitir.html' + '?tscache=' + date,
        controller: 'emitirNFSeCtrl',
        controllerAs: 'ctrl',
        data: {
          pageTitle: 'Emitir NFS-e',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true), /// tenta acessar aqui e na de baixo
        },
      })

      .state('importararquivos', {
        url: '/operacional/importararquivos',
        cache: false,
        templateUrl: '/views/operacional/importarXML.html' + '?tscache=' + date,
        controller: 'importarXMLCtrl',
        controllerAs: 'ctrl',
        data: {
          pageTitle: 'Importar Arquivos',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true), /// tenta acessar aqui e na de baixo
        },
      })

      .state('manutencaonfse', {
        url: '/operacional/nfse/manutencao',
        cache: false,
        templateUrl:
          '/views/operacional/nfse.manutencao.html' + '?tscache=' + date,
        controller: 'ManutencaoNFSeCtrl',
        controllerAs: 'ctrl',
        resolve: {},
        data: {
          pageTitle: 'Manutenção NFS-e',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('certificadosdigitais', {
        url: '/cadastros/certificadosdigitais',
        cache: false,
        templateUrl:
          '/views/cadastros/certificadosdigitais/certificadosdigitais.html' +
          '?tscache=' +
          date,
        controller: 'certificadosDigitaisCtrl',
        controllerAs: 'ctrl',
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: 'Certificados Digitais',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('lancdespesasextras', {
        url: '/operacional/lancdespesasextras',
        cache: false,
        controller: 'despesasExtrasCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/operacional/despesasextras.html' + '?tscache=' + date,
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('clientes', {
        url: '/cadastros/clientes',
        cache: false,
        controller: 'clientesCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/cadastros/clientes/clientes.html' + '?tscache=' + date,
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('itens', {
        url: '/cadastros/itens',
        cache: false,
        controller: 'itensCtrl',
        controllerAs: 'ctrl',
        templateUrl: '/views/cadastros/itens/itens.html' + '?tscache=' + date,
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
          ListaDeItens: [
            'NFSeDescricoesDoItensAPI',
            function (NFSeDescricoesDoItensAPI) {
              return NFSeDescricoesDoItensAPI.Get();
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('medicos', {
        url: '/cadastros/medicos',
        cache: false,
        controller: 'medicosCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/cadastros/medicos/medicos.html' + '?tscache=' + date,
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('tomadores', {
        url: '/cadastros/tomadores',
        cache: false,
        controller: 'tomadoresCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/cadastros/tomadores/tomadores.html' + '?tscache=' + date,
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('fornecedores', {
        url: '/cadastros/fornecedores',
        cache: false,
        controller: 'fornecedoresCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/cadastros/fornecedores/fornecedores.html' +
          '?tscache=' +
          date,
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('servicos', {
        url: '/cadastros/servicos',
        cache: false,
        controller: 'servicosCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/cadastros/servicos/servicos.html' + '?tscache=' + date,
        resolve: {
          ListaDeServicos: [
            '$q',
            'servicosAPI',            
            function ($q, servicosAPI) {
              var deferred = $q.defer();
              servicosAPI.GetPage(1, 50, '').then(function (response) {
                deferred.resolve(response.data);
              }).catch(function (response) {
                deferred.reject();
              });           
              return deferred.promise;   
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('servicosplugnotas', {
        url: '/cadastros/servicosplugnotas',
        cache: false,
        controller: 'servicosPlugNotasCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/cadastros/servicosplugnotas/servicosplugnotas.html' +
          '?tscache=' +
          date,
        resolve: {
          ListaDeServicos: [
            'servicosplugNotasAPI',
            function (servicosplugNotasAPI) {
              return servicosplugNotasAPI.Get();
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('municipios', {
        url: '/cadastros/municipios',
        cache: false,
        controller: 'municipiosCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/cadastros/municipios/municipios.html' + '?tscache=' + date,
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('meusrelatorios', {
        url: '/meusrelatorios/meusrelatorios',
        cache: false,
        controller: 'meusrelatoriosCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/meusrelatorios/meusrelatorios.html' + '?tscache=' + date,
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: 'Meus Relatórios',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('relatorionfseemitidas', {
        url: '/meusrelatorios/nfseemitidas',
        cache: false,
        controller: 'relatorioNFSeEmitidasCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/meusrelatorios/relatorioNFSeEmitidas.html' +
          '?tscache=' +
          date,
        data: {
          pageTitle: 'NFSe Emitidas',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('distribuicaolucrosporsocio', {
        url: '/meusrelatorios/lucrosporsocio',
        cache: false,
        controller: 'distribuicaoLucrosPorSocioCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/meusrelatorios/relatorioDistribuicaoDeLucrosPorSocio.html' +
          '?tscache=' +
          date,
        data: {
          pageTitle: 'Lucros por Sócio',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('anexos', {
        url: '/cadastros/anexos',
        cache: false,
        controller: 'anexosCtrl',
        controllerAs: 'ctrl',
        templateUrl: '/views/cadastros/anexos/anexos.html' + '?tscache=' + date,
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('meusdados', {
        url: '/pessoal/meuperfil',
        cache: false,
        controller: 'meuPerfilCtrl',
        controllerAs: 'ctrl',
        templateUrl: '/views/pessoal/meuPerfil.html' + '?tscache=' + date,
        // resolve: {
        //   Configs: [
        //     '$q',
        //     'SharedDataFactory',
        //     'usuarioAPI',
        //     function ($q, SharedDataFactory, usuarioAPI) {
        //       var deferred = $q.defer();
        //       usuarioAPI
        //         .Get()
        //         .then(function (response) {
        //           SharedDataFactory.Set(response.data);
        //           deferred.resolve();
        //         })
        //         .catch(function (response) {
        //           deferred.reject();
        //         });
        //       return deferred.promise;
        //     },
        //   ],
        // },
        data: {
          pageTitle: 'Meu Perfil',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('usuarios', {
        url: '/configuracoes/usuarios',
        cache: false,
        controller: 'usuariosCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/cadastros/usuarios/usuarios.html' + '?tscache=' + date,
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('ajustes', {
        url: '/configuracoes/ajustes',
        cache: false,
        controller: 'ajustesCtrl',
        controllerAs: 'ctrl',
        templateUrl: '/views/configuracoes/ajustes.html' + '?tscache=' + date,
        // resolve: {
        //   Configs: [
        //     '$q',
        //     'SharedDataFactory',
        //     'usuarioAPI',
        //     function ($q, SharedDataFactory, usuarioAPI) {
        //       var deferred = $q.defer();
        //       usuarioAPI
        //         .Get()
        //         .then(function (response) {
        //           SharedDataFactory.Set(response.data);
        //           deferred.resolve();
        //         })
        //         .catch(function (response) {
        //           deferred.reject();
        //         });
        //       return deferred.promise;
        //     },
        //   ],
        // },
        data: {
          pageTitle: 'Ajustes',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('importacaodetomadores', {
        url: '/administrativo/importacaodetomadores',
        cache: false,
        controller: 'importacaodetomadoresCtrl',
        controllerAs: 'ctrl',
        templateUrl:
          '/views/administrativo/importacaodetomadores.html' +
          '?tscache=' +
          date,
        resolve: {
          Configs: [
            '$q',
            'SharedDataFactory',
            'usuarioAPI',
            function ($q, SharedDataFactory, usuarioAPI) {
              var deferred = $q.defer();
              usuarioAPI
                .Get()
                .then(function (response) {
                  SharedDataFactory.Set(response.data);
                  deferred.resolve();
                })
                .catch(function (response) {
                  deferred.reject();
                });
              return deferred.promise;
            },
          ],
        },
        data: {
          pageTitle: '',
          authorize: true,
          roles: [],
          ShowMenu: mostrarMenuSeTiverCookie(true),
        },
      })

      .state('error', {
        url: '/error',
        cache: false,
        templateUrl: '/views/error/error.html' + '?tscache=' + date,
        data: {
          pageTitle: '',
          authorize: false,
          roles: [],
        },
      });
  }

  function run($rootScope, $state, AuthFactory, $templateCache) {
    $rootScope.$state = $state;
    $rootScope.$on(
      '$stateChangeStart',
      function (event, toState, toParams, fromState, fromParams) {
        $templateCache.removeAll();

        console.log('AuthFactory.isAutenticated: ', AuthFactory.isAutenticated);

        if (toState.data.authorize && !AuthFactory.isAutenticated) {
          event.preventDefault();
          return $state.go('login');
        }
        if (
          toState.data.roles &&
          toState.data.roles.length &&
          !AuthFactory.HasPermission(toState.data.roles)
        ) {
          event.preventDefault();
          return $state.go('acesso-negado');
        }
      }
    );
  }

  function configureTemplateFactory($provide) {
    // Set a suffix outside the decorator function
    var cacheBuster = Date.now().toString();

    function templateFactoryDecorator($delegate) {
      var fromUrl = angular.bind($delegate, $delegate.fromUrl);
      $delegate.fromUrl = function (url, params) {
        if (url !== null && angular.isDefined(url)) {
          if (typeof url == 'function') {
            url = url.call(url, params);
          }
          if (angular.isString(url)) {
            url += url.indexOf('?') === -1 ? '?' : '&';
            url += 'vcache=' + Date.now().toString();
          }
        }

        return fromUrl(url, params);
      };

      return $delegate;
    }

    $provide.decorator('$templateFactory', [
      '$delegate',
      templateFactoryDecorator,
    ]);
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .constant('config', {
      baseUrl: window.MitfokusConfig.BaseURL,
      Dev: window.MitfokusConfig.Dev,
      sentryUrl: window.MitfokusConfig.SentryUrl,
      baseProfilePictures: window.MitfokusConfig.BaseProfilePictures,
      socketIOUrl: window.MitfokusConfig.SignalRUrl,
      environment: window.MitfokusConfig.Environment,
      migrationUrl: window.MitfokusConfig.MigrationURL,
      loginSsoUrl: window.MitfokusConfig.LoginSsoURL,
      versao: window.MitfokusConfig.Release,
      desenvolvedor: 'D.A.C Sistemas',
    })
    .config([
      'cfpLoadingBarProvider',
      function (cfpLoadingBarProvider) {
        // Disable Spinner on TOP LEFT
        cfpLoadingBarProvider.includeSpinner = false;
      },
    ])
    .config([
      'plUploadServiceProvider',
      function (plUploadServiceProvider) {
        //http://sahusoftcom.github.io/plupload-angular-directive/
        plUploadServiceProvider.setConfig(
          'flashPath',
          './libs/plupload-angular-directive/dist/plupload.flash.swf'
        );
        plUploadServiceProvider.setConfig(
          'silverLightPath',
          './libs/plupload-angular-directive/dist/plupload.silverlight.xap'
        );
        //plUploadServiceProvider.setConfig('uploadPath', 'upload.php');
      },
    ]);
})();

angular
  .module('fokus')
  .directive('pageTitle', pageTitle)
  .directive('icheck', icheck)
  .directive('numbersOnly', numbersOnly)
  .directive('uiSelectNoAnimate', uiSelectNoAnimate)
  .directive('focusOn', focusOn);

pageTitle.$inject = ['$rootScope', '$timeout'];
icheck.$inject = ['$timeout'];
numbersOnly.$inject = [];
uiSelectNoAnimate.$inject = [];

function pageTitle($rootScope, $timeout) {
  return {
    link: function (scope, element) {
      var listener = function (
        event,
        toState,
        toParams,
        fromState,
        fromParams
      ) {
        // Default title
        var title = 'Sistema Fokus';
        // Create your own title pattern
        if (toState.data && toState.data.pageTitle)
          title = 'Sistema Fokus | ' + toState.data.pageTitle;
        $timeout(function () {
          element.text(title);
        });
      };
      $rootScope.$on('$stateChangeStart', listener);
    },
  };
}

function icheck($timeout) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function ($scope, element, $attrs, ngModel) {
      return $timeout(function () {
        var value;
        value = $attrs['value'];

        $scope.$watch($attrs['ngModel'], function (newValue) {
          // eslint-disable-line no-unused-vars
          $(element).iCheck('update');
        });

        return $(element)
          .iCheck({
            checkboxClass: 'icheckbox_square-green',
            radioClass: 'iradio_square-green',
          })
          .on('ifChanged', function (event) {
            if ($(element).attr('type') === 'checkbox' && $attrs['ngModel']) {
              $scope.$apply(function () {
                return ngModel.$setViewValue(event.target.checked);
              });
            }
            if ($(element).attr('type') === 'radio' && $attrs['ngModel']) {
              return $scope.$apply(function () {
                return ngModel.$setViewValue(value);
              });
            }
          });
      });
    },
  };
}

function numbersOnly() {
  return {
    require: 'ngModel',
    link: function (scope, element, attr, ngModelCtrl) {
      function fromUser(text) {
        if (text) {
          var transformedInput = text.replace(/[^0-9]/g, '');

          if (transformedInput !== text) {
            ngModelCtrl.$setViewValue(transformedInput);
            ngModelCtrl.$render();
          }
          return transformedInput;
        }
        return undefined;
      }
      ngModelCtrl.$parsers.push(fromUser);
    },
  };
}

function uiSelectNoAnimate() {
  return {
    restrict: 'A',
    require: 'uiSelect',
    link: (scope, element, attrs, $select) => {
      $select.$animate = null;
    },
  };
}

function focusOn() {
  return function (scope, elem, attr) {
    scope.$on('focusOn', function (e, name) {
      if (name === attr.focusOn) {
        elem[0].focus();
      }
    });
  };
}

(function (undefined) { // eslint-disable-line no-unused-vars
    'use strict';

    var app = angular.module('fokus');

    app.directive('enterTab', function () {
      return function (scope, element, attrs) { // eslint-disable-line no-unused-vars
        element.bind('keydown keypress', function (event) {
          if (event.which === 13) {
            event.preventDefault();
            var fields = $(this).parents('form:eq(0),body').find('input, textarea, select');
            var index = fields.index(this);
            if (index > -1 && (index + 1) < fields.length){
              fields.eq(index + 1).focus();
            }
          }
        });
      };
    });

  })();

(function (undefined) {
  'use strict';

  var app = angular.module('fokus');

  app.directive('brabaoValidator', [
    '$parse',
    'sweetAlert',
    function ($parse, sweetAlert) {
      return {
        priority: 0,
        restrict: 'A',
        require: '?ngSubmit',
        scope: true,
        link: function (scope, element, attrs) {
          var form = $parse(attrs.name)(scope);

          element.off('submit'); //Cancel ng-submit

          //submit
          element.on('submit', function (e) {
            form.$setSubmitted();

            if (form.$invalid) {
              e.preventDefault();

              sweetAlert.swal(
                {
                  title: 'Atenção!',
                  text: 'Verifique o preenchimento de todos os campos.',
                  type: 'warning',
                  showCancelButton: false,
                },
                function () {
                  element.find('.ng-invalid:eq(0)').focus();
                }
              );
            }

            //form.$setPristine();
            scope.$apply(attrs.ngSubmit); //call ng-submit
          });

          //onbeforeunload
          /*
                scope.$watch(function () {
                    window.onbeforeunload = form.$dirty ? function () { return "É possível que as alterações feitas não sejam salvas"; } : null;
                });
                */
        },
      };
    },
  ]);

  app.directive('validateErrorFor', [
    '$parse',
    function ($parse) {
      return {
        restrict: 'A',
        scope: true,
        link: function (scope, element, attrs, ctrl) {
          // eslint-disable-line no-unused-vars
          scope.$watch(
            function () {
              var formElement = element.parents('form:eq(0)');
              var formName = formElement.attr('name');

              var form = $parse(formName)(scope);
              if (!form) {
                return true;
              } //invalid

              var item = $parse(formName + '.' + attrs.validateErrorFor)(scope);
              if (!item) {
                return true;
              } //invalid

              return item.$invalid && (item.$dirty || form.$submitted);
            },
            function (isInvalid) {
              element.toggleClass('has-error', isInvalid);
            }
          );
        },
      };
    },
  ]);

  app.directive('errorMessageFor', [
    '$parse',
    function ($parse) {
      return {
        restrict: 'A',
        templateUrl: '/views/common/directives/errorMessageFor.html',
        scope: true,
        transclude: true,
        link: function (scope, element, attrs, ctrl) {
          // eslint-disable-line no-unused-vars
          scope.$watch(function () {
            var formElement = element.parents('form:eq(0)');
            var formName = formElement.attr('name');
            scope.Form = $parse(formName)(scope);
            scope.Field = $parse(attrs.errorMessageFor)(scope);
          });
        },
      };
    },
  ]);

  app.directive('labelFor', [
    function () {
      return {
        restrict: 'E',
        scope: { name: '@', description: '@' },
        templateUrl: '/views/common/directives/labelFor.html',
        link: function (scope, element, attrs, ctrl) {
          // eslint-disable-line no-unused-vars
          scope.required = attrs.required !== undefined;
        },
      };
    },
  ]);

  app.directive('initFocus', [
    function () {
      return {
        restrict: 'A',
        link: function postLink(scope, element, attrs) {
          if (attrs.myFocus == '') {
            attrs.myFocus = 'focusElement';
          }
          scope.$watch(attrs.myFocus, function (value) {
            if (value == attrs.id) {
              element[0].focus();
            }
          });
          element.on('blur', function () {
            scope[attrs.myFocus] = '';
            scope.$apply();
          });
        },
      };
    },
  ]);

  app.directive('numbersOnly', [
    function () {
      return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {
          function fromUser(text) {
            if (text) {
              var transformedInput = text.replace(/[^0-9]/g, '');

              if (transformedInput !== text) {
                ngModelCtrl.$setViewValue(transformedInput);
                ngModelCtrl.$render();
              }
              return transformedInput;
            }
            return undefined;
          }
          ngModelCtrl.$parsers.push(fromUser);
        },
      };
    },
  ]);

  app.directive('selectOnClick', [
    function () {
      return {
        restrict: 'A',
        link: function (scope, element) {
          var focusedElement;
          element.on('click', function () {
            if (focusedElement != this) {
              this.select();
              focusedElement = this;
            }
          });
          element.on('blur', function () {
            focusedElement = null;
          });
        },
      };
    },
  ]);
})();

(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('fokus');

  var searchFieldTypes = {
    String: 0,
    Integer: 1,
    Date: 2,
    Select: 3,
    CEP: 4,
    CPF: 5,
    CNPJ: 6,
    CPFCNPJ: 7,
    Moeda: 8,
    Mask: 9,
  };

  app.directive('pagination', [
    function () {
      return {
        restrict: 'A',
        scope: { config: '=' },
        controller: [
          '$scope',
          function ($scope) {
            var $public = this;
            var $private = { TableHeadersItens: [] };

            $public.Config = $scope.config;

            $public.RegisterTableHeader = function (tableHeaderItem) {
              if (
                $scope.config.Paginacao.OrderBy === tableHeaderItem.field ||
                $scope.config.Paginacao.OrderBy === '-' + tableHeaderItem.field
              ) {
                tableHeaderItem.active = true;
                tableHeaderItem.OrderType = $scope.config.Paginacao.OrderBy.startsWith(
                  '-'
                )
                  ? 1
                  : 0;
              }

              $private.TableHeadersItens.push(tableHeaderItem);
            };

            $public.Ordenar = function (tableHeaderItem) {
              if (tableHeaderItem) {
                $scope.config.Paginacao.OrderBy =
                  $scope.config.Paginacao.OrderBy === tableHeaderItem.field
                    ? '-' + tableHeaderItem.field
                    : tableHeaderItem.field;

                $private.TableHeadersItens.forEach(function (item) {
                  item.active =
                    $scope.config.Paginacao.OrderBy === item.field ||
                    $scope.config.Paginacao.OrderBy === '-' + item.field;
                  item.OrderType = $scope.config.Paginacao.OrderBy.startsWith(
                    '-'
                  )
                    ? 1
                    : 0;
                });
              }

              $scope.config.Action();
            };
          },
        ],
      };
    },
  ]);

  function MontaDirectivaSortItem(type) {
    return {
      restrict: 'A',
      scope: { field: '@' },
      require: '^pagination',
      transclude: true,
      templateUrl: 'views/shared/directivepagination/SortItem.html',
      link: function (scope, element, attrs, ctrl) {
        scope.type = type;

        scope.Config = ctrl.Config;

        ctrl.RegisterTableHeader(scope);

        scope.Toggle = function () {
          if (scope.Config.Loading) {
            return;
          }

          ctrl.Ordenar(scope);
        };
      },
    };
  }

  app.directive('sortItemNumeric', [
    function () {
      return MontaDirectivaSortItem('numeric');
    },
  ]);
  app.directive('sortItemAlpha', [
    function () {
      return MontaDirectivaSortItem('alpha');
    },
  ]);

  app.directive('paginationInfo', [
    function () {
      return {
        restrict: 'A',
        scope: { totalspan: '@' },
        require: '^pagination',
        templateUrl: 'views/shared/directivepagination/Info.html',
        link: function (scope, element, attrs, ctrl) {
          scope.Config = ctrl.Config;
        },
      };
    },
  ]);

  app.directive('paginationPageItens', [
    function () {
      return {
        restrict: 'A',
        require: '^pagination',
        templateUrl: 'views/shared/directivepagination/PageItens.html',
        link: function (scope, element, attrs, ctrl) {
          scope.Config = ctrl.Config;
          scope.RegistrosPorPagina = [3, 5, 10, 20, 30, 40, 50];
          scope.Buscar = function () {
            scope.Config.Paginacao.Page = 1;
            ctrl.Ordenar();
          };
        },
      };
    },
  ]);

  app.directive('paginationButtons', [
    '$window',
    function ($window) {
      return {
        restrict: 'A',
        scope: { totalspan: '@' },
        require: '^pagination',
        templateUrl: 'views/shared/directivepagination/Buttons.html',
        link: function (scope, element, attrs, ctrl) {
          scope.Config = ctrl.Config;

          var onResize = function () {
            if ($window.innerWidth < 576) {
              scope.Config.PaginationMaxSize = 2;
            } //xs
            if ($window.innerWidth >= 576 && $window.innerWidth < 768) {
              scope.Config.PaginationMaxSize = 6;
            } //sm
            if ($window.innerWidth >= 768) {
              scope.Config.PaginationMaxSize = 10;
            } //md, lg, xl
          };

          angular.element($window).bind('resize', function () {
            scope.$apply(onResize);
          });

          onResize();

          scope.Buscar = function () {
            ctrl.Ordenar();
          };
        },
      };
    },
  ]);

  app.directive('paginationSearch', [
    '$timeout',
    function ($timeout) {
      return {
        restrict: 'AE',
        scope: { totalspan: '@' },
        require: '^pagination',
        templateUrl: 'views/shared/directivepagination/Search.html',
        transclude: true,
        replace: true,
        controller: [
          '$scope',
          function ($scope) {
            var $public = this;

            $scope.searchFields = [];
            $scope.currentItem = undefined;
            $scope.Config = angular.copy($scope.Config);

            $public.PaginationConfig = $scope.$parent.ctrl.PaginationConfig;

            $public.Register = function (searchField, defaultField) {
              // eslint-disable-line no-unused-vars
              $scope.searchFields.push(searchField);
              //if(defaultField) { $scope.currentItem = searchField; }
              if (
                $scope.$parent.ctrl.PaginationConfig.Paginacao.SearchField ===
                searchField.field
              ) {
                $scope.currentItem = searchField;
              }
            };

            $public.LimparSearchItem = function () {
              $scope.$parent.ctrl.PaginationConfig.Paginacao.Search = undefined;
            };
          },
        ],
        link: function (scope, element, attrs, ctrl) {
          scope.Config = ctrl.Config;
          scope.searchFieldTypes = searchFieldTypes;

          var _timeout;

          scope.onSearchFieldChange = function (searchItem) {
            if (searchItem === scope.currentItem) {
              return;
            }

            scope.Config.Paginacao.Search = undefined;

            scope.currentItem = searchItem;
            scope.Config.Paginacao.SearchField = searchItem.field;
            if (
              scope.Config.Paginacao.Search &&
              scope.Config.Paginacao.Search.length
            ) {
              scope.Buscar();
            }
          };

          scope.Buscar = function () {
            if (_timeout) {
              $timeout.cancel(_timeout);
            }

            _timeout = $timeout(function () {
              scope.isDatepickerOpen = false;
              ctrl.Ordenar();

              _timeout = null;
            }, 1000);
          };
        },
      };
    },
  ]);

  function MontaDirectivaSearchField(type) {
    return {
      restrict: 'AE',
      require: '^paginationSearch',
      controller: [
        '$scope',
        function ($scope) {
          var $public = this;
          $scope.searchItem = { options: [] };

          $public.UpdateOptions = function (values) {
            $scope.searchItem.options.push(values);
          };
        },
      ],
      link: function (scope, element, attrs, ctrl) {
        scope.searchItem.field = attrs.field;
        scope.searchItem.name = attrs.name;
        scope.searchItem.type = type;

        if (scope.searchItem.type === searchFieldTypes.Date) {
          scope.searchItem.isDatepickerOpen = false;
          scope.searchItem.datepickerOptions = {
            showWeeks: false,
            minMode: attrs.mode || 'day',
          };
          scope.searchItem.datepickerFormat = attrs.format || 'dd/MM/yyyy';
        }

        if (scope.searchItem.type === searchFieldTypes.Mask) {
          scope.searchItem.mask = attrs.mask;
        }

        if (!attrs.name) {
          scope.searchItem.name = scope.searchItem.field;
        }

        var defaultItem = true; //scope.$parent.Config.Paginacao === scope.searchItem.field;

        ctrl.Register(scope.searchItem, defaultItem);
      },
    };
  }

  app.directive('searchField', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.String);
    },
  ]);
  app.directive('searchFieldString', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.String);
    },
  ]);
  app.directive('searchFieldInteger', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Integer);
    },
  ]);
  app.directive('searchFieldDate', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Date);
    },
  ]);
  app.directive('searchFieldSelect', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Select);
    },
  ]);
  app.directive('searchFieldCep', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.CEP);
    },
  ]);
  app.directive('searchFieldCpf', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.CPF);
    },
  ]);
  app.directive('searchFieldCnpj', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.CNPJ);
    },
  ]);
  app.directive('searchFieldCpfCnpj', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.CPFCNPJ);
    },
  ]);
  app.directive('searchFieldMoeda', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Moeda);
    },
  ]);
  app.directive('searchFieldMask', [
    function () {
      return MontaDirectivaSearchField(searchFieldTypes.Mask);
    },
  ]);

  app.directive('searchFieldSelectOption', [
    function () {
      return {
        restrict: 'AE',
        require: '^searchFieldSelect',
        link: function (scope, element, attrs, ctrl) {
          var searchItem = { Name: attrs.name, Value: attrs.value };
          ctrl.UpdateOptions(searchItem);
        },
      };
    },
  ]);

  app.directive('searchFieldSelectEnum', [
    function () {
      return {
        restrict: 'AE',
        require: '^paginationSearch',
        scope: { enum: '=' },
        link: function (scope, element, attrs, ctrl) {
          var searchItem = {
            field: attrs.field,
            name: attrs.name,
            type: searchFieldTypes.Select,
            options: [],
          };

          var _name = attrs.label || 'Name';
          var _value = attrs.value || 'Value';

          //Iniciar os itens
          angular.forEach(scope.enum, function (item) {
            searchItem.options.push({ Name: item[_name], Value: item[_value] });
          });

          //Itens rest
          scope.$watch('enum', function () {
            searchItem.options = [];

            // var resultSearch = undefined;

            angular.forEach(scope.enum, function (item) {
              // if (ctrl.PaginationConfig.Search && item[_value] === ctrl.PaginationConfig.Search) {
              //   resultSearch = item[_value];
              // }
              searchItem.options.push({
                Name: item[_name],
                Value: item[_value],
              });
            });

            // if (!resultSearch) { ctrl.LimparSearchItem(); }
          });

          if (!attrs.name) {
            searchItem.name = searchItem.field;
          }

          ctrl.Register(searchItem);
        },
      };
    },
  ]);
})();

(function (undefined) {
  'use strict';

  var app = angular.module('fokus');

  app.directive('scrollDetector', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var raw = element[0];
        element.bind('scroll', function () {
          if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
            scope.$apply(attrs.scrollDetector);
          }
        });
      },
    };
  });
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('controleNFSEsCtrl', controleNFSEsCtrl)
    .controller('modalVisualizarCertificadosCtrl', modalVisualizarCertificadosCtrl)
    .controller('modalPesquisarNFSesCtrl', modalPesquisarNFSesCtrl)
    .controller('modalHistoricoDeStatusCtrl', modalHistoricoDeStatusCtrl)

  modalVisualizarCertificadosCtrl.$inject = [
    'ListaDeCertificados',
    '$uibModalInstance'
  ]

  modalPesquisarNFSesCtrl.$inject = [
    'ListaDeNFSes',
    '$uibModalInstance',
    '$uibModal',
    '$q',
    'ControleNFSEsAPI',
  ]

  modalHistoricoDeStatusCtrl.$inject = [
    'HistoricoDeStatus',
    '$uibModalInstance',
    '$uibModal',
    '$q',
    'NFSeAPI',
    'GetControleData',
    'sweetAlert',
    'AuditoriaFactory'
  ]

  controleNFSEsCtrl.$inject = [
    '$q',
    '$scope',
    '$uibModal',
    'ControleNFSEsAPI',
    'AuditoriaFactory',
    'SocketIO',
    'certificadoAPI',
    '$localStorage',
    'ControleNFSEsFactory'
  ];

  function modalPesquisarNFSesCtrl(
    ListaDeNFSes,
    $uibModalInstance,
    $uibModal,
    $q,
    ControleNFSEsAPI
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModalPesquisaNFSes = function () {
      $uibModalInstance.dismiss('cancel');
    }

    $public.PesquisarNota = function() {
      if(!$public.InputPesquisaNFSe) {
        $public.NotasFiltradas = [];
        return;
      } else {
        const input = $public.InputPesquisaNFSe.toUpperCase();

        const notasFiltradas = $public.ListaDeNFSes.Todas.filter(nota => {
          return (
            nota.NumeroDaNota?.toString().includes(input)
            || nota.Empresa?.includes(input)
            || nota.Tomador?.includes(input)
          )
        })

        $public.NotasFiltradas = notasFiltradas
      }
    }

    $public.abrirModalHistoricoDeStatus = function(nota) {
      const modalHistorico = $uibModal.open({
        templateUrl:
        '/views/controleNFSEs/modals/modalHistoricoDeStatus.html',
        controller: 'modalHistoricoDeStatusCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          HistoricoDeStatus: function () {
            var deferred = $q.defer();

            ControleNFSEsAPI
            .GetHistoricoDeStatus(nota.NFSeID)
            .then(function(response) {
              deferred.resolve({
                Info: nota,
                ListaDeStatus: response.data.Registros.reverse()
              })
            })
            .catch(function (response) {
              console.log(response)
            })

            return deferred.promise;
          },
        },
      });

      modalHistorico.result.then(function () {

      });
    }

    $public.init = function() {
      $public.InputPesquisaNFSe = null;
      $public.NFSePesquisada = null;
      $public.NotasFiltradas = []
      $public.ListaDeNFSes = ListaDeNFSes
      console.log('ListaDeNFSes Dentro do modal: ', $public.ListaDeNFSes)
    };

    $public.init()
  }

  function modalVisualizarCertificadosCtrl(
    ListaDeCertificados,
    $uibModalInstance,
    ) {
    var $public = this;
    var $private = {};

    $public.FecharModalCertificados = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $public.init = function () {
      $public.Certificados = ListaDeCertificados
    }

    $public.init();
  }

  function modalHistoricoDeStatusCtrl(
    HistoricoDeStatus,
    $uibModalInstance,
    $uibModal,
    $q,
    NFSeAPI,
    GetControleData,
    sweetAlert,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModalHistoricoDeStatus = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $public.abrirModalLogsDeRejeicao = function (NFSeID) {
      $uibModal.open({
        templateUrl:
          '/views/operacional/nfseManutencao/modals/modalLogsDeRejeicao.html',
        controller: 'modalLogsDeRejeicaoDaNFSeCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          Motivos: function () {
            var deferred = $q.defer();

            NFSeAPI.GetLogDeErros(NFSeID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });
    };

    $public.AbrirPopUpAlterarStatus = function (NFSe) {
      const option = {
        // 1: 'Enviada para Prefeitura',
        // 2: 'Aguardando Autorização',
        // 3: 'Autorizada',
        4: 'Rejeitada',
        5: 'Cancelada na Prefeitura',
        // 6: 'Apagada',
        // 7: 'Importada via XML',
        // 8: 'Aguardando assinatura',
        // 10: 'Recebida',
      };

      // if (NFSe.StatusID == 4) {
      //   delete option['3'];
      //   delete option['5'];
      // }

      sweetAlert.swal
        .fire({
          title: 'Nova Situação para o NFS-e',
          input: 'select',
          inputOptions: option,
          inputPlaceholder: 'Selecione uma nova Situação para o NFS-e',
          showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve();
              } else {
                resolve('Você precisa escolher uma nova situação.');
              }
            });
          },
        })
        .then(function (result) {
          if (result.value) {
            NFSeAPI.PostAddNFSeStatus(NFSe, result.value)
              .then(function (response) {
                sweetAlert.swal.fire({
                  title: 'Atualizada!',
                  type: 'success',
                  html: 'Situação da NFS-e atualizada com sucesso',
                });

                /**** Auditoria *****/
                /*************/
                AuditoriaFactory.CriarAuditoria(
                  'Acesso ao modal: Detalhes de notas fiscais e histórico de status',
                  'principal.controleNFSEs',
                  'Alterou o status da nota fiscal que estava processando',
                  null,
                  null,
                  null
                );
              })
              .then(function () {
                $public.GetControleData()
              })
              .catch(function () {
                $public.GetControleData()
              });
          }
        });
    };

    $public.init = function () {
      $public.HistoricoDeStatus = HistoricoDeStatus
      $public.GetControleData = GetControleData
      console.log('$public.HistoricoDeStatus:', HistoricoDeStatus)
    }

    $public.init();
  }

  function controleNFSEsCtrl(
    $q,
    $scope,
    $uibModal,
    ControleNFSEsAPI,
    AuditoriaFactory,
    SocketIO,
    certificadoAPI,
    $localStorage,
    ControleNFSEsFactory
  ) {
    var $public = this;
    var $private = {};

    $private.SetIntervalRefresh = function() {
      $public.getDataByInterval();
      $public.Timer = setInterval(() => {
        $public.DateNow = new Date();
        $public.getDataByInterval();
        console.log('$public.Report', $public.Report)
      }, $public.TimerSelecionado.Segundos * 1000);
    }

    $public.RefreshContador = function() {
      $localStorage['controle_timer_selecionado'] = $public.TimerSelecionado;

      if($public.Timer !== null) {
        clearInterval($public.Timer)
      }

      if($public.TimerSelecionado && $public.TimerSelecionado.Segundos !== null) {
        $private.SetIntervalRefresh();
      } else if($public.Timer !== null && $public.TimerSelecionado.Segundos === null) {
        clearInterval($public.Timer)
      }
    }

    $public.getDataByInterval = function () {
      $public.SelectDate($public.Report.DiaSelecionado);
      $public.GetTomadores();
      $public.GetCertificados();
    }

    $public.SocketIOListen = function () {
      SocketIO.on('ControleNFSEs', function (vm) {
        // $public.UpdateDataControleNFSEs(vm);
        $public.UpdateFromSocketIO();
      });
      SocketIO.on('ControleDeCertificados', function (vm) {
        // $public.UpdateDataControleDeCertificados(vm);
        $public.UpdateFromSocketIO();
      });
      SocketIO.on('ControleDeTomadores', function (vm) {
        // $public.UpdateDataControleDeTomadores(vm);
        $public.UpdateFromSocketIO();
      });

      $scope.$on('$destroy', function () {
        SocketIO.removeListener('ControleNFSEs');
        SocketIO.removeListener('ControleDeCertificados');
        SocketIO.removeListener('ControleDeTomadores');
      });
    };

    $public.UpdateFromSocketIO = function() {
      if($public.TimerSelecionado && $public.TimerSelecionado.Segundos === null) {
        $public.GetControleData();
      }
    }

    $public.UpdateDataControleNFSEs = function(vm) {
      // TODO - criar update personalizado
    }

    $public.UpdateDataControleDeCertificados = function(vm) {
      // TODO - criar update personalizado
    }

    $public.UpdateDataControleDeTomadores = function(vm) {
      // TODO - criar update personalizado
    }

    $public.abrirModalVisualizarCertificados = function () {

      if(!$public.AlgumModalAberto) {
        $public.AlgumModalAberto = true
        console.log('Modal visualizar certificados aberto? ', $public.AlgumModalAberto)

        $uibModal.open({
          templateUrl:
            '/views/controleNFSEs/modals/modalVisualizarCertificados.html',
          controller: 'modalVisualizarCertificadosCtrl',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            ListaDeCertificados: function() {
              $public.GetCertificados();
              return $public.CertificadosAVencer;
            }
          }
        })
        .closed
        .then(function() {
          $public.AlgumModalAberto = false;
          console.log('Modal visualizar certificados aberto? ', $public.AlgumModalAberto)
        });
      }
    }

    $public.abrirModalPesquisarNFSes = function () {
      if(!$public.AlgumModalAberto) {

        $public.AlgumModalAberto = true
        console.log('Modal pesquisa de notas aberto? ', $public.AlgumModalAberto)

        $uibModal.open({
          templateUrl:
          '/views/controleNFSEs/modals/modalPesquisarNFSes.html',
          controller: 'modalPesquisarNFSesCtrl',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            ListaDeNFSes: function () {
              $public.GetControleData();
              return $public.NotasSeparadasPorStatus
            },
          },
        })
        .closed
        .then(function() {
          $public.AlgumModalAberto = false;
          console.log('Modal pesquisa de notas aberto? ', $public.AlgumModalAberto)
        });
      }
    }

    $public.abrirModalHistoricoDeStatus = function(nota) {
      if(!$public.AlgumModalAberto) {

        $public.AlgumModalAberto = true
        console.log('Modal historico status aberto? ', $public.AlgumModalAberto)

        $uibModal.open({
          templateUrl:
          '/views/controleNFSEs/modals/modalHistoricoDeStatus.html',
          controller: 'modalHistoricoDeStatusCtrl',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            HistoricoDeStatus: function () {
              var deferred = $q.defer();

              ControleNFSEsAPI
              .GetHistoricoDeStatus(nota.NFSeID)
              .then(function(response) {
                deferred.resolve({
                  Info: nota,
                  ListaDeStatus: response.data.Registros.reverse()
                })
              })
              .catch(function (response) {
                console.log(response)
              })

              return deferred.promise;
            },
            GetControleData: $public.GetControleData
          },
        })
        .closed
        .then(function() {
          $public.AlgumModalAberto = false;
          console.log('Modal historico status aberto? ', $public.AlgumModalAberto)
        });
      }
    }

    $public.GetCertificados = function() {
      certificadoAPI
      .GetExpirando()
      .then(function(response) {
        const certificadosLimpos = response.data.filter(certificado => certificado.Empresa);
        console.log(certificadosLimpos)

        $public.CertificadosAVencer = certificadosLimpos;
      })
      .catch(function (response) {
        console.log(response)
      })
    }

    $public.GetControleData = function () {
      var deferred = $q.defer();

      ControleNFSEsAPI
      .GetNotas(
        $public.Report.DateInit,
        $public.Report.DateEnd
      )
      .then(function(response) {
        const data = response.data;

        const notasSeparadasPorStatus = data.Registros.reduce((prev, nota) => {
          switch(nota.StatusID) {
            case 3:
              prev.Autorizadas.push(nota);
              break;
            case 4:
              prev.Rejeitadas.push(nota);
              break;
            case 5:
              prev.Canceladas.push(nota);
              break;
            case 11:
              prev.Processando.push(nota);
              break;
            case 9:
              prev.Geradas.push(nota);
              prev.Outras.push(nota);
              break;
            default:
              prev.Outras.push(nota);
              break;
          }

          return prev;
        }, {
          Autorizadas: [],
          Canceladas: [],
          Rejeitadas: [],
          Processando: [],
          Geradas: [],
          Outras: [],
        })

        notasSeparadasPorStatus.Todas = data.Registros;

        deferred.resolve($public.NotasSeparadasPorStatus = notasSeparadasPorStatus);

        console.log("$public.NotasSeparadasPorStatus", $public.NotasSeparadasPorStatus)
      })
      .catch(function (response) {
        console.log(response)
        deferred.resolve(undefined);
      })
    }

    $public.GetTomadores = function () {
      ControleNFSEsAPI
      .GetTomadores(
        $public.Report.DateInit,
        $public.Report.DateEnd,
        $public.PaginationConfig.Paginacao
        )
      .then(function(response) {
        const data = response.data;

        console.log('Retorno API: Tomadores: ', data)

        $public.Tomadores = data.Registros;
        $public.PaginationConfig.Paginacao.OrderBy = data.OrderBy;
        $public.PaginationConfig.Paginacao.Page = data.Page;
        $public.PaginationConfig.Paginacao.TotalRegistros =
          data.TotalRegistros;
        $public.PaginationConfig.Paginacao.Max = data.Max;
        $public.PaginationConfig.Loading = false;

        console.log('$public.Tomadores', $public.Tomadores)
      })
      .catch(function (response) {
        $public.PaginationConfig.Loading = false;
        console.log(response)
      })
    }

    $public.SelectDate = function (dia) {
      const date = $public.DateNow;
      $public.Report.DiaSelecionado = dia;

      if(!$public.Report.Dates) {
        $public.Report.Dates = {
          Hoje: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
          Ontem: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
        }
      }

      switch(dia) {
        case 'hoje':
          $public.Report.DateInit = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          $public.Report.DateEnd = date;
          break;

        case 'ontem':
          $public.Report.DateInit = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 0, 0, 0);
          $public.Report.DateEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 23, 59, 59);
          break;

        default:
          $public.Report.DiaSelecionado = 'hoje';
          $public.Report.DateInit = new Date(date.getFullYear(), date.getMonth(), date.getDate()),
          $public.Report.DateEnd = date;
          break;
      }
      $public.GetControleData();
    }

    $private.Init = function () {
      $public.SocketIOListen();
      $public.CertificadosAVencer = [];
      $public.NotasSeparadasPorStatus = {};
      $private.Consts = ControleNFSEsFactory;
      $public.DateNow = new Date();
      $public.AlgumModalAberto = false;

      $public.Report = {}
      $public.SelectDate('hoje');

      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Nome',
          OrderBy: '-Nome',
          Max: 5,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $public.GetTomadores,
      };

      const timerSelecionadoStorage = $localStorage['controle_timer_selecionado'];
      console.log('timerSelecionadoStorage', timerSelecionadoStorage);

      $public.GetControleData();
      $public.GetTomadores();
      $public.GetCertificados();
      $public.ListaDeTimers = $private.Consts.TempoDeAtualizacao;
      $public.TimerSelecionado = timerSelecionadoStorage ? timerSelecionadoStorage : $public.ListaDeTimers[1];
      $public.Timer = $public.RefreshContador();
      $public.StatusIDs = $private.Consts.StatusIDs;


      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao menu: Acompanhamentos de NFSEs',
        'principal.controleNFSEs',
        'Acessou o menu de Acompanhamento de NFSE',
        null,
        null,
        null
      );
    };

    $private.Init();
  }
})();

(function () {
    'use strict';

    angular
      .module('fokus')
      .controller('dashBoardProcessamentosCtrl', dashBoardProcessamentosCtrl);

      dashBoardProcessamentosCtrl.$inject = [
      '$q',
      '$uibModal',
      'dashboardAPI',
      'SharedDataFactory',
    ];


    function dashBoardProcessamentosCtrl(
      $q,
      $uibModal,
      dashboardAPI,
      SharedDataFactory
    ) {
      var $public = this;
      var $private = {};

      $public.GetDashboardData = function () {
        var deferred = $q.defer();

        let clienteID = $public.ClienteSelecionado || 0;

        var date = new Date();

        var periodo = new Date($public.Periodo);
        var year = periodo.getFullYear() || date.getFullYear();
        var month = periodo.getMonth() + 1 || date.getMonth() + 1;

        if (clienteID !== 0) {
          clienteID = $public.ClienteSelecionado.ClienteID;

          dashboardAPI
            .Get(clienteID, month, year)
            .then(function (response) {
              deferred.resolve(($public.Counter = response.data));
            })
            .catch(function () {
              deferred.resolve(undefined);
            });
        } else {
          $public.Counter = undefined;
        }

        // console.log(clienteID)

        return deferred.promise;
      };

      $private.Init = function () {

        $public.PaginationConfig = {
          Loading: false,
          Paginacao: {
            Search: '',
            SearchField: 'Nome',
            OrderBy: '-ClienteID',
            Max: 3,
            Page: 1,
            TotalRegistros: 0,
            NumPages: undefined,
          },
          Action: $public.GetDashboardData,
        };
        $public.GetDashboardData();

        $public.Config = SharedDataFactory.Get().Configuracoes;
      };

      $private.Init();
    }


  })();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('ImportacaoDeTomadoresCtrl', ImportacaoDeTomadoresCtrl);

  ImportacaoDeTomadoresCtrl.$inject = [
    '$q',
    '$uibModal',
    'dashboardAPI',
    'SharedDataFactory',
  ];

  function ImportacaoDeTomadoresCtrl(
    $q,
    $uibModal,
    dashboardAPI,
    SharedDataFactory
  ) {
    var $public = this;
    var $private = {};

    $public.GetDashboardData = function () {
      var deferred = $q.defer();

      let clienteID = $public.ClienteSelecionado || 0;

      var date = new Date();

      var periodo = new Date($public.Periodo);
      var year = periodo.getFullYear() || date.getFullYear();
      var month = periodo.getMonth() + 1 || date.getMonth() + 1;

      if (clienteID !== 0) {
        clienteID = $public.ClienteSelecionado.ClienteID;

        dashboardAPI
          .Get(clienteID, month, year)
          .then(function (response) {
            deferred.resolve(($public.Counter = response.data));
          })
          .catch(function () {
            deferred.resolve(undefined);
          });
      } else {
        $public.Counter = undefined;
      }

      // console.log(clienteID)

      return deferred.promise;
    };

    $private.Init = function () {
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Nome',
          OrderBy: '-ClienteID',
          Max: 3,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $public.GetDashboardData,
      };
      $public.GetDashboardData();

      $public.Config = SharedDataFactory.Get().Configuracoes;
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular.module('fokus').controller('bottomFormCtrl', bottomFormCtrl);

  bottomFormCtrl.$inject = ['config'];

  function bottomFormCtrl(config) {
    var $public = this;
    $public.config = config;
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('navigationCtrl', navigationCtrl)
    .controller('modalMeuGestorCtrl', modalMeuGestorCtrl)
    .controller('modalAvisoCtrl', modalAvisoCtrl);

  navigationCtrl.$inject = [
    '$q',
    'usuarioAPI',
    'clientesAPI',
    '$state',
    '$uibModal',
    'config',
    '$scope',
    'AuthFactory',
    'toastr',
    'gestorAPI',
    '$localStorage',
    '$cookies',
  ];
  modalMeuGestorCtrl.$inject = [
    '$uibModalInstance',
    'config',
    'usuarioSelecionado',
  ];
  modalAvisoCtrl.$inject = ['$uibModalInstance', '$localStorage'];

  function navigationCtrl(
    $q,
    usuarioAPI,
    clientesAPI,
    $state,
    $uibModal,
    config,
    $scope,
    AuthFactory,
    toastr,
    gestorAPI,
    $localStorage,
    $cookies
  ) {
    var $public = this;
    var $private = {};
    usuarioAPI.Get().then(function (response) {
      $scope.dados = response.data;
    });

    //FUNCTION WORDS COUNTER
    $public.wordCounter = function () {
      if ($public.NFSe.DescricaodaNFSe.split(' ').length > 1000) {
        $public.NFSe.DescricaodaNFSe = $public.NFSe.DescricaodaNFSe.split(' ')
          .slice(0, 1000)
          .join(' ');
      }
    };

    $public.abrirModalEnotas = function () {
      $uibModal.open({
        templateUrl: '/views/configuracoes/enotas.html',
        controller: 'modalEnotasCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          Clientes: function () {
            var deferred = $q.defer();
            clientesAPI
              .GetCompleto()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });
            return deferred.promise;
          },
        },
      });
    };

    //MODAL Meu Gesto
    $public.abrirModalMeuGestor = function () {
      $uibModal.open({
        templateUrl: '/views/pessoal/modals/modalMeuGestor.html',
        controller: 'modalMeuGestorCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'xs',
        resolve: {
          usuarioSelecionado: function () {
            var deferred = $q.defer();
            gestorAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                toastr.warning(
                  'Você ainda não possuí um <strong>Gestor</strong>. Para maiores detalhes entre em contato com a Mitfokus',
                  'Gestor',
                  {
                    autoDismiss: true,
                    allowHtml: true,
                    preventDuplicates: true,
                    closeButton: true,
                    progressBar: true,
                    timeOut: 4000,
                  }
                );
                deferred.reject(undefined);
              });

            return deferred.promise;
          },
        },
      });
    };

    $public.TrocarDeMenu = $state.TrocarDeMenu;

    $private.Init = function () {
      var cookiesAll = document.cookie.split(';');

      var idToken = '';

      //console.log('cookies',test2.split('='));
      cookiesAll.forEach((element) => {
        var key = element.split('=');
        if (key[0].split('.').pop() === 'accessToken') {
          idToken = key[1];
          //console.log('idToken',key[1]);
        }
      });
      var tokens = { accessToken: idToken, expires_in: new Date() };

      var storageData = tokens;

      if (!storageData) {
        storageData = $localStorage['authToken'];
      }

      var read = storageData.ReadPopUpAlert;

      if (localStorage['msgVersionDate'] !== new Date().toLocaleString().split(',')[0]) {
        localStorage['msgVersionDate'] = new Date().toLocaleString().split(',')[0];
        setTimeout(function () {
          $uibModal.open({
            templateUrl: '/views/operacional/modals/modalAnnounce.html',
            controller: 'modalAvisoCtrl',
            controllerAs: 'ctrl',
            backdrop: 'static',
            size: 'md',
          });
        }, 500);
      }

      $public.caminhoURL = config.baseProfilePictures;
      $public.HasPermission = AuthFactory.HasPermission;
      $public.MenuRecolhido = false;

      usuarioAPI.Get().then(function (response) {
        $public.usuario = response.data;
        $scope.dados = response.data;
      });
    };

    $private.Init();
  }

  function modalMeuGestorCtrl($uibModalInstance, config, usuarioSelecionado) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

   
    

    $private.Init = function () {
      $public.Title = 'Meu Gestor';
      $public.usuario = usuarioSelecionado;
      $public.caminhoURL = config.baseProfilePictures;
      console.log(usuarioSelecionado);
    };

    $private.Init();
  }

  function modalAvisoCtrl($uibModalInstance, $localStorage) {
    var $public = this;

    var cookiesAll = document.cookie.split(';');
    var idToken = '';

    //console.log('cookies',test2.split('='));
    cookiesAll.forEach((element) => {
      var key = element.split('=');
      if (key[0].split('.').pop() === 'accessToken') {
        idToken = key[1];
        //console.log('idToken',key[1]);
      }
    });

    var tokens = {
      accessToken: idToken,
      expires_in: new Date(),
      Token: idToken,
    };

    var storageData = tokens;

    if (!storageData) {
      storageData = $localStorage['authToken'];
    }

    var read = storageData.ReadPopUpAlert;

    $public.FecharModalDirect2 = function () {
      window.location.href = 'https://www.mitnotas.mitfokus.com.br/';
    };

    $public.FecharModal = function () {
      storageData.ReadPopUpAlert = true;
      $uibModalInstance.dismiss('Cancel');
    };
  }
})();

(function () {
  'use strict';

  angular.module('fokus').controller('topFormCtrl', topFormCtrl);

  topFormCtrl.$inject = [
    '$q',
    '$scope',
    '$uibModal',
    'AuthFactory',
    '$state',
    'toastr',
    'config',
    'ngNotify',
    'usuarioAPI',
    'gestorAPI',
    'SocketIO',
    '$cookies',
  ];

  function topFormCtrl(
    $q,
    $scope,
    $uibModal,
    AuthFactory,
    $state,
    toastr,
    config,
    ngNotify,
    usuarioAPI,
    gestorAPI,
    SocketIO,
    $cookies
  ) {
    var $public = this;
    var $private = {};
    $public.ssoAccess = false;

    if ('https://www.login.mitfokus.com.br/' === document.referrer) {
      $public.ssoAccess = true;
    }

    var playAuthorizedSound = function () {
      var audioElement = document.createElement('audio');
      audioElement.setAttribute('src', '/sounds/alert.mp3');
      audioElement.play();
    };

    var playRejectedSound = function () {
      var audioElement = document.createElement('audio');
      audioElement.setAttribute('src', '/sounds/reject.mp3');
      audioElement.play();
    };

    SocketIO.on('NFSeStatusNotification', function (vm) {
      console.log(vm);

      if (
        vm.type === 'NFS-e' &&
        (vm.status.toUpperCase() === 'AUTORIZADA' ||
          vm.status.toUpperCase() === 'CONCLUIDO')
      ) {
        playAuthorizedSound();

        toastr.success(
          `<font style="font-size:14px;">A <strong>NFS-e</strong> de número <strong>${vm.numero}</strong> foi autorizada.</font>`,
          'NFS-e Autorizada',
          {
            autoDismiss: true,
            allowHtml: true,
            preventDuplicates: false,
            closeButton: true,
            progressBar: true,
            timeOut: 3500,
          }
        );
      }

      if (
        vm.type === 'NFS-e' &&
        (vm.status.toUpperCase() === 'NEGADA' ||
          vm.status.toUpperCase() === 'REJEITADA' ||
          vm.status.toUpperCase() === 'REJEITADO')
      ) {
        playRejectedSound();
        toastr.error(
          '<font style="font-size:14px;">A <strong>NFS-e</strong> foi rejeitada pela prefeitura.</font>',
          'NFS-e Rejeitada',
          {
            autoDismiss: true,
            allowHtml: true,
            preventDuplicates: true,
            closeButton: true,
            progressBar: true,
            timeOut: 3500,
          }
        );
      }

      if (
        vm.type === 'NFS-e' &&
        (vm.status.toUpperCase() === 'CANCELADA' ||
          vm.status.toUpperCase() === 'CANCELADO')
      ) {
        playAuthorizedSound();
        toastr.success(
          '<font style="font-size:14px;">A <strong>NFS-e</strong> foi cancelada na prefeitura.</font>',
          'NFS-e Cancelada',
          {
            autoDismiss: true,
            allowHtml: true,
            preventDuplicates: true,
            closeButton: true,
            progressBar: true,
            timeOut: 3500,
          }
        );
      }

      if (
        vm.type === 'NFS-e' &&
        vm.status.toUpperCase() === 'CancelamentoNegado'
      ) {
        playRejectedSound();
        toastr.error(
          `<font style="font-size:14px;">Pedido de cancelamento <strong>recusado</strong>.<br>Entre em contato com a Mitfokus.</font>`,
          'Cancelamento de NFS-e',
          {
            autoDismiss: true,
            allowHtml: true,
            preventDuplicates: false,
            closeButton: true,
            progressBar: true,
            timeOut: 3500,
          }
        );
      }
    });

    SocketIO.on('MITUpdateStatusNotification', function (vm) {
      console.log(vm);

      if (vm.StatusCode === 200) {
        playAuthorizedSound();

        toastr.success(
          `<font style="font-size:14px;">O <strong>Arquivo</strong> de importação de tomadores está em processamento.</font>

          <br>Total: <strong>${vm.LineNumbers} registros.</strong>
          <br>Válidos: <strong>${vm.ValidLineNumbers} registros.</strong>
          <br>Inválidos: <strong>${vm.InValidLineNumbers} registros</strong>`,
          'Importação de Tomadores',
          {
            autoDismiss: true,
            allowHtml: true,
            preventDuplicates: false,
            closeButton: true,
            progressBar: true,
            timeOut: 3500,
          }
        );
      }

      if (vm.StatusCode === 400) {
        playRejectedSound();
        toastr.error(
          '<font style="font-size:14px;">O <strong>Arquivo</strong> de importação não foi aceita.</font>',
          'Importação de Tomadores',
          {
            autoDismiss: true,
            allowHtml: true,
            preventDuplicates: true,
            closeButton: true,
            progressBar: true,
            timeOut: 3500,
          }
        );
      }
    });

    SocketIO.on('ReportNotification', function (vm) {
      console.log(vm);

      playAuthorizedSound();
      toastr.success(
        '<font style="font-size:14px;">O seu <strong>Relatório</strong> já se encontra disponível para Download.</font>',
        'Meus Relatórios',
        {
          autoDismiss: true,
          allowHtml: true,
          preventDuplicates: true,
          closeButton: true,
          progressBar: true,
          timeOut: 5500,
        }
      );
    });

    SocketIO.on('MITUploadXMLNFSeStatusNotification', function (vm) {
      console.log(vm);

      playAuthorizedSound();
      toastr.info(
        `<font style="font-size:14px;">
          Empresa: <strong>${vm.company}</strong></font>
          <br>Arquivo: <strong>${vm.fileName}</strong>
          <br>Registros: <strong>${vm.totalNFSes}</strong>
          <br>NFS-e importadas: <strong>${vm.registeredNFSes}</strong>
          <br>Tomadores importados: <strong>${vm.registeredTomadores}</strong>`,
        'Importação de NFS-e',
        {
          autoDismiss: false,
          allowHtml: true,
          preventDuplicates: false,
          closeButton: true,
          progressBar: true,
          timeOut: 120000,
        }
      );
    });

    SocketIO.on('MITUploadCSVClientesStatusNotification', function (vm) {
      console.log(vm);

      playAuthorizedSound();
      toastr.info(
        `<font style="font-size:14px;">
          <br>Total de Registros:  <strong>${vm.registeredClients}</strong>
          <br>Empresas importadas: <strong>${vm.success}</strong>`,
        'Importação de Clientes',
        {
          autoDismiss: false,
          allowHtml: true,
          preventDuplicates: false,
          closeButton: true,
          progressBar: true,
          timeOut: 120000,
        }
      );
    });

    $scope.$on('$destroy', function () {
      SocketIO.removeListener('NFSeStatusNotification');
      SocketIO.removeListener('MITUpdateStatusNotification');
      SocketIO.removeListener('ReportNotification');
      SocketIO.removeListener('MITUploadXMLNFSeStatusNotification');
      SocketIO.removeListener('MITUploadCSVClientesStatusNotification');
    });

    $public.logout = function () {
      //ngNotify.set('A sessão foi encerrada.', {
      //  type: 'info',
      //  position: 'top',
      //  html: false,
      //});
      //AuthFactory.Logout();
      window.location.href = window.MitfokusConfig.LogoutSSO;
    };

    $public.backSSO = function () {
      window.location.href = 'https://www.login.mitfokus.com.br/';
    };

    //MODAL Meu Gesto
    $public.abrirModalMeuGestor = function () {
      $uibModal.open({
        templateUrl: '/views/pessoal/modals/modalMeuGestor.html',
        controller: 'modalMeuGestorCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'xs',
        resolve: {
          usuarioSelecionado: function () {
            var deferred = $q.defer();
            gestorAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                toastr.warning(
                  'Você ainda não possuí um <strong>Gestor</strong>. Para maiores detalhes entre em contato com a Mitfokus',
                  'Gestor',
                  {
                    autoDismiss: true,
                    allowHtml: true,
                    preventDuplicates: true,
                    closeButton: true,
                    progressBar: true,
                    timeOut: 4000,
                  }
                );
                deferred.reject(undefined);
              });

            return deferred.promise;
          },
        },
      });
    };

    $private.Init = function () {
      $scope.classe = 'fa fa-calendar fa-lg';
      $public.config = config;
      $public.HasPermission = AuthFactory.HasPermission;
      $public.temCookieAuth = false;

      usuarioAPI.Get().then(function (response) {
        $public.usuario = response.data;
        $scope.dados = response.data;
      });

      //var cookie = $cookies.getObject('mf_sso');
      //if (cookie) {
        $public.temCookieAuth = true;
      //}
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('ajustesCtrl', ajustesCtrl)

  ajustesCtrl.$inject = [
    '$q',
    'sweetAlert',
    'configuracoesAPI',
    'EnumFactory',
  ]

  function ajustesCtrl(
    $q,
    sweetAlert,
    configuracoesAPI,
    EnumFactory,
  ) {
    var $public = this;
    var $private = {};

    $public.GetConfiguracoesNFSECampinas = function () {
      var deferred = $q.defer();

      configuracoesAPI
        .GetConfiguracaoCampinas()
        .then(function (response) {
          deferred.resolve((
            $public.ConfiguracoesCampinas = response.data
          ));
        })
        .catch(function () {
          deferred.resolve(undefined);
        });

      return deferred.promise;
    },

      $public.GetConfiguracoesDoXML = function () {
        var deferred = $q.defer();

        configuracoesAPI
          .GetConfiguracoesXML()
          .then(function (response) {
            deferred.resolve((
              $public.ConfiguracoesdoXML = response.data
            ));
          })
          .catch(function () {
            deferred.resolve(undefined);
          });

        return deferred.promise;
      },

      $public.GetConfiguracoesImpostosFederais = function () {
        var deferred = $q.defer();

        configuracoesAPI
          .GetConfiguracoesImpostosFederais()
          .then(function (response) {
            deferred.resolve((
              $public.ConfiguracoesImpostosFederais = response.data
            ));
          })
          .catch(function () {
            deferred.resolve(undefined);
          });

        return deferred.promise;
      },

      $public.Enviar = function () {
        var result = {
          OnSuccess: function () {
            sweetAlert.swal('Dados alterados com sucesso!', '', 'success');
          },
          OnError: function (response) {
            switch (response.status) {
              case 401:
                sweetAlert.swal({
                  title: 'Atenção',
                  type: 'error',
                  html:
                    'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                    response.data.Message +
                    '</font>',
                });
                break;

              case 404:
                sweetAlert.swal(
                  'Atenção',
                  'A configuração não foi encontrada, verifique!!!',
                  'error'
                );
                break;

              case 417:
                sweetAlert.swal({
                  title: 'Atenção',
                  type: 'error',
                  html:
                    'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                    response.data.ExceptionMessage +
                    '</font>',
                });
                break;

              default:
                sweetAlert.swal(
                  'Atenção',
                  'Verifique o preenchimento do formulário',
                  'warning'
                );
                break;
            }
          },
        };

        console.log($public.ConfiguracoesdoXML);
        configuracoesAPI
          .PutConfigNFSeCampinas($public.ConfiguracoesCampinas)
          .then(
            configuracoesAPI
              .PutConfigPadraoXML($public.ConfiguracoesdoXML)
              .then(
                configuracoesAPI.PutConfigImpostosFederais(
                  $public.ConfiguracoesImpostosFederais
                )
              )
              .then(result.OnSuccess)
          )
          .catch(result.OnError);
      };

    $private.Init = function () {
      $public.ConfiguracoesCampinas = null;
      $public.ConfiguracoesdoXML = null;
      $public.ConfiguracoesImpostosFederais = null;
      $public.Enuns = EnumFactory;

      $public.GetConfiguracoesNFSECampinas()
      $public.GetConfiguracoesDoXML()
      $public.GetConfiguracoesImpostosFederais()
    };

    $private.Init();
  }

})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('dashBoardCtrl', dashBoardCtrl)
    .controller(
      'modalListaDeCertificadosExpirandoCtrl',
      modalListaDeCertificadosExpirandoCtrl
    );

  dashBoardCtrl.$inject = [
    '$q',
    'AuthFactory',
    '$uibModal',
    'dashboardAPI',
    'clientesAPI',
    'SharedDataFactory',
    'AuditoriaFactory',
    'DashboardFactory',
  ];

  modalListaDeCertificadosExpirandoCtrl.$inject = [
    '$uibModalInstance',
    'ListaDeCertificados',
  ];

  function dashBoardCtrl(
    $q,
    AuthFactory,
    $uibModal,
    dashboardAPI,
    clientesAPI,
    SharedDataFactory,
    AuditoriaFactory,
    DashboardFactory
  ) {
    var $public = this;
    var $private = {};

    if(!AuthFactory.Payload.Roles.includes("ExibirDashboard")){
      return window.location.href = "/#!/operacional/nfse/emitir";
    }
    
  


    $private.GetClientes = function () {
      var deferred = $q.defer();

      clientesAPI
        .Get()
        .then(function (response) {
          const selectedClientCache = localStorage.getItem('@dashboard-selected-client')
          const clients = response.data.Registros.reverse();

          if(selectedClientCache) {
            const clientJSON = JSON.parse(selectedClientCache)
            const hasSelectedClient = clients.find(client => client.ClienteID === clientJSON.ClienteID)

            if(hasSelectedClient) {
              $public.ClienteSelecionado = clientJSON;
            } else {
              $public.ClienteSelecionado = clients[0];
            }

          } else {
            $public.ClienteSelecionado = clients[0];
          }

          $public.GetDashboardData();

          deferred.resolve(($public.Clientes = clients));
        })
        .catch(function () {
          deferred.resolve(undefined);
        });

      return deferred.promise;
    };

    $private.CheckCertificatesExpiration = function () {
      var perm = $q(function (resolve, reject) {
        var result = AuthFactory.HasPermission(['PermiteVisualizarCertEmVenc']);
        return resolve(result);
      });

      if (perm) {
        dashboardAPI.ExpiringCertificates().then(function (response) {
          if (response.data.length > 100) {
            $uibModal.open({
              templateUrl:
                '/views/dashboard/modals/modalCertificadosExpirando.html',
              controller: 'modalListaDeCertificadosExpirandoCtrl',
              controllerAs: 'ctrl',
              backdrop: 'static',
              size: 'lg',
              resolve: {
                ListaDeCertificados: function () {
                  return response.data;
                },
              },
            });
          }
        });
      }
    };

    $public.LiberarPesquisa = function () {
      $public.TeveAlteracaoNaPesquisa = true;
    }

    $private.TravarPesquisa = function () {
      $public.TeveAlteracaoNaPesquisa = false;
    }

    $public.GetDashboardData = function () {
      var deferred = $q.defer();

      localStorage.setItem('@dashboard-selected-client', JSON.stringify($public.ClienteSelecionado))

      let clienteID = $public.ClienteSelecionado.ClienteID;
      var date = $public.dateNow;

      $public.ErroBackend = false;

      $public.Faturamento = $public.Faturamento || null;
      $public.ComplementoNotasNoPeriodo = $public.ComplementoNotasNoPeriodo || {};
      $public.Charts = $public.Charts || {};
      $public.Economia = $public.Economia || {};

      $public.Report.dateInit = new Date(
        $public.Report.dateInit.getFullYear(),
        $public.Report.dateInit.getMonth(),
        $public.Report.dateInit.getDate()
      );

      $public.Report.dateEnd = new Date(
        $public.Report.dateEnd.getFullYear(),
        $public.Report.dateEnd.getMonth(),
        $public.Report.dateEnd.getDate()
      );

      const mesInicial =
        $public.Constants.MesesCompletos[$public.Report.dateInit.getMonth()];

      const mesFinal =
        $public.Constants.MesesCompletos[$public.Report.dateEnd.getMonth()];

      const dataSubtitulo =
      mesInicial === mesFinal
        ? mesInicial
        : mesInicial + ' - ' + mesFinal;

      $public.Report.SomenteNFSeRecebidas = $public.SomenteNFSeRecebidas;

      if (clienteID !== 0) {
        $public.CarregandoCharts = true

        /**
         *
         * Carregar informações do Cliente
         *
         */
        dashboardAPI
        .GetClienteInfo(clienteID)
        .then(function(resolve) {
          console.log(resolve.data)
          deferred.resolve((
            $public.Cliente = resolve.data
          ))

          if(!$public.Cliente.PossuiFiliais) {
            $public.Consolidado = false;
          }

          // /**
          //  *
          //  * Carregando informações dos gráficos
          //  *
          //  */

          dashboardAPI
          .GetChartData({
            EmpresaID: clienteID,
            DataInicial: $public.Report.dateInit,
            DataFinal: $public.Report.dateEnd,
            PorCompetencia: $public.PorCompetencia,
            Consolidado: $public.Consolidado
          })
          .then(function(response) {
            $public.ErroBackend = false
            const data = response.data

            /**
             *
             * Mapeamento do response para os dados para renderizar
             * os gráficos.
             *
             */

            const notasNoPeriodoSerie = data.charts.invoices.map(invoice => ({
              name: invoice.label,
              type: 'column',
              data: [invoice.amount],
            }))

            const impostosNoPeriodoSerie = data.charts.taxes.taxList.map(tax => ({
              name: tax.label,
              type: 'column',
              data: [tax.value],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return (
                    'R$ ' + $public.Constants.FormatarDinheiroSemCents(this.y)
                  );
                },
              },
            }))

            const faturamentoAnualSerie = (seriesConfig = []) => {
              const renders = seriesConfig.map(serie => {
                return {
                  type: serie.type,
                  name: '',
                  showInLegend: false,
                  data: [...data.charts.anualRevenue.ammounts].reverse(),
                  dataLabels: {
                    enabled: serie.enableDataLabel,
                    formatter: function () {
                      return (
                        'R$ ' +
                        $public.Constants.FormatarDinheiroSemCents(this.y)
                      );
                    },
                  },
                }
              })

              return renders
            };

            const economiaSerie = [{
              type: 'pie',
              name: 'Economia de',
              data: [
                {
                  name: '',
                  y: data.charts.saving.percent,
                  selected: false
                },
                {
                  name: '',
                  y: 100 - data.charts.saving.percent,
                  selected: false
                },
              ],
              innerSize: '85%',
            }];

            /**
             *
             * Renderizando os gráficos à partir da Factory RenderChart
             *
             */

            $public.Charts.NotasNoPeriodo = $public.Constants.RenderChart({
              title: 'Notas fiscais no período',
              xAxisVisible: false,
              subtitle: dataSubtitulo,
              series: notasNoPeriodoSerie,
            });

            $public.Charts.ImpostosNoPeriodo = $public.Constants.RenderChart({
              xAxisVisible: false,
              subtitle: dataSubtitulo,
              title: 'Impostos no período',
              series: impostosNoPeriodoSerie,
              currency: true,
            });

            $public.ComplementoNotasNoPeriodo = {
              TotalValor: data.charts.taxes.total.amount.toLocaleString('pt-BR', { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' }),
              TotalAliquota: data.charts.taxes.total.aliquot.toLocaleString('pt-BR') + '%',
            };

            $public.Charts.FaturamentoAnual = $public.Constants.RenderChart({
              categories: [...data.charts.anualRevenue.mounths].reverse(),
              title: 'Faturamento Anual',
              currency: true,
              subtitle: '',
              series: faturamentoAnualSerie([
                {type: 'column', enableDataLabel:false},
                {type: 'spline', enableDataLabel: true}
              ]),
            });

            $public.Faturamento = data.generalAmount.revenue;
            $public.TotalDeImpostos = data.charts.taxes.total.amount;

            $public.Economia = {
              EconomiaTotal: data.charts.saving.value,
              PercentualTotal: data.charts.saving.percent / 100,
              PercentualTotalTexto: data.charts.saving.percent.toLocaleString('pt-BR') + '%',
              Pos: data.generalAmount.posConsultancy,
              Pre: data.generalAmount.preConsultancy
            }

            $public.Charts.Economia = $public.Constants.RenderChart({
              series: economiaSerie,
              chart: {
                height: 250,
              },
            });

            $public.CarregandoCharts = false
          })
          .catch(function(response) {
            deferred.resolve(undefined);

            $public.Charts.ImpostosNoPeriodo = null;
            $public.Charts.NotasNoPeriodo = null;
            $public.Charts.FaturamentoAnual = null;
            $public.Charts.Economia = null;

            $public.ComplementoNotasNoPeriodo = null;
            $public.Economia = null;
            $public.Faturamento = null;

            $public.CarregandoCharts = false
            $public.ErroBackend = true
          });
        })

      } else {
        $public.Counter = undefined;
      }

      $private.TravarPesquisa()

      return deferred.promise;
    };

    $private.HighchartInitConfig = function () {
      Highcharts.setOptions({
        lang: {
          decimalPoint: '\u002c',
          thousandsSep: '.',
        },
      });
    };

    $private.Init = function () {
      $private.HighchartInitConfig();

      $private.GetClientes();

      $public.PorCompetencia = false;
      $public.Consolidado = false;
      $public.Constants = DashboardFactory;
      $public.Charts = {};
      $public.Report = {};
      $public.Faturamento = null;
      $public.ComplementoNotasNoPeriodo = {};
      $public.dateNow = new Date();
      $public.TeveAlteracaoNaPesquisa = false;

      $public.Report.dateInit = new Date(
        $public.dateNow.getFullYear(),
        $public.dateNow.getMonth(),
        1
      );
      $public.Report.dateEnd = new Date();

      $public.certificados = [];

      $public.Periodo = new Date();
      $private.CheckCertificatesExpiration();

      $public.CarregandoCharts = false
      $public.ErroBackend = false

      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Nome',
          OrderBy: '-ClienteID',
          Max: 3,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $private.GetClientes,
      };

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao menu: Dashboard',
        'principal.dashboard',
        'Acessou o menu de Dashboard',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

  function modalListaDeCertificadosExpirandoCtrl(
    $uibModalInstance,
    ListaDeCertificados
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $private.Init = function () {
      $public.Title = 'Certificados com Validade Próxima';
      $public.certificados = ListaDeCertificados;
    };

    $private.Init();
  }


})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('loginCtrl', loginCtrl)
    .controller('modalRecuperarSenhaloginCtrl', modalRecuperarSenhaloginCtrl);

  loginCtrl.$inject = [
    '$scope',
    '$state',
    '$window',
    'focus',
    'config',
    'AuthFactory',
    'ngNotify',
    '$uibModal',
    'payloadAPI'
  ];
  modalRecuperarSenhaloginCtrl.$inject = [
    'sweetAlert',
    '$uibModalInstance',
    'usuarioAPI',
  ];

  function loginCtrl(
    $scope,
    $state,
    $window,
    focus,
    config,
    AuthFactory,
    ngNotify,
    $uibModal,
    payloadAPI
  ) {
    var $public = this;
    var $private = {};
    if (AuthFactory.isAutenticated) {
      AuthFactory.Logout();
    }
    $public.viewCookieMenu = true;
    // if(document.cookie.includes('mf_sso_hide_menu=true')){
    //   document.cookie = 'mf_sso_hide_menu=false';
    //   location.reload();
    // } else {
      $public.viewCookieMenu = false;
    //}
    
    window.location.href = config.loginSsoUrl;

    $public.logIn = function () {
      $public.loading = true;
      $scope.login_form.$setPristine();

      var vm = angular.copy($public.login);
      vm.grand_type = 'password';
      // METODO DE LOGIN BUTTON
      AuthFactory.Login(vm)
        .then(function () {
          ngNotify.set('Login realizado com sucesso.', {
            type: 'success',
            theme: 'success',
            position: 'top',
            html: false,
          })

          payloadAPI
          .Get()
          .then(function(response) {
            const roles = response.data[0].Roles

            if(roles.includes('ExibirDashboard')) {
              $state.go('dashboard');
            } else {
              $state.go('emissor')
            }
          })

        })
        .catch(function (err) {
          $public.login.Password = undefined;
          console.log(err);
          switch (err.status) {
            case 400: {
              if (err.data.error_description === 'Internal Server Error.') {
                ngNotify.set(
                  'Estamos sofrendo com problemas técnicos, por favor tente mais tarde.',
                  {
                    type: 'error',
                    theme: 'error',
                    position: 'top',
                    html: false,
                  }
                );
              } else {
                console.log(
                  'estou no primeiro else: %s',
                  err.data.error_description
                );
                if (err.data.error === 'not_activated') {
                  ngNotify.set(
                    'Seu login não está ativo, entre em contato com a <strong>MITFOKUS</strong>.',
                    {
                      type: 'grimace',
                      theme: 'grimace',
                      position: 'top',
                      html: true,
                    }
                  );
                } else {
                  ngNotify.set('Usuário ou senha inválida.', {
                    type: 'error',
                    theme: 'error',
                    position: 'top',
                    html: false,
                  });
                }
              }

              break;
            }
            case 409: {
              console.log('409 AQUI');
              localStorage.setItem('migrated','true');
              $window.location.href = config.loginSsoUrl
              break;
            }
            // Migração usuário já migrado retorno 405 api Redirect para login SSO
            case 405: {
              console.log('405 AQUI');
              localStorage.setItem('migrated','true');
              $window.location.href = config.loginSsoUrl
              break;
            }

            default:
              ngNotify.set(
                'Estamos sofrendo com problemas técnicos, por favor tente mais tarde.',
                {
                  type: 'grimace',
                  theme: 'grimace',
                  position: 'top',
                  html: false,
                }
              );
              break;
          }
        })
        .finally(function () {
          $public.loading = false;
        });
    };

    $public.abrirModalRecuperarSenha = function () {
      var modalInstance = $uibModal.open({
        templateUrl: '/views/common/modals/login/recuperarsenha.html',
        controller: 'modalRecuperarSenhaloginCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        keyboard: false,
      });

      var onSuccess = function () {};

      var onCancel = function () {};
      modalInstance.result.then(onSuccess, onCancel);
    };

    $private.Init = function () {
      //      angular.element(".inmodal").hide();
      focus('focusMe');
      $public.buildVersion = config.versao;
      $public.loading = false;
      $public.migrated = true;      
      if (localStorage.migrated){
        if (localStorage.migrated == 'true') {
          window.location.href = config.loginSsoUrl;
        } else {
          $public.migrated = false;
        }
      } else {
        $public.migrated = false;
      }
    };

    $private.Init();
  }

  function modalRecuperarSenhaloginCtrl(
    sweetAlert,
    $uibModalInstance,
    usuarioAPI
  ) {
    var $public = this;
    var $private = {};

    $public.recuperarSenha = function () {
      sweetAlert.swal.queue([
        {
          title: 'Recuperar Senha',
          html:
            'Deseja enviar um e-mail recuperação para <strong><u>' +
            $public.email +
            '</u></strong>?',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#5cb85c',
          confirmButtonText: 'Sim, enviar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return usuarioAPI
              .RecuperarSenha($public.email)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'E-mail de recuperação enviado!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $uibModalInstance.dismiss('Success');
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'O E-mail informado não pertence a nenhum usuário.',
                      type: 'warning',
                    });
                    break;

                  case 503:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'O servidor de recuperação está fora do ar.',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao enviar o e-mail de confirmação.',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $private.Init = function () {
      $('#signup_form_Email').focus();
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular.module('fokus').controller('recoveryCtrl', recoveryCtrl);

  recoveryCtrl.$inject = [
    '$state',
    '$stateParams',
    'sweetAlert',
    'usuarioAPI',
    'AuthFactory',
    'ngNotify',
  ];

  function recoveryCtrl(
    $state,
    $stateparams,
    sweetAlert,
    usuarioAPI,
    AuthFactory,
    ngNotify
  ) {
    var $public = this;
    var $private = {};
    $public.usuario = {};

    $public.AlterarSenha = function () {
      sweetAlert.swal.queue([
        {
          title: 'Alterar Senha',
          html: 'Deseja realmente alterar a senha?',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#5cb85c',
          confirmButtonText: 'Sim, alterar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return usuarioAPI
              .AlterarSenha($private.Token, $public.usuario)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Senha alterada com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $state.go('login');
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'O E-mail informado não pertence a nenhum usuário.',
                      type: 'warning',
                    });
                    break;

                  case 503:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'O servidor de alteração está fora do ar.',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao tentar alterar a senha.',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.ValidarPadraoSenha = function (senha) {
      if (!senha) {
        return false;
      }

      return senha.length > 5;
    };

    $public.CompararSenhas = function (senha, confirmacaoSenha) {
      if (!confirmacaoSenha || senha !== confirmacaoSenha) {
        return false;
      }
      return true;
    };

    $private.Init = function () {
      AuthFactory.Logout();
      //$rootScope.$state.go('error');

      $private.Token = $stateparams.token;

      usuarioAPI
        .CheckValidToken($private.Token)
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
          ngNotify.set(
            'O link de recuperação é inválido, realize uma nova recuperação de senha.',
            {
              type: 'warn',
              theme: 'warn',
              position: 'top',
              html: false,
              duration: 7000,
            }
          );
          $state.go('login');
        });
    };

    $private.Init();
  }

})();

(function () {
  'use strict';

  angular.module('fokus').controller('logoutCtrl', logoutCtrl);

  logoutCtrl.$inject = ['$state', 'AuthFactory'];

  function logoutCtrl($state, AuthFactory) {
    var $private = {};

    $private.Init = function () {
      if ($state.current.url != '/login') {
        $state.go('login');
        AuthFactory.Logout();
      }
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('distribuicaoLucrosPorSocioCtrl', distribuicaoLucrosPorSocioCtrl)

  distribuicaoLucrosPorSocioCtrl.$inject = [
    '$scope',
    '$window',
    '$q',
    'config',
    'usuarioAPI',
    'sweetAlert',
    'relatoriosAPI',
    'NFSeAPI',
    'AuditoriaFactory',
    'clientesAPI'
  ]

  function distribuicaoLucrosPorSocioCtrl(
    $scope,
    $window,
    $q,
    config,
    usuarioAPI,
    sweetAlert,
    relatoriosAPI,
    NFSeAPI,
    AuditoriaFactory,
    clientesAPI
  ) {
    var $public = this;
    var $private = {};

    $scope.log = '';
    $scope.viewby = 2;

    $private.GetClientes = function () {
      var deferred = $q.defer();
      clientesAPI
        .GetCompleto()
        .then(function (response) {
          deferred.resolve(($public.Clientes = response.data));
        })
        .catch(function () {
          deferred.resolve(undefined);
        });

      return deferred.promise;
    }

    $public.Enviar = function () {
      $public.LimparForm();
      var date = new Date();

      var periodoInicial = new Date($public.PeriodoInicial);
      var periodoFinal = new Date($public.PeriodoFinal);

      var dayInicial = periodoInicial.getDate() || date.getDate();
      var yearInicial = periodoInicial.getFullYear() || date.getFullYear();
      var monthInicial = periodoInicial.getMonth() + 1 || date.getMonth() + 1;

      var dayFinal = periodoFinal.getDate() || date.getDate();
      var yearFinal = periodoFinal.getFullYear() || date.getFullYear();
      var monthFinal = periodoFinal.getMonth() + 1 || date.getMonth() + 1;

      var reportFilterDays = periodoFinal.getTime() - periodoInicial.getTime();

      if (reportFilterDays / (1000 * 3600 * 24) < 60) {
        sweetAlert.swal.queue([
          {
            title: 'Atenção!',
            html: 'O período informado é inferior a <strong>3 MESES</strong>, deseja realmente continuar com a apuração?',
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#14B0A5',
            confirmButtonText: 'Sim, continuar!',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            preConfirm: function () {
              $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

              if ($public.SomenteNFSeRecebidas === undefined) {
                $public.SomenteNFSeRecebidas = angular.copy(
                  ($public.SomenteNFSeRecebidas = false)
                );
              }

              return relatoriosAPI
                .GetRelatorioDeDistribuicao(
                  $public.cliente.ClienteID,
                  dayInicial,
                  monthInicial,
                  yearInicial,
                  dayFinal,
                  monthFinal,
                  yearFinal,
                  $public.medico.MedicoID,
                  $public.SomenteNFSeRecebidas,
                  $public.PorCompetencia
                )
                .then(function (response) {
                  $public.report = response.data;

                  $public.valorApuradoMedicoSelecionado =
                    $public.report.Rateio.Medicos.find(
                      (medico) => medico.MedicoID == $public.medico.MedicoID
                    );

                  // Soma da Despesas Extras
                  var TotalDasDespesasExtras = 0;
                  $public.valorApuradoMedicoSelecionado.DespesasExtras.map(
                    (item) => {
                      TotalDasDespesasExtras += item.Valor;
                    }
                  );

                  $public.TotalDasDespesasExtras = TotalDasDespesasExtras;

                  var NFSeEmitidasDoMedico = angular.copy(
                    $public.report.NFSeEmitidas.filter(
                      (medico) => medico.MedicoID == $public.medico.MedicoID
                    )
                  );
                  $public.NFSeEmitidasDoMedico = NFSeEmitidasDoMedico;

                  var totalImpostosRetidos = 0;
                  var totalImpostosAPagar = 0;

                  NFSeEmitidasDoMedico.map((nfse) => {
                    totalImpostosRetidos +=
                      nfse.ValorDoIR +
                      nfse.ValorDoCSLL +
                      nfse.ValorDoPIS +
                      nfse.ValorDoCOFINS +
                      nfse.ISSQNDevido;

                    totalImpostosAPagar +=
                      nfse.ImpostosAPagar.IRPJaPagar +
                      nfse.ImpostosAPagar.AdicionalRIPJaPagar +
                      nfse.ImpostosAPagar.CSLLaPagar +
                      nfse.ImpostosAPagar.PISaPagar +
                      nfse.ImpostosAPagar.COFINSaPagar +
                      nfse.ImpostosAPagar.ISSaPagar;
                  });

                  //var saldoARestituir = response.data.ImpostosARestituir.CreditoDeIRPJ;

                  var TotalFaturadoDoMedico =
                    $public.valorApuradoMedicoSelecionado.Faturamento -
                    TotalDasDespesasExtras;

                  $public.SaldoADistribuir =
                    TotalFaturadoDoMedico -
                    (totalImpostosAPagar + totalImpostosRetidos);

                  $public.TotalDeImpostosAPagar =
                    totalImpostosAPagar + totalImpostosRetidos;

                  if (!$public.permissoesDoUsuario.TodosOsMedicNoRelatDistrib) {
                    $public.report.NFSeEmitidas = angular.copy(
                      $public.report.NFSeEmitidas.filter(
                        (medico) => medico.MedicoID == $public.medico.MedicoID
                      )
                    );
                  }

                  $public.viewby = '3';
                  $scope.totalItems = $public.report.NFSeEmitidas.length;
                  $scope.totalItemsNFSeEmitidasDoMedico =
                    $public.NFSeEmitidasDoMedico.length;
                  $scope.totalDespesasExtras =
                    $public.valorApuradoMedicoSelecionado.DespesasExtras.length;
                  $scope.currentPage = 1;
                  $scope.itemsPerPage = $scope.viewby;
                  $scope.maxSize = 10; //Number of pager buttons to show
                  $scope.sortType = 'DataDeCadastro';
                  $scope.sortReverse = false;
                })
                .catch(function (err) {
                  switch (err.status) {
                    case 400:
                      sweetAlert.swal({
                        title: 'Atenção!',
                        type: 'warning',
                        html: 'Empresa não optante do Lucro Presumido!<br><font size=3 color=#B22222>Para continuar é necessário alterar o regime dentro do cadastro do cliente.</font>',
                      });
                      break;

                    case 404:
                      sweetAlert.swal({
                        title: 'Atenção!',
                        text: 'A Empresa não possuí Faturamento no período informado!',
                        type: 'warning',
                      });
                  }
                });
            },
          },
        ]);
      } else {
        if ($public.SomenteNFSeRecebidas === undefined) {
          $public.SomenteNFSeRecebidas = angular.copy(
            ($public.SomenteNFSeRecebidas = false)
          );
        }

        relatoriosAPI
          .GetRelatorioDeDistribuicao(
            $public.cliente.ClienteID,
            dayInicial,
            monthInicial,
            yearInicial,
            dayFinal,
            monthFinal,
            yearFinal,
            $public.medico.MedicoID,
            $public.SomenteNFSeRecebidas,
            $public.PorCompetencia
          )
          .then(function (response) {
            $public.report = response.data;

            $public.valorApuradoMedicoSelecionado =
              $public.report.Rateio.Medicos.find(
                (medico) => medico.MedicoID == $public.medico.MedicoID
              );

            // Soma da Despesas Extras
            var TotalDasDespesasExtras = 0;
            $public.valorApuradoMedicoSelecionado.DespesasExtras.map((item) => {
              TotalDasDespesasExtras += item.Valor;
            });

            $public.TotalDasDespesasExtras = TotalDasDespesasExtras;

            var NFSeEmitidasDoMedico = angular.copy(
              $public.report.NFSeEmitidas.filter(
                (medico) => medico.MedicoID == $public.medico.MedicoID
              )
            );
            $public.NFSeEmitidasDoMedico = NFSeEmitidasDoMedico;

            var totalImpostosRetidos = 0;
            var totalImpostosAPagar = 0;

            NFSeEmitidasDoMedico.map((nfse) => {
              totalImpostosRetidos +=
                nfse.ValorDoIR +
                nfse.ValorDoCSLL +
                nfse.ValorDoPIS +
                nfse.ValorDoCOFINS +
                nfse.ISSQNDevido;

              totalImpostosAPagar +=
                nfse.ImpostosAPagar.IRPJaPagar +
                nfse.ImpostosAPagar.AdicionalRIPJaPagar +
                nfse.ImpostosAPagar.CSLLaPagar +
                nfse.ImpostosAPagar.PISaPagar +
                nfse.ImpostosAPagar.COFINSaPagar +
                nfse.ImpostosAPagar.ISSaPagar;
            });

            var saldoARestituir =
              response.data.ImpostosARestituir.CreditoDeIRPJ;

            var TotalFaturadoDoMedico =
              $public.valorApuradoMedicoSelecionado.Faturamento +
              saldoARestituir -
              TotalDasDespesasExtras;
            $public.SaldoADistribuir =
              TotalFaturadoDoMedico -
              (totalImpostosAPagar + totalImpostosRetidos);

            if (!$public.permissoesDoUsuario.TodosOsMedicNoRelatDistrib) {
              $public.report.NFSeEmitidas = angular.copy(
                $public.report.NFSeEmitidas.filter(
                  (medico) => medico.MedicoID == $public.medico.MedicoID
                )
              );
            }

            $public.viewby = '3';
            $scope.totalItems = $public.report.NFSeEmitidas.length;
            $scope.totalItemsNFSeEmitidasDoMedico =
              $public.NFSeEmitidasDoMedico.length;
            $scope.totalDespesasExtras =
              $public.valorApuradoMedicoSelecionado.DespesasExtras.length;
            $scope.currentPage = 1;
            $scope.itemsPerPage = $scope.viewby;
            $scope.maxSize = 10; //Number of pager buttons to show
            $scope.sortType = 'DataDeCadastro';
            $scope.sortReverse = false;
          })
          .catch(function (err) {
            console.log(err);
            switch (err.status) {
              case 400:
                sweetAlert.swal({
                  title: 'Atenção!',
                  type: 'warning',
                  html: 'Empresa não optante do Lucro Presumido!<br><font size=3 color=#B22222>Para continuar é necessário alterar o regime dentro do cadastro do cliente.</font>',
                });
                break;

              case 404:
                sweetAlert.swal({
                  title: 'Atenção!',
                  text: 'A Empresa não possuí Faturamento no período informado!',
                  type: 'warning',
                });
            }
          });
      }
    };

    $public.VoltarFiltro = function () {
      $public.report = undefined;
    };

    $public.VerDetalhes = function (URL) {
      var filename = URL;
      $window.open(config.baseUrl + '/files/csv/' + filename);
    };

    $public.VerDetalhesUnificado = function () {
      var date = new Date();

      var periodoInicial = new Date($public.PeriodoInicial);
      var periodoFinal = new Date($public.PeriodoFinal);

      var dayInicial = periodoInicial.getDate() || date.getDate();
      var yearInicial = periodoInicial.getFullYear() || date.getFullYear();
      var monthInicial = periodoInicial.getMonth() + 1 || date.getMonth() + 1;

      var dayFinal = periodoFinal.getDate() || date.getDate();
      var yearFinal = periodoFinal.getFullYear() || date.getFullYear();
      var monthFinal = periodoFinal.getMonth() + 1 || date.getMonth() + 1;

      var reportFilterDays = periodoFinal.getTime() - periodoInicial.getTime();

      relatoriosAPI
        .GetCSVFileDeDistribuicaoUnificado(
          $public.cliente.ClienteID,
          dayInicial,
          monthInicial,
          yearInicial,
          dayFinal,
          monthFinal,
          yearFinal,
          $public.SomenteNFSeRecebidas,
          $public.PorCompetencia
        )
        .then(function (response) {
          $public.VerDetalhes(response.data.File.URL);
        });
    };

    $public.LimparForm = function () {
      $public.report = undefined;
      $public.valorApuradoMedicoSelecionado = undefined;
    };

    $public.VerificaSeOClienteEhLucroPresumido = function () {
      if ($public.cliente.RegimeID != 2) {
        sweetAlert.swal({
          title: 'Atenção',
          type: 'error',
          html: 'Empresa não optante do Lucro Presumido!<br><font size=3 color=#B22222></font>',
        });
        $public.cliente = undefined;
        $public.medico = undefined;
        return;
      }

      $public.medico = undefined;
    };

    $public.VisualizarRPS = function (NFSe) {
      sweetAlert.swal.queue([
        {
          title: 'Visualizar RPS',
          html:
            'Deseja visualizar o <strong>RPS</strong> da NFS-e de número: <strong><u>' +
            NFSe.NumeroDaNota +
            '</u></strong>?',
          showCancelButton: true,
          confirmButtonColor: '#262763',
          confirmButtonText: 'Sim, Gerar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return $scope.$apply(function () {
              NFSeAPI.GetRPS(NFSe.NFSeID).then(function (response) {
                var filename = response.data;
                $window.open(config.baseUrl + '/files/pdf/' + filename);
                //   setTimeout(function(){ NFSeAPI.DeleteRPSPDFFile(filename) }, 5000);
              });
            });
          },
        },
      ]);
    };

    $public.setPage = function (pageNo) {
      $scope.currentPage = pageNo;
    };

    $public.pageChanged = function () {
      // console.log("Page changed to: " + $scope.currentPage);
    };

    $public.setItemsPerPage = function (num) {
      $scope.itemsPerPage = num;
      $scope.currentPage = 1; //reset to first page
    };


    $private.Init = function () {
      usuarioAPI.Get().then(function (response) {
        $public.permissoesDoUsuario = response.data.Configuracoes;
      });

      $public.Title = 'Relatório de Distribuição de Lucros por Sócio';
      $public.Clientes = null;
      $private.GetClientes();

      var date = new Date();
      $public.PeriodoInicial = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        1
      );
      $public.PeriodoFinal = new Date(date.getFullYear(), date.getMonth(), 0);
      $public.PorCompetencia = false;
      $public.SomenteNFSeRecebidas = false;
      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao modal: Lucros por Sócio',
        'modal.relatorio.lucrosporsocio',
        'Acessou o modal de Lucros por Sócio',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('meusrelatoriosCtrl', meusrelatoriosCtrl)
    .controller(
      'modalGerarTxtFaturamentoQuestorCtrl',
      modalGerarTxtFaturamentoQuestorCtrl
    )
    .controller(
      'modalGerarTxtFaturamentoDominioCtrl',
      modalGerarTxtFaturamentoDominioCtrl
    )
    .controller('modalGerarMITPdfCtrl', modalGerarMITPdfCtrl);

  meusrelatoriosCtrl.$inject = [
    '$scope',
    '$window',
    '$http',
    '$q',
    'config',
    '$uibModal',
    'meusRelatoriosAPI',
    'sweetAlert',
    'SharedDataFactory',
    'clientesAPI',
    'toastr',
    'SocketIO',
    'AuditoriaFactory',
  ];
  modalGerarTxtFaturamentoQuestorCtrl.$inject = [
    '$scope',
    'sweetAlert',
    'config',
    '$uibModalInstance',
    'Clientes',
    'meusRelatoriosAPI',
    'EnumFactory',
  ];
  modalGerarTxtFaturamentoDominioCtrl.$inject = [
    '$scope',
    'sweetAlert',
    'config',
    '$uibModalInstance',
    'Clientes',
    'meusRelatoriosAPI',
  ];
  modalGerarMITPdfCtrl.$inject = [
    '$scope',
    'sweetAlert',
    'config',
    '$uibModalInstance',
    'Clientes',
    'meusRelatoriosAPI',
    'EnumFactory',
  ];

  function meusrelatoriosCtrl(
    $scope,
    $window,
    $http,
    $q,
    config,
    $uibModal,
    meusRelatoriosAPI,
    sweetAlert,
    SharedDataFactory,
    clientesAPI,
    toastr,
    SocketIO,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};

    $public.GetMeusRelatorios = function () {
      meusRelatoriosAPI
        .Get($public.PaginationConfig.Paginacao)
        .then(function (response) {
          response.data.Registros.forEach((element) => {
            element.DataInicial = new Date(element.DataInicial);
            element.DataFinal = new Date(element.DataFinal);
          });

          console.log(response.data.Registros);

          $public.MeusRelatorios = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    $public.AbrirModalGerarTxtFaturamentoQuestor = function () {
      var modalGerarTxtFaturamentoQuestorinstance = $uibModal.open({
        templateUrl:
          '/views/meusrelatorios/modals/modalGerarTxtFaturamentoQuestor.html',
        controller: 'modalGerarTxtFaturamentoQuestorCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          Clientes: function () {
            var deferred = $q.defer();
            clientesAPI
              .GetCompleto()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalGerarTxtFaturamentoQuestorinstance.result.then(function () {
        $public.GetMeusRelatorios();
        toastr.info('Seu relatório está sendo processado.', 'Meus Relatórios', {
          autoDismiss: true,
          allowHtml: true,
          preventDuplicates: true,
          closeButton: true,
          progressBar: true,
          timeOut: 5000,
        });
      });
    };

    $public.AbrirModalGerarTxtFaturamentoDominio = function () {
      var modalGerarTxtFaturamentoDominioinstance = $uibModal.open({
        templateUrl:
          '/views/meusrelatorios/modals/modalGerarTxtFaturamentoDominio.html',
        controller: 'modalGerarTxtFaturamentoDominioCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          Clientes: function () {
            var deferred = $q.defer();
            clientesAPI
              .GetCompleto()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalGerarTxtFaturamentoDominioinstance.result.then(function () {
        $public.GetMeusRelatorios();
        toastr.info('Seu relatório está sendo processado.', 'Meus Relatórios', {
          autoDismiss: true,
          allowHtml: true,
          preventDuplicates: true,
          closeButton: true,
          progressBar: true,
          timeOut: 5000,
        });
      });
    };

    $public.AbrirModalMITPDF = function () {
      var modalGerarTxtFaturamentoDominioinstance = $uibModal.open({
        templateUrl: '/views/meusrelatorios/modals/modalGerarMITPdf.html',
        controller: 'modalGerarMITPdfCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          Clientes: function () {
            var deferred = $q.defer();
            clientesAPI
              .GetCompleto()
              .then(function (response) {
                console.log(response.data);
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalGerarTxtFaturamentoDominioinstance.result.then(function () {
        $public.GetMeusRelatorios();
        toastr.info('Seu relatório está sendo processado.', 'Meus Relatórios', {
          autoDismiss: true,
          allowHtml: true,
          preventDuplicates: true,
          closeButton: true,
          progressBar: true,
          timeOut: 5000,
        });
      });
    };

    function saveTextAsFile(data, filename) {
      if (!data) {
        console.error('Console.save: No data');
        return;
      }

      if (!filename) filename = 'console.json';

      var blob = new Blob([data], { type: 'text/plain' }),
        e = document.createEvent('MouseEvents'),
        a = document.createElement('a');
      // FOR IE:

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        var e = document.createEvent('MouseEvents'),
          a = document.createElement('a');

        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
        e.initEvent(
          'click',
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        a.dispatchEvent(e);
      }
    }

    function savePDFAsFile(data, filename) {
      if (!data) {
        console.error('Console.save: No data');
        return;
      }

      if (!filename) filename = 'console.json';

      var blob = new Blob([data], { type: 'application/json' }),
        e = document.createEvent('MouseEvents'),
        a = document.createElement('a');
      // FOR IE:

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        var e = document.createEvent('MouseEvents'),
          a = document.createElement('a');

        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['application/json', a.download, a.href].join(
          ':'
        );
        e.initEvent(
          'click',
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        a.dispatchEvent(e);
      }
    }
    //Baixar TXT
    $public.BaixarTXT = function (TXTName, DataSolicitacao, FileName, FileUrl) {
      // var result = $http({
      //   url:
      //     config.baseUrl +
      //     '/files/microservices/mitdominio/' +
      //     FileName +
      //     '.txt',
      //   method: 'GET',
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     'Content-Type': 'text/plain',
      //   },
      // });
      var fileName = `${TXTName}_${DataSolicitacao}`;
      $window.open(
        config.baseUrl + '/files/microservices/mitdominio/' + FileName + '.txt'
      );
      //saveTextAsFile(result, fileName);
    };

    $public.BaixarQuestor = function (
      TXTName,
      DataSolicitacao,
      FileName,
      FileUrl
    ) {
      // var result = $http({
      //   url:
      //     config.baseUrl +
      //     '/files/microservices/mitdominio/' +
      //     FileName +
      //     '.txt',
      //   method: 'GET',
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     'Content-Type': 'text/plain',
      //   },
      // });
      var fileName = `${TXTName}_${DataSolicitacao}`;
      $window.open(
        config.baseUrl + '/files/microservices/mitquestor/' + FileName + '.csv'
      );
      //saveTextAsFile(result, fileName);
    };

    $public.BaixarPDF = function (fileName) {
      console.log(fileName);
      var FileUrl =
        config.baseUrl + '/files/microservices/mitpdf/' + fileName + '.pdf';
      $window.open(FileUrl);
    };

    $public.SocketIOListen = function () {
      SocketIO.on('ReportGridUpdate', function (vm) {
        $public.GetMeusRelatorios();
      });

      $scope.$on('$destroy', function () {
        SocketIO.removeListener('ReportGridUpdate');
      });
    };

    $private.Init = function () {
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'NomeDoRelatorio',
          OrderBy: '-DataDeCadastro',
          Max: 10,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $public.GetMeusRelatorios,
      };
      $public.GetMeusRelatorios();
      $public.Config = SharedDataFactory.Get().Configuracoes;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao cadastro: Meus Relátorios',
        'cadastro.meusrelatorios',
        'Acessou o menu Meus Relátorios',
        null,
        null,
        null
      );
    };

    $private.Init();
    $public.SocketIOListen();
  }

  function modalGerarTxtFaturamentoQuestorCtrl(
    $scope,
    sweetAlert,
    config,
    $uibModalInstance,
    Clientes,
    meusRelatoriosAPI,
    EnumFactory
  ) {
    var $public = this;
    var $private = {};

    $public.Enviar = function () {
      $public.loading = true;

      $public.Report.InitDate = new Date(angular.copy($public.Report.InitDate));
      $public.Report.FinalDate = new Date(
        angular.copy($public.Report.FinalDate)
      );

      $public.Report.ClientId = $public.ClienteSelecionado.ClienteID;
      $public.Report.Type = $public.Type.Valor;
      console.log('Type? ', $public.Type);
      var result = {
        OnSuccess: function () {
          // sweetAlert.swal('Arquivo solicitado com sucesso!', '', 'success');
          $uibModalInstance.close();
        },
        OnError: function (response) {
          $public.loading = false;
          switch (response.status) {
            case 404:
              sweetAlert.swal(
                'Atenção',
                'Não existe faturamento para o período informado.',
                'info'
              );
              break;

            case 503:
              sweetAlert.swal(
                'Serviço Indisponível',
                'O serviço está indisponível no momento, tente mais tarde.',
                'info'
              );
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      console.log($public.Report);
      meusRelatoriosAPI
        .RequestMitQuestor($public.Report)
        .then(result.OnSuccess)
        .catch(result.OnError);
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    var date = new Date();
    $public.PeriodoInicial = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      1
    );

    $private.Init = function () {
      $public.Enuns = EnumFactory;
      $public.Title = 'Gerar arquivo CSV de faturamento para o Questor';
      $public.Clientes = Clientes;
    };

    $private.Init();
  }

  function modalGerarTxtFaturamentoDominioCtrl(
    $scope,
    sweetAlert,
    config,
    $uibModalInstance,
    Clientes,
    meusRelatoriosAPI
  ) {
    var $public = this;
    var $private = {};

    $public.Enviar = function () {
      $public.loading = true;

      $public.Report.DataInicial = new Date(
        angular.copy($public.Report.DataInicial)
      );
      $public.Report.DataFinal = new Date(
        angular.copy($public.Report.DataFinal)
      );

      $public.Report.NFSeID = 0;
      $public.Report.EmpresaID = $public.ClienteSelecionado.ClienteID;

      var result = {
        OnSuccess: function () {
          // sweetAlert.swal('Arquivo solicitado com sucesso!', '', 'success');
          $uibModalInstance.close();
        },
        OnError: function (response) {
          $public.loading = false;
          switch (response.status) {
            case 404:
              sweetAlert.swal(
                'Atenção',
                'Não existe faturamento para o período informado.',
                'info'
              );
              break;

            case 503:
              sweetAlert.swal(
                'Serviço Indisponível',
                'O serviço está indisponível no momento, tente mais tarde.',
                'info'
              );
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      console.log($public.Report);
      meusRelatoriosAPI
        .RequestMitDominio($public.Report)
        .then(result.OnSuccess)
        .catch(result.OnError);
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    var date = new Date();
    $public.PeriodoInicial = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      1
    );

    $private.Init = function () {
      $public.certificado = {};
      $public.certificado.IncluirNoPlugNotas = false;
      $public.Title = 'Gerar arquivo TXT de faturamento para o Domínio';
      $public.Clientes = Clientes;
    };

    $private.Init();
  }

  function modalGerarMITPdfCtrl(
    $scope,
    sweetAlert,
    config,
    $uibModalInstance,
    Clientes,
    meusRelatoriosAPI,
    EnumFactory
  ) {
    var $public = this;
    var $private = {};

    $public.Enviar = function () {
      $public.loading = true;

      $public.Report.dateInit = new Date(angular.copy($public.Report.dateInit));
      $public.Report.dateEnd = new Date(angular.copy($public.Report.dateEnd));
      $public.Report.clientId = $public.ClienteSelecionado.ClienteID;
      $public.Report.consolidado = angular.copy($public.Report.consolidado) ? true : false;

      if ($public.MedicoSelecionado) {
        $public.Report.doctorId = $public.MedicoSelecionado.MedicoID;
      }

      var result = {
        OnSuccess: function () {
          // sweetAlert.swal('Arquivo solicitado com sucesso!', '', 'success');
          $uibModalInstance.close();
        },
        OnError: function (response) {
          $public.loading = false;
          switch (response.status) {
            case 404:
              sweetAlert.swal(
                'Atenção',
                'Não existe faturamento para o período informado.',
                'info'
              );
              break;

            case 503:
              sweetAlert.swal(
                'Serviço Indisponível',
                'O serviço está indisponível no momento, tente mais tarde.',
                'info'
              );
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      console.log($public.Report);
      meusRelatoriosAPI
        .RequestMitPDF($public.Report)
        .then(result.OnSuccess)
        .catch(result.OnError);
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    var date = new Date();
    $public.PeriodoInicial = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      1
    );

    $private.Init = function () {
      $public.Enuns = EnumFactory;
      $public.certificado = {};
      $public.certificado.IncluirNoPlugNotas = false;
      $public.Title = 'Gerar PDF de faturamento';
      $public.Clientes = Clientes;
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('relatorioNFSeEmitidasCtrl', relatorioNFSeEmitidasCtrl)

  relatorioNFSeEmitidasCtrl.$inject = [
    '$window',
    '$q',
    'config',
    'sweetAlert',
    'relatoriosAPI',
    'AuditoriaFactory',
    'clientesAPI'
  ]

  function relatorioNFSeEmitidasCtrl(
    $window,
    $q,
    config,
    sweetAlert,
    relatoriosAPI,
    AuditoriaFactory,
    clientesAPI
  ) {
    var $public = this;
    var $private = {};

    $private.GetClientes = function () {
      var deferred = $q.defer();
      clientesAPI
        .GetCompleto()
        .then(function (response) {
          deferred.resolve(($public.Clientes = response.data));
        })
        .catch(function () {
          deferred.resolve(undefined);
        });

      return deferred.promise;
    }

    $public.Enviar = function () {
      var date = new Date();

      var periodo = new Date($public.Periodo);
      var year = periodo.getFullYear() || date.getFullYear();
      var month = periodo.getMonth() + 1 || date.getMonth() + 1;
      var porcompetencia = $public.PorCompetencia;
      var consolidado = $public.Consolidado;

      sweetAlert.swal.queue([
        {
          title: 'Visualizar Relatório',
          html:
            'Deseja gerar o <strong>Relatório de NFSe Emitidas Por Período</strong> para o Cliente: <strong><u>' +
            $public.ClienteSelecionado.Nome +
            '</u></strong>?',
          showCancelButton: true,
          confirmButtonColor: '#262763',
          confirmButtonText: 'Sim, Gerar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return relatoriosAPI
              .GetByClienteIDEPeriodo(
                $public.ClienteSelecionado.ClienteID,
                month,
                year,
                porcompetencia,
                consolidado
              )
              .then(function (response) {
                var filename = response.data;
                console.log(response.data);
                console.log(config.baseUrl + '/files/csv/' + filename);
                $window.open(config.baseUrl + '/files/csv/' + filename);

                //$scope.$apply(function() {  $uibModalInstance.dismiss("Cancel"); });
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Não existe NFS-e emitidas para a empresa no período informado!',
                      type: 'warning',
                    });

                    break;

                  default:
                    sweetAlert.swal({
                      title: 'Atenção!',
                      text: 'Não foi possível gerar o relatório pois ocorreu um erro de sistema!',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.Title = 'Relatório de NFS-e Emitidas por Cliente e Período';
      $public.Clientes = null
      $private.GetClientes()
      $public.Periodo = new Date();
      $public.PorCompetencia = false;
      $public.Consolidado = false;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao modal: Relatório de NFS-e Emitidas',
        'modal.relatorio.nfseemitidas',
        'Acessou o modal de Relatório de NFS-e Emitidas',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('despesasExtrasCtrl', despesasExtrasCtrl)
    .controller(
      'modalNovoEditarDespesasExtrasCtrl',
      modalNovoEditarDespesasExtrasCtrl
    )
    .controller(
      'modalNovoEditarDespesasRecorrentesCtrl',
      modalNovoEditarDespesasRecorrentesCtrl
    )
    .controller('modalPainelDeReajustesCtrl', modalPainelDeReajustesCtrl)
    .controller('modalReajustesDaDespesaCtrl', modalReajustesDaDespesaCtrl)
    .controller(
      'modalIncluirReajustesDaDespesaCtrl',
      modalIncluirReajustesDaDespesaCtrl
    )
    .controller(
      'modalEditarInicioEterminoDaDespesaCtrl',
      modalEditarInicioEterminoDaDespesaCtrl
    );

  despesasExtrasCtrl.$inject = [
    '$scope',
    '$q',
    '$uibModal',
    'sweetAlert',
    'despesasRecorrentesAPI',
  ];

  modalNovoEditarDespesasExtrasCtrl.$inject = [
    '$q',
    '$scope',
    '$uibModal',
    '$uibModalInstance',
    'sweetAlert',
    'EnumFactory',
    'despesasRecorrentesAPI',
    'clientesAPI',
    'DespesaSelecionada',
    'fornecedoresAPI',
  ];

  modalNovoEditarDespesasRecorrentesCtrl.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    'EnumFactory',
    'despesasRecorrentesAPI',
    'medicosAPI',
    'DespesaSelecionada',
    'Clientes',
    'Fornecedores',
  ];

  modalPainelDeReajustesCtrl.$inject = [
    '$scope',
    '$uibModal',
    '$uibModalInstance',
    'sweetAlert',
    'despesasRecorrentesAPI',
    'DespesaSelecionada',
  ];

  modalReajustesDaDespesaCtrl.$inject = [
    '$scope',
    '$uibModal',
    '$uibModalInstance',
    'sweetAlert',
    'despesasRecorrentesAPI',
    'DespesaRecorrenteMedico',
  ];

  modalIncluirReajustesDaDespesaCtrl.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    '$filter',
    'despesasRecorrentesAPI',
    'Despesa',
  ];

  modalEditarInicioEterminoDaDespesaCtrl.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    'despesasRecorrentesAPI',
    'DespesaRecorrenteMedico',
  ];

  function despesasExtrasCtrl(
    $scope,
    $q,
    $uibModal,
    sweetAlert,
    despesasRecorrentesAPI
  ) {
    var $public = this;
    var $private = {};

    //MODAL Editar Despesa Extra
    $public.abrirModalNovoEditarIncluirDespesaExtra = function (
      itemSelecionado
    ) {
      var modalNovoEditarDespesasExtrasCtrl = $uibModal.open({
        templateUrl: '/views/operacional/modals/modalIncluirDespesaExtra.html',
        controller: 'modalNovoEditarDespesasExtrasCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          DespesaSelecionada: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            despesasRecorrentesAPI
              .GetByID(itemSelecionado.DespesaExtraID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalNovoEditarDespesasExtrasCtrl.result.then(function () {
        $public.GetDespesas();
      });
    };

    //POPUP Excluir Despesas Extras
    $public.modalExcluirDespesaExtra = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Você tem certeza absoluta?',
          html:
            'Deseja excluir completamente a Despesa Extra <strong><u>' +
            itemSelecionado.Descricao +
            `</strong></u> com todos os seus reajustes informados?</strong></u><br><br>
            <form method="post" id="taxcode-update" name="taxcodeUpdate">
            <p>Digite <strong><u><i>${itemSelecionado.Descricao}</i></u></strong> para confirmar a exclusão.</p>
            <input id="input-confirmation" autofocus minlength="3"
            class="form-control wedding-input-text wizard-input-pad" type="text" name="confirmationData">
            </form>
            `,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, confirmar a exclusão irreversivel!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            var inputValue = document.getElementById('input-confirmation');

            if (inputValue.value !== itemSelecionado.Descricao) {
              sweetAlert.swal.insertQueueStep({
                title: 'Cancelamento não realizado!',
                text:
                  'O campo de confirmação é diferente do informado ou está em branco.',
                type: 'info',
                timer: 3500,
              });
              return;
            }

            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return despesasRecorrentesAPI
              .Delete(itemSelecionado.DespesaRecorrenteID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Despesa Extra removida com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $public.GetDespesas();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Despesa Extra não encontrada, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text:
                        'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    // MODAL Painel de Reajustes
    $public.abrirModalReajustes = function (itemSelecionado) {
      var modalPainelDeReajustesCtrl = $uibModal.open({
        templateUrl: '/views/operacional/modals/modalPainelDeReajuste.html',
        controller: 'modalPainelDeReajustesCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          DespesaSelecionada: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            despesasRecorrentesAPI
              .GetByID(itemSelecionado.DespesaRecorrenteID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalPainelDeReajustesCtrl.result.then(function () {
        $public.GetDespesas();
      });
    };

    $public.GetDespesas = function () {
      despesasRecorrentesAPI
        .Get($public.PaginationConfig.Paginacao)
        .then(function (response) {
          $public.DespesasRecorrentes = response.data.Registros;
          console.log(response.data);
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    //MODAL Editar Despesa Recorrente
    $public.abrirModalNovoEditarIncluirDespesaRecorrente = function (
      itemSelecionado
    ) {
      var modalNovoEditarDespesasRecorrentesCtrl = $uibModal.open({
        templateUrl:
          '/views/cadastros/despesasrecorrentes/modals/modalIncluirDespesaRecorrente.html',
        controller: 'modalNovoEditarDespesasRecorrentesCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          DespesaSelecionada: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            despesasRecorrentesAPI
              .GetByID(itemSelecionado.DespesaRecorrenteID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          Clientes: function () {
            var deferred = $q.defer();

            clientesAPI
              .GetCompleto()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          Fornecedores: function () {
            var deferred = $q.defer();

            fornecedoresAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalNovoEditarDespesasRecorrentesCtrl.result.then(function () {
        $public.GetDespesas();
      });
    };

    //POPUP Excluir Tipos de Serviços
    $public.modalExcluirDespesaRecorrente = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover a Despesa Recorrente <strong><u>' +
            itemSelecionado.Descricao +
            '</strong></u>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return despesasRecorrentesAPI
              .Delete(itemSelecionado.DespesaRecorrenteID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Despesa Recorrente removida com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $public.GetDespesas();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Despesa Recorrente não encontrada, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text:
                        'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.setPage = function (pageNo) {
      $scope.currentPage = pageNo;
    };

    $public.pageChanged = function () {
      // console.log("Page changed to: " + $scope.currentPage);
    };

    $public.setItemsPerPage = function (num) {
      $scope.itemsPerPage = num;
      $scope.currentPage = 1; //reset to first page
    };

    $private.Init = function () {
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Descricao',
          OrderBy: '-Descricao',
          Max: 5,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $public.GetDespesas,
      };
      $public.GetDespesas();
    };

    $private.Init();
  }

  function modalNovoEditarDespesasExtrasCtrl(
    $q,
    $scope,
    $uibModal,
    $uibModalInstance,
    sweetAlert,
    EnumFactory,
    despesasRecorrentesAPI,
    clientesAPI,
    DespesaSelecionada,
    fornecedoresAPI
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.close($public.despesaExtra);
    };

    // External

    $public.abrirModalNovoEditarIncluirDespesaRecorrente = function () {
      var modalNovoEditarDespesasRecorrentesInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/despesasrecorrentes/modals/modalIncluirDespesaRecorrente.html',
        controller: 'modalNovoEditarDespesasRecorrentesCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          DespesaSelecionada: function () {
            return undefined;
          },
          Clientes: function () {
            var deferred = $q.defer();

            clientesAPI
              .GetCompleto()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          Fornecedores: function () {
            var deferred = $q.defer();

            fornecedoresAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalNovoEditarDespesasRecorrentesInstance.result.then(function (vm) {
        if (
          vm.Clientes[0].ClienteID ===
          $public.DespesaRecorrente.Cliente.ClienteID
        ) {
          $public.DespesasRecorrentes.push(vm);
          $public.DespesaRecorrente.Despesa = vm;
        } else {
          sweetAlert.swal({
            title: 'Aviso',
            type: 'info',
            html: 'A Despesa cadastrada não pertence a Empresa seleciona.',
          });
        }
      });
    };

    //MODAL Incluir e Editar Fornecedor
    $public.abrirModalNovoEditarFornecedor = function () {
      var modalNovoEditarFornecedoresInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/fornecedores/modals/modalNovoEditarFornecedor.html',
        controller: 'modalNovoEditarFornecedorCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          FornecedorSelecionado: function () {
            // PASSAR O FORNECEDOR PARA PEGAR COMO PADRÃO
            // TODO
            return undefined;
          },
          Clientes: function () {
            var deferred = $q.defer();

            clientesAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data);
                console.log(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalNovoEditarFornecedoresInstance.result.then(function (vm) {
        if (
          vm.Cliente.ClienteID === $public.DespesaRecorrente.Cliente.ClienteID
        ) {
          $public.Fornecedores.push(vm);
          $public.DespesaRecorrente.Fornecedor = vm;
        } else {
          sweetAlert.swal({
            title: 'Aviso',
            type: 'info',
            html: 'O Fornecedor cadastrado não pertence a Empresa seleciona.',
          });
        }
      });
    };

    $public.SelecionouCliente = function () {
      $public.DespesaRecorrente.Medico = undefined;
      $public.DespesaRecorrente.Despesa = undefined;
      $public.DespesaRecorrente.Fornecedor = undefined;

      var clienteID = $public.DespesaRecorrente.Cliente.ClienteID;
      despesasRecorrentesAPI
        .GetByClienteID(clienteID)
        .then(function (response) {
          $public.DespesasRecorrentes = response.data;
        });
      fornecedoresAPI.GetByClienteID(clienteID).then(function (response) {
        $public.Fornecedores = response.data;
      });
    };

    $public.GetDespesasRecorrentesDoClienteByMedicoID = function (clienteID) {
      despesasRecorrentesAPI
        .GetByClienteID(clienteID)
        .then(function (response) {
          console.log(response.data);
        });
    };

    $private.GetEmpresas = function () {
      clientesAPI.GetCompleto().then(function (response) {
        $public.Empresas = response.data;
      });
    };

    $scope.changeHandler = function () {
      // console.log($public.DespesaRecorrente.IniciadoEm.getTime())
      // console.log($public.DespesaRecorrente.IniciadoEm)
      // console.log($public.DespesaRecorrente.Cliente.DataDeAbertura)
      // console.log($public.DespesaRecorrente.IniciadoEm.getTime() >= $public.DespesaRecorrente.Cliente.DataDeAbertura)
      //$scope.dateForm.dt.$setValidity('$valid', $public.DespesaRecorrente.IniciadoEm.getTime() >= $scope.minDate.getTime());
    };

    $public.Enviar = function () {
      // $public.despesasextras.periodo = new Date($public.despesasextras.periodo);
      var result = {
        OnSuccess: function () {
          var msg = DespesaSelecionada ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close($public.despesaExtra);
        },
        OnError: function (response) {
          console.log(response);
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'A Despesa Extra não foi encontrada, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'O médico já se encontra vinculado para a despesa informada, caso o valor ou o período tenha mudado realize um lançamento de reajuste.',
                'error'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (DespesaSelecionada) {
        // EDITAR DESPESA EXTRA
        despesasRecorrentesAPI
          .Put($public.DespesaRecorrente)
          .then(result.OnSuccess)
          .catch(result.OnError);
      } else {
        // CADASTRAR DESPESA EXTRA
        despesasRecorrentesAPI
          .PostDespesaExtra($public.DespesaRecorrente)
          .then(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $private.Init = function () {
      $public.despesaExtra = DespesaSelecionada;
      if (DespesaSelecionada) {
        $public.despesaExtra.DataDeCadastro = angular.copy(
          new Date($public.despesaExtra.DataDeCadastro)
        );
      }
      $public.Title = DespesaSelecionada
        ? 'Editar Despesa Extra'
        : 'Incluir Despesa Extra';
      $public.Enuns = EnumFactory;
    };

    $private.Init();
    //$private.GetMedicos();
    $private.GetEmpresas();
  }

  function modalNovoEditarDespesasRecorrentesCtrl(
    $uibModalInstance,
    sweetAlert,
    EnumFactory,
    despesasRecorrentesAPI,
    medicosAPI,
    DespesaSelecionada,
    Clientes,
    Fornecedores
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.close($public.despesaRecorrente);
    };

    $private.GetMedicos = function () {
      medicosAPI.Get().then(function (response) {
        $public.Medicos = response.data;
      });
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = DespesaSelecionada ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close($public.despesaRecorrente);
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O tipo de serviço não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (DespesaSelecionada) {
        // $public.despesaRecorrente.Clientes = [angular.copy($public.despesaRecorrente.Clientes)]
        // EDITAR DESPESA RECORRENTE
        despesasRecorrentesAPI
          .Put($public.despesaRecorrente)
          .then(result.OnSuccess)
          .catch(result.OnError);
      } else {
        $public.despesaRecorrente.Clientes = [
          angular.copy($public.despesaRecorrente.Clientes),
        ];
        // CADASTRAR DESPESA RECORRENTE
        despesasRecorrentesAPI
          .Post($public.despesaRecorrente)
          .then(function (response) {
            $public.despesaRecorrente.DespesaRecorrenteID =
              response.data.DespesaRecorrenteID;
          })
          .finally(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $private.Init = function () {
      $public.despesaRecorrente = DespesaSelecionada;
      if (DespesaSelecionada) {
        $public.despesaRecorrente.DataDeCadastro = angular.copy(
          new Date($public.despesaRecorrente.DataDeCadastro)
        );
      }
      $public.Clientes = Clientes;
      $public.Title = DespesaSelecionada
        ? 'Editar Despesa Recorrente'
        : 'Incluir Despesa Recorrente';
      $public.Enuns = EnumFactory;

      $public.Fornecedores = Fornecedores;
    };

    $private.Init();
    $private.GetMedicos();
  }

  function modalPainelDeReajustesCtrl(
    $scope,
    $uibModal,
    $uibModalInstance,
    sweetAlert,
    despesasRecorrentesAPI,
    DespesaSelecionada
  ) {
    var $public = this;
    var $private = {};
    $scope.viewby = 2;

    $public.FecharModal = function () {
      $uibModalInstance.close();
    };

    $public.VisualizarReajustes = function (itemSelecionado) {
      var modalReajusteDaDespesa = $uibModal.open({
        templateUrl: '/views/operacional/modals/modalReajustesDaDespesa.html',
        controller: 'modalReajustesDaDespesaCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'md',
        resolve: {
          DespesaRecorrenteMedico: function () {
            return itemSelecionado;
          },
        },
      });

      modalReajusteDaDespesa.result
        .then(function () {})
        .finally(function () {
          despesasRecorrentesAPI
            .GetByID(itemSelecionado.DespesaRecorrenteID)
            .then(function (response) {
              $public.despesaRecorrente = response.data;
            });
        });
    };

    $public.EditarInicioETermino = function (itemSelecionado) {
      var modalEditarInicioETermino = $uibModal.open({
        templateUrl:
          '/views/operacional/modals/modalEditarInicioEterminoDaDespesa.html',
        controller: 'modalEditarInicioEterminoDaDespesaCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'md',
        resolve: {
          DespesaRecorrenteMedico: function () {
            return itemSelecionado;
          },
        },
      });

      modalEditarInicioETermino.result
        .then(function () {})
        .finally(function () {
          despesasRecorrentesAPI
            .GetByID(itemSelecionado.DespesaRecorrenteID)
            .then(function (response) {
              $public.despesaRecorrente = response.data;
            });
        });
    };

    $private.GetDespesasRecorrentesMedicos = function (DespesaRecorrenteID) {
      despesasRecorrentesAPI
        .GetByID(DespesaRecorrenteID)
        .then(function (response) {
          $public.despesaRecorrente = response.data;
        });
    };

    $public.ExcluirReajuste = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Você tem certeza absoluta?',
          html:
            'Deseja excluir completamente o vinculo do Médico(a) <strong><u>' +
            itemSelecionado.Nome +
            `</strong></u> com todos os seus reajustes informados?</strong></u><br><br>
            <form method="post" id="taxcode-update" name="taxcodeUpdate">
            <p>Digite <strong><u><i>${itemSelecionado.Nome}</i></u></strong> para confirmar a exclusão.</p>
            <input id="input-confirmation" autofocus minlength="3"
            class="form-control wedding-input-text wizard-input-pad" type="text" name="confirmationData">
            </form>
            `,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, confirmar a exclusão irreversivel!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            var inputValue = document.getElementById('input-confirmation');

            if (inputValue.value !== itemSelecionado.Nome) {
              sweetAlert.swal.insertQueueStep({
                title: 'Exclusão não realizada!',
                text:
                  'O campo de confirmação é diferente do informado ou está em branco.',
                type: 'info',
                timer: 3500,
              });
              return;
            }

            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.
            return despesasRecorrentesAPI
              .DeleteDespesasRecorrentesMedicos(
                itemSelecionado.MedicoID,
                itemSelecionado.ClienteID,
                itemSelecionado.DespesaRecorrenteID
              )
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Vinculo do Médico removido com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $private.GetDespesasRecorrentesMedicos(
                  itemSelecionado.DespesaRecorrenteID
                );
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Despesa Extra não encontrada, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text:
                        'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.despesaRecorrente = DespesaSelecionada;
      $public.Title = 'Painel de Reajuste';
      $public.viewby = '2';
      $scope.totalItems =
        $public.despesaRecorrente.DespesasRecorrentesMedicos.length;
      $scope.currentPage = 1;
      $scope.itemsPerPage = $scope.viewby;
      $scope.maxSize = 10; //Number of pager buttons to show
      $scope.sortType = 'DespesaRecorrenteID';
      $scope.sortReverse = true;
    };

    $private.Init();
  }

  function modalEditarInicioEterminoDaDespesaCtrl(
    $uibModalInstance,
    sweetAlert,
    despesasRecorrentesAPI,
    DespesaRecorrenteMedico
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.close();
    };

    $public.Enviar = function () {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            "Confirma a atualização da <strong>data de início</strong> e <strong>término</strong>?<br><br><strong style='color: red; font-size: 16px;'>ATENÇÃO:<br>Atualizar a data de inicio requer um lançamento de reajuste com a data inicial informada.</strong>",
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3D3A6D',
          confirmButtonText: 'Sim, atualizar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return despesasRecorrentesAPI
              .PutDespesaRecorrenteMedico($public.despesaRecorrente)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Dados alterados com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $uibModalInstance.close();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Despesa não encontrada, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text:
                        'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.Title = 'Alterar Início e Término da Despesa';
      DespesaRecorrenteMedico.IniciadoEm = new Date(
        DespesaRecorrenteMedico.IniciadoEm
      );

      if (DespesaRecorrenteMedico.EncerradoEm !== null) {
        DespesaRecorrenteMedico.EncerradoEm = new Date(
          DespesaRecorrenteMedico.EncerradoEm
        );
      }

      $public.despesaRecorrente = DespesaRecorrenteMedico;
    };

    $private.Init();
  }

  function modalReajustesDaDespesaCtrl(
    $scope,
    $uibModal,
    $uibModalInstance,
    sweetAlert,
    despesasRecorrentesAPI,
    DespesaRecorrenteMedico
  ) {
    var $public = this;
    var $private = {};
    $scope.viewby = 3;

    $public.FecharModal = function () {
      $uibModalInstance.close();
    };

    $public.abrirModalAdicionarReajuste = function () {
      var modalIncluirReajusteDaDespesa = $uibModal.open({
        templateUrl: '/views/operacional/modals/modalIncluirReajuste.html',
        controller: 'modalIncluirReajustesDaDespesaCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'md',
        resolve: {
          Despesa: function () {
            return DespesaRecorrenteMedico;
          },
        },
      });

      modalIncluirReajusteDaDespesa.result.then(function (reajuste) {
        var { Vigencia, Valor } = reajuste;
        var newReajuste = { Vigencia, Valor };
        DespesaRecorrenteMedico.Reajustes.push(newReajuste);
        $public.Reajustes = DespesaRecorrenteMedico.Reajustes;
      });
    };

    $public.ExcluirReajuste = function (vm) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html: 'Deseja realmente excluir o reajuste?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, excluir!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            var {
              DespesaRecorrenteID,
              ClienteID,
              MedicoID,
            } = DespesaRecorrenteMedico;
            var { Vigencia } = vm;

            var dateVigencia = new Date(Vigencia);
            var yearVigencia = dateVigencia.getFullYear();
            var monthVigencia = dateVigencia.getMonth() + 1;

            $public.Reajustes = $public.Reajustes.filter(
              (f) => f.Vigencia !== Vigencia
            );
            $scope.totalItems = $public.Reajustes.length;

            return despesasRecorrentesAPI
              .DeleteReajusteDespesasRecorrentesMedicos(
                DespesaRecorrenteID,
                MedicoID,
                ClienteID,
                monthVigencia,
                yearVigencia
              )
              .then(function (response) {
                sweetAlert.swal.insertQueueStep({
                  title: 'Excluído!',
                  text: 'Reajuste excluído com sucesso',
                  type: 'success',
                  timer: 2000,
                });

                $public.Reajustes = $public.Reajustes.filter(
                  (f) => f.Vigencia !== Vigencia
                );
                $scope.totalItems = $public.Reajustes.length;
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Reajuste não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;
                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text:
                        'Erro ao exluir o reajuste, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.Reajustes = DespesaRecorrenteMedico.Reajustes;
      $public.Title = 'Lista de Reajustes';

      $scope.totalItems = $public.Reajustes.length;
      $scope.currentPage = 1;
      $scope.itemsPerPage = $scope.viewby;
      $scope.maxSize = 3; //Number of pager buttons to show
      $scope.sortType = 'Vigencia';
      $scope.sortReverse = true;
    };

    $private.Init();
  }

  function modalIncluirReajustesDaDespesaCtrl(
    $uibModalInstance,
    sweetAlert,
    $filter,
    despesasRecorrentesAPI,
    Despesa
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.close();
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          sweetAlert.swal('Dados adicionados com sucesso!', '', 'success');
          $uibModalInstance.close($public.newReajuste);
        },
        OnError: function (response) {
          $uibModalInstance.close();
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'A Despesa do Reajuste não foi encontrada, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um reajuste para <strong>' +
                  $filter('date')($public.reajuste.Vigencia, 'MM/yyyy') +
                  '</strong> cadastrado.',
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };
      despesasRecorrentesAPI
        .PostReajuste($public.reajuste)
        .then(function (response) {
          $public.newReajuste = response.data;
        })
        .finally(result.OnSuccess)
        .catch(result.OnError);
    };

    $private.Init = function () {
      $public.reajuste = Despesa;
      $public.Title = 'Incluir Reajuste';
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('emitirNFSeCtrl', emitirNFSeCtrl)
    .controller('modalNovoItemCtrl', modalNovoItemCtrl)
    .controller('modalPercDeCadaMedicoCtrl', modalPercDeCadaMedicoCtrl)
    .controller(
      'modalNovoEditarTomadorNaEmissaoCtrl',
      modalNovoEditarTomadorNaEmissaoCtrl
    );

  emitirNFSeCtrl.$inject = [
    '$q',
    '$state',
    '$scope',
    '$filter',
    '$uibModal',
    'sweetAlert',
    'anexosAPI',
    'EnumFactory',
    'toastr',
    'faixasAPI',
    'NFSeAPI',
    'configuracoesAPI',
    'faturamentoAPI',
    'municipiosAPI',
    'tomadoresAPI',
    'AuditoriaFactory',
  ];
  modalNovoItemCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'sweetAlert',
    'Item',
    'medicos',
    'ListaDeDescricoesDeItem',
    'NFSeAPI',
    'ClienteID'
  ];
  modalPercDeCadaMedicoCtrl.$inject = ['$uibModalInstance', 'Itens'];
  modalNovoEditarTomadorNaEmissaoCtrl.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    'EnumFactory',
    'viaCepAPI',
    'tomadorSelecionado',
    'municipiosAPI',
    'tomadoresAPI',
    'configuracoesDoUsuario',
    'camposObrigatorios',
    'type',
  ];

  function emitirNFSeCtrl(
    $q,
    $state,
    $scope,
    $filter,
    $uibModal,
    sweetAlert,
    anexosAPI,
    EnumFactory,
    toastr,
    faixasAPI,
    NFSeAPI,
    configuracoesAPI,
    faturamentoAPI,
    municipiosAPI,
    tomadoresAPI,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};
    $public.NFSe = {};
    $public.NFSe.Itens = [];
    $public.maxDatedataCompetencia = new Date();
    $public.NFSe.dataCompetencia = new Date();
    var button = document.getElementById("btnEmitir");
    

    $public.Enviar = function () {
      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Tentou Emitir uma NFS-e',
        'operacional.emissaonfse',
        'Tentou emitir uma NFS-e',
        null,
        null,
        null
      );
      

      if ($public.NFSe.Cliente.EmiteTambemNaPrefeitura) {
        button.removeAttribute("disabled");
        sweetAlert.swal.queue([
          {
            title: 'Atenção!',
            html:
              'Esta empresa faz emissão de NFS-e em paralelo com o sistema da Prefeitura.<br><br><u><strong>Para que não ocorra divergência de número de NFS-e é necessário confirmar se não houve emissão de NFS-e na Prefeitura.</strong></u>' +
              '<br><br>Confirma a verificação da NFS-e junto a Prefeitura?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F44336',
            confirmButtonText: 'Sim, confirmo!',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            preConfirm: function () {
              $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.
              return sweetAlert.swal.queue([
                {
                  title: 'Atenção!',
                  html:
                    'Você confirma a emissão da NFS-e para <strong><u>' +
                    $public.NFSe.Tomador.Nome +
                    '</u></strong> no valor de <strong><u>' +
                    $filter('currency')($public.NFSe.ValorTotal, 'R$ ') +
                    '</u></strong>?',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#5cb85c',
                  confirmButtonText: 'Sim, Emitir!',
                  cancelButtonText: 'Cancelar',
                  showLoaderOnConfirm: true,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  preConfirm: function () {
                    $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.
                    $public.loading = true;

                    return $scope.$apply(function () {
                      var result = {
                        OnSuccess: function (response) {
                          $state.go('manutencaonfse');
                          if (response.status === 201) {
                            toastr.info(
                              'Realize a assinatura da NFS-e assinador externo.',
                              'NFS-e',
                              {
                                autoDismiss: true,
                                allowHtml: true,
                                preventDuplicates: true,
                                closeButton: true,
                                progressBar: true,
                                timeOut: 3000,
                              }
                            );                            
                          } else {
                            toastr.success(
                              'A NFS-e foi enviada para a prefeitura.',
                              'NFS-e',
                              {
                                autoDismiss: true,
                                allowHtml: true,
                                preventDuplicates: true,
                                closeButton: true,
                                progressBar: true,
                                timeOut: 3000,
                              }
                            );
                          }
                        },
                        OnError: function (response) {
                          switch (response.status) {
                            case 401:
                              sweetAlert.swal({
                                title: 'Atenção',
                                type: 'error',
                                html:
                                  'Um erro inesperado impediu a emissão da NFS-e. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                                  response.data.Message +
                                  '</font>',
                              });

                              break;

                            case 409:
                              sweetAlert.swal(
                                'Atenção',
                                'Empresa sem certificado digital vinculado ou sem permissão para emissão sem o uso de certificado digital.',
                                'warning'
                              );
                              break;

                            case 417:
                              sweetAlert.swal({
                                title: 'Atenção',
                                type: 'error',
                                html:
                                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                                  response.data.ExceptionMessage +
                                  '</font>',
                              });
                              break;

                            default:
                              sweetAlert.swal(
                                'Atenção',
                                'Verifique o preenchimento do formulário',
                                'warning'
                              );
                              break;
                          }
                        },
                      };

                      // // Inclui o Valor Líquido na descrição do serviço
                      // $public.NFSe.DescricaodaNFSe =
                      //   $public.NFSe.DescricaodaNFSe +
                      //   '\nVALOR LIQUIDO DA NFS-E:' +
                      //   $filter('currency')($public.ValorLiquidoDaNFSe, 'R$ ');

                      // if ($public.DadosComplementares != undefined) {
                      //   $public.NFSe.DescricaodaNFSe =
                      //     $public.NFSe.DescricaodaNFSe +
                      //     '\n' +
                      //     $public.DadosComplementares;
                      // }
                      button.setAttribute("disabled", "disabled");
                      // ENVIAR NFS-E
                       NFSeAPI.Post($public.NFSe)
                         .then(result.OnSuccess)
                         .catch(result.OnError)
                         .finally(function () {
                           $public.loading = false;
                           button.removeAttribute("disabled");
                         });
                    });
                  }
                },
              ]);
            },
          },
        ]);
      } else {
        console.log($public.NFSe);
        sweetAlert.swal.queue([
          {
            title: 'Atenção!',
            html:
              'Você confirma a emissão da NFS-e para <strong><u>' +
              $public.NFSe.Tomador.Nome +
              '</u></strong> no valor de <strong><u>' +
              $filter('currency')($public.NFSe.ValorTotal, 'R$ ') +
              '</u></strong>?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#5cb85c',
            confirmButtonText: 'Sim, Emitir!',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            preConfirm: function () {
              $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.
              $public.loading = true;

              return $scope.$apply(function () {
                var result = {
                  OnSuccess: function (response) {
                    $state.go('manutencaonfse');
                    if (response.status === 201) {
                      toastr.info(
                        'Realize a assinatura da NFS-e assinador externo.',
                        'NFS-e',
                        {
                          autoDismiss: true,
                          allowHtml: true,
                          preventDuplicates: true,
                          closeButton: true,
                          progressBar: true,
                          timeOut: 3000,
                        }
                      );
                    } else {
                      toastr.success(
                        'A NFS-e foi enviada para a prefeitura.',
                        'NFS-e',
                        {
                          autoDismiss: true,
                          allowHtml: true,
                          preventDuplicates: true,
                          closeButton: true,
                          progressBar: true,
                          timeOut: 3000,
                        }
                      );
                    }
                  },
                  OnError: function (response) {
                    switch (response.status) {
                      case 400:
                        sweetAlert.swal({
                          title: 'Atenção',
                          type: 'error',
                          html:
                            "Um erro de validação impediu o envio da NFS-e para prefeitura.<br><br><div style='font-size:16px; font-weight:600; color:#dc3545;'>Motivo:<br>" +
                            response.data.Message +
                            '</div>',
                        });
                        break;

                      case 401:
                        sweetAlert.swal({
                          title: 'Atenção',
                          type: 'error',
                          html:
                            'Um erro inesperado impediu a emissão da NFS-e. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                            response.data.Message +
                            '</font>',
                        });
                        break;

                      case 409:
                        sweetAlert.swal(
                          'Atenção',
                          'Empresa sem certificado digital vinculado ou sem permissão para emissão sem o uso de certificado digital.',
                          'warning'
                        );
                        break;

                      case 417:
                        sweetAlert.swal({
                          title: 'Atenção',
                          type: 'error',
                          html:
                            'Um erro inesperado impediu a emissão da NFS-e. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                            response.data.ExceptionMessage +
                            '</font>',
                        });
                        break;

                      default:
                        sweetAlert.swal(
                          'Atenção',
                          'Ocorreu um erro no envio da NFS-e tente novamente mais tarde.',
                          'warning'
                        );
                        break;
                    }
                  },
                };

                // Inclui o Valor Líquido na descrição do serviço
                // $public.NFSe.DescricaodaNFSe =
                //   $public.NFSe.DescricaodaNFSe +
                //   '\nVALOR LIQUIDO DA NFS-E:' +
                //   $filter('currency')($public.ValorLiquidoDaNFSe, 'R$ ');

                // if ($public.DadosComplementares != undefined) {
                //   $public.NFSe.DescricaodaNFSe =
                //     $public.NFSe.DescricaodaNFSe +
                //     '\n' +
                //     $public.DadosComplementares;
                // }

                button.setAttribute("disabled", "disabled");
                NFSeAPI.Post($public.NFSe)
                  .then(result.OnSuccess)
                  .catch(result.OnError)
                  .finally(function () {
                    button.removeAttribute("disabled");
                    $public.loading = false;
                  });
              });
            }
          },
        ]);
      }
    };

    $public.BuscaMunicipios = function (limparModel) {
      // Sempre que trocar de estado, limpa os campos
      $public.Municipio = [];

      if (limparModel) {
        $public.MunicipioDaPrestacao = undefined;
      }

      if (!$public.UF) {
        return;
      }

      municipiosAPI.GetByUF($public.UF).then(function (response) {
        $public.Municipio = response.data;
        //console.log(response.data);
      });
    };

    $public.EscolherMunicipio = function () {
      $public.NFSe.MunicipioPrestacao = $public.MunicipioDaPrestacao.SIAFI;
      $public.NFSe.MunicipioPrestacaoDescricao =
        $public.MunicipioDaPrestacao.Nome;
    };

    $public.DestacarRowTabela = function () {
      angular.forEach($public.TabelaSimplesNacional, function (item) {
        item.DestaqueRow = $public.FaixaSimplesNacional === item.Faixa;
      });
    };

    $public.onSelectedPrestadores = function (selectedItem) {
      console.log('aqui:', selectedItem)
      $public.NFSe.Cliente = selectedItem;
      $public.NFSe.Itens = [];
      $public.totalDaNFSe = 0;

      const selectedMunicipioNome = selectedItem.Endereco.Municipio.Nome.toLowerCase()
      const selectedMunicipioID = selectedItem.Endereco.Municipio.MunicipioID
      const municipios = $private.MunicipiosComLimite

      const temLimite = municipios.find(municipio => municipio.MunicipioID === selectedMunicipioID)

      if(temLimite) {
        $public.LimiteDeItens = {
          NomeDoMunicipio: selectedMunicipioNome,
          Max: temLimite.Max,
        };
        console.log('$public.LimiteDeItens', $public.LimiteDeItens)
      } else {
        $public.LimiteDeItens = null;
      }

      $public.NFSe.Intermediario = {};
      $public.NFSe.PossuiIntermediario = false;

      $public.loading = true;

      if (!$public.NFSe || !$public.NFSe.Cliente) {
        return;
      }

      // Regra 1: Verifica se o prestador não é optante do simples nacional e pega a faixa
      if ($public.NFSe.Cliente.RegimeTributario.RegimeID == 1) {
      } else {
        // Regra 1.2: É Simples Nacional então tem que pegar Faixa do Cliente
        faturamentoAPI
          .GetByClienteID(selectedItem.ClienteID)
          .then(function (response) {
            $public.DestacarRowTabela();
          });
      }

      NFSeAPI.GetServicosByClienteID(selectedItem.ClienteID).then(function (
        response
      ) {
        $public.Servicos = response.data;
      });
      $public.NFSe.Servico = undefined;
      $public.NFSe.Anexo = undefined;

      $public.UF = $public.NFSe.Cliente.Endereco.Municipio.UF;
      $public.BuscaMunicipios();

      municipiosAPI
        .GetByUF($public.NFSe.Cliente.Endereco.Municipio.UF)
        .then(function (response) {
          $public.MunicipioDaPrestacao = response.data;

          var selecionar = response.data.find(
            (f) =>
              f.MunicipioID ==
              $public.NFSe.Cliente.Endereco.Municipio.MunicipioID
          );
          $public.MunicipioDaPrestacao = selecionar;
        })
        .finally(function () {
          $public.EscolherMunicipio();
        });
    };

    $public.onSelectedAnexo = function (selectedItem) {
      var anexoID = selectedItem.AnexoID;
      var clienteID = $public.NFSe.Cliente.ClienteID;

      anexosAPI
        .GetByID(anexoID)
        .then(function (response) {
          $public.TabelaSimplesNacional = response.data.Faixas;
        })
        .finally(function () {
          $public.DestacarRowTabela();
        });

      faturamentoAPI
        .GetFaixaByClienteIDAndFaixaID(clienteID, anexoID)
        .then(function (response) {
          $public.NFSe.FaturamentoDoPrestador = {};

          $public.NFSe.FaturamentoDoPrestador.MesesDeAbertura =
            response.data.MesesDeAbertura;
          $public.NFSe.FaturamentoDoPrestador.MesesDeAbertura =
            response.data.MesesDeAbertura;
          $public.NFSe.FaturamentoDoPrestador.FaturamentoAnual =
            response.data.FaturamentoAnual;
          $public.NFSe.FaturamentoDoPrestador.QuantidadeDeNFSe =
            response.data.QuantidadeDeNFSe;
          $public.NFSe.FaturamentoDoPrestador.ValorTotalNFSe =
            response.data.ValorTotalNFSe;

          $public.FaixaSimplesNacional = response.data.Faixa;
          $public.DestacarRowTabela();

          faixasAPI.GetByFaixaID(response.data.Faixa).then(function (response) {
            $public.NFSe.Faixa = response.data;
          });
        });
    };

    $public.OnSelectedTomadores = function (selectedItem) {
      $public.NFSe.Tomador = selectedItem;
    };

    $public.OnSelectedIntermediarios = function (selectedItem) {
      $public.TomadorIntermediario = selectedItem;
      $public.NFSe.TomadorIntermediarioID = selectedItem.TomadorID;
    };

    $public.OnSelectedServico = function (selectedItem) {
      $public.NFSe.Servico = selectedItem;
    };

    $public.OnSelectedRetemISS = function (itemSelecionado) {
      return NFSeAPI.UpdateISSTomador(itemSelecionado)
        .then(function (response) {
          sweetAlert.swal.insertQueueStep({
            title: 'ISS do tomador atualizado com sucesso!',
            text: '',
            type: 'success',
            timer: 3500,
          });
        })
        .catch(function (response) {
          console.log('Error: %o', response);
          switch (response.status) {
            case 404:
              sweetAlert.swal.insertQueueStep({
                title: 'Atenção!',
                text: 'Tomador não encontrado, Verifique!',
                type: 'error',
              });
              break;

            default:
              sweetAlert.swal.insertQueueStep({
                title: 'Atenção!',
                text: 'Erro ao atualizar o registro, verifique se o mesmo não esta vinculado com outra tabela',
                type: 'error',
              });
              break;
          }
        });
    };

    //FUNCTION WORDS COUNTER
    $public.wordCounter = function () {
      if ($public.NFSe.DescricaodaNFSe.split(' ').length > 3500) {
        $public.NFSe.DescricaodaNFSe = $public.NFSe.DescricaodaNFSe.split(' ')
          .slice(0, 3500)
          .join(' ');
      }
    };

    $public.GetValorTotalSemFracao = function (value) {
      if (value && value != undefined) {
        return Math.round(value * 1e2) / 1e2;
      } else {
        return 0;
      }
    };

    $public.EscreverDescricaoDosServicos = function () {
      var DescricaoDosItens = '';
      $public.NFSe.DescricaodaNFSe = '';
      $public.NFSe.Itens.forEach(function (item, index) {
        DescricaoDosItens =
          DescricaoDosItens +
          '\n' +
          (item.ItemDescricao.Descricao +
            ' - ' +
            $filter('currency')(item.ValorTotal, 'R$ '));
      });

      $public.NFSe.DescricaodaNFSe = DescricaoDosItens.substring(
        1,
        DescricaoDosItens.length
      );

      //   if($public.NFSe.ValorTotal != undefined && $public.NFSe.ValorTotal > 0) {
      //     $public.NFSe.DescricaodaNFSe = $public.NFSe.DescricaodaNFSe + "\nVALOR LIQUIDO DA NFS-E: %valortotal%";

      var primeiroCalculo = 0;

      if (
        $public.NFSe.ValorPIS !== undefined ||
        $public.NFSe.ValorCOFINS !== undefined ||
        $public.NFSe.ValorINSS !== undefined ||
        $public.NFSe.ValorIR !== undefined ||
        $public.NFSe.ValorCSLL !== undefined ||
        $public.NFSe.ISSQNDevido !== undefined
      ) {
        $public.NFSe.ValorPIS = parseFloat($public.NFSe.ValorPIS);
        $public.NFSe.ValorCOFINS = parseFloat($public.NFSe.ValorCOFINS);
        $public.NFSe.ValorINSS = parseFloat($public.NFSe.ValorINSS);
        $public.NFSe.ValorIR = parseFloat($public.NFSe.ValorIR);
        $public.NFSe.ValorCSLL = parseFloat($public.NFSe.ValorCSLL);
        $public.NFSe.ISSQNDevido = parseFloat($public.NFSe.ISSQNDevido);
      }

      if ($public.NFSe.Tomador.RetemISS == false) {
        primeiroCalculo =
          $public.GetValorTotalSemFracao($public.NFSe.ValorPIS) +
          $public.GetValorTotalSemFracao($public.NFSe.ValorCOFINS) +
          $public.GetValorTotalSemFracao($public.NFSe.ValorINSS) +
          $public.GetValorTotalSemFracao($public.NFSe.ValorIR) +
          $public.GetValorTotalSemFracao($public.NFSe.ValorCSLL);
      } else {
        primeiroCalculo =
          $public.GetValorTotalSemFracao($public.NFSe.ValorPIS) +
          $public.GetValorTotalSemFracao($public.NFSe.ValorCOFINS) +
          $public.GetValorTotalSemFracao($public.NFSe.ValorINSS) +
          $public.GetValorTotalSemFracao($public.NFSe.ValorIR) +
          $public.GetValorTotalSemFracao($public.NFSe.ValorCSLL) +
          $public.GetValorTotalSemFracao($public.NFSe.ISSQNDevido);
      }

      console.log(
        'VALOR TOTAL:',
        $public.GetValorTotalSemFracao($public.NFSe.ValorTotal)
      );
      console.log('PRIMEIRO CALCULO: ', primeiroCalculo);
      $public.ValorLiquidoDaNFSe = $public.NFSe.ValorTotal - primeiroCalculo;
      console.log(
        $public.GetValorTotalSemFracao(
          $public.GetValorTotalSemFracao($public.NFSe.ValorTotal) -
            $public.GetValorTotalSemFracao(primeiroCalculo)
        )
      );
      //   $public.NFSe.DescricaodaNFSe = $public.NFSe.DescricaodaNFSe.replace('%valortotal%', $filter('currency')($public.ValorLiquidoDaNFSe, 'R$ '));

      // }

      $public.NFSe.AliquotaPIS =
        $public.NFSe.ValorPIS / $public.NFSe.ValorTotal;

      $public.NFSe.AliquotaCOFINS =
        $public.NFSe.ValorCOFINS / $public.NFSe.ValorTotal;
      $public.NFSe.AliquotaINSS =
        $public.NFSe.ValorINSS / $public.NFSe.ValorTotal;
      $public.NFSe.AliquotaIR = $public.NFSe.ValorIR / $public.NFSe.ValorTotal;
      $public.NFSe.AliquotaCSLL =
        $public.NFSe.ValorCSLL / $public.NFSe.ValorTotal;
    };

    $public.MudouValorDosImpostos = function () {
      //$public.CalculoDoImposto();
      $public.EscreverDescricaoDosServicos();
    };
    //MODAL Incluir Item
    $public.abrirModalNovoItem = function (itemSelecionado) {
      $public.ProcessarOutrasInformacoesDaNota();
      var modalNovoItemCtrl = $uibModal.open({
        templateUrl: '/views/operacional/modals/modalIncluirItem.html',
        controller: 'modalNovoItemCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          Item: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();
            deferred.resolve(itemSelecionado);

            return deferred.promise;
          },
          medicos: function () {
            return $public.NFSe.Cliente.Medicos;
          },
          ListaDeDescricoesDeItem: function () {
            var deferred = $q.defer();

            // NFSeDescricoesDoItensAPI.Get()
            //   .then(function(response) {
            //     deferred.resolve(response.data);
            //   })
            NFSeAPI.GetItensByClienteID($public.NFSe.Cliente.ClienteID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });
            return deferred.promise;
          },
          ClienteID: function () {
            return $public.NFSe.Cliente.ClienteID
          }
        },
      });

      modalNovoItemCtrl.result.then(function (Item) {
        console.log(Item);
        $public.NFSe.Itens.push(Item);
        // $public.CalculoDoImposto();
        $public.EscreverDescricaoDosServicos();
      });
    };

    //MODAL Lançar Percentual Medico de cada Item
    $public.abrirModalPercentualDeCadaMedicoCtrl = function (itemSelecionado) {
      $uibModal.open({
        templateUrl: '/views/operacional/nfse/modal/modalcotamedica.html',
        controller: 'modalPercDeCadaMedicoCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          Itens: function () {
            return itemSelecionado;
          },
        },
      });
    };

    //POPUP Excluir Item
    $public.abrirModalExcluirDescricaoDoServico = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover o Item <strong><u>' +
            itemSelecionado.ItemDescricao.Descricao +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return $scope.$apply(function () {
              var index = $public.NFSe.Itens.indexOf(itemSelecionado);
              //Atualiza o valor total da Prestação de Serviço
              $public.totalDaNFSe =
                $public.totalDaNFSe - $public.NFSe.Itens[index].ValorTotal;

              $public.NFSe.Itens.splice(index, 1);

              var DescricaoDosItens = '';
              $public.NFSe.DescricaodaNFSe = '';
              $public.NFSe.Itens.forEach(function (item, index) {
                DescricaoDosItens =
                  DescricaoDosItens +
                  '\n' +
                  (item.ItemDescricao.Descricao +
                    ' - ' +
                    $filter('currency')(item.ValorTotal, 'R$ '));
              });

              $public.NFSe.DescricaodaNFSe = DescricaoDosItens.substring(
                1,
                DescricaoDosItens.length
              );

              if (DescricaoDosItens.length > 0) {
                $public.CalculoDoImposto();
                $public.EscreverDescricaoDosServicos();
              } else {
                $public.NFSe.DescricaodaNFSe = '';
              }

              sweetAlert.swal.insertQueueStep({
                title: 'Removido!',
                text: 'Serviço prestado removido com sucesso',
                type: 'success',
                timer: 2000,
              });
            });
          },
        },
      ]);
    };

    // Processamento das outras Informações da NFS-e
    $public.ProcessarOutrasInformacoesDaNota = function () {
      button.removeAttribute("disabled");
      // Regra 1: Pega os parametros de configuração no DB
      configuracoesAPI
        .GetConfiguracaoCampinas()
        .then(function (response) {
          $public.NFSe.OutrasInformacoes = response.data.LayoutDeDescricao;
        })
        .finally(function () {
          // Regra 2: %DataDaCompetencia%
          $public.NFSe.OutrasInformacoes =
            $public.NFSe.OutrasInformacoes.replace(
              '%DataDaCompetencia%',
              $public.NFSe.dataCompetencia.toLocaleDateString("pt-BR")
            )

          // Regra 3: %MunicipioDaPrestacao% & %UFDaPrestacao%
          var municipio = $public.MunicipioDaPrestacao.Nome;
          var UF = $public.UF;
          $public.NFSe.OutrasInformacoes =
            $public.NFSe.OutrasInformacoes.replace(
              '%MunicipioDaPrestacao%',
              municipio
            ).replace('%UFDaPrestacao%', UF);

          // Regra 4: Verifica se o tomador Retem ou não o ISS
          var fraseDeRetencaoDoISS = undefined;
          if ($public.NFSe.Tomador.RetemISS == true) {
            // fraseDeRetencaoDoISS = "ISS retido na fonte pelo Tomador";
            fraseDeRetencaoDoISS = 'ISS retido na fonte pelo Tomador';
          } else {
            //fraseDeRetencaoDoISS = "ISS a Recolher pelo Prestador";
            fraseDeRetencaoDoISS = 'ISS a recolher pelo Prestador';
          }
          // Regra 4.1: %FraseDeRetencaoDoISS%
          $public.NFSe.OutrasInformacoes =
            $public.NFSe.OutrasInformacoes.replace(
              '%FraseDeRetencaoDoISS%',
              fraseDeRetencaoDoISS
            );
          // Regra 6:  %CodDoServico% - %DescricaoDoServico%

          var descricaoDoServico = $public.NFSe.Servico.Descricao;
          $public.NFSe.OutrasInformacoes =
            $public.NFSe.OutrasInformacoes.replace(
              '%DescricaoDoServico%',
              descricaoDoServico
            );

          var fraseTributacao = undefined;
          if ($public.NFSe.Cliente.RegimeTributario.RegimeID == 3) {
            fraseTributacao = 'Tributável S.N';
          } else {
            fraseTributacao = 'Tributável';
          }
          $public.NFSe.OutrasInformacoes =
            $public.NFSe.OutrasInformacoes.replace(
              '%SituacaoTributacao%',
              fraseTributacao
            );

          // Regra 7: Valida se o cliente exibe as ValorAproxDosTributos e adiciona as outras observações
          var fraseValorAprox =
            'Conf. Lei Federal n. 12741/12 - Carga Tributária Aprox. 13,45% Federal (IBPT) e de 2.0% Municipal';
          if ($public.NFSe.Cliente.ExibeValorAproxTributos) {
            $public.NFSe.OutrasInformacoes =
              $public.NFSe.OutrasInformacoes.replace(
                '%ValorAproxDosTributos%',
                fraseValorAprox
              );
          }
        });
    };

    $public.VerificarSeImpostosEhMenorQue10Reais = function () {
      // POPUP de Aviso:
      var valorDoImpostoMunicipal =
        $public.NFSe.ValorPIS +
        $public.NFSe.ValorCOFINS +
        $public.NFSe.ValorCSLL;
      console.log(valorDoImpostoMunicipal);

      if (valorDoImpostoMunicipal < 10 && valorDoImpostoMunicipal > 0) {
        sweetAlert.swal.queue([
          {
            title: 'Atenção!',
            html: 'As retenções são inferiores a <b><span color="red">R$ 10,00</span></b>, deseja zerar?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F44336',
            confirmButtonText: 'Sim, zerar!',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            preConfirm: function () {
              $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

              return $scope.$apply(function () {
                //TODO ZERAR OS IMPOSTOS
                $public.NFSe.AliquotaPIS = 0;
                $public.NFSe.AliquotaCOFINS = 0;
                $public.NFSe.AliquotaINSS = 0;
                $public.NFSe.AliquotaCSLL = 0;

                $public.NFSe.ValorPIS = 0;
                $public.NFSe.ValorCOFINS = 0;
                $public.NFSe.ValorCSLL = 0;
                $public.NFSe.ValorIR = 0;

                // Calculo do Valor Liquido da NFS-e
                $public.EscreverDescricaoDosServicos();


                sweetAlert.swal.insertQueueStep({
                  title: 'Removido!',
                  text: 'Impostos zerados com sucesso',
                  type: 'success',
                  timer: 2000,
                });
              });
            },
          },
        ]);
      }
    };

    $private.Arredondamento = function (numero, casasDecimais) {
      casasDecimais = typeof casasDecimais !== 'undefined' ? casasDecimais : 2;
      return +(
        Math.floor(numero + ('e+' + casasDecimais)) +
        ('e-' + casasDecimais)
      );
    };

    // Calculo do Imposto da NFS-e

    $public.CalculoDoImposto = function () {
      //Pegar Total da NFS-e:
      var totalDANFSe = 0;
      angular.forEach($public.NFSe.Itens, function (item) {
        totalDANFSe = totalDANFSe + item.ValorTotal;
      });
      //SETA o Valor total da NFS-e na variavel
      $public.NFSe.ValorTotal = totalDANFSe;

      // Campos Padrões:
      $public.NFSe.ValorDaDeducao = 0;
      $public.NFSe.ValorPIS = 0;
      $public.NFSe.ValorCOFINS = 0;
      $public.NFSe.ValorINSS = 0;
      $public.NFSe.ValorIR = 0;
      $public.NFSe.ValorCSLL = 0;

      $public.NFSe.ValorBaseCalcISSQN = 0;
      $public.NFSe.ISSQNDevido = 0;
      $public.NFSe.AliquotaISSQN = 0;

      // Regra 1: Verifica se o Prestador é Pessoa Fisica, se for os tributos são todos zerados
      if ($public.NFSe.Cliente.Tipo === 0) {
        console.log(
          '%cCLIENTE é Pessoa Física. O Imposto é zerado.',
          'color: blue; font-size:30px;'
        );
        $public.NFSe.AliquotaPIS = 0;
        $public.NFSe.AliquotaCOFINS = 0;
        $public.NFSe.AliquotaINSS = 0;
        $public.NFSe.AliquotaIR = 0;
        $public.NFSe.AliquotaCSLL = 0;
      } else {
        // Regra 2: Se o prestador é Pessoa Júridica

        //Regra 2.1: Informa o percentual padrão das Aliquotas
        $public.NFSe.AliquotaPIS = 0.0065;
        $public.NFSe.AliquotaCOFINS = 0.03;
        $public.NFSe.AliquotaINSS = 0;
        $public.NFSe.AliquotaIR = 0.015;
        $public.NFSe.AliquotaCSLL = 0.01;

        // Regra 2.3: Se For Lucro Presumido:

        if ($public.NFSe.Cliente.RegimeTributario.RegimeID == 2) {
          console.log(
            '%cO CLIENTE é optante do lucro presumido. Alíquota do ISSQN é a do Serviço selecionado.',
            'color: blue; font-size:30px;'
          );

          //Calculo do ISSQN Devido:
          if (!$public.NFSe.Cliente.PossuiISSFixo) {
            $public.NFSe.ValorBaseCalcISSQN = totalDANFSe;
            $public.NFSe.AliquotaISSQN = $public.NFSe.Servico.Aliquota;
            $public.NFSe.ISSQNDevido = totalDANFSe * $public.NFSe.AliquotaISSQN;
          }

          //Calculo do PIS/COFINS/INSS/IR/CSLL:
          $public.NFSe.ValorPIS = totalDANFSe * $public.NFSe.AliquotaPIS;
          $public.NFSe.ValorCOFINS = totalDANFSe * $public.NFSe.AliquotaCOFINS;
          $public.NFSe.ValorINSS = totalDANFSe * $public.NFSe.AliquotaINSS;
          $public.NFSe.ValorIR = totalDANFSe * $public.NFSe.AliquotaIR;
          $public.NFSe.ValorCSLL = totalDANFSe * $public.NFSe.AliquotaCSLL;
        } else {
          // Regra 2.2: Se For Simples Nacional

          // Regra 3: Tomador Retem o ISS?

          if ($public.NFSe.Tomador.RetemISS == false) {
            console.log(
              '%cCLIENTE é optante do SIMPLES NACIONAL, porem o TOMADOR não RETEM o ISS. O Imposto é zerado.',
              'color: blue; font-size:30px;'
            );
            // Regra 3.1: Não retem
            // Todos os valores são zerados.
            $public.NFSe.AliquotaPIS = 0;
            $public.NFSe.AliquotaCOFINS = 0;
            $public.NFSe.AliquotaINSS = 0;
            $public.NFSe.AliquotaIR = 0;
            $public.NFSe.AliquotaCSLL = 0;
          } else {
            // Regra 3.2: Tomador Retem o ISS
            // Utiliza a tabela de anexo para calculo.
            console.log(
              '%cCLIENTE é optante do SIMPLES NACIONAL, o TOMADOR RETEM o ISS. Alíquota do ISSQN é calculada com base na faixa do ANEXO informado.',
              'color: blue; font-size:17px;'
            );
            var faixaIDDoCliente = $public.FaixaSimplesNacional;
            var faixa = $public.NFSe.Anexo.Faixas[faixaIDDoCliente - 1];
            var faturamentoAnual =
              $public.NFSe.FaturamentoDoPrestador.FaturamentoAnual;

            // Verifica se o Cliente Possúi Faturamento:
            if (faturamentoAnual == 0) {
              $public.NFSe.ValorBaseCalcISSQN = totalDANFSe;
              $public.NFSe.AliquotaPIS = 0;
              $public.NFSe.AliquotaCOFINS = 0;
              $public.NFSe.AliquotaINSS = 0;
              $public.NFSe.AliquotaIR = 0;
              $public.NFSe.AliquotaCSLL = 0;

              var primeiroCalculo = faixa.Aliquota * faixa.ISS;
              $public.NFSe.AliquotaISSQN = primeiroCalculo;
              // $public.NFSe.AliquotaISSQN = 0;

              $public.NFSe.ISSQNDevido = totalSSQNDevido;
            } else {
              // Se Possuir faturamento então:

              var primeiroCalculo = faturamentoAnual * faixa.Aliquota;
              var segundoCalculo = primeiroCalculo - faixa.Deducao;
              // (3) Calc da Aliquota Efetiva.
              var calculoDaAliquotaEfetiva =
                (segundoCalculo / faturamentoAnual) * 100;
              var quartoCalculo = (calculoDaAliquotaEfetiva / 100) * faixa.ISS;
              $public.NFSe.AliquotaISSQN = quartoCalculo;

              // Arredondamento
              var quartoCalculoArredondado = quartoCalculo.toFixed(4);
              var ISSQNDevido = totalDANFSe * quartoCalculoArredondado;
              $public.NFSe.ISSQNDevido = $private.Arredondamento(
                ISSQNDevido,
                2
              );
            }
          }
        }
      }

      // Regra Willian e Lucas: Se o Tomador for pessoa fisica então tem que zerar os impostos:
      if ($public.NFSe.Tomador.Tipo === 0) {
        console.log('Regra Willian e Lucas: Se o Tomador for pessoa fisica então tem que zerar os impostos:')
        $public.NFSe.AliquotaPIS = 0;
        $public.NFSe.ValorPIS = 0;

        $public.NFSe.AliquotaCOFINS = 0;
        $public.NFSe.ValorCOFINS = 0;

        $public.NFSe.AliquotaINSS = 0;
        $public.NFSe.ValorINSS = 0;

        $public.NFSe.AliquotaIR = 0;
        $public.NFSe.ValorIR = 0;

        $public.NFSe.AliquotaCSLL = 0;
        $public.NFSe.ValorCSLL = 0;

      }
    };

    $public.GerarPDFPreview = function () {
      NFSeAPI.GetBase64PDFString($public.NFSe).then(function (response) {
        $scope.pdfUrl = response.data;
      });
    };

    $public.CalcularPercentualINSS = function () {
      var result = $public.NFSe.ValorINSS / $public.totalDaNFSe;
      $public.NFSe.AliquotaINSS = result;
    };

    $public.CalcularPercentualPIS = function () {
      var result = $public.NFSe.ValorPIS / $public.totalDaNFSe;
      $public.NFSe.AliquotaPIS = result;
    };

    $public.CalcularPercentualCOFINS = function () {
      var result = $public.NFSe.ValorCOFINS / $public.totalDaNFSe;
      $public.NFSe.AliquotaCOFINS = result;
    };

    $public.CalcularPercentualIR = function () {
      var result = $public.NFSe.ValorIR / $public.totalDaNFSe;
      $public.NFSe.AliquotaIR = result;
    };

    $public.CalcularPercentualCSLL = function () {
      var result = $public.NFSe.ValorCSLL / $public.totalDaNFSe;
      $public.NFSe.AliquotaCSLL = result;
    };

    //MODAL Incluir e Editar Tomador
    $public.abrirModalNovoEditarTomador = function (itemSelecionado, type) {
      console.log(itemSelecionado);
      var modalNovoEditarTomadoresInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/tomadores/modals/modalNovoEditarTomador.html',
        controller: 'modalNovoEditarTomadorNaEmissaoCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          tomadorSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            tomadoresAPI
              .GetByID(itemSelecionado)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              })
              .finally(function () {
                $public.loading = false;
              });

            return deferred.promise;
          },

          configuracoesDoUsuario: function () {
            //$public.loading = false;
            return false;
          },
          camposObrigatorios: function () {
            var deferred = $q.defer();

            tomadoresAPI
              .GetCamposObrigatoriosDoTomador()
              .then(function (response) {
                if (response) {
                  deferred.resolve(response.data);
                } else {
                  deferred.resolve(undefined);
                }
              });
            return deferred.promise;
          },
          type: function () {
            return type;
          },
        },
      });

      modalNovoEditarTomadoresInstance.result.then(function (vm) {
        NFSeAPI.GetTomadoresWithSearch({
          Page: 1,
          Search: vm.Nome,
          OrderBy: 'Nome',
        }).then(function (response) {
          console.log(response.data);
          $public.Tomadores = response.data.Registros;
        });

        if (vm.type == 'Tomador') {
          $public.OnSelectedTomadores(vm.tomador);
        } else {
          $public.GetUISelectAsync();
          $public.OnSelectedIntermediarios(vm.tomador);
        }
      });
    };

    $public.loadMore = function () {
      $public.currentElement = $public.currentElement + 20;
    };

    $public.GetUISelectAsync = function (search) {
      $public.currentElement = 20;

      if (!search) {
        return;
      }

      $public.Loading = true;
      NFSeAPI.GetTomadoresWithSearch({
        Search: search,
        Page: 1,
        OrderBy: 'Nome',
        SearchField: 'Nome',
      })
        .then(function (response) {
          $public.Tomadores = response.data.Registros;
          $public.Loading = false;
        })
        .catch(function () {
          $public.Loading = false;
        });
    };

    $private.Init = function () {
      $public.Steps = {
        PrestadorETomador: 'Empresa e Cliente',
        Anexo: 'Anexo da Empresa',
        Servico: 'Serviço Prestado',
        ListaDeItems: 'Itens da NFS-e',
        DescricaoImpostoEValidacao: 'Outras Informações, Imposto e Emissão',
        ConfirmacaoEEmissao: 'Confirmação dos Dados',
      };

      $public.Enuns = EnumFactory;
      $public.CurrentStep = $public.Steps.PrestadorETomador;
      $public.TomadorIntermediario = {};

      $public.LimiteDeItens = null;
      $private.MunicipiosComLimite = [
        {MunicipioID: 4894, Max: 25},
      ];

      NFSeAPI.GetClientes().then(function (response) {
        console.log('TESTEEEE: ', response);

        $public.Prestadores = response.data;


        if (response.data.length === 1) {
          $public.NFSe.Cliente = response.data[0];
          $public.onSelectedPrestadores($public.NFSe.Cliente);
        }
      });

      NFSeAPI.GetTomadoresWithSearch({
        Page: 1,
        OrderBy: 'Nome',
      }).then(function (response) {
        console.log(response.data);
        $public.Tomadores = response.data.Registros;
      });
      // NFSeAPI.GetTomadores().then(function (response) {
      //   $public.Tomadores = response.data;
      // });

      NFSeAPI.GetAnexos().then(function (response) {
        $public.Anexo = response.data;
      });

      $public.BuscaMunicipios();

      $public.loading = false;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao menu: Emitir NFS-e',
        'operacional.emitirnfse',
        'Acesso ao menu de Emitir NFS-e',
        null,
        null,
        null
      );

      // AutoComplete:
    };

    $private.Init();
  }

  function modalNovoItemCtrl(
    $scope,
    $uibModalInstance,
    sweetAlert,
    Item,
    medicos,
    ListaDeDescricoesDeItem,
    NFSeAPI,
    ClienteID
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.Enviar = function () {
      if (
        $public.GetTotalFracionadoMedicos() !=
        $public.Item.ValorTotal.toFixed(2)
      ) {
        sweetAlert.swal(
          '',
          'O Valor total do ITEM deve ser preenchido com o total da cota.',
          'warning'
        );
        //alert('Valor deve ser preenchido total bla bla bla');
        // vc abre o sweetalert pra avisar o kra
        return;
      }

      $uibModalInstance.close($public.Item);
    };

    function arredondar(nr) {
      if (nr.indexOf(',') != -1) nr = nr.replace(',', '.');
      nr = parseFloat(nr) * 100;
      return Math.floor(nr) / 100;
    }

    $public.GetValorTotalSemFracao = function (value) {
      if (value) {
        return Math.round(value * 1e2) / 1e2;
      }
    };

    $public.GetTotalFracionadoMedicos = function () {
      var result = 0;

      angular.forEach($public.Item.Cotas, function (item) {
        result = result + item.ValorDaCota;
      });
      return Math.round(result * 100) / 100;
    };

    $public.abrirModalExcluirCotaDoMedico = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover a Cota do Médico <strong><u>' +
            itemSelecionado.Medico.Nome +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return $scope.$apply(function () {
              var index = $public.Item.Cotas.indexOf(itemSelecionado);
              //Atualiza o valor total da Prestação de Serviço
              $public.totalDaNFSe =
                $public.totalDaNFSe -
                $public.Item.Cotas[index].Medico.ValorTotal;

              $public.Item.Cotas.splice(index, 1);

              sweetAlert.swal.insertQueueStep({
                title: 'Removido!',
                text: 'Cota do Médico removida com sucesso',
                type: 'success',
                timer: 2000,
              });
            });
          },
        },
      ]);
    };

    $public.IncluirCotaDoMedico = function (vm) {
      if (
        Math.round($public.GetTotalFracionadoMedicos() + vm.ValorDaCota * 100) /
          100 >
        Math.round($public.Item.ValorTotal * 1e2) / 1e2
        //Math.round($public.Item.ValorTotal)
      ) {
        sweetAlert.swal(
          '',
          'O valor inserido ultrapassa o valor total disponível.',
          'warning'
        );
        // alert('o valor que voce esta inserindo ultrapassa o valor total permitido....');
        return;
      }

      $public.Item.Cotas.push(angular.copy(vm));
      // $public.Cota.Medicos = undefined;
      // $public.Cota.Valor = 0;
    };

    $public.somarValores = function () {
      var quantidade = $public.Item.Quantidade;
      var ValorUnitario = $public.Item.ValorUnitario;
      var total = quantidade * ValorUnitario;

      $public.Item.ValorTotal = total;
    };

    $public.loadMore = function () {
      console.log($public.currentElement);
      $public.currentElement = $public.currentElement + 20;
    };

    $public.GetUISelectAsync = function (search) {
      console.log('Entrou!: ', ClienteID)
      console.log('Search!', search)
      $public.currentElement = 20;

      // if (!search) {
      //   return;
      // }

      $public.Loading = true;
      NFSeAPI.GetItensByClienteID(ClienteID, ({
          Search: search,
          Max: 100,
          Page: 1,
          SearchField: 'Descricao',
        }))
        .then(function (response) {
          $public.ListaDeDescricoesDeItem = response.data.Registros;
          console.log(response.data.Registros);
          $public.Loading = false;
        })
        .catch(function () {
          $public.Loading = false;
        })

      }

    $private.Init = function () {
      $public.Item = {};
      $public.Item.Cotas = [];
      $public.ListaDeDescricoesDeItem = ListaDeDescricoesDeItem.Registros;

      $public.ListaDeMedicos = {};
      $public.ListaDeMedicos.Medicos = medicos;

      $public.Title = Item ? 'Visualizar Item' : 'Incluir Item';
    };

    $private.Init();
  }

  function modalPercDeCadaMedicoCtrl($uibModalInstance, Itens) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $private.Init = function () {
      $public.Title = 'Visualizar Cota';
      $public.Itens = Itens;
    };

    $private.Init();
  }

  function modalNovoEditarTomadorNaEmissaoCtrl(
    $uibModalInstance,
    sweetAlert,
    EnumFactory,
    viaCepAPI,
    tomadorSelecionado,
    municipiosAPI,
    tomadoresAPI,
    configuracoesDoUsuario,
    camposObrigatorios,
    type
  ) {
    var $public = this;
    var $private = {};

    $public.endereco = {};
    $public.CEPPendente = false;
    $public.CNPJPendente = false;

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $public.buscarCep = function () {
      $public.ErroViaCEP = undefined;

      if (
        !$public.tomador ||
        !$public.tomador.Endereco.CEP ||
        !$public.tomador.Endereco
      ) {
        return;
      }
      $public.CEPPendente = true;
      viaCepAPI
        .GetEndereco($public.tomador.Endereco.CEP)
        .then(function (response) {
          if (response.data.erro) {
            $public.ErroViaCEP = response.data;
            $public.CEPPendente = false;
            return;
          }

          $public.CEPPendente = false;

          $public.tomador.Endereco.Logradouro = response.data.logradouro;
          $public.tomador.Endereco.Bairro = response.data.bairro;
          $public.tomador.Endereco.CodIBGE = response.data.ibge;
          $public.tomador.Endereco.CodGIA = response.data.gia;
          $public.tomador.Endereco.CodSIAFI = response.data.SIAFI.CodSIAFI;
          $public.tomador.Endereco.Municipio = {};
          $public.ViaCEP = response.data;

          $public.tomador.Endereco.Numero = undefined;
          $public.tomador.Endereco.Complemento = undefined;

          municipiosAPI
            .GetByNome(response.data.localidade, response.data.uf)
            .then(function (response) {
              $public.tomador.Endereco.Municipio.UF = response.data.UF;
              $public.tomador.Endereco.MunicipioID = response.data.MunicipioID;

              $public.BuscaMunicipios(false);
            })
            .catch(function (response) {
              $public.BuscaMunicipios(true);
            });

          if (response.data.logradouro === '') {
            $('#signup_form_Endereco').focus();
          } else {
            $('#signup_form_Numero').focus();
          }
        })
        .catch(function () {
          $public.CEPPendente = false;
          $public.tomador.Endereco.Logradouro = '';
          $public.tomador.Endereco.Bairro = '';
          $public.tomador.Endereco.Localidade = '';
          $public.tomador.Endereco.UF = '';
          $public.tomador.Endereco.CodIBGE = '';
          $public.tomador.Endereco.CodGIA = '';
          $public.tomador.Endereco.CodSIAFI = '';
          $('#signup_form_Endereco').focus();
          return;
        });
    };

    $public.buscarCNPJ = function () {
      if (!$public.tomador || !$public.tomador.CNPJ) {
        $public.CNPJPendente = false;
        return;
      }
      if ($public.tomador.CNPJ.length < 13) {
        $public.CNPJPendente = false;
        return;
      }

      $public.CNPJPendente = true;
      $public.BuscouCNPJOuCPF = false;

      tomadoresAPI
        .GetByCNPJorCPF($public.tomador.CNPJ)
        .then(function (response) {
          $public.BuscouCNPJOuCPF = true;
          if (response.data.erro) {
            $public.CNPJPendente = false;
            return;
          }
          if (response.data) {
            $public.tomador.TomadorID = response.data.TomadorID;
            $public.tomador.Ativo = response.data.Ativo;
            $public.tomador.Tipo = response.data.Tipo;
            $public.tomador.CNPJ = response.data.CNPJ;
            $public.tomador.InscricaoMunicipal =
              response.data.InscricaoMunicipal;
            $public.tomador.RetemISS = response.data.RetemISS;
            $public.tomador.Nome = response.data.Nome;
            $public.tomador.Email = response.data.Email;
            $public.tomador.Contato = response.data.Contato;
            $public.tomador.Telefone = response.data.Telefone;

            $public.tomador.Endereco = {};
            $public.tomador.Endereco.CEP = response.data.Endereco.CEP;
            $public.tomador.Endereco.Logradouro =
              response.data.Endereco.Logradouro;
            $public.tomador.Endereco.Numero = response.data.Endereco.Numero;
            $public.tomador.Endereco.Complemento =
              response.data.Endereco.Complemento;
            $public.tomador.Endereco.Bairro = response.data.Endereco.Bairro;

            $public.tomador.Endereco.Municipio = {};
            $public.tomador.Endereco.Municipio.UF =
              response.data.Endereco.Municipio.UF;

            $public.BuscaMunicipios(false);
            $public.tomador.Endereco.MunicipioID =
              response.data.Endereco.Municipio.MunicipioID;
            $public.tomador.Endereco.CodSIAFI =
              response.data.Endereco.Municipio.SIAFI;

            $public.CNPJPendente = false;
            $public.BuscouCNPJOuCPF = true;
            $('#signup_form_Nome').focus();
          }

          $public.CNPJPendente = false;
          $public.BuscouCNPJOuCPF = true;
          $('#signup_form_Nome').focus();
        })
        .catch(function (err) {
          $public.CNPJPendente = false;
          $public.BuscouCNPJOuCPF = true;
          if (err.status === 409) {
            sweetAlert.swal('O Tomador já cadastrado', '', 'info');
            $public.tomador.CNPJ = '';
            $public.tomador.Nome = '';
            $public.tomador.Email = '';
            $public.tomador.Contato = '';
            $public.tomador.Telefone = '';
            $public.BuscouCNPJOuCPF = false;
            $('#signup_form_Nome').focus();
          }
          $public.tomador.InscricaoMunicipal = '';
          $public.tomador.RetemISS = false;
          $public.tomador.Nome = '';
          $public.tomador.Email = '';
          $public.tomador.Contato = '';
          $public.tomador.Telefone = '';
        });
    };

    $public.buscarCPF = function () {
      if (!$public.tomador || !$public.tomador.CPF) {
        $public.CPFPendente = false;
        return;
      }
      if ($public.tomador.CPF.length < 10) {
        $public.CPFPendente = false;
        return;
      }

      $public.CPFPendente = true;
      $public.BuscouCNPJOuCPF = false;

      tomadoresAPI
        .GetByCNPJorCPF($public.tomador.CPF)
        .then(function (response) {
          $public.BuscouCNPJOuCPF = true;
          if (response.data.erro) {
            $public.CPFPendente = false;
            return;
          }
          if (response.data) {
            $public.tomador.TomadorID = response.data.TomadorID;
            $public.tomador.Ativo = response.data.Ativo;
            $public.tomador.Tipo = response.data.Tipo;
            $public.tomador.CPF = response.data.CPF;
            $public.tomador.RG = response.data.RG;
            $public.tomador.Nome = response.data.Nome;
            $public.tomador.Email = response.data.Email;
            $public.tomador.Contato = response.data.Contato;
            $public.tomador.Telefone = response.data.Telefone;

            $public.tomador.Endereco = {};
            $public.tomador.Endereco.CEP = response.data.Endereco.CEP;
            $public.tomador.Endereco.Logradouro =
              response.data.Endereco.Logradouro;
            $public.tomador.Endereco.Numero = response.data.Endereco.Numero;
            $public.tomador.Endereco.Complemento =
              response.data.Endereco.Complemento;
            $public.tomador.Endereco.Bairro = response.data.Endereco.Bairro;

            $public.tomador.Endereco.Municipio = {};
            $public.tomador.Endereco.Municipio.UF =
              response.data.Endereco.Municipio.UF;

            $public.BuscaMunicipios(false);
            $public.tomador.Endereco.MunicipioID =
              response.data.Endereco.Municipio.MunicipioID;

            $public.tomador.Endereco.CodSIAFI =
              response.data.Endereco.Municipio.SIAFI;

            //$public.tomador.Endereco.Municipio.MunicipioID = response.data.tomador.Endereco.Municipio.MunicipioID;

            //$public.tomador.Endereco.CEP = response.data.Endereco.CEP;
            //$public.tomador.Endereco.Logradouro = response.data.Endereco.Logradouro;
            // $public.tomador.Endereco.Bairro = response.data.Endereco.bairro;
            //$public.tomador.Endereco.CodIBGE = response.data.Endereco.ibge;
            //$public.tomador.Endereco.CodGIA = response.data.Endereco.gia;
            $public.CPFPendente = false;
            $public.BuscouCNPJOuCPF = true;
            $('#signup_form_CPF').focus();
          }

          $public.CPFPendente = false;
          $public.BuscouCNPJOuCPF = true;
          $('#signup_form_CPF').focus();
        })
        .catch(function (err) {
          $public.CPFPendente = false;
          $public.BuscouCNPJOuCPF = true;
          if (err.status === 409) {
            sweetAlert.swal('O Tomador já cadastrado', '', 'info');
            $public.tomador.CPF = '';
            $public.tomador.RG = '';
            $public.tomador.Nome = '';
            $public.tomador.Email = '';
            $public.tomador.Contato = '';
            $public.tomador.Telefone = '';
            $public.BuscouCNPJOuCPF = false;
            $('#signup_form_Nome').focus();
          }

          //$public.tomador.Ativo = 1;
          //$public.tomador.Tipo = response.data.Tipo;
          //$public.tomador.CNPJ = response.data.CNPJ;
          $public.tomador.InscricaoMunicipal = response.data.InscricaoMunicipal;
          $public.tomador.RetemISS = false;
          $public.tomador.Nome = '';
          $public.tomador.Email = '';
          $public.tomador.Contato = '';
          $public.tomador.Telefone = '';

          //$("#signup_form_Endereco").focus();
        });
    };

    $public.BuscaMunicipios = function (limparModel) {
      // Sempre que trocar de estado, limpa os campos
      $public.Municipio = [];

      if (limparModel) {
        $public.tomador.Endereco.MunicipioID = undefined;
      }

      if (!$public.tomador.Endereco.Municipio.UF) {
        return;
      }

      municipiosAPI
        .GetByUF($public.tomador.Endereco.Municipio.UF)
        .then(function (response) {
          $public.Municipio = response.data;
        });
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = tomadorSelecionado ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close({ tomador: $public.tomador, type: type });
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O tomador não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um tomador com este CNPJ ou CPF: \n' +
                  $public.tomador.Nome,
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (tomadorSelecionado) {
        // EDITAR TOMADOR
        tomadoresAPI
          .Put($public.tomador)
          .then(result.OnSuccess)
          .catch(result.OnError);
      } else {
        // CADASTRAR TOMADOR
        tomadoresAPI
          .Post($public.tomador)
          .then(function (response) {
            $public.tomador = response.data;
          })
          .finally(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $private.Init = function () {
      $public.tomador = tomadorSelecionado;

      $public.type = type;
      var Config = configuracoesDoUsuario;

      if (type == 'Tomador') {
        $public.Title = tomadorSelecionado
          ? 'Editar Cliente'
          : 'Incluir Novo Cliente';
      } else {
        $public.Title = tomadorSelecionado
          ? 'Editar Cliente (Intermediario)'
          : 'Incluir Novo Cliente (Intermediario)';
      }

      $public.Enuns = EnumFactory;

      $public.BuscouCNPJOuCPF = false;

      if (tomadorSelecionado) {
        $public.BuscouCNPJOuCPF = true;
        $public.BuscaMunicipios();
      }

      $public.camposObrigatorios =
        camposObrigatorios.CamposObrigatoriosDoTomador;
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular.module('fokus').controller('importarXMLCtrl', importarXMLCtrl);

  importarXMLCtrl.$inject = [
    '$scope',
    '$q',
    'config',
    'sweetAlert',
    'EnumFactory',
    'AuthFactory',
    'mitUploadAPI',
    'AuditoriaFactory',
    'clientesAPI',
  ];

  function importarXMLCtrl(
    $scope,
    $q,
    config,
    sweetAlert,
    EnumFactory,
    AuthFactory,
    mitUploadAPI,
    AuditoriaFactory,
    clientesAPI
  ) {
    var $public = this;
    var $private = {};

    $public.TipoArquivo = [];

    const WITHOUT_COMPANY_ID = 2;

    $private.GetClientes = function () {
      var deferred = $q.defer();
      clientesAPI
        .GetCompleto()
        .then(function (response) {
          deferred.resolve(($public.Clientes = response.data));
          let data = {
            ClienteID: WITHOUT_COMPANY_ID,
            Nome: 'SEM EMPRESA',
          };
          $public.Clientes = [data, ...$public.Clientes];
        })
        .catch(function () {
          deferred.resolve(undefined);
        });

      return deferred.promise;
    };

    $scope.getEmployedHomologation = function (county) {
      if (county) {
        mitUploadAPI
          .CheckHomologation(county.UF, county.Nome)
          .then(function (response) {
            var { homologado } = JSON.parse(response.data);

            if (!homologado) {
              sweetAlert.swal({
                title: 'Prefeitura não homologada para importação de XML.',
                type: 'error',
              });
              $public.ClienteSelecionado = undefined;
              $public.TipoArquivoSelecionado = undefined;
            }
          });
      }
      if ($public.ClienteSelecionado?.ClienteID === WITHOUT_COMPANY_ID) {
        let tomador = {
          Nome: 'Tomador',
          Valor: 'tomador',
        };

        let nfse = {
          Nome: 'NFS-e',
          Valor: 'nfse',
        };

        

        $public.TipoDeArquivos = [tomador, nfse];
        $public.TipoArquivoSelecionado = tomador || nfse;
      } else {
        $public.TipoDeArquivos = EnumFactory.MITUploadTemplate;
      }
    };

    function SeparaArray(itens) {
      var elementos = new Map();
      var indice = 0;
      for (indice = 0; indice < itens.length; indice++) {
        var extensao = ObterExtensao(itens[indice].name).toLowerCase();
        if (!elementos.has(extensao)) {
          elementos.set(extensao, []);
        }

        elementos.get(extensao).push(itens[indice]);
      }
      return elementos;
    }

    function ObterExtensao(str) {
      var ponto = str.lastIndexOf('.');
      if (ponto >= 0) {
        return str.substring(ponto + 1);
      } else {
        return '';
      }
    }

    $public.clearForm = function () {
      $public.Plupload.Files = [];
      $public.Plupload.Percent = 0;
      $public.ClienteSelecionado = undefined;
      $public.TipoArquivoSelecionado = undefined;
    };

    $private.Init = function () {
      $public.Clientes = null;
      $public.TipoDeArquivos = EnumFactory.MITUploadTemplate;
      $public.Title = 'Importar Arquivos';
      $private.GetClientes();
      $public.Enuns = EnumFactory;
      $public.Periodo = new Date();
      $public.originalList = [];

      //Anexo de documentos
      $public.Plupload = {
        Files: [],
        FileTypes: [{ title: 'All Files', extensions: '*' }],
        Percent: null,
        Uploader: {},
        // Params: { ClienteID: 1, Tipo: $public.TipoArquivo }, //Tipo 0 = ImportarXML
        Enviar: function (TipoArquivoSelecionado) {
          this.Uploader.settings.headers = {
            Authorization: 'Bearer ' + AuthFactory.Token,
          };

          var arrSeparado = SeparaArray($public.Plupload.Files);          
          switch (TipoArquivoSelecionado.Valor) {            
            case 'cliente':
              arrSeparado.forEach((element, fileExtension) => {
                var url = `/api/mitupload/upload/cliente`;
                this.Uploader.settings.url = config.baseUrl + url;
                $public.Plupload.Files = element;
              });
              break;

            default:
              if($public.ClienteSelecionado.ClienteID === 2){
                arrSeparado.forEach((element, fileExtension) => {
                  var url = `/api/mitupload/upload/nfse/padrao/${fileExtension}`;
                  this.Uploader.settings.url = config.baseUrl + url;
                  $public.Plupload.Files = element;
                });
              } else {
                arrSeparado.forEach((element, fileExtension) => {
                  var url = `/api/mitupload/upload/nfse/${fileExtension}/client/${$public.ClienteSelecionado.ClienteID}`;
                  this.Uploader.settings.url = config.baseUrl + url;
                  $public.Plupload.Files = element;
                });
              }
              break;
          }

          this.Uploader.start();
          // arrSeparado.forEach((element, fileExtension) => {
          //   var url = `/api/mitupload/upload/nfse/${fileExtension}/client/${$public.ClienteSelecionado.ClienteID}`;
          //   this.Uploader.settings.url = config.baseUrl + url;
          //   $public.Plupload.Files = element;
          //   this.Uploader.start();
          // });
        },
      };

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao modal: Importar Arquivos',
        'modal.importararquivos',
        'Acessou o modal de Importar Arquivos',
        null,
        null,
        null
      );
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('ManutencaoNFSeCtrl', ManutencaoNFSeCtrl)
    .controller(
      'modalPercentualDeCadaMedicoCtrl',
      modalPercentualDeCadaMedicoCtrl
    )
    .controller(
      'modalIncluirEditarMedicoNaCotaCtrl',
      modalIncluirEditarMedicoNaCotaCtrl
    )
    .controller('modalStatusNFSeCtrl', modalStatusNFSeCtrl)
    .controller('modalLogsDeRejeicaoDaNFSeCtrl', modalLogsDeRejeicaoDaNFSeCtrl)
    .controller('modalEditarNFSECtrl', modalEditarNFSECtrl)
    .controller('modalInformarRecebimentoCtrl', modalInformarRecebimentoCtrl)
    .controller('modalJSONDeEnvioNFSeCtrl', modalJSONDeEnvioNFSeCtrl)
    .controller('modalEspelharNFSeCtrl', modalEspelharNFSeCtrl)
    .controller(
      'modalIncluirItemNaNotaPelaConsultaCtrl',
      modalIncluirItemNaNotaPelaConsultaCtrl
    );

  ManutencaoNFSeCtrl.$inject = [
    '$q',
    '$scope',
    '$uibModal',
    '$window',
    '$filter',
    'config',
    'sweetAlert',
    'toastr',
    'SocketIO',
    'NFSeAPI',
    'EnumFactory',
    'AuthFactory',
    'emptyDoubleClickSelectFactory',
    'AuditoriaFactory',
  ];
  modalPercentualDeCadaMedicoCtrl.$inject = [
    '$q',
    '$uibModal',
    '$uibModalInstance',
    'sweetAlert',
    'toastr',
    'NFSeAPI',
    'NFSeItemCotaAPI',
    'clientesAPI',
    'Itens',
    'medicos',
    'NFSe',
  ];
  modalIncluirEditarMedicoNaCotaCtrl.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    'NFSeItemCotaAPI',
    'medicos',
    'item',
  ];
  modalStatusNFSeCtrl.$inject = ['$uibModalInstance', 'Status'];
  modalLogsDeRejeicaoDaNFSeCtrl.$inject = ['$uibModalInstance', 'Motivos'];
  modalEditarNFSECtrl.$inject = ['$uibModalInstance', 'NFSeAPI', 'NFSe'];
  modalInformarRecebimentoCtrl.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    '$filter',
    'NFSeAPI',
    'NFSe',
    'ValorDaNFSe',
  ];
  modalJSONDeEnvioNFSeCtrl.$inject = ['$uibModalInstance', 'NFSe'];
  modalEspelharNFSeCtrl.$inject = [
    '$scope',
    'toastr',
    '$uibModalInstance',
    'sweetAlert',
    '$filter',
    'NFSe',
    'Medicos',
    'NFSeAPI',
  ];
  modalIncluirItemNaNotaPelaConsultaCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'sweetAlert',
    'toastr',
    'NFSeAPI',
    'nfse',
    'itens',
  ];

  function ManutencaoNFSeCtrl(
    $q,
    $scope,
    $uibModal,
    $window,
    $filter,
    config,
    sweetAlert,
    toastr,
    SocketIO,
    NFSeAPI,
    EnumFactory,
    AuthFactory,
    emptyDoubleClickSelectFactory,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};

    $public.GetNFSe = function () {
      NFSeAPI.Get({...$public.PaginationConfig.Paginacao, Page: 1})
        .then(function (response) {         
          $public.NFSe_Emitidas = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = 1;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    $public.AlterPage = function () {
        NFSeAPI.Get($public.PaginationConfig.Paginacao)
        .then(function (response) {         
          $public.NFSe_Emitidas = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    // MODAL EspelharNFSe
    $public.EspelharNFSe = function (itemSelecionado) {
      var modalEspelharInstance = $uibModal.open({
        templateUrl:
          '/views/operacional/nfseManutencao/modals/modalEspelharWithDescAndValue.html',
        controller: 'modalEspelharNFSeCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          NFSe: function () {
            var deferred = $q.defer();

            NFSeAPI.GetByID(itemSelecionado.NFSeID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          Medicos: function () {
            var deferred = $q.defer();

            NFSeAPI.GetMedicosByClienteID(itemSelecionado.ClienteID)
              .then(function (response) {
                deferred.resolve(response.data.Items);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalEspelharInstance.result.then(function () {
        console.log('Retornou');
        $public.GetNFSe();
      });
    };

    //GERAR RPS EM PDF
    $public.SolicitarRPSemPDF = function (NFSe) {
      emptyDoubleClickSelectFactory.request();

      if (![3, 10, 5].includes(NFSe.StatusID)) return;

      var numeroDaNota = 0;
      if (NFSe.ImportadaViaXML == true) {
        numeroDaNota = NFSe.NumeroDaNota;
      } else {
        numeroDaNota = NFSe.NumeroDaNotaConvertido;
      }

      NFSeAPI.GetRPS(NFSe.NFSeID)
        .then(function (response) {
          var fileUrl = response.data;
          $window.open(fileUrl);
          console.log(fileUrl);
          //   setTimeout(function(){ NFSeAPI.DeleteRPSPDFFile(filename) }, 5000);
        })
        .catch((error) => {
          console.log(error);
          sweetAlert.swal.fire({
            title: 'PDF indisponível',
            text: 'Para maiores informações, entre em contato com seu Gestor.',
            type: 'error',
            timer: 3500,
          });
        });
    };

    //ENVIAR RPS POR EMAIL
    $public.EnviarRPSPorEmail = function (NFSe) {
      sweetAlert.swal
        .mixin({
          input: 'email',
          confirmButtonColor: '#262763',
          confirmButtonText: 'Próximo &rarr;',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          progressSteps: ['1', '2'],
        })
        .queue([
          {
            title: 'Enviar RPS por E-Mail',
            input: 'text',
            inputValue: NFSe.EmailTomador,
            html: 'Informe o e-mail do destinatário<br><br><strong>Caso tenha mais de um destinatário separar por ;</strong>',
          },
          {
            title: 'Enviar RPS por E-Mail',
            input: 'textarea',
            html: 'Informe o corpo da mensagem',
          },
        ])
        .then((result) => {
          var listaDeDestinatario = [];
          var listaDeDestinatario = result.value[0].split(';');

          if (result.value) {
            NFSeAPI.EnviarRPSPorEmail(
              NFSe.NFSeID,
              listaDeDestinatario,
              result.value[1]
            ).then(function (response) {
              sweetAlert.swal.fire({
                title: 'E-mail enviado!',
                text: '',
                type: 'success',
                timer: 3500,
              });
            });
          }
        });
    };

    //MODAL Percentual Medico de cada Item
    $public.abrirModalPercentualDeCadaMedicoCtrl = function (itemSelecionado) {
      $uibModal.open({
        templateUrl:
          '/views/operacional/modals/modalVisualizarPercentualDeCadaMedico.html',
        controller: 'modalPercentualDeCadaMedicoCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          Itens: function () {
            var deferred = $q.defer();

            NFSeAPI.GetByID(itemSelecionado.NFSeID)
              .then(function (response) {
                deferred.resolve(response.data.Items);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          medicos: function () {
            var deferred = $q.defer();

            NFSeAPI.GetMedicosByClienteID(itemSelecionado.ClienteID)
              .then(function (response) {
                deferred.resolve(response.data.Items);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          NFSe: function () {
            return itemSelecionado;
          },
        },
      });
    };

    //MODAL Histórico de Transação da NFS-e Emitida
    $public.abrirModalHistoricoDeTransacao = function (NFSeID) {
      $uibModal.open({
        templateUrl:
          '/views/operacional/nfseManutencao/modals/modalVisualizarStatusDaNFSe.html',
        controller: 'modalStatusNFSeCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'md',
        resolve: {
          Status: function () {
            var deferred = $q.defer();

            NFSeAPI.GetStatusDaNFSe(NFSeID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });
    };

    //MODAL Logs de Rejeição da NFS-e Enviada
    $public.abrirModalLogsDeRejeicao = function (NFSeID) {
      $uibModal.open({
        templateUrl:
          '/views/operacional/nfseManutencao/modals/modalLogsDeRejeicao.html',
        controller: 'modalLogsDeRejeicaoDaNFSeCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          Motivos: function () {
            var deferred = $q.defer();

            NFSeAPI.GetLogDeErros(NFSeID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });
    };

    $public.abrirPOPUPIDNFSe = function (NFSe) {
      var id = null;
      if (NFSe.PlugNotasID != null) {
        id = NFSe.PlugNotasID;
      }

      if (NFSe.EnotasNFeID != null) {
        id = NFSe.EnotasNFeID;
      }

      console.log(NFSe);
      if (NFSe.EmissorID == 4) {
        id = NFSe.NFSeID;
      }

      sweetAlert.swal.fire({
        title: 'ID da NFS-e',
        text: id,
      });
    };

    //POOPUP Motivo do Cancelamento
    $public.abrirPopUpMotivoCancelamento = function (NFSe) {
      if (NFSe.ImportadaViaXML == false) {
        sweetAlert.swal.fire({
          title: 'Motivo do Cancelamento',
          text: NFSe.MotivoCancelamento.toUpperCase(),
        });
      } else {
        sweetAlert.swal.fire({
          title: 'Nota Importada',
          text: 'Esta NFS-e foi importada via XML, realize a consulta diretamente no site da prefeitura.',
        });
      }
    };

    //POPUP Cancelar NFS-e
    $public.abrirModalCancelarNFSe = function (NFSe) {
      var numeroDaNota = 0;
      if (NFSe.ImportadaViaXML == true) {
        numeroDaNota = NFSe.NumeroDaNota;
      } else {
        numeroDaNota = NFSe.NumeroDaNotaConvertido;
      }

      sweetAlert.swal
        .mixin({
          input: 'text',
          type: 'warning',
          confirmButtonColor: '#262763',
          confirmButtonText: 'Próximo &rarr;',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          progressSteps: ['1'],
        })
        .queue([
          {
            title: 'Cancelar RPS ' + numeroDaNota,
            input: 'text',
            //inputValue: Motivo,
            html: 'Informe o motivo do cancelamento',
          },
        ])
        .then((result) => {
          if (result.value) {
            NFSeAPI.CancelarNFSe(NFSe, result.value[0])
              .then(function (response) {
                $public.GetNFSe();
                toastr.info(
                  `O pedido de <strong>cancelamento</strong> da NFS-e <strong>${numeroDaNota}</strong> foi enviado para prefeitura.`,
                  'NFS-e',
                  {
                    autoDismiss: true,
                    allowHtml: true,
                    preventDuplicates: true,
                    closeButton: true,
                    progressBar: true,
                    timeOut: 3000,
                  }
                );
              })
              .catch(function (err) {
                console.log(err);
                switch (err.status) {
                  case 409:
                    sweetAlert.swal.fire({
                      type: 'warning',
                      title: 'Rejeição de Cancelamento',
                      html: `Não foi possivel cancelar a última NFS-e pois a empresa está cadastrada no E-Transparência.`,
                    });
                    break;

                  default:
                    sweetAlert.swal.fire({
                      type: 'warning',
                      title: 'Rejeição de Cancelamento',
                      html: `Verifique se a NFS-e já se encontra cancelada na prefeitura, caso sim realize a baixa manualmente.<br><br><b>Motivo da Rejeição:</b><br><span style='color:red'><b>${err.data.Message}</b><span>`,
                    });
                    break;
                }
              });
          }
        });
    };

    //POPUP Excluir NFS-e não autorizada
    $public.AbrirPopupExcluirNFSENaoAutorizada = function (NFSe) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja EXCLUIR do sistema à NFS-E não autorizada de número <strong><u>' +
            NFSe.NumeroDaNotaConvertido +
            '</u></strong>?' +
            "<br><br><div style='color:#262763; font-size=15px;'><strong>Observação:</strong><br>Este processo somente excluí à <strong>NFSe</strong> do sistema.</div>",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, excluir!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return NFSeAPI.DeletarNFSeNaoAutorizada(NFSe.NFSeID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Excluída!',
                  text: 'NFS-e excluída com sucesso',
                  type: 'success',
                  timer: 2000,
                });
                $public.GetNFSe();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'NFS-e não encontrada, Verifique!',
                      type: 'error',
                    });
                    break;
                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.realizarAlteracaoDeRetencaoDoISS = function (NFSe) {
      var frase = '';

      if (NFSe.RetemISS == 1) {
        frase = 'Pago pelo Prestador';
      } else {
        frase = 'Retido pelo Tomador';
      }
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            "O ISS da NFSe é <strong><u>'" +
            frase +
            "'</u></strong>, deseja realizar a alteração?" +
            "<br><br><div style='color:#262763; font-size=15px;'><strong>Observação:</strong><br>Alterar estes dados " +
            ' pode acarretar divergência entre o sistema e a Prefeitura.</div>' +
            '<br>Deseja continuar?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#14B0A5',
          confirmButtonText: 'Sim, alterar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return NFSeAPI.PutToogleTipoDeRecolhimentoDaNFSe(NFSe.NFSeID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Alterada!',
                  text: 'O Tipo de Regimento da NFS-e foi alterado com sucesso',
                  type: 'success',
                  timer: 2000,
                });
                $public.GetNFSe();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'NFS-e não encontrada, Verifique!',
                      type: 'error',
                    });
                    break;
                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao alterar o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.AbrirPopUpAlterarStatus = function (NFSe) {
      const option = {
        // 1: 'Enviada para Prefeitura',
        // 2: 'Aguardando Autorização',
        3: 'Autorizada',
        // 4: 'Rejeitada',
        5: 'Cancelada na Prefeitura',
        // 6: 'Apagada',
        // 7: 'Importada via XML',
        // 8: 'Aguardando assinatura',
        // 10: 'Recebida',
      };

      // if (NFSe.StatusID == 4) {
      //   delete option['3'];
      //   delete option['5'];
      // }

      sweetAlert.swal
        .fire({
          title: 'Nova Situação para o NFS-e',
          input: 'select',
          inputOptions: option,
          inputPlaceholder: 'Selecione uma nova Situação para o NFS-e',
          showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve, reject) {
              if (value !== '') {
                resolve();
              } else {
                resolve('Você precisa escolher uma nova situação.');
              }
            });
          },
        })
        .then(function (result) {
          if (result.value) {
            // sweetAlert.swal.insertQueueStep({
            //   title: "Excluída!",
            //   text: "NFS-e excluída com sucesso",
            //   type: "success",
            //   timer: 2000
            // });
            // NFSeAPI.Get().then(function(response) {
            //   $public.NFSe_Emitidas = response.data;
            // });
            NFSeAPI.PostAddNFSeStatus(NFSe, result.value)
              .then(function (response) {
                sweetAlert.swal.fire({
                  title: 'Atualizada!',
                  type: 'success',
                  html: 'Situação da NFS-e atualizada com sucesso',
                });
              })
              .then(function () {
                $public.GetNFSe();
              })
              .catch(function () {
                $public.GetNFSe();
              });

            //          + result.value
          }
        });
    };

    $public.AbrirPopUpInformarEnotasID = function (NFSe) {
      sweetAlert.swal
        .mixin({
          input: 'text',
          confirmButtonColor: '#262763',
          confirmButtonText: 'Atualizar',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          progressSteps: ['1'],
        })
        .queue([
          {
            title: 'Correção de vinculo Enotas',
            input: 'text',
            inputValue: '',
            html: 'Informe o valor do campo nfeId:= que está no anexo do e-mail.',
          },
        ])
        .then((result) => {
          if (result.value) {
            NFSeAPI.UpdateEnotasID(NFSe.NFSeID, result.value[0]).then(function (
              response
            ) {
              sweetAlert.swal.fire({
                title: 'Vinculo realizado com sucesso!',
                text: 'Aguarde a nova tentativa de processamento do Enotas.',
                type: 'success',
                timer: 4500,
              });
              $public.GetNFSe();
            });
          }
        });
    };

    $public.abrirModalInformarRecebimento = function (NFSe) {
      var modalAbrirInformarRecebimentoInstance = $uibModal.open({
        templateUrl:
          '/views/operacional/nfseManutencao/modals/modalInformarRecebimento.html',
        controller: 'modalInformarRecebimentoCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'xs',
        resolve: {
          NFSe: function () {
            var deferred = $q.defer();
            deferred.resolve(NFSe);
            return deferred.promise;
          },
          ValorDaNFSe: function () {
            var deferred = $q.defer();
            deferred.resolve(NFSe.Valor);
            return deferred.promise;
          },
        },
      });

      modalAbrirInformarRecebimentoInstance.result.then(function () {
        $public.GetNFSe();
      });
    };

    $public.AbrirModalEditarNFSe = function (NFSe) {
      var modalAbrirModalEditarNFSeInstance = $uibModal.open({
        templateUrl:
          '/views/operacional/nfseManutencao/modals/modalEditarNFSE.html',
        controller: 'modalEditarNFSECtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          NFSe: function () {
            var deferred = $q.defer();

            NFSeAPI.GetByID(NFSe.NFSeID)
              .then(function (response) {
                response.data.DataDeCadastro = new Date(
                  response.data.DataDeCadastro
                );
                console.log(response.data);
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalAbrirModalEditarNFSeInstance.result.then(function () {
        $public.GetNFSe();
      });
    };

    $public.AbrirModalJSONDeEnvioNFSe = function (NFSe) {
      $uibModal.open({
        templateUrl:
          '/views/operacional/nfseManutencao/modals/modalJSONDeEnvio.html',
        controller: 'modalJSONDeEnvioNFSeCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          NFSe: function () {
            var deferred = $q.defer();

            NFSeAPI.GetByID(NFSe.NFSeID)
              .then(function (response) {
                response.data.DataDeCadastro = new Date(
                  response.data.DataDeCadastro
                );
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });
    };

    $public.VisualizarXML = function (NFSe) {
      emptyDoubleClickSelectFactory.request();

      if (![3, 10].includes(NFSe.StatusID)) return;

      if (NFSe.ImportadaViaXML == true) {
        sweetAlert.swal(
          'Arquivo XML indisponível',
          'Não foi possível baixar o arquivo XML pois a NFS-e foi importada da prefeitura.',
          'error'
        );
        return;
      }
      NFSeAPI.GetXMLNFSeFile(NFSe).then(function (response) {
        var filename = response.data;
        console.log(filename);
        if (filename !== null) {
          $window.open(config.baseUrl + '/files/xml/' + filename);
          console.log(config.baseUrl + '/files/xml/' + filename);
        } else {
          sweetAlert.swal(
            'Arquivo XML indisponível',
            'Não foi possível baixar o arquivo XML.',
            'error'
          );
        }
      });
    };

    $public.SocketIOListen = function () {
      SocketIO.on('NFSeGridUpdate', function (vm) {
        $public.GetNFSe();
      });

      $scope.$on('$destroy', function () {
        SocketIO.removeListener('NFSeGridUpdate');
      });
    };

    $private.Init = function () {
      $public.SituacaoDaNFSe = EnumFactory.SituacaoDaNFSe;
      $public.HasPermission = AuthFactory.HasPermission;
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Cliente',
          OrderBy: '-DataDeEmissao',
          Max: 30,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: () => {},
      };
      $public.GetNFSe();

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao menu: Consultar NFS-e',
        'operacional.consultarnfse',
        'Acesso ao menu de Consultar NFS-e',
        null,
        null,
        null
      );
    };

    $private.Init();
    $public.SocketIOListen();
  }

  function modalPercentualDeCadaMedicoCtrl(
    $q,
    $uibModal,
    $uibModalInstance,
    sweetAlert,
    toastr,
    NFSeAPI,
    NFSeItemCotaAPI,
    clientesAPI,
    Itens,
    medicos,
    NFSe
  ) {
    var $public = this;
    var $private = {};
    $public.cotaDosMedicos = [];

    $private.GetItens = function () {
      NFSeAPI.GetByID(NFSe.NFSeID).then(function (response) {
        $public.Itens = response.data.Items;
      });
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.GetTotalFracionadoMedicos = function (item) {
      var result = 0;
      angular.forEach(item.Cotas, function (item) {
        result = result + item.ValorDaCota;
      });
      return result;
    };

    //MODAL Incluir e Editar Medico
    $public.modalIncluirEditarMedicoNaCota = function (itemSelecionado) {
      var modalIncluirMedicoNaCotaInstance = $uibModal.open({
        templateUrl:
          '/views/operacional/nfseManutencao/modals/modalIncluirEditarMedicoNaCota.html',
        controller: 'modalIncluirEditarMedicoNaCotaCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          medicos: function () {
            var deferred = $q.defer();

            clientesAPI
              .GetMedicos(NFSe.ClienteID)
              .then(function (response) {
                deferred.resolve(response.data.Medicos);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          item: function () {
            return itemSelecionado;
          },
        },
      });
      modalIncluirMedicoNaCotaInstance.result.then(function () {
        $private.GetItens();
      });
    };

    $public.AbrirPopUpExcluirCotaDoMedico = function (
      itemSelecionado,
      cotaSelecionada
    ) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover a cota do médico <strong><u>' +
            cotaSelecionada.Medico.Nome +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return NFSeItemCotaAPI.DeleteCotaMedica(
              itemSelecionado.ItemID,
              cotaSelecionada.Medico.MedicoID
            )
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Cota médica removida com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $private.GetItens();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Cota médica não encontrada, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.AbrirPopUpToogleServico = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja alterar o status da consulta do item <strong><u>' +
            itemSelecionado.NFSeDescricao.Descricao +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, alterar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return NFSeItemCotaAPI.ToogleServicoStatusNoItem(
              itemSelecionado.NFSeDescricao.DescricaoID,
              itemSelecionado.NFSeDescricao.Consulta
            )
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Status alterado com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $private.GetItens();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Serviço da cota não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.modalExcluirItemDaNFSe = function (item) {
      console.log(item);
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover o item da NFS-e <strong><u>' +
            item.NFSeDescricao.Descricao +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return NFSeItemCotaAPI.DeleteItemDaNFSe(item.ItemID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Item removido com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $private.GetItens();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Item não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.modalInclurItemNaNotaPelaConsulta = function () {
      var modalInclurItemNaNotaPelaConsultaInstance = $uibModal.open({
        templateUrl:
          '/views/operacional/modals/modalIncluirItemNaNotaPelaConsulta.html',
        controller: 'modalIncluirItemNaNotaPelaConsultaCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          nfse: function () {
            return NFSe;
          },
          itens: function () {
            var deferred = $q.defer();

            NFSeAPI.GetItensByClienteID(NFSe.ClienteID,{
              Search: null,
              Max: 100,
              Page: 1,
              SearchField: 'Descricao',
            })
              .then(function (response) {
                deferred.resolve(response.data.Registros);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });
      modalInclurItemNaNotaPelaConsultaInstance.result.then(function () {
        $private.GetItens();
      });
    };

    $private.Init = function () {
      $public.Title = 'Visualizar Cota';
      $public.Itens = Itens;
      $public.Medicos = medicos;
    };

    $private.Init();
  }

  function modalIncluirEditarMedicoNaCotaCtrl(
    $uibModalInstance,
    sweetAlert,
    NFSeItemCotaAPI,
    medicos,
    item
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.GetValorTotalDoItem = function () {
      var result = $public.cota.Item.ValorTotal;
      return Math.round(result * 100) / 100;
    };

    $public.GetTotalDasCotasJaCadastadas = function () {
      var valorTotalDasCotasJaLancadas = 0;

      $public.cota.Item.Cotas.map((c) => {
        valorTotalDasCotasJaLancadas += c.ValorDaCota;
      });

      return Math.round(valorTotalDasCotasJaLancadas * 100) / 100;
    };

    $public.GetValorDaCotaDoInput = function () {
      var result = $public.cota.ValorDaCota;
      return Math.round(result * 100) / 100;
    };

    $public.GetValorDaCotaRestante = function () {
      var result =
        $public.GetValorTotalDoItem() -
        $public.GetTotalDasCotasJaCadastadas() -
        $public.GetValorDaCotaDoInput();

      return Math.round(result * 100) / 100;
    };

    $public.EnviarTeste = function () {
      console.log('GetValorTotalDoItem(): ', $public.GetValorTotalDoItem());
      console.log(
        'GetTotalDasCotasJaCadastadas(): ',
        $public.GetTotalDasCotasJaCadastadas()
      );

      console.log(
        '$GetValorDaCotaDoInput(): ',
        $public.GetValorDaCotaDoInput()
      );

      console.log(
        'GetValorDaCotaRestante(): ',
        $public.GetValorDaCotaRestante()
      );

      if ($public.GetValorDaCotaRestante() < 0) {
        sweetAlert.swal(
          '',
          'Os Valores das cotas ultrapassou o valor total do item.',
          'warning'
        );
        return;
      }
    };

    $public.Enviar = function () {
      if ($public.GetValorDaCotaRestante() < 0) {
        sweetAlert.swal(
          '',
          'Os Valores das cotas ultrapassou o valor total do item.',
          'warning'
        );
        return;
      }

      var result = {
        OnSuccess: function (response) {
          sweetAlert.swal('Cota cadastrada com sucesso!', '', 'success');
          $uibModalInstance.close();
        },
        OnError: function (response) {
          switch (response.status) {
            case 404:
              sweetAlert.swal(
                'Atenção',
                'O item não foi encontrado, verifique!!!',
                'error'
              );
              break;
            case 409:
              sweetAlert.swal('O médico já está vinculado.', '', 'warning');
              break;
            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      // CADASTRAR
      NFSeItemCotaAPI.PostCotaMedica(item.ItemID, $public.cota)
        .then(result.OnSuccess)
        .catch(result.OnError);
    };

    $private.Init = function () {
      $public.Title = 'Incluir Cota Médica';
      $public.cota = {};
      $public.cota.Item = item;
      $public.medicos = medicos;
      $public.cota.ValorDaCota = 0;
    };

    $private.Init();
  }

  function modalStatusNFSeCtrl($uibModalInstance, Status) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $private.Init = function () {
      $public.Title = 'Processamento da NFS-e';
      $public.Status = Status;
    };

    $private.Init();
  }

  function modalLogsDeRejeicaoDaNFSeCtrl($uibModalInstance, Motivos) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $private.Init = function () {
      $public.Title = 'Motivo(s) de rejeição da NFS-e';
      $public.Motivos = Motivos;
    };

    $private.Init();
  }

  function modalEditarNFSECtrl($uibModalInstance, NFSeAPI, NFSe) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.Enviar = function () {
      NFSeAPI.PutEditarNFSe($public.NFSe)
        .then(function (response) {})
        .then(function () {
          $uibModalInstance.close();
        });
    };

    $private.Init = function () {
      $public.Title = 'Editar NFS-e';
      $public.NFSe = NFSe;
    };

    $private.Init();
  }

  function modalInformarRecebimentoCtrl(
    $uibModalInstance,
    sweetAlert,
    $filter,
    NFSeAPI,
    NFSe,
    ValorDaNFSe
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.Enviar = function () {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Confirma o recebimento da NFS-e na data de <strong>' +
            $filter('date')(
              $public.Recebimento.DataDoRecebimento,
              'dd/MM/yyyy'
            ) +
            '</strong> no valor de <br><strong>' +
            $filter('currency')($public.Recebimento.Valor, 'R$ ') +
            '</strong>?',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#14B0A5',
          confirmButtonText: 'Sim, confirmar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            var result = {
              OnSuccess: function () {
                var msg = $public.Recebimento.NFSeRecebidaID
                  ? 'alterados'
                  : 'adicionados';
                sweetAlert.swal.insertQueueStep({
                  title: 'Dados ' + msg + ' com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });

                $uibModalInstance.close();
              },
              OnError: function (response) {
                switch (response.status) {
                  case 401:
                    sweetAlert.swal({
                      title: 'Atenção',
                      type: 'error',
                      html:
                        'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                        response.data.Message +
                        '</font>',
                    });
                    break;

                  case 404:
                    sweetAlert.swal(
                      'Atenção',
                      'O recebimento não foi encontrado, verifique!!!',
                      'error'
                    );
                    break;

                  case 409:
                    sweetAlert.swal(
                      'Atenção',
                      'Já existe um recebimento para este cliente',
                      'warning'
                    );
                    break;

                  case 417:
                    sweetAlert.swal({
                      title: 'Atenção',
                      type: 'error',
                      html:
                        'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                        response.data.ExceptionMessage +
                        '</font>',
                    });
                    break;

                  default:
                    sweetAlert.swal(
                      'Atenção',
                      'Verifique o preenchimento do formulário',
                      'warning'
                    );
                    break;
                }
              },
            };

            if ($public.Recebimento.NFSeRecebidaID) {
              // EDITAR RECEBIMENTO
              return NFSeAPI.PutInformarRecebimento($public.Recebimento)
                .then(result.OnSuccess)
                .catch(result.OnError);
            } else {
              // CADASTRAR RECEBIMENTO
              return NFSeAPI.PostInformarRecebimento($public.Recebimento)
                .then(result.OnSuccess)
                .catch(result.OnError);
            }
          },
        },
      ]);
    };

    $private.GetRecebimento = function () {
      NFSeAPI.GetRecebimento(NFSe.NFSeID).then(function (response) {
        console.log(response.data);
        if (response.data) {
          response.data.DataDoRecebimento = new Date(
            response.data.DataDoRecebimento
          );
          $public.Recebimento = response.data;
        }
      });
    };

    $private.Init = function () {
      $public.Title = 'Informar Recebimento da NFS-e';

      $public.Recebimento = {};
      $public.Recebimento.NFSeID = NFSe.NFSeID;
      $public.ValorDaNFSe = ValorDaNFSe;

      $private.GetRecebimento();
    };

    $private.Init();
  }

  function modalJSONDeEnvioNFSeCtrl($uibModalInstance, NFSe) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $private.Init = function () {
      $public.Title = 'JSON';
      $public.NFSe = NFSe;
    };

    $private.Init();
  }

  function modalEspelharNFSeCtrl(
    $scope,
    toastr,
    $uibModalInstance,
    sweetAlert,
    $filter,
    NFSe,
    Medicos,
    NFSeAPI
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.Enviar = function () {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Confirma a emissão de um espelho da NFS-e<strong><u>' +
            '</u></strong> no valor de <strong><u>' +
            $filter('currency')(NFSe.Valor, 'R$ ') +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#5cb85c',
          confirmButtonText: 'Sim, Emitir!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.
            $public.loading = true;

            return $scope.$apply(function () {
              var result = {
                OnSuccess: function (response) {
                  console.log('Sucess: ', response.data);
                  toastr.success(
                    'O espelho da NFS-e foi enviada para a prefeitura.',
                    'NFS-e',
                    {
                      autoDismiss: true,
                      allowHtml: true,
                      preventDuplicates: true,
                      closeButton: true,
                      progressBar: true,
                      timeOut: 3000,
                    }
                  );
                  $uibModalInstance.close();
                },
                OnError: function (response) {
                  console.log('Error: ', response);
                  switch (response.status) {
                    case 400:
                      sweetAlert.swal({
                        title: 'Atenção',
                        type: 'error',
                        html:
                          "Um erro de validação impediu o envio da NFS-e para prefeitura.<br><br><div style='font-size:16px; font-weight:600; color:#dc3545;'>Motivo:<br>" +
                          response.data.Message +
                          '</div>',
                      });
                      break;

                    case 401:
                      sweetAlert.swal({
                        title: 'Atenção',
                        type: 'error',
                        html:
                          'Um erro inesperado impediu a emissão da NFS-e. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                          response.data.Message +
                          '</font>',
                      });
                      break;

                    case 409:
                      sweetAlert.swal(
                        'Atenção',
                        'Empresa sem certificado digital vinculado ou sem permissão para emissão sem o uso de certificado digital.',
                        'warning'
                      );
                      break;

                    case 417:
                      sweetAlert.swal({
                        title: 'Atenção',
                        type: 'error',
                        html:
                          'Um erro inesperado impediu a emissão da NFS-e. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                          response.data.ExceptionMessage +
                          '</font>',
                      });
                      break;

                    default:
                      sweetAlert.swal(
                        'Atenção',
                        'Ocorreu um erro no envio da NFS-e tente novamente mais tarde.',
                        'warning'
                      );
                      break;
                  }
                },
              };

              // ENVIAR NFS-E
              NFSeAPI.PostEspelhado(
                NFSe,
                angular.copy($public.DadosComplementares)
              )
                .then(result.OnSuccess)
                .catch(result.OnError)
                .finally(function () {
                  $public.loading = false;
                  // $public.GetNFSe();
                });
            });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.Title = 'Espelhar NFS-e';
      $public.NFSe = NFSe;
      console.log(NFSe);
      $public.DadosComplementares = NFSe.DadosComplementares;
    };

    $private.Init();
  }

  function modalIncluirItemNaNotaPelaConsultaCtrl(
    $scope,
    $uibModalInstance,
    sweetAlert,
    toastr,
    NFSeAPI,
    nfse,
    itens
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.loadMore = function () {
      $public.currentElement = $public.currentElement + 20;
    };

    $public.GetUISelectAsync = function (search) {
      $public.currentElement = 20;

      if (!search) {
        return;
      }

      $public.Loading = true;
      console.log('AQUI:', search);
      NFSeAPI.GetItensByClienteID(nfse.ClienteID,{
        Search: search,
        Page: 1,
        OrderBy: 'Descricao',
        SearchField: 'Descricao',
      })
        .then(function (response) {
          $public.itens = response.data.Registros;
          $public.Loading = false;
        })
        .catch(function () {
          $public.Loading = false;
        });
    };

    $public.Enviar = function () {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html: 'Incluir o item na NFS-e?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#5cb85c',
          confirmButtonText: 'Sim, Incluir!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.
            $public.loading = true;

            return $scope.$apply(function () {
              var result = {
                OnSuccess: function (response) {
                  console.log('Sucess: ', response.data);
                  toastr.success(
                    'O Item foi cadastrado na NFS-e.',
                    'Item na NFS-e',
                    {
                      autoDismiss: true,
                      allowHtml: true,
                      preventDuplicates: true,
                      closeButton: true,
                      progressBar: true,
                      timeOut: 3000,
                    }
                  );
                  $uibModalInstance.close();
                },
                OnError: function (response) {
                  console.log('Error: ', response);
                  switch (response.status) {
                    case 400:
                      sweetAlert.swal({
                        title: 'Atenção',
                        type: 'error',
                        html:
                          "Um erro de validação impediu o cadastro do item na NFS-e para prefeitura.<br><br><div style='font-size:16px; font-weight:600; color:#dc3545;'>Motivo:<br>" +
                          response.data.Message +
                          '</div>',
                      });
                      break;

                    case 401:
                      sweetAlert.swal({
                        title: 'Atenção',
                        type: 'error',
                        html:
                          'Um erro inesperado impediu o cadastro do item na NFS-e. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                          response.data.Message +
                          '</font>',
                      });
                      break;

                    default:
                      sweetAlert.swal(
                        'Atenção',
                        'Ocorreu um erro no envio dos dados tente novamente mais tarde.',
                        'warning'
                      );
                      break;
                  }
                },
              };

              // ENVIAR NFS-E
              NFSeAPI.InsertItemIntoNFSe(nfse.NFSeID, $public.Item)
                .then(result.OnSuccess)
                .catch(result.OnError)
                .finally(function () {
                  $public.loading = false;
                  // $public.GetNFSe();
                });
            });
          },
        },
      ]);
    };

    $public.somarValores = function () {
      var quantidade = $public.Item.Quantidade;
      var ValorUnitario = $public.Item.ValorUnitario;
      var total = quantidade * ValorUnitario;

      $public.Item.ValorTotal = total;
    };

    $private.Init = function () {
      $public.Item = {};
      $public.NFSe = nfse;
      $public.itens = itens;
      console.log(nfse);
      console.log(itens);
      $public.Title = 'Incluir Item na NFS-e';
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('meuPerfilCtrl', meuPerfilCtrl)

  meuPerfilCtrl.$inject = [
    '$q',
    'sweetAlert',
    'usuarioAPI',
    'AuditoriaFactory',
  ]

  function meuPerfilCtrl(
    $q,
    sweetAlert,
    usuarioAPI,
    AuditoriaFactory,
  ) {
    var $public = this;
    var $private = {};


    $private.GetDetalhesDoUsuarioSelecionado = function () {
      var deferred = $q.defer();

      usuarioAPI
        .Get()
        .then(function (response) {
          response.data.DataNascimento = new Date(
            response.data.DataNascimento
          );

          $public.EmailUsuario = response.data.Email;

          deferred.resolve((
            $public.usuario = response.data
          ));
        })
        .catch(function () {
          deferred.resolve(undefined);
        });

      return deferred.promise;
    }

    $public.recuperarSenha = function () {
      sweetAlert.swal.queue([
        {
          title: 'Recuperar Senha',
          html:
            'Deseja enviar um e-mail recuperação para <strong><u>' +
            $public.EmailUsuario +
            '</u></strong>?',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#5cb85c',
          confirmButtonText: 'Sim, enviar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return usuarioAPI
              .RecuperarSenha($public.EmailUsuario)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'E-mail de recuperação enviado!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
              })
              .catch(function (response) {
                console.log(response)
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'O E-mail informado não pertence a nenhum usuário.',
                      type: 'warning',
                    });
                    break;

                  case 503:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'O servidor de recuperação está fora do ar.',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao enviar o e-mail de confirmação.',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.usuario = null;
      $public.EmailUsuario = null;

      $private.GetDetalhesDoUsuarioSelecionado();

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao modal: Meus Dados',
        'modal.meusdados',
        'Acessou o modal Meus Dados',
        null,
        null,
        null
      );

    };

    $private.Init();
  }

})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('mudarFotoCtrl', mudarFotoCtrl)

  mudarFotoCtrl.$inject = [
    '$state',
    '$scope',
    'sweetAlert',
    'Upload',
    'config',
    'SharedDataFactory'
  ]

  function mudarFotoCtrl(
    $state,
    $scope,
    sweetAlert,
    Upload,
    config,
  ) {

    var $public = this;
    $scope.myImage = '';
    $scope.myCroppedImage = '';

    //Para Funcionar o envio da foto no MODAL
    $scope.init = function () {
      var file = angular.element(document.getElementById('fileInput'));
      file.on('change', handleFileSelect);
    };

    var handleFileSelect = function (evt) {
      console.log('fileEvent', evt)
      var file = evt.currentTarget.files[ 0 ];
      var reader = new FileReader();
      reader.onload = function (evt) {
        $scope.$apply(function ($scope) {
          $scope.myImage = evt.target.result;
        });
      };
      reader.readAsDataURL(file);
    };

    angular
      .element(document.querySelector('#fileInput'))
      .on('change', handleFileSelect);

    $public.Enviar = function () {
      var dataUrl = $scope.myImage;

      var binary = atob(dataUrl.split(',')[ 1 ]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }

      var leblob = new Blob([ new Uint8Array(array) ], { type: 'image/jpeg' });
      leblob.lastModifiedDate = new Date();

      Upload.upload({
        url: config.baseUrl + '/api/usuario/foto',
        data: { file: leblob, name: $scope.username },
      }).then(
        function (resp) {
          // console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
          result.OnSuccess();
        },
        function (resp) {
          console.log('Error status: ' + resp.status);
        },
        function (evt) {
          var progressPercentage = parseInt((100.0 * evt.loaded) / evt.total);
          //  console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
        }
      );

      var result = {
        OnSuccess: function (response) {
          sweetAlert.swal('Foto de perfil atualizada', '', 'success');
          $state.go('dashboard')
        },
        OnError: function (response) { },
      };
    };


    $scope.init();

  }

})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('anexosCtrl', anexosCtrl)
    .controller('modalNovoEditarAnexosCtrl', modalNovoEditarAnexosCtrl)
    .controller('modalEditarFaixaCtrl', modalEditarFaixaCtrl);

  anexosCtrl.$inject = [
    '$q',
    '$uibModal',
    'anexosAPI',
    'SharedDataFactory',
    'AuditoriaFactory',
  ];
  modalNovoEditarAnexosCtrl.$inject = [
    '$q',
    '$uibModal',
    '$uibModalInstance',
    'sweetAlert',
    'EnumFactory',
    'anexosAPI',
    'faixasAPI',
    'AnexoSelecionado',
  ];
  modalEditarFaixaCtrl.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    'anexosAPI',
    'FaixaSelecionada',
    'NomeDoAnexo',
  ];

  function anexosCtrl(
    $q,
    $uibModal,
    anexosAPI,
    SharedDataFactory,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};

    $public.GetAnexos = function () {
      anexosAPI
        .Get($public.PaginationConfig.Paginacao)
        .then(function (response) {
          $public.anexos = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    $public.Enviar = function () {};

    //MODAL Editar Anexos
    $public.abrirModalNovoEditarAnexo = function (itemSelecionado) {
      if ($public.loading === true) {
        return;
      }
      $public.loading = true;

      var modalNovoEditarAnexosCtrl = $uibModal.open({
        templateUrl:
          '/views/cadastros/anexos/modals/modalNovoEditarAnexos.html',
        controller: 'modalNovoEditarAnexosCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          AnexoSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            anexosAPI
              .GetByID(itemSelecionado.AnexoID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              })
              .finally(function () {
                $public.loading = false;
              });

            return deferred.promise;
          },
        },
      });

      modalNovoEditarAnexosCtrl.result.then(function () {
        $private.GetAnexos();
      });
    };

    $private.Init = function () {
      $public.loading = false;
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Descricao',
          OrderBy: '-AnexoID',
          Max: 3,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $public.GetAnexos,
      };
      $public.GetAnexos();
      $public.Config = SharedDataFactory.Get().Configuracoes;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao cadastro: Anexos',
        'cadastro.anexos',
        'Acessou o menu de Anexos',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

  function modalNovoEditarAnexosCtrl(
    $q,
    $uibModal,
    $uibModalInstance,
    sweetAlert,
    EnumFactory,
    anexosAPI,
    faixasAPI,
    AnexoSelecionado
  ) {
    var $public = this;
    var $private = {};

    $private.GetFaixas = function () {
      anexosAPI.GetByID(AnexoSelecionado.AnexoID).then(function (response) {
        $public.anexo = response.data;
      });
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = AnexoSelecionado ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close($public.anexo);
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O anexo não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um anexo na faixa: \n' + $public.anexo.Faixa,
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (AnexoSelecionado) {
        // EDITAR ANEXO
        anexosAPI
          .Put($public.anexo)
          .then(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    //MODAL Editar Anexos
    $public.abrirModalEditarFaixa = function (itemSelecionado) {
      var modalEditarFaixaCtrl = $uibModal.open({
        templateUrl: '/views/cadastros/anexos/modals/modalEditarFaixa.html',
        controller: 'modalEditarFaixaCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          FaixaSelecionada: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            faixasAPI
              .GetByFaixaID(itemSelecionado.FaixaAnexoID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          NomeDoAnexo: function () {
            return $public.anexo.Descricao;
          },
        },
      });

      modalEditarFaixaCtrl.result.then(function () {
        $private.GetFaixas();
      });
    };

    $private.Init = function () {
      $public.anexo = AnexoSelecionado;
      $public.Title = AnexoSelecionado ? 'Editar Anexo' : 'Incluir Novo Anexo';
      //$public.Enuns = EnumFactory;
    };

    $private.Init();
  }

  function modalEditarFaixaCtrl(
    $uibModalInstance,
    sweetAlert,
    anexosAPI,
    FaixaSelecionada,
    NomeDoAnexo
  ) {
    var $public = this;
    var $private = {};

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = FaixaSelecionada ? 'alterada' : 'adicionada';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close();
        },
        OnError: function (response) {
          //console.log($public.faixa);
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'A faixa não foi encontrada, verifique!!!',
                'error'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (FaixaSelecionada) {
        // EDITAR FAIXA
        anexosAPI
          .PutFaixa($public.faixa)
          .then(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $private.Init = function () {
      $public.faixa = FaixaSelecionada;
      $public.Title = FaixaSelecionada ? 'Editar Faixa' : 'Incluir Nova Faixa';
      $public.SubTitle = NomeDoAnexo;
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('certificadosDigitaisCtrl', certificadosDigitaisCtrl)
    .controller(
      'modalIncluirCertificadoDigitalCtrl',
      modalIncluirCertificadoDigitalCtrl
    );

  certificadosDigitaisCtrl.$inject = [
    '$uibModal',
    'certificadoAPI',
    'sweetAlert',
    'SharedDataFactory',
    'AuditoriaFactory',
  ];
  modalIncluirCertificadoDigitalCtrl.$inject = [
    '$scope',
    'sweetAlert',
    'config',
    '$uibModalInstance',
    'Upload',
  ];

  function certificadosDigitaisCtrl(
    $uibModal,
    certificadoAPI,
    sweetAlert,
    SharedDataFactory,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};

    $public.GetCertificadosDigitais = function () {
      certificadoAPI
        .Get($public.PaginationConfig.Paginacao)
        .then(function (response) {
          console.log(response.data.Registros);
          $public.CertificadosDigitais = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    $public.AbrirModalAdicionarCertificadoDigital = function (itemSelecionado) {
      var modalIncluirCertificadoDigitalinstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/certificadosdigitais/modals/modalIncluirCertificadoDigital.html',
        controller: 'modalIncluirCertificadoDigitalCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
      });

      modalIncluirCertificadoDigitalinstance.result.then(function () {
        $public.GetCertificadosDigitais();
      });
    };

    //POPUP Excluir CERTIFICADO DIGITAL
    $public.modalExcluirCertificadoDigital = function (itemSelecionado) {
      console.log(itemSelecionado);
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover o certificado digital da empresa: <strong><u>' +
            (itemSelecionado.NomeDaEmpresa
              ? itemSelecionado.NomeDaEmpresa
              : itemSelecionado.NomeDaCertificadora) +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return certificadoAPI
              .DeleteCertificadoPelaHash(itemSelecionado.CertificadoID)
              .then(function (response) {
                console.log('Success: %o', response);

                sweetAlert.swal.insertQueueStep({
                  title: 'Certificado digital removido com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Certificado Digital não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              })
              .finally(function () {
                $public.GetCertificadosDigitais();
              });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'NomeDaEmpresa',
          OrderBy: '-Validade',
          Max: 3,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $public.GetCertificadosDigitais,
      };
      $public.GetCertificadosDigitais();
      $public.Config = SharedDataFactory.Get().Configuracoes;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao cadastro: Cert. Digitais',
        'cadastro.certificadosdigitais',
        'Acessou o menu de Certificados Digitais',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

  function modalIncluirCertificadoDigitalCtrl(
    $scope,
    sweetAlert,
    config,
    $uibModalInstance,
    Upload
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $public.CompararSenhas = function (senha, confirmacaoSenha) {
      if (!confirmacaoSenha || senha !== confirmacaoSenha) {
        return false;
      }
      return true;
    };

    $public.ValidarPadraoSenha = function (senha) {
      if (!senha) {
        return false;
      }

      return senha.length > 3;
    };

    $scope.$watch('files', function () {
      console.log($public.certificado);
      $scope.uploadCertFile($scope.file);
    });
    $scope.$watch('file', function () {
      if ($scope.file != null) {
        $scope.files = [$scope.file];
      }
    });

    $scope.uploadCertFile = function (
      files,
      senhaDoCertificado,
      IncluirNoPlugNotas
    ) {
      if (files && files.size) {
        sweetAlert.swal.queue([
          {
            title: 'Atenção!',
            html: 'Deseja realmente incluir o Certificado Digital?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#262763',
            confirmButtonText: 'Sim, incluir!',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            preConfirm: function () {
              $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

              return Upload.upload({
                url: config.baseUrl + '/api/certificado/' + IncluirNoPlugNotas,
                data: { file: files, senha: senhaDoCertificado },
              })
                .then(function (response) {
                  sweetAlert.swal.insertQueueStep({
                    title: 'Adicionado!',
                    text: 'Certificado adicionado com sucesso',
                    type: 'success',
                    timer: 2000,
                  });
                  $uibModalInstance.close();
                })
                .catch(function (response) {
                  console.log('Error: %o', response);
                  switch (response.status) {
                    case 403:
                      sweetAlert.swal.insertQueueStep({
                        title: 'Atenção!',
                        text: 'A senha do certificado digital está incorreta, informe a senha correta e tente novamente!',
                        type: 'error',
                      });
                      break;

                    case 409:
                      sweetAlert.swal.insertQueueStep({
                        title: 'Atenção!',
                        text: 'Este certificado digital já foi adicionado ao sistema.',
                        type: 'error',
                      });
                      break;

                    case 415:
                      sweetAlert.swal.insertQueueStep({
                        title: 'Atenção!',
                        text: 'O arquivo enviado não é um Certificado Digital.',
                        type: 'error',
                      });
                      break;

                    default:
                      sweetAlert.swal.insertQueueStep({
                        title: 'Atenção!',
                        text: 'Não foi possivel incluir o certificado digital',
                        type: 'error',
                      });
                      break;
                  }
                });
            },
          },
        ]);
      }
    };

    $private.Init = function () {
      $public.certificado = {};
      $public.certificado.IncluirNoPlugNotas = false;
      $public.Title = 'Incluir Certificado Digital';
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('clientesCtrl', clientesCtrl)
    .controller('modalNovoEditarClienteCtrl', modalNovoEditarClienteCtrl)
    .controller('modalIncluirRemoverMedicoCtrl', modalIncluirRemoverMedicoCtrl)
    .controller('modalEscolherMedicoCtrl', modalEscolherMedicoCtrl)
    .controller('modalEscolherGestorCtrl', modalEscolherGestorCtrl)
    .controller('modalEscolherFilialCtrl', modalEscolherFilialCtrl)
    .controller('modalIncluirFatorRCtrl', modalIncluirFatorRCtrl)
    .controller('modalCamposPadroesEmissaoCtrl', modalCamposPadroesEmissaoCtrl);

  clientesCtrl.$inject = [
    '$q',
    '$uibModal',
    'clientesAPI',
    'anexosAPI',
    'usuariosAPI',
    'sweetAlert',
    'AuthFactory',
    'SharedDataFactory',
    'AuditoriaFactory',
    'servicosAPI',
  ];

  modalNovoEditarClienteCtrl.$inject = [
    '$q',
    '$scope',
    'config',
    '$uibModalInstance',
    '$uibModal',
    'sweetAlert',
    'EnumFactory',
    '$window',
    'viaCepAPI',
    'clienteSelecionado',
    'tabelaSimplesNacional',
    'clientesAPI',
    'faturamentoAPI',
    'municipiosAPI',
    'NFSeAPI',
    'certificadoAPI',
    'enotasAPI',
    'focus',
    'configuracoesDoUsuario',
    'filiaisAPI',
  ];

  modalIncluirRemoverMedicoCtrl.$inject = [
    '$q',
    '$uibModal',
    '$uibModalInstance',
    'sweetAlert',
    'clienteSelecionado',
    'listaDeMedicosVinculados',
    'medicosAPI',
    'clientesAPI',
  ];

  modalEscolherMedicoCtrl.$inject = [
    'sweetAlert',
    '$uibModalInstance',
    'clientesAPI',
    'medicos',
    'clienteID',
  ];

  modalEscolherGestorCtrl.$inject = [
    'sweetAlert',
    '$uibModalInstance',
    'clientesAPI',
    'clienteSelecionado',
    'ListaDeGestores',
  ];

  modalCamposPadroesEmissaoCtrl.$inject = [
    '$q',
    '$uibModal',
    'sweetAlert',
    '$uibModalInstance',
    'clientesAPI',
    'medicosAPI',
    'clienteSelecionado',
    'ListaDeTomadores',
    'ListaDeServicos',
  ];

  modalIncluirFatorRCtrl.$inject = [
    '$q',
    '$uibModal',
    'NFSeAPI',
    'sweetAlert',
    'clienteSelecionado',
    '$uibModalInstance',
  ];

  modalEscolherFilialCtrl.$inject = [
    'sweetAlert',
    '$uibModalInstance',
    'filiaisAPI',
    'clienteSelecionado',
    'ListaDeClientes',
  ];

  function clientesCtrl(
    $q,
    $uibModal,
    clientesAPI,
    anexosAPI,
    usuariosAPI,
    sweetAlert,
    AuthFactory,
    SharedDataFactory,
    AuditoriaFactory,
    servicosAPI
  ) {
    var $public = this;
    var $private = {};

    $public.GetClientes = function () {
      clientesAPI
        .Get($public.PaginationConfig.Paginacao)
        .then(function (response) {
          $public.clientes = angular.copy(response.data.Registros);
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    //MODAL Incluir e Editar Cliente
    $public.abrirModalNovoEditarCliente = function (itemSelecionado) {
      // if ($public.loading === true) {
      //   return;
      // }
      $public.loading = true;

      var modalNovoEditarClienteInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/clientes/modals/modalNovoEditarCliente.html',
        controller: 'modalNovoEditarClienteCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          clienteSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            clientesAPI
              .GetByID(itemSelecionado.ClienteID)
              .then(function (response) {
                response.data.DataDeAbertura = new Date(
                  response.data.DataDeAbertura
                );

                response.data.ValidadeTokenEspecial = new Date(
                  response.data.ValidadeTokenEspecial
                );

                deferred.resolve(response.data);
                $public.loading = false;
              })
              .catch(function () {
                deferred.resolve(undefined);
                $public.loading = false;
              })
              .finally(function () {
                $public.loading = false;
              });

            return deferred.promise;
          },
          tabelaSimplesNacional: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            anexosAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          configuracoesDoUsuario: function () {
            return $public.Config;
          },
        },
      });

      modalNovoEditarClienteInstance.result
        .then(function () {
          $public.GetClientes();
          $public.loading = false;
        })
        .catch(function () {
          $public.loading = false;
        });
    };

    //MODAL Incluir ou Remover Médicos
    $public.modalIncluirRemoverMedicoCtrl = function (itemSelecionado) {
      var modalIncluirRemoverMediconstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/clientes/modals/modalIncluirRemoverMedicos.html',
        controller: 'modalIncluirRemoverMedicoCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          clienteSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            clientesAPI
              .GetByID(itemSelecionado.ClienteID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          listaDeMedicosVinculados: function () {
            var deferred = $q.defer();

            clientesAPI
              .GetMedicos(itemSelecionado.ClienteID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalIncluirRemoverMediconstance.result.then(function () {
        $public.GetClientes();
      });
    };

    $public.modalSelecionarGestorCtrl = function (itemSelecionado) {
      var modalEscolherGestorInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/clientes/modals/modalEscolherGestor.html',
        controller: 'modalEscolherGestorCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          clienteSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            clientesAPI
              .GetByID(itemSelecionado.ClienteID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          ListaDeGestores: function () {
            var deferred = $q.defer();

            usuariosAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data.Registros);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalEscolherGestorInstance.result.then(
        function () {
          $public.GetClientes();
        }

        //function (selectedItem) {},
      );
    };

    $public.modalCamposPadroesEmissaoCtrl = function (itemSelecionado) {
      $uibModal.open({
        templateUrl:
          '/views/cadastros/clientes/modals/modalCamposPadroesNFSe.html',
        controller: 'modalCamposPadroesEmissaoCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          clienteSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            clientesAPI
              .GetByID(itemSelecionado.ClienteID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          ListaDeTomadores: function () {
            var deferred = $q.defer();

            usuariosAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data.Registros);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          ListaDeServicos: function () {
            var deferred = $q.defer();

            servicosAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });
    };

    //POPUP Excluir Cliente
    $public.modalExcluirCliente = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover o cliente <strong><u>' +
            itemSelecionado.Nome +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return clientesAPI
              .Delete(itemSelecionado.ClienteID)
              .then(function (response) {
                sweetAlert.swal.insertQueueStep({
                  title: 'Cliente removido com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $public.GetClientes();
              })
              .catch(function (response) {
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Cliente não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.loading = false;
      $public.NFSeEmitidasDoMedico = [];
      $public.HasPermission = AuthFactory.HasPermission;
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'NomeFantasia',
          OrderBy: 'Nome',
          Max: 5,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $public.GetClientes,
      };
      $public.GetClientes();
      $public.Config = SharedDataFactory.Get().Configuracoes;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao cadastro: Empresas',
        'cadastro.clientes',
        'Acessou o menu de Empresas',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

  function modalNovoEditarClienteCtrl(
    $q,
    $scope,
    config,
    $uibModalInstance,
    $uibModal,
    sweetAlert,
    EnumFactory,
    $window,
    viaCepAPI,
    clienteSelecionado,
    tabelaSimplesNacional,
    clientesAPI,
    faturamentoAPI,
    municipiosAPI,
    NFSeAPI,
    certificadoAPI,
    enotasAPI,
    focus,
    configuracoesDoUsuario,
    filiaisAPI
  ) {
    var $public = this;
    var $private = {};
    $scope.viewby = 5;
    $scope.Filiais_viewby = 3;
    $public.endereco = {};
    $public.CEPPendente = false;

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $public.buscarCep = function () {
      $public.ErroViaCEP = undefined;

      if (
        !$public.cliente ||
        !$public.cliente.Endereco ||
        !$public.cliente.Endereco.CEP
      ) {
        return;
      }

      $public.CEPPendente = true;
      viaCepAPI
        .GetEndereco($public.cliente.Endereco.CEP)
        .then(function (response) {
          if (response.data.erro) {
            $public.ErroViaCEP = response.data;
            $public.CEPPendente = false;
            return;
          }

          $public.cliente.Endereco.Logradouro = response.data.logradouro;
          $public.cliente.Endereco.Bairro = response.data.bairro;
          $public.cliente.Endereco.CodIBGE = response.data.ibge;
          $public.cliente.Endereco.CodGIA = response.data.gia;
          $public.cliente.Endereco.CodSIAFI = response.data.SIAFI.CodSIAFI;
          $public.cliente.Endereco.Municipio = {};

          municipiosAPI
            .GetByNome(response.data.localidade, response.data.uf)
            .then(function (response) {
              $public.cliente.Endereco.Municipio.UF = response.data.UF;
              $public.cliente.Endereco.MunicipioID = response.data.MunicipioID;

              $public.BuscaMunicipios(false);
            })
            .catch(function (response) {
              $public.BuscaMunicipios(true);
            });

          $public.ViaCEP = response.data;
          $public.CEPPendente = false;
          $public.cliente.Endereco.Numero = undefined;
          $public.cliente.Endereco.Complemento = undefined;
          $('#signup_form_Numero').focus();
        })
        .catch(function () {
          $public.CEPPendente = false;
          $public.cliente.Endereco.Logradouro = '';
          $public.cliente.Endereco.Bairro = '';
          $public.cliente.Endereco.Localidade = '';
          $public.cliente.Endereco.UF = '';
          $public.cliente.Endereco.CodIBGE = '';
          $public.cliente.Endereco.CodGIA = '';
          $public.cliente.Endereco.CodSIAFI = '';
          $('#signup_form_Endereco').focus();
        });
      $public.RegimeEspecialDeTributacaoSelecionado = null;
    };

    $public.buscarCNPJOuCPF = function () {};

    $public.Enviar = function () {
      $public.loading = true;
      //delete $public.cliente.CertificadosVinculados;

      var result = {
        OnSuccess: function () {
          var msg = clienteSelecionado ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close($public.cliente);
        },
        OnError: function (response) {
          $public.loading = false;
          switch (response.status) {
            case 400:
              var msg = clienteSelecionado ? 'alterado' : 'adicionado';
              sweetAlert.swal({
                title: 'Atenção',
                type: 'info',
                html:
                  'Os cadastro não foi ' +
                  msg +
                  " no <b>MITNotas</b> porque o Emissor selecionado retornou o erro: <br><br><div style='font-size:16px; font-weight:600; color:#dc3545;'>Mensagem:<br>" +
                  response.data.Message.replace('{', '').replace('}', '') +
                  '</div>',
              });
              $uibModalInstance.close($public.cliente);
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O cliente não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um cliente com este CNPJ: \n<b>' +
                  $public.cliente.Nome +
                  '</b>',
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (
        ($public.cliente.TipoEmissor === 2 ||
          $public.cliente.TipoEmissor === 4) &&
        $public.RegimeEspecialDeTributacao
      ) {
        // Regime Especial de Tributacao
        var RegimeEspecialDeTributacaoSelecionado =
          $public.RegimeEspecialDeTributacao.find(
            (f) => f.nome === $public.RegimeEspecialDeTributacaoSelecionado
          );
        $public.cliente.RegimeEspecialDeTributacao =
          RegimeEspecialDeTributacaoSelecionado;
      }

      if (clienteSelecionado) {
        // EDITAR CLIENTE
        clientesAPI
          .Put($public.cliente)
          .then(result.OnSuccess)
          .catch(result.OnError);
      } else {
        // CADASTRAR CLIENTE
        clientesAPI
          .Post($public.cliente)
          .then(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $public.modalIncluirFatorRCtrl = function (itemSelecionado) {
      var modalIncluirFatorRInstance = $uibModal.open({
        templateUrl: '/views/cadastros/clientes/modals/modalIncluirFatorR.html',
        controller: 'modalIncluirFatorRCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          clienteSelecionado: function () {
            if (itemSelecionado) {
              return itemSelecionado;
            }
          },
        },
      });
      modalIncluirFatorRInstance.result.then((value) => {
        if (value === 'Cancel') {
          setTimeout(() => {
            $public.CarregarFatorR();
          }, 200);
        }
      });
    };

    $public.modalEditarFatorRCtrl = function (itemSelecionado) {
      var modalIncluirFatorRInstance = $uibModal.open({
        templateUrl: '/views/cadastros/clientes/modals/modalEditarFatorR.html',
        controller: 'modalIncluirFatorRCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          clienteSelecionado: function () {
            if (itemSelecionado) {
              return itemSelecionado;
            }
          },
        },
      });
      modalIncluirFatorRInstance.result.then((value) => {
        if (value === 'Cancel') {
          setTimeout(() => {
            $public.CarregarFatorR();
          }, 200);
        }
      });
    };

    $public.VisualizarRPS = function (NFSe) {
      sweetAlert.swal.queue([
        {
          title: 'Visualizar RPS',
          html:
            'Deseja visualizar o <strong>RPS</strong> da NFS-e de número: <strong><u>' +
            NFSe.NumeroDaNotaConvertido +
            '</u></strong>?',
          showCancelButton: true,
          confirmButtonColor: '#262763',
          confirmButtonText: 'Sim, Gerar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return $scope.$apply(function () {
              NFSeAPI.GetRPS(NFSe.NFSeID).then(function (response) {
                var filename = response.data;
                $window.open(config.baseUrl + '/files/pdf/' + filename);
                //   setTimeout(function(){ NFSeAPI.DeleteRPSPDFFile(filename) }, 5000);
              });
            });
          },
        },
      ]);
    };

    $public.CarregarFaturamento = function () {
      faturamentoAPI
        .GetByClienteID(clienteSelecionado.ClienteID)
        .then(function (response) {
          $public.faturamento = response.data;
        });

      faturamentoAPI
        .GetFaturamentoTotalByClienteID(clienteSelecionado.ClienteID)
        .then(function (response) {
          $public.faturamentoTotal = response.data;
        });
    };

    $public.CarregarNFSeDoCliente = function () {
      NFSeAPI.GetByClienteID(clienteSelecionado.ClienteID).then(function (
        response
      ) {
        focus('focusMe');
        $public.ListaDeNFSeDoCliente = response.data;

        $public.viewby = '3';
        $scope.totalItems = $public.ListaDeNFSeDoCliente.length;
        $scope.currentPage = 1;
        $scope.itemsPerPage = $scope.viewby;
        $scope.maxSize = 10; // Number of pager buttons to show
        $scope.sortType = 'DataDeCadastro';
        $scope.sortReverse = false;
      });
    };

    $public.CarregarFolhaPagamentoDoCliente = function () {
      NFSeAPI.GetPayroll(clienteSelecionado.ClienteID).then(function (
        response
      ) {
        focus('focusMe');
        $public.FolhaPagamentoDoCliente = response.data.Registros;

        $public.viewby = '3';
        $scope.totalItems = $public.FolhaPagamentoDoCliente.length;
        $scope.currentPage = 1;
        $scope.itemsPerPage = $scope.viewby;
        $scope.maxSize = 10; //Number of pager buttons to show
        $scope.sortReverse = 'FolhaDePagamentoID';
      });
    };

    $public.CarregarSincronizacoesQuestor = function () {
      NFSeAPI.GetQuestorSync(clienteSelecionado.ClienteID, 1).then(function (
        response
      ) {
        focus('focusMe');
        $public.HistoricoQuestor = response.data;
        $public.viewby = '3';
        $scope.totalItems = $public.HistoricoQuestor.length;
        $scope.currentPage = 1;
        $scope.itemsPerPage = $scope.viewby;
        $scope.maxSize = 10; //Number of pager buttons to show
        $scope.sortReverse = 'dataSincronizacao';
      });
    };

    $public.CarregarFatorR = function () {
      NFSeAPI.GetRfactor(clienteSelecionado.ClienteID, 1, 5).then(function (
        response
      ) {
        focus('focusMe');
        $public.HistoricoFatorR = response.data.Registros;
        $public.viewby = '5';
        $scope.totalItems = $public.HistoricoFatorR.Registros.length;
        $scope.currentPage = 1;
        $scope.itemsPerPage = $scope.viewby;
        $scope.maxSize = 10; //Number of pager buttons to show
        //$scope.sortReverse = 'dataSincronizacao';
      });
    };

    $public.BuscaMunicipios = function (limparModel) {
      // Sempre que trocar de estado, limpa os campos
      $public.Municipio = [];

      if (limparModel) {
        $public.cliente.Endereco.MunicipioID = undefined;
      }

      if (!$public.cliente.Endereco.Municipio.UF) {
        return;
      }

      municipiosAPI
        .GetByUF($public.cliente.Endereco.Municipio.UF)
        .then(function (response) {
          $public.Municipio = response.data;

          var selecionar = response.data.find(
            (f) => f.MunicipioID == $public.cliente.Endereco.MunicipioID
          );
          $public.EscolheuMunicipio(selecionar.Nome);
        });
    };

    $public.EscolheuMunicipio = function (vm) {
      $public.GetRegimeEspecialDeTributacao(vm);
    };

    //POPUP Excluir Certificado
    $public.POPUPExcluirCertificado = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html: 'Deseja realmente excluir o certificado?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, excluir!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return certificadoAPI
              .DeleteCertificadoPelaHash(itemSelecionado.MD5CertHash)
              .then(function (response) {
                sweetAlert.swal.insertQueueStep({
                  title: 'Excluído!',
                  text: 'Certificado excluído com sucesso',
                  type: 'success',
                  timer: 2000,
                });
                certificadoAPI
                  .Get($public.cliente.ClienteID)
                  .then(function (response) {
                    $public.Certificados = response.data;
                  });
              })
              .catch(function (response) {
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Certificado não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;
                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o certificado, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    //POPUP Excluir Item Folha de pagamento
    $public.DeletarFolhaDePagamento = function (FolhaID) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html: 'Deseja realmente excluir a folha de pagamento?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, excluir!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.
            NFSeAPI.RemovePayroll(FolhaID).then(function (response) {
              sweetAlert.swal('Folha deletada com sucesso!', '', 'success');
              $public.CarregarFolhaPagamentoDoCliente();
            });
          },
        },
      ]);
    };

    //Sincroniza o questor
    $public.SincronizarQuestor = function (ClienteID) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html: 'Deseja realmente sincronizar o faturamento da empresa com o Questor?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#5cb85c',
          confirmButtonText: 'Sim, sincronizar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.
            NFSeAPI.PostQuestorSync(ClienteID).then(function (response) {
              if (response.status === 200) {
                sweetAlert.swal(
                  'Sincronização feita com sucesso! (Pode demorar alguns minutos!)',
                  '',
                  'success'
                );
                $public.CarregarSincronizacoesQuestor();
              } else {
                sweetAlert.swal(
                  'Algo deu errado, entre em contato com o suporte',
                  '',
                  'error'
                );
              }
            });
          },
        },
      ]);
    };

    $public.GetRegimeEspecialDeTributacao = function (nome) {
      if (nome == null) {
        var { UF, Nome } = $public.cliente.Endereco.Municipio;
      } else {
        var { UF } = $public.cliente.Endereco.Municipio;
        var Nome = nome;
      }
      enotasAPI
        .GetRegimeEspecialDeTributacaoPorUFECidade(UF, Nome)
        .then(function (response) {
          $public.RegimeEspecialDeTributacao = response.data;

          var { codigo, nome } = $public.cliente.RegimeEspecialDeTributacao;
          var selecionar = response.data.find(
            (f) => f.nome == nome && f.codigo == codigo
          );

          if (selecionar) {
            $public.RegimeEspecialDeTributacaoSelecionado = selecionar.nome;
          }
        });
    };

    $public.GetCertificadosDigitais = function () {
      certificadoAPI
        .Get($public.PaginationConfig.Paginacao)
        .then(function (response) {
          $public.Certificados = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    $public.setPage = function (pageNo) {
      $scope.currentPage = pageNo;
    };

    $public.pageChanged = function () {};

    $public.setItemsPerPage = function (num) {
      $scope.itemsPerPage = num;
      $scope.currentPage = 1; //reset to first page
    };

    $public.GetFiliaisDoCliente = function () {
      filiaisAPI
        .GetFiliaisDoCliente($public.cliente.ClienteID)
        .then(function (result) {
          $public.Filiais = result.data;
          $public.Filiais_viewby = '3';
          $scope.Filiais_totalItems = result.data.length;
          $scope.Filiais_currentPage = 1;
          $scope.Filiais_itemsPerPage = $scope.Filiais_viewby;
          $scope.Filiais_maxSize = 3; //Number of pager buttons to show
          $scope.Filiais_sortType = 'Nome';
          $scope.Filiais_sortReverse = false;
        });
    };

    $public.ExcluirVinculoDeFilial = function (filialID) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html: 'Deseja realmente desvincular a empresa?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, desvincular!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.
            return filiaisAPI
              .Delete($public.cliente.ClienteID, filialID)
              .then(function (response) {
                sweetAlert.swal.insertQueueStep({
                  title: 'Excluído!',
                  text: 'Vínculo com a filial excluída com sucesso',
                  type: 'success',
                  timer: 2000,
                });
                $public.GetFiliaisDoCliente();
                // $public.Filiais = $public.Filiais.filter(
                //   (f) => f.EmpresaID !== filialID
                // );
              })
              .catch(function (response) {
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Filial não encontrada, Verifique!',
                      type: 'error',
                    });
                    break;
                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o vínculo, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.abrirModalVincularFilial = function (ClienteID) {
      var modalEscolherFilialInstance = $uibModal.open({
        templateUrl: '/views/cadastros/clientes/modals/modalIncluirFilial.html',
        controller: 'modalEscolherFilialCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          clienteSelecionado: function () {
            if (!ClienteID) {
              return undefined;
            }
            var deferred = $q.defer();

            clientesAPI
              .GetByID(ClienteID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          ListaDeClientes: function () {
            var deferred = $q.defer();

            clientesAPI
              .GetAll()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalEscolherFilialInstance.result.then(function () {
        $public.GetFiliaisDoCliente();
      });
    };

    $private.Init = function () {
      $public.Enuns = EnumFactory;
      var Config = configuracoesDoUsuario;
      $public.cliente = clienteSelecionado;

      $public.TabelaSimplesNacional = tabelaSimplesNacional;
      $public.Title = clienteSelecionado
        ? Config.CadastroFacilitadoParaCliente
          ? 'Editar Minha Empresa'
          : 'Editar Cliente'
        : 'Incluir Novo Cliente';

      if (clienteSelecionado) {
        $public.BuscaMunicipios();
        $public.GetRegimeEspecialDeTributacao();
        $public.GetFiliaisDoCliente();
      }

      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {},
        Action: $public.GetTomadores,
      };

      $public.Config = configuracoesDoUsuario;
      $public.GetCertificadosDigitais();
    };

    $private.Init();
  }

  function modalIncluirRemoverMedicoCtrl(
    $q,
    $uibModal,
    $uibModalInstance,
    sweetAlert,
    clienteSelecionado,
    listaDeMedicosVinculados,
    medicosAPI,
    clientesAPI
  ) {
    var $public = this;
    var $private = {};

    $uibModalInstance.result.catch(function () {
      //Do stuff with respect to dismissal
      $uibModalInstance.dismisAll('Cancel');
    });

    $private.GetMedicosDoCliente = function (clienteID) {
      clientesAPI.GetByID(clienteID).then(function (response) {
        $public.medicosVinculados = response.data;
      });
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    //POPUP Desvincular Médico
    $public.POPUPDesvincularMedico = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja desvincular o médico <strong><u>' +
            itemSelecionado.Nome +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, desvincular!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.
            return clientesAPI
              .DeleteRemoveMedicoDoCliente(
                clienteSelecionado.ClienteID,
                itemSelecionado.MedicoID
              )
              .then(function (response) {
                sweetAlert.swal.insertQueueStep({
                  title: 'Desvinculado!',
                  text: 'Médico desvinculado com sucesso',
                  type: 'success',
                  timer: 2000,
                });
                $private.GetMedicosDoCliente(clienteSelecionado.ClienteID);
              })
              .catch(function (response) {
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Médico não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;
                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.abrirModalAdicionarMedico = function (itemSelecionado) {
      var modalEditarInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/clientes/modals/modalSelecionarMedico.html',
        controller: 'modalEscolherMedicoCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        resolve: {
          medicos: function () {
            if (!itemSelecionado) {
              return undefined;
            }

            var deferred = $q.defer();

            medicosAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data.Registros);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          clienteID: function () {
            return clienteSelecionado.ClienteID;
          },
        },
      });

      modalEditarInstance.result
        //RESULT QUANDO INCLUIDO MÉDICO
        .then(function (clienteID) {
          clientesAPI.GetByID(clienteID).then(function (response) {
            $public.medicosVinculados = response.data;
          });
        });
    };

    $private.Init = function () {
      $public.cliente = clienteSelecionado;
      $public.medicosVinculados = listaDeMedicosVinculados;
    };

    $private.Init();
  }

  function modalEscolherMedicoCtrl(
    sweetAlert,
    $uibModalInstance,
    clientesAPI,
    medicos,
    clienteID
  ) {
    var $public = this;
    var $private = {};
    $public.Title = 'Vincular Médico';
    $public.medicos = medicos;

    $public.Enviar = function () {
      var result = {
        OnSuccess: function (response) {
          sweetAlert.swal('Médico vinculado com sucesso!', '', 'success');
          $uibModalInstance.close(clienteID);
        },
        OnError: function (response) {
          switch (response.status) {
            case 404:
              sweetAlert.swal(
                'Atenção',
                'O médico não foi encontrado, verifique!!!',
                'error'
              );
              break;
            case 409:
              sweetAlert.swal('Médico já vinculado.', '', 'warning');
              break;
            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      // CADASTRAR
      clientesAPI
        .PostAdicionaMedicoNoCliente(clienteID, $public.medico)
        .then(result.OnSuccess)
        .catch(result.OnError);
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };
  }

  function modalEscolherGestorCtrl(
    sweetAlert,
    $uibModalInstance,
    clientesAPI,
    clienteSelecionado,
    ListaDeGestores
  ) {
    var $public = this;
    var $private = {};
    $public.Title = 'Vincular Gestor';
    $public.Gestor = [];

    $public.Enviar = function () {
      var result = {
        OnSuccess: function (response) {
          sweetAlert.swal('Gestor vinculado com sucesso!', '', 'success');
          $uibModalInstance.close(clienteSelecionado.ClienteID);
        },
        OnError: function (response) {
          switch (response.status) {
            case 404:
              sweetAlert.swal(
                'Atenção',
                'O gestor não foi encontrado, verifique!!!',
                'error'
              );
              break;
            case 409:
              sweetAlert.swal('Gestor já vinculado.', '', 'warning');
              break;
            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      // CADASTRAR
      clientesAPI
        .PostGestor(clienteSelecionado.ClienteID, $public.gestor)
        .then(result.OnSuccess)
        .catch(result.OnError);
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $private.Init = function () {
      $public.cliente = clienteSelecionado;
      $public.Gestores = ListaDeGestores;

      if (clienteSelecionado.GestorID !== null) {
        var gestorSelecionado = ListaDeGestores.find(
          (f) => f.UsuarioID == clienteSelecionado.GestorID
        );
        $public.gestor = gestorSelecionado;
      }
    };

    $private.Init();
  }

  function modalCamposPadroesEmissaoCtrl(
    $q,
    $uibModal,
    sweetAlert,
    $uibModalInstance,
    clientesAPI,
    medicosAPI,
    clienteSelecionado,
    ListaDeTomadores,
    ListaDeServicos
  ) {
    var $public = this;
    var $private = {};
    $public.Title = 'Campos Padrões da NFS-e';
    $public.Gestor = [];

    $public.Enviar = function () {
      var result = {
        OnSuccess: function (response) {
          sweetAlert.swal('Gestor vinculado com sucesso!', '', 'success');
          $uibModalInstance.close(clienteSelecionado.ClienteID);
        },
        OnError: function (response) {
          switch (response.status) {
            case 404:
              sweetAlert.swal(
                'Atenção',
                'O gestor não foi encontrado, verifique!!!',
                'error'
              );
              break;
            case 409:
              sweetAlert.swal('Gestor já vinculado.', '', 'warning');
              break;
            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      // CADASTRAR
      clientesAPI
        .PostGestor(clienteSelecionado.ClienteID, $public.gestor)
        .then(result.OnSuccess)
        .catch(result.OnError);
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.POPUPDesvincularMedico = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja desvincular o médico <strong><u>' +
            itemSelecionado.Nome +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, desvincular!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return clientesAPI
              .DeleteRemoveMedicoDoCliente(
                clienteSelecionado.ClienteID,
                itemSelecionado.MedicoID
              )
              .then(function (response) {
                sweetAlert.swal.insertQueueStep({
                  title: 'Desvinculado!',
                  text: 'Médico desvinculado com sucesso',
                  type: 'success',
                  timer: 2000,
                });
                $private.GetMedicosDoCliente(clienteSelecionado.ClienteID);
              })
              .catch(function (response) {
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Médico não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;
                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.abrirModalIncluirCotaMedica = function (itemSelecionado) {
      var modalEditarInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/clientes/modals/padroesnfse/modalIncluirCotaMedica.html',
        controller: 'modalEscolherMedicoCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          medicos: function () {
            if (!itemSelecionado) {
              return undefined;
            }

            var deferred = $q.defer();

            medicosAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data.Registros);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          clienteID: function () {
            return clienteSelecionado.ClienteID;
          },
        },
      });

      modalEditarInstance.result
        //RESULT QUANDO INCLUIDO MÉDICO
        .then(function (clienteID) {
          clientesAPI.GetByID(clienteID).then(function (response) {
            $public.medicosVinculados = response.data;
          });
        });
    };

    $private.Init = function () {
      $public.cliente = clienteSelecionado;
      $public.tomadores = ListaDeTomadores;
      $public.servicos = ListaDeServicos;
    };

    $private.Init();
  }

  function modalIncluirFatorRCtrl(
    $q,
    $uibModal,
    NFSeAPI,
    sweetAlert,
    clienteSelecionado,
    $uibModalInstance
  ) {
    var $public = this;
    var $private = {};

    $public.EnviarFolha = function () {
      var result = {
        OnSuccess: function (response) {
          sweetAlert.swal('Fator R cadastrado com sucesso!', '', 'success');
          $uibModalInstance.close(clienteSelecionado);
        },
        OnError: function (response) {
          sweetAlert.swal(
            'Erro',
            'Verifique o preenchimento do formulário',
            'error'
          );
        },
      };

      var resultEdit = {
        OnSuccess: function (response) {
          sweetAlert.swal('Fator R alterado com sucesso!', '', 'success');
          $uibModalInstance.close(clienteSelecionado);
        },
        OnError: function (response) {
          sweetAlert.swal(
            'Erro',
            'Verifique o preenchimento do formulário',
            'error'
          );
        },
      };

      if (clienteSelecionado.FatorR) {
        // ALTERAR
        NFSeAPI.PutRfactor(
          $public.data.FatorR,
          clienteSelecionado.FatorRCacheID
        )
          .then(resultEdit.OnSuccess)
          .catch(resultEdit.OnError);
        this.FecharModalFactor();
      } else {
        resultEdit.OnError;
      }

      if (
        $public.folha.mes > 0 &&
        $public.folha.mes !== null &&
        $public.folha.ano &&
        $public.folha.valor
      ) {
        // CADASTRAR
        NFSeAPI.PostRfactor(
          clienteSelecionado,
          $public.folha.valor,
          $public.folha.ano + '-' + $public.folha.mes + '-01'
        )
          .then(result.OnSuccess)
          .catch(result.OnError);
        this.FecharModalFactor();
      } else {
        result.OnError;
      }
    };

    $public.FecharModalFactor = function () {
      $uibModalInstance.close('Cancel');
    };

    $private.Init = function () {
      $public.data = clienteSelecionado;
    };

    $private.Init();
  }

  function modalEscolherFilialCtrl(
    sweetAlert,
    $uibModalInstance,
    filiaisAPI,
    clienteSelecionado,
    ListaDeClientes
  ) {
    var $public = this;
    var $private = {};
    $public.Title = 'Vincular Filial';
    $public.Gestor = [];

    $public.Enviar = function () {
      var result = {
        OnSuccess: function (response) {
          sweetAlert.swal('Filial vinculada com sucesso!', '', 'success');
          $uibModalInstance.close(clienteSelecionado.ClienteID);
        },
        OnError: function (response) {
          switch (response.status) {
            case 404:
              sweetAlert.swal(
                'Atenção',
                'A filial não foi encontrada, verifique!!!',
                'error'
              );
              break;
            case 409:
              sweetAlert.swal('Filial já vinculada.', '', 'warning');
              break;
            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };
      // CADASTRAR
      filiaisAPI
        .Add(clienteSelecionado.ClienteID, $public.filialselecionada.ClienteID)
        .then(result.OnSuccess)
        .catch(result.OnError);
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $private.Init = function () {
      $public.cliente = clienteSelecionado;
      $public.Filiais = ListaDeClientes;

      if (clienteSelecionado.EmpresaID !== null) {
        var gestorSelecionado = ListaDeClientes.find(
          (f) => f.UsuarioID == clienteSelecionado.EmpresaID
        );
        $public.filial = gestorSelecionado;
      }
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('fornecedoresCtrl', fornecedoresCtrl)
    .controller('modalNovoEditarFornecedorCtrl', modalNovoEditarFornecedorCtrl);

  fornecedoresCtrl.$inject = [
    '$q',
    '$uibModal',
    'fornecedoresAPI',
    'clientesAPI',
    'sweetAlert',
    'SharedDataFactory',
    'AuditoriaFactory',
  ];
  modalNovoEditarFornecedorCtrl.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    'FornecedorSelecionado',
    'fornecedoresAPI',
    'Clientes',
  ];

  function fornecedoresCtrl(
    $q,
    $uibModal,
    fornecedoresAPI,
    clientesAPI,
    sweetAlert,
    SharedDataFactory,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};

    $private.GetFornecedores = function () {
      fornecedoresAPI
        .Get($public.PaginationConfig.Paginacao)
        .then(function (response) {
          $public.fornecedores = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    //MODAL Incluir e Editar Fornecedor
    $public.abrirModalNovoEditarFornecedor = function (itemSelecionado) {
      if ($public.loading === true) {
        return;
      }
      $public.loading = true;

      var modalNovoEditarFornecedoresInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/fornecedores/modals/modalNovoEditarFornecedor.html',
        controller: 'modalNovoEditarFornecedorCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          FornecedorSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            fornecedoresAPI
              .GetByID(itemSelecionado.FornecedorID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              })
              .finally(function () {
                $public.loading = false;
              });

            return deferred.promise;
          },
          Clientes: function () {
            var deferred = $q.defer();

            clientesAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              })
              .finally(function () {
                $public.loading = false;
              });

            return deferred.promise;
          },
        },
      });

      modalNovoEditarFornecedoresInstance.result.then(function () {
        $private.GetFornecedores();
      });
    };

    //POPUP Excluir Fornecedor
    $public.modalExcluirFornecedor = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover o Fornecedor <strong><u>' +
            itemSelecionado.Nome +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return fornecedoresAPI
              .Delete(itemSelecionado.FornecedorID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Fornecedor removido com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $private.GetFornecedores();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Fornecedor não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.loading = false;
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Nome',
          OrderBy: '-FornecedorID',
          Max: 3,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $private.GetFornecedores,
      };
      $private.GetFornecedores();
      $public.Config = SharedDataFactory.Get().Configuracoes;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao cadastro: Fornecedores',
        'cadastro.fornecedores',
        'Acessou o menu de Fornecedores',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

  function modalNovoEditarFornecedorCtrl(
    $uibModalInstance,
    sweetAlert,
    FornecedorSelecionado,
    fornecedoresAPI,
    Clientes
  ) {
    var $public = this;
    var $private = {};

    $public.endereco = {};
    $public.CEPPendente = false;
    $public.CNPJPendente = false;

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = FornecedorSelecionado ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close($public.fornecedor);
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O Fornecedor não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um Fornecedor cadastrado com este nome: \n' +
                  $public.fornecedor.Nome,
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (FornecedorSelecionado) {
        // EDITAR Fornecedor
        fornecedoresAPI
          .Put($public.fornecedor)
          .then(result.OnSuccess)
          .catch(result.OnError);
      } else {
        // CADASTRAR Fornecedor
        fornecedoresAPI
          .Post($public.fornecedor)
          .then(function (response) {
            $public.fornecedor.FornecedorID = response.data.FornecedorID;
          })
          .finally(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $private.Init = function () {
      $public.fornecedor = FornecedorSelecionado;
      $public.Clientes = Clientes;
      console.log(Clientes);

      $public.Title = FornecedorSelecionado
        ? 'Editar Fornecedor'
        : 'Incluir Novo Fornecedor';
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('itensCtrl', itensCtrl)
    .controller('modalNovoEditarItem', modalNovoEditarItem);

  itensCtrl.$inject = [
    '$scope',
    '$q',
    '$uibModal',
    'ListaDeItens',
    'NFSeDescricoesDoItensAPI',
    'clientesAPI',
    'sweetAlert',
    'focus',
    'AuditoriaFactory',
    'SharedDataFactory'
  ];
  modalNovoEditarItem.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    'NFSeDescricoesDoItensAPI',
    'Clientes',
    'itemSelecionado',
    'clienteSelecionado',
  ];

  function itensCtrl(
    $scope,
    $q,
    $uibModal,
    ListaDeItens,
    NFSeDescricoesDoItensAPI,
    clientesAPI,
    sweetAlert,
    focus,
    AuditoriaFactory,
    SharedDataFactory
  ) {
    var $public = this;
    var $private = {};
    $scope.viewby = 3;

    $private.GetItens = function () {
      NFSeDescricoesDoItensAPI.GetWithPagination($public.PaginationConfig.Paginacao).then(function (response) {

        console.log(response.data.Registros);
          $public.Itens = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
      });
    };

    //MODAL Incluir e Editar ITEM
    $public.abrirModalNovoEditarItem = function (itemSelecionado) {
      console.log(itemSelecionado);

      var modalIncluirRemoverItemCtrl = $uibModal.open({
        templateUrl:
          '/views/cadastros/itens/modals/modalIncluirRemoverItem.html',
        controller: 'modalNovoEditarItem',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          itemSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            NFSeDescricoesDoItensAPI.GetByID(itemSelecionado.DescricaoID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function (err) {
                console.log(err);
                deferred.resolve(undefined);
              })
              .finally(function () {
                $public.loading = false;
              });

            return deferred.promise;
          },
          clienteSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();
            deferred.resolve(itemSelecionado.ClienteNome);
            return deferred.promise;
          },
          Clientes: function () {
            var deferred = $q.defer();
            clientesAPI
              .GetCompleto()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });
            return deferred.promise;
          },
        },
      });

      modalIncluirRemoverItemCtrl.result.then(function () {
        $private.GetItens();
      });
    };

    //POPUP Excluir ITEM
    $public.modalExcluirItem = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover o item <strong><u>' +
            itemSelecionado.Descricao +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return NFSeDescricoesDoItensAPI.Delete(itemSelecionado.DescricaoID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Item removido com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $private.GetItens();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Item não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };


    $private.Init = function () {
      $public.loading = false;
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'ClienteNome',
          OrderBy: 'ClienteNome',
          Max: 3,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $private.GetItens,
      };
      $private.GetItens();
      $public.Config = SharedDataFactory.Get().Configuracoes;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao cadastro: Itens',
        'cadastro.descricaodositens',
        'Acessou o menu de Itens',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

  function modalNovoEditarItem(
    $uibModalInstance,
    sweetAlert,
    NFSeDescricoesDoItensAPI,
    Clientes,
    itemSelecionado,
    clienteSelecionado
  ) {
    var $public = this;
    var $private = {};

    $uibModalInstance.result.catch(function () {
      //Do stuff with respect to dismissal
      $uibModalInstance.dismisAll('Cancel');
    });

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = itemSelecionado ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close($public.item);
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O item não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um item com a Descrição: \n' +
                  $public.atividade.Descricao +
                  'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (itemSelecionado) {
        // EDITAR ITEM
        NFSeDescricoesDoItensAPI.Put($public.item)
          .then(result.OnSuccess)
          .catch(result.OnError);
      } else {
        // CADASTRAR ITEM
        $public.item.Clientes = [];
        $public.item.Clientes.push(angular.copy($public.empresa));

        NFSeDescricoesDoItensAPI.Post($public.item)
          .then(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $private.Init = function () {
      $public.Title = itemSelecionado ? 'Editar Item' : 'Incluir Item';
      $public.item = itemSelecionado;
      $public.clientes = Clientes;

      if (itemSelecionado) {
        var empresa = Clientes.find((f) => (f.Nome = clienteSelecionado));
        $public.empresa = empresa;
      }
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('medicosCtrl', medicosCtrl)
    .controller('modalNovoEditarMedicoCtrl', modalNovoEditarMedicoCtrl);

  medicosCtrl.$inject = [
    '$q',
    '$uibModal',
    'medicosAPI',
    'sweetAlert',
    'SharedDataFactory',
    'AuditoriaFactory',
  ];
  modalNovoEditarMedicoCtrl.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    'EnumFactory',
    'medicosAPI',
    'viaCepAPI',
    'municipiosAPI',
    'medicoSelecionado',
  ];

  function medicosCtrl(
    $q,
    $uibModal,
    medicosAPI,
    sweetAlert,
    SharedDataFactory,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};

    $private.GetMedicos = function () {
      medicosAPI
        .Get($public.PaginationConfig.Paginacao)
        .then(function (response) {
          $public.medicos = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    //MODAL Incluir e Editar Medico
    $public.abrirModalNovoEditarMedico = function (itemSelecionado) {
      var modalNovoEditarMedicoInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/medicos/modals/modalNovoEditarMedico.html',
        controller: 'modalNovoEditarMedicoCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          medicoSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            medicosAPI
              .GetByID(itemSelecionado.MedicoID)
              .then(function (response) {
                response.data.Nascimento = new Date(response.data.Nascimento);
                response.data.ValidadeDoCRM = new Date(
                  response.data.ValidadeDoCRM
                );

                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              })
              .finally(function () {});

            return deferred.promise;
          },
        },
      });

      modalNovoEditarMedicoInstance.result.then(function () {
        $private.GetMedicos();
      });
    };

    //POPUP Excluir Médico
    $public.modalExcluirMedico = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover o médico <strong><u>' +
            itemSelecionado.Nome +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return medicosAPI
              .Delete(itemSelecionado.MedicoID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Médico removido com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $private.GetMedicos();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Médico não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.loading = false;
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Nome',
          OrderBy: '-MedicoID',
          Max: 3,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $private.GetMedicos,
      };
      $private.GetMedicos();
      $public.Config = SharedDataFactory.Get().Configuracoes;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao cadastro: Médicos',
        'cadastro.medicos',
        'Acessou o menu de Médicos',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

  function modalNovoEditarMedicoCtrl(
    $uibModalInstance,
    sweetAlert,
    EnumFactory,
    medicosAPI,
    viaCepAPI,
    municipiosAPI,
    medicoSelecionado
  ) {
    var $public = this;
    var $private = {};
    $public.endereco = {};
    $public.CEPPendente = false;

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.buscarCep = function () {
      $public.ErroViaCEP = undefined;

      if (!$public.medico || !$public.medico.Endereco.CEP) {
        return;
      }
      $public.CEPPendente = true;

      viaCepAPI
        .GetEndereco($public.medico.Endereco.CEP)
        .then(function (response) {
          if (response.data.erro) {
            $public.ErroViaCEP = response.data;
            $public.CEPPendente = false;
            return;
          }

          $public.medico.Endereco.Logradouro = response.data.logradouro;
          $public.medico.Endereco.Bairro = response.data.bairro;
          $public.medico.Endereco.CodIBGE = response.data.ibge;
          $public.medico.Endereco.CodGIA = response.data.gia;
          $public.medico.Endereco.Municipio = {};
          console.log($public.medico);

          municipiosAPI
            .GetByNome(response.data.localidade, response.data.uf)
            .then(function (response) {
              console.log(response.data);
              $public.medico.Endereco.Municipio.UF = response.data.UF;
              $public.medico.Endereco.MunicipioID = response.data.MunicipioID;

              $public.BuscaMunicipios(false);
            })
            .catch(function (response) {
              $public.BuscaMunicipios(true);
            });

          $public.ViaCEP = response.data;
          $public.CEPPendente = false;
          $public.medico.Endereco.Numero = undefined;
          $public.medico.Endereco.Complemento = undefined;
          $('#signup_form_Numero').focus();
        });
    };

    $public.BuscaMunicipios = function (limparModel) {
      // Sempre que trocar de estado, limpa os campos
      $public.Municipio = [];

      if (limparModel) {
        $public.medico.Endereco.MunicipioID = undefined;
      }

      if (!$public.medico.Endereco.Municipio.UF) {
        return;
      }

      municipiosAPI
        .GetByUF($public.medico.Endereco.Municipio.UF)
        .then(function (response) {
          $public.Municipio = response.data;
        });
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = medicoSelecionado ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close($public.medico);
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O médico não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um médico com este CPF: ' +
                  $public.medico.CPF +
                  'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (medicoSelecionado) {
        // EDITAR MEDICO
        medicosAPI
          .Put($public.medico)
          .then(result.OnSuccess)
          .catch(result.OnError);
      } else {
        // CADASTRAR MEDICO
        medicosAPI
          .Post($public.medico)
          .then(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $private.Init = function () {
      $public.medico = medicoSelecionado;
      $public.Title = medicoSelecionado
        ? 'Editar Médico'
        : 'Incluir Novo Médico';
      $public.Enuns = EnumFactory;
      $public.DatePickerNascimento;

      if (medicoSelecionado) {
        $public.BuscaMunicipios();
      }
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('municipiosCtrl', municipiosCtrl)
    .controller('modalNovoEditarMunicipioCtrl', modalNovoEditarMunicipioCtrl)
    .controller(
      'modalIncluirRemoverAliquotaDaAtividadeNoMunicipioCtrl',
      modalIncluirRemoverAliquotaDaAtividadeNoMunicipioCtrl
    )
    .controller(
      'modalAtualizarObrigatoriedadeDoTomadorNoMunicipioCtrl',
      modalAtualizarObrigatoriedadeDoTomadorNoMunicipioCtrl
    );

  municipiosCtrl.$inject = [
    '$scope',
    '$q',
    '$uibModal',
    'municipiosAPI',
    'SharedDataFactory',
    'AuditoriaFactory',
  ];
  modalNovoEditarMunicipioCtrl.$inject = [
    '$q',
    '$uibModal',
    '$uibModalInstance',
    'sweetAlert',
    'EnumFactory',
    'municipiosAPI',
    'municipioSelecionado',
  ];
  modalIncluirRemoverAliquotaDaAtividadeNoMunicipioCtrl.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    'municipiosAPI',
    'atividadesAPI',
    'MunicipioAliquotasDaAtividade',
    'MunicipioID',
  ];
  modalAtualizarObrigatoriedadeDoTomadorNoMunicipioCtrl.$inject = [
    '$q',
    '$uibModal',
    '$uibModalInstance',
    'sweetAlert',
    'municipiosAPI',
    'municipioSelecionado',
    'EnumFactory'
  ];

  function municipiosCtrl(
    $scope,
    $q,
    $uibModal,
    municipiosAPI,
    SharedDataFactory,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};
    $scope.viewby = 3;

    $private.GetMunicipios = function () {
      municipiosAPI
        .Get($public.PaginationConfig.Paginacao)
        .then(function (response) {
          console.log(response.data.Registros);
          $public.municipios = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        });
    };

    //MODAL Incluir e Editar Municípios
    $public.abrirModalNovoEditarMunicipio = function (itemSelecionado) {
      var modalNovoEditarMunicipioInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/municipios/modals/modalNovoEditarMunicipios.html',
        controller: 'modalNovoEditarMunicipioCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          municipioSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            municipiosAPI
              .GetByID(itemSelecionado.MunicipioID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalNovoEditarMunicipioInstance.result.then(function () {
        $private.GetComAliquota();
      });
    };

    //MODAL Incluir e Editar Municípios
    $public.abrirModalEditarCamposObrigatoriosDoTomadorNoMunicipio = function (
      itemSelecionado
    ) {
      console.log('cheguei!');
      var modalNovoEditarMunicipioInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/municipios/modals/modalCamposObrigatoriosParaOTomador.html',
        controller: 'modalAtualizarObrigatoriedadeDoTomadorNoMunicipioCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          municipioSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            municipiosAPI
              .GetByID(itemSelecionado.MunicipioID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalNovoEditarMunicipioInstance.result.then(function () {
        $private.GetComAliquota();
      });
    };

    $public.setPage = function (pageNo) {
      $scope.currentPage = pageNo;
    };

    $public.pageChanged = function () {
      // console.log("Page changed to: " + $scope.currentPage);
    };

    $public.setItemsPerPage = function (num) {
      $scope.itemsPerPage = num;
      $scope.currentPage = 1; //reset to first page
    };

    $private.Init = function () {
      $public.loading = false;
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Nome',
          OrderBy: '-MunicipioID',
          Max: 3,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $private.GetMunicipios,
      };
      $private.GetMunicipios();
      $public.Config = SharedDataFactory.Get().Configuracoes;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao cadastro: Municípios',
        'cadastro.municipios',
        'Acessou o menu de Municípios',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

  function modalNovoEditarMunicipioCtrl(
    $q,
    $uibModal,
    $uibModalInstance,
    sweetAlert,
    EnumFactory,
    municipiosAPI,
    municipioSelecionado
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.close($public.municipio);
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = municipioSelecionado ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');

          $uibModalInstance.close($public.municipio);
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O município não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um município de nome \n' + $public.municipio.Nome,
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (municipioSelecionado) {
        // EDITAR MUNICIPIO
        console.log('Municipio selecionado', $public.municipio)
        municipiosAPI
          .Put($public.municipio)
          .then(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $private.GetTiposDeAtividades = function () {
      municipiosAPI
        .GetByID(municipioSelecionado.MunicipioID)
        .then(function (response) {
          $public.municipio = response.data;
        });
    };

    //POPUP Excluir Aliquota no Municipio
    $public.modalExcluirAliquotaDoServicoVinculado = function (
      itemSelecionado
    ) {
      console.log(itemSelecionado);
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover a Alíquota da atividade vinculada <strong><u>' +
            itemSelecionado.Descricao +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return municipiosAPI
              .DeleteAtividadeVinculadaAoServico(
                $public.municipio.MunicipioID,
                itemSelecionado.AtividadeID
              )
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title:
                    'Alíquota da atividade vinculada desvinculada com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $private.GetTiposDeAtividades();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Aliquota da atividade vinculada não encontrada, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    //MODAL Incluir e Editar Tipo de Servico do Municipio
    $public.modalIncluirEditarAliquotaDoServicoNoMunicipio = function (
      itemSelecionado
    ) {
      var modalIncluirRemoverAliquotaDaAtividadeNoMunicipioCtrl =
        $uibModal.open({
          templateUrl:
            '/views/cadastros/municipios/modals/modalIncluirEditarAliquotaDoServicoNoMunicipio.html',
          controller: 'modalIncluirRemoverAliquotaDaAtividadeNoMunicipioCtrl',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            MunicipioAliquotasDaAtividade: function () {
              if (!itemSelecionado) {
                return undefined;
              }

              var deferred = $q.defer();

              municipiosAPI
                .GetAtividadeVinculadaAoMunicipio(
                  $public.municipio.MunicipioID,
                  itemSelecionado.AtividadeID
                )
                .then(function (response) {
                  console.log(response.data);
                  deferred.resolve(response.data);
                })
                .catch(function () {
                  deferred.resolve(undefined);
                });

              return deferred.promise;
            },
            MunicipioID: function () {
              return municipioSelecionado.MunicipioID;
            },
          },
        });

      modalIncluirRemoverAliquotaDaAtividadeNoMunicipioCtrl.result.then(
        function () {
          $private.GetTiposDeAtividades();
        }
      );
    };

    $private.Init = function () {
      $public.municipio = municipioSelecionado;
      $public.Title = municipioSelecionado
        ? 'Editar Município'
        : 'Incluir Novo Município';
      $public.Enuns = EnumFactory;
    };

    $private.Init();
  }

  function modalIncluirRemoverAliquotaDaAtividadeNoMunicipioCtrl(
    $uibModalInstance,
    sweetAlert,
    municipiosAPI,
    atividadesAPI,
    MunicipioAliquotasDaAtividade,
    MunicipioID
  ) {
    var $public = this;
    var $private = {};

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = MunicipioAliquotasDaAtividade ? 'alteradas' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close();
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O tipo de atividade não foi encontrada, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'O tipo de atividade selecionada já está cadastrada para este município',
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (MunicipioAliquotasDaAtividade) {
        // EDITAR ATIVIDADE DO MUNICIPIO
        municipiosAPI
          .PutAtividadeNoMunicipio(
            MunicipioID,
            $public.MunicipioAliquotasDaAtividade
          )
          .then(result.OnSuccess)
          .catch(result.OnError);
      } else {
        // CADASTRAR ATIVIDADE DO MUNICIPIO
        municipiosAPI
          .PostAtividadeNoMunicipio(
            MunicipioID,
            $public.MunicipioAliquotasDaAtividade
          )
          .then(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $private.Init = function () {
      $public.MunicipioAliquotasDaAtividade = MunicipioAliquotasDaAtividade;
      $public.Title = MunicipioAliquotasDaAtividade
        ? 'Editar Atividade e Alíquota ao Munícipo'
        : 'Incluir Atividade e Alíquota ao Munícipo';

      if (!MunicipioAliquotasDaAtividade) {
        atividadesAPI.Get().then(function (response) {
          $public.Atividades = response.data;
        });
      }
      // tipoDeServicosAPI.Get().then(function (response) {
      //    $public.TiposDeServicos = response.data;
      //});
    };

    $private.Init();

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };
  }

  function modalAtualizarObrigatoriedadeDoTomadorNoMunicipioCtrl(
    $q,
    $uibModal,
    $uibModalInstance,
    sweetAlert,
    municipiosAPI,
    municipioSelecionado,
    EnumFactory
  ) {
    var $public = this;
    var $private = {};

    $public.FecharModal = function () {
      $uibModalInstance.close();
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = municipioSelecionado ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');

          $uibModalInstance.close();
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O município não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um município de nome \n' + $public.municipio.Nome,
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (municipioSelecionado) {
        municipiosAPI.Put($public.municipio).then(function () {
          municipiosAPI
            .PutEhMultiServico(
              $public.municipio.MunicipioID,
              $public.municipio.MultiServico
            )
            .then(function () {
              // EDITAR MUNICIPIO
              municipiosAPI
                .PutCamposObrigatorios($public.municipio.CamposObrigatorios)
                .then(result.OnSuccess)
                .catch(result.OnError);
            });
        });
      }
    };

    $private.Init = function () {
      $public.municipio = municipioSelecionado;
      $public.Title = municipioSelecionado
        ? 'Editar Município'
        : 'Incluir Novo Município';
      
        $public.Enuns = EnumFactory;
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('servicosCtrl', servicosCtrl)
    .controller(
      'modalIncluirRemoverServicosCtrl',
      modalIncluirRemoverServicosCtrl
    )
    .controller(
      'modalIncluirRemoverServicosPlugNotasCtrl',
      modalIncluirRemoverServicosPlugNotasCtrl
    );

  servicosCtrl.$inject = [
    '$scope',
    '$q',
    '$uibModal',
    'ListaDeServicos',
    'servicosAPI',
    'clientesAPI',
    'servicosplugNotasAPI',
    'sweetAlert',
    'focus',
    'AuditoriaFactory',
  ];
  modalIncluirRemoverServicosCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'sweetAlert',
    'servicosAPI',
    'enotasAPI',
    'Clientes',
    'servicoSelecionado',
  ];
  modalIncluirRemoverServicosPlugNotasCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'sweetAlert',
    'servicosplugNotasAPI',
    'enotasAPI',
    'Clientes',
    'servicoSelecionado',
    'EnumFactory',
  ];

  function servicosCtrl(
    $scope,
    $q,
    $uibModal,
    ListaDeServicos,
    servicosAPI,
    clientesAPI,
    servicosplugNotasAPI,
    sweetAlert,
    focus,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};
    $scope.viewby = 50;
    $public.searchBar = '';

    $public.GetServicos = function () {
      servicosAPI
        .GetPage($public.currentPage, $public.itemsPerPage, $public.searchBar)
        .then(function (response) {
          $public.servicos = response.data.Registros;
          $scope.totalItems = response.data.TotalRegistros;
          $public.totalItems = response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
        });
    };

    //MODAL Incluir e Editar Serviços
    $public.abrirModalNovoEditarServico = function (itemSelecionado) {
      var modalIncluirRemoverServicosCtrl = $uibModal.open({
        templateUrl:
          '/views/cadastros/servicos/modals/modalIncluirRemoverServico.html',
        controller: 'modalIncluirRemoverServicosCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          servicoSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            servicosAPI
              .GetByID(itemSelecionado.ServicoID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          Clientes: function () {
            var deferred = $q.defer();
            clientesAPI
              .GetCompleto()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });
            return deferred.promise;
          },
        },
      });

      modalIncluirRemoverServicosCtrl.result.then(function () {
        $private.GetServicos();
      });
    };

    //POPUP Excluir Tipos de Serviços
    $public.modalExcluirServico = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover o serviço <strong><u>' +
            itemSelecionado.Descricao +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return servicosAPI
              .Delete(itemSelecionado.ServicoID, itemSelecionado.ClienteID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Serviço removido com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $public.GetServicos();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Serviço não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    $public.GetServicos();
                    // sweetAlert.swal.insertQueueStep({
                    //   title: 'Atenção!',
                    //   text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                    //   type: 'error',
                    // });
                    break;
                }
              });
          },
        },
      ]);
    };

    //MODAL Incluir e Editar Serviços
    $public.abrirModalNovoEditarServicoPlugNotas = function (itemSelecionado) {
      var modalIncluirRemoverServicosPlugNotasCtrl = $uibModal.open({
        templateUrl:
          '/views/cadastros/servicosplugnotas/modals/modalIncluirEditarServicosPlugNotas.html',
        controller: 'modalIncluirRemoverServicosPlugNotasCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          servicoSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }

            var deferred = $q.defer();

            servicosplugNotasAPI
              .GetByID(itemSelecionado.PlugNotasServicoID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
          Clientes: function () {
            var deferred = $q.defer();
            clientesAPI
              .GetCompleto()
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });
            return deferred.promise;
          },
        },
      });

      modalIncluirRemoverServicosPlugNotasCtrl.result.then(function () {
        $private.GetServicos();
      });
    };

    $public.setPage = function (pageNo) {
      $scope.currentPage = pageNo;
    };

    $public.pageChanged = function () {
      $public.currentPage = $scope.currentPage;
      $public.itemsPerPage = $public.viewby;
      $public.GetServicos();
    };

    $public.setItemsPerPage = function (num) {
      $public.itemsPerPage = num;
      $scope.itemsPerPage = num;
      $scope.currentPage = 1; //reset to first page
      $public.currentPage = 1;
      $public.GetServicos();
    };

    $public.searchService = function () {
      $public.itemsPerPage = $scope.itemsPerPage;
      $public.currentPage = 1;
      $public.GetServicos();
    };

    $public.pressEnter = function(event) {
      if (event.keyCode === 13) {
        $public.searchService();
      }
    }

    $private.Init = function () {
      focus('focusMe');
      console.log('li', ListaDeServicos)
      $public.servicos = ListaDeServicos.Registros;
      $public.viewby = '50';
      $scope.totalItems = ListaDeServicos.TotalRegistros;
      $scope.currentPage = 1;
      $scope.itemsPerPage = $scope.viewby;
      $scope.maxSize = 10; //Number of pager buttons to show
      $scope.sortType = 'Descricao';
      $scope.sortReverse = false;

      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: ListaDeServicos.Search,
          SearchField: ListaDeServicos.SearchField,
          OrderBy: ListaDeServicos.OrderBy,
          Max: $scope.viewby,
          Page: ListaDeServicos.Page,
          TotalRegistros: ListaDeServicos.TotalRegistros,
        },
        Action: $public.GetServicos,
      };

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao cadastro: Serviços',
        'cadastro.servicos',
        'Acessou o menu de Serviços',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

  function modalIncluirRemoverServicosCtrl(
    $scope,
    $uibModalInstance,
    sweetAlert,
    servicosAPI,
    enotasAPI,
    Clientes,
    servicoSelecionado
  ) {
    var $public = this;
    var $private = {};

    $uibModalInstance.result.catch(function () {
      //Do stuff with respect to dismissal
      $uibModalInstance.dismisAll('Cancel');
    });

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = servicoSelecionado ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close($public.servico);
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O tipo de serviço não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um tipo de serviço código: \n' +
                  $public.atividade.CodigoDaAtividade,
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (servicoSelecionado) {
        // EDITAR TIPO DE SERVIÇO
        servicosAPI
          .Put($public.servico)
          .then(result.OnSuccess)
          .catch(result.OnError);
      } else {
        // CADASTRAR TIPO DE SERVIÇO
        $public.servico.EnotasID = angular.copy($public.servico.codigo);
        $public.servico.AliquotaSugerida = angular.copy($public.Aliquota);
        $public.servico.CodigoDeServicoMunicipal = angular.copy(
          $public.CodigoServicoMunicipal
        );
        $public.servico.ItemListaServicoLC116 = angular.copy($public.LC116);
        servicosAPI
          .Post($public.servico, $public.empresa.ClienteID)
          .then(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $public.SelecionouCliente = function () {
      $public.servico = undefined;
      $scope.servicos = undefined;
    };

    $scope.refreshServicos = function (servico) {
      if (servico.length > 80 || servico.split(' ').length > 10) return;

      $public.Searching = false;
      if (servico.length > 3 && !$public.empresa !== null) {
        $public.Searching = true;

        var { UF, Nome } = $public.empresa.Endereco.Municipio;

        console.log(servico);
        return enotasAPI
          .ServiçosMunicipaisdaCidade(UF, Nome, servico)
          .then(function (response) {
            console.log(response.data);
            $scope.servicos = response.data;
            $public.UsaCodigoServicoMunicipal =
              response.data.UsaCodigoServicoMunicipal;
          })
          .finally(function () {
            $public.Searching = false;
          });
      }
    };

    $private.GetServico = function (servicoID) {
      servicosAPI.GetByID(servicoID).then(function (response) {
        $public.servico = response.data;
      });
    };

    $private.Init = function () {
      $public.Title = servicoSelecionado ? 'Editar Serviço' : 'Incluir Serviço';
      $public.servico = servicoSelecionado;
      $public.clientes = Clientes;
      $public.UsaCodigoServicoMunicipal = false;
    };

    $private.Init();
  }

  function modalIncluirRemoverServicosPlugNotasCtrl(
    $scope,
    $uibModalInstance,
    sweetAlert,
    servicosplugNotasAPI,
    enotasAPI,
    Clientes,
    servicoSelecionado,
    EnumFactory
  ) {
    var $public = this;
    var $private = {};

    $uibModalInstance.result.catch(function () {
      //Do stuff with respect to dismissal
      $uibModalInstance.dismisAll('Cancel');
    });

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = servicoSelecionado ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close($public.ServicoCompleto);
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O tipo de serviço não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um tipo de serviço código: \n' +
                  $public.atividade.CodigoDaAtividade,
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (servicoSelecionado) {
        // EDITAR TIPO DE SERVIÇO
        $public.ServicoCompleto.AnexoPadraoID =
          +$public.ServicoCompleto.AnexoPadraoID;
        console.log('Edit', $public.ServicoCompleto);
        servicosplugNotasAPI
          .Put($public.ServicoCompleto)
          .then(result.OnSuccess)
          .catch(result.OnError);
      } else {
        // CADASTRAR TIPO DE SERVIÇO
        $public.ServicoCompleto.AnexoPadraoID =
          +$public.ServicoCompleto.AnexoPadraoID;
        servicosplugNotasAPI
          .Post($public.ServicoCompleto, $public.empresa.ClienteID)
          .then(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $public.SelecionouCliente = function () {
      $public.ServicoCompleto = undefined;
      $scope.ServicoCompleto = undefined;
    };

    $scope.refreshServicos = function (servico) {
      if (servico.length > 80 || servico.split(' ').length > 10) return;

      $public.Searching = false;
      if (servico.length > 3 && !$public.empresa !== null) {
        $public.Searching = true;

        var { UF, Nome } = $public.empresa.Endereco.Municipio;

        console.log(servico);
        return enotasAPI
          .ServiçosMunicipaisdaCidade(UF, Nome, servico)
          .then(function (response) {
            console.log(response.data);
            $scope.ServicoCompleto = response.data;
            $public.UsaCodigoServicoMunicipal =
              response.data.UsaCodigoServicoMunicipal;
          })
          .finally(function () {
            $public.Searching = false;
          });
      }
    };

    $private.GetServico = function (servicoID) {
      servicosplugNotasAPI.GetByID(servicoID).then(function (response) {
        $public.ServicoCompleto = response.data;
      });
    };

    

    $private.Init = function () {
      $scope.options = [
        {
          name: 'Anexo 3',
          value: 1,
        },
        {
          name: 'Anexo 5',
          value: 2,
        },
      ];

      $public.Enuns = EnumFactory;
      $public.Title = servicoSelecionado ? 'Editar Serviço' : 'Incluir Serviço';
      $public.ServicoCompleto = servicoSelecionado;
      $public.clientes = Clientes;
      //$public.empresa =
      //console.log($public.clientes.find(f => f.ClienteID == servicoSelecionado.ClienteID))
    };

    $private.Init();
  }
})();

(function () {
    'use strict';

    angular
      .module('fokus')
      .controller('servicosPlugNotasCtrl', servicosPlugNotasCtrl);

      servicosPlugNotasCtrl.$inject = [
      '$scope',
      '$q',
      '$uibModal',
      'ListaDeServicos',
      'servicosplugNotasAPI',
      'clientesAPI',
      'sweetAlert',
      'focus',
    ];

    function servicosPlugNotasCtrl(
      $scope,
      $q,
      $uibModal,
      ListaDeServicos,
      servicosplugNotasAPI,
      clientesAPI,
      sweetAlert,
      focus
    ) {
      var $public = this;
      var $private = {};
      $scope.viewby = 3;

      $private.GetServicos = function () {
        servicosplugNotasAPI.Get().then(function (response) {
          $public.servicosPlugNotas = response.data;
          console.log(response.data);
          $scope.totalItems = $public.servicosPlugNotas.length;
        });
      };

      //MODAL Incluir e Editar Serviços
      $public.abrirModalNovoEditarServicoPlugNotas = function (itemSelecionado) {
        var modalIncluirRemoverServicosPlugNotasCtrl = $uibModal.open({
          templateUrl:
            '/views/cadastros/servicosplugnotas/modals/modalIncluirEditarServicosPlugNotas.html',
          controller: 'modalIncluirRemoverServicosPlugNotasCtrl',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            servicoSelecionado: function () {
              if (!itemSelecionado) {
                return undefined;
              }
              var deferred = $q.defer();

              servicosplugNotasAPI
                .GetByID(itemSelecionado.ServicoCompletoID)
                .then(function (response) {
                  deferred.resolve(response.data);
                })
                .catch(function () {
                  deferred.resolve(undefined);
                });

              return deferred.promise;
            },
            Clientes: function () {
              var deferred = $q.defer();
              clientesAPI
                .GetCompleto()
                .then(function (response) {
                  deferred.resolve(response.data);
                })
                .catch(function () {
                  deferred.resolve(undefined);
                });
              return deferred.promise;
            },
          },
        });

        modalIncluirRemoverServicosPlugNotasCtrl.result.then(function () {
          $private.GetServicos();
        });
      };

      //POPUP Excluir Tipos de Serviços
      $public.modalExcluirServicoPlugNotas = function (itemSelecionado) {
        sweetAlert.swal.queue([
          {
            title: 'Atenção!',
            html:
              'Deseja remover o serviço <strong><u>' +
              itemSelecionado.Discriminacao +
              '</u></strong>?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F44336',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            preConfirm: function () {
              $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

              return servicosplugNotasAPI
                .Delete(itemSelecionado.ServicoCompletoID, itemSelecionado.ClienteID)
                .then(function (response) {
                  console.log('Success: %o', response);
                  sweetAlert.swal.insertQueueStep({
                    title: 'Serviço removido com sucesso!',
                    text: '',
                    type: 'success',
                    timer: 3500,
                  });
                  $private.GetServicos();
                })
                .catch(function (response) {
                  console.log('Error: %o', response);
                  switch (response.status) {
                    case 404:
                      sweetAlert.swal.insertQueueStep({
                        title: 'Atenção!',
                        text: 'Serviço não encontrado, Verifique!',
                        type: 'error',
                      });
                      break;

                    default:
                      sweetAlert.swal.insertQueueStep({
                        title: 'Atenção!',
                        text:
                          'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                        type: 'error',
                      });
                      break;
                  }
                });
            },
          },
        ]);
      };

      $public.setPage = function (pageNo) {
        $scope.currentPage = pageNo;
      };

      $public.pageChanged = function () {
        // console.log("Page changed to: " + $scope.currentPage);
      };

      $public.setItemsPerPage = function (num) {
        $scope.itemsPerPage = num;
        $scope.currentPage = 1; //reset to first page
      };

      $private.Init = function () {
        focus('focusMe');
        $public.servicosPlugNotas = ListaDeServicos.data;
        console.log(ListaDeServicos.data);
        $public.viewby = '3';
        $scope.totalItems = $public.servicosPlugNotas.length;
        $scope.currentPage = 1;
        $scope.itemsPerPage = $scope.viewby;
        $scope.maxSize = 10; //Number of pager buttons to show
        $scope.sortType = 'Descricao';
        $scope.sortReverse = false;
      };

      $private.Init();
    }

  })();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('tomadoresCtrl', tomadoresCtrl)
    .controller('modalNovoEditarTomadorCtrl', modalNovoEditarTomadorCtrl);

  tomadoresCtrl.$inject = [
    '$q',
    '$uibModal',
    'tomadoresAPI',
    'sweetAlert',
    'SharedDataFactory',
    'AuditoriaFactory',
  ];
  modalNovoEditarTomadorCtrl.$inject = [
    '$uibModalInstance',
    'sweetAlert',
    'EnumFactory',
    'viaCepAPI',
    'tomadorSelecionado',
    'municipiosAPI',
    'tomadoresAPI',
    'configuracoesDoUsuario',
    'camposObrigatorios',
    'AuditoriaFactory',
  ];

  function tomadoresCtrl(
    $q,
    $uibModal,
    tomadoresAPI,
    sweetAlert,
    SharedDataFactory,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};

    $public.GetTomadores = function () {
      tomadoresAPI
        .Get($public.PaginationConfig.Paginacao)
        .then(function (response) {
          $public.tomadores = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    //MODAL Incluir e Editar Tomador
    $public.abrirModalNovoEditarTomador = function (itemSelecionado) {
      if ($public.loading === true) {
        return;
      }
      $public.loading = true;

      var modalNovoEditarTomadoresInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/tomadores/modals/modalNovoEditarTomador.html',
        controller: 'modalNovoEditarTomadorCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          tomadorSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            tomadoresAPI
              .GetByID(itemSelecionado.TomadorID)
              .then(function (response) {
                deferred.resolve(response.data);
              })
              .catch(function () {
                deferred.resolve(undefined);
              })
              .finally(function () {
                $public.loading = false;
              });

            return deferred.promise;
          },
          configuracoesDoUsuario: function () {
            $public.loading = false;
            return $public.Config;
          },

          camposObrigatorios: function () {
            var deferred = $q.defer();

            tomadoresAPI
              .GetCamposObrigatoriosDoTomador()
              .then(function (response) {
                if (response) {
                  deferred.resolve(response.data);
                } else {
                  deferred.resolve(undefined);
                }
              });
            return deferred.promise;
          },
        },
      });

      modalNovoEditarTomadoresInstance.result.then(function () {
        $public.GetTomadores();
        $public.loading = false;
      });
    };

    //POPUP Excluir Tomador
    $public.modalExcluirTomador = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover o ' +
            ($public.Config.CadastroFacilitadoParaCliente
              ? 'cliente'
              : 'tomador') +
            ' <strong><u>' +
            (itemSelecionado.Nome != null
              ? itemSelecionado.Nome
              : 'CPF: ' + itemSelecionado.CPF) +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return tomadoresAPI
              .Delete(itemSelecionado.TomadorID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Tomador removido com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $public.GetTomadores();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Tomador não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Nome',
          OrderBy: '-TomadorID',
          Max: 3,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
        },
        Action: $public.GetTomadores,
      };
      $public.GetTomadores();
      $public.Config = SharedDataFactory.Get().Configuracoes;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao cadastro: Tomadores',
        'cadastro.tomadores',
        'Acessou o menu de Tomadores',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

  function modalNovoEditarTomadorCtrl(
    $uibModalInstance,
    sweetAlert,
    EnumFactory,
    viaCepAPI,
    tomadorSelecionado,
    municipiosAPI,
    tomadoresAPI,
    configuracoesDoUsuario,
    camposObrigatorios,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};

    $public.endereco = {};
    $public.CEPPendente = false;
    $public.CNPJPendente = false;

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $public.buscarCep = function () {
      $public.ErroViaCEP = undefined;

      if (
        !$public.tomador ||
        !$public.tomador.Endereco ||
        !$public.tomador.Endereco.CEP
      ) {
        return;
      }

      $public.CEPPendente = true;
      viaCepAPI
        .GetEndereco($public.tomador.Endereco.CEP)
        .then(function (response) {
          if (response.data.erro) {
            $public.ErroViaCEP = response.data;
            $public.CEPPendente = false;
            return;
          }

          $public.CEPPendente = false;

          $public.tomador.Endereco.Logradouro = response.data.logradouro;
          $public.tomador.Endereco.Bairro = response.data.bairro;
          $public.tomador.Endereco.CodIBGE = response.data.ibge;
          $public.tomador.Endereco.CodGIA = response.data.gia;
          $public.tomador.Endereco.CodSIAFI = response.data.SIAFI.CodSIAFI;
          $public.tomador.Endereco.Municipio = {};
          $public.ViaCEP = response.data;

          $public.tomador.Endereco.Numero = undefined;
          $public.tomador.Endereco.Complemento = undefined;

          municipiosAPI
            .GetByNome(response.data.localidade, response.data.uf)
            .then(function (response) {
              $public.tomador.Endereco.Municipio.UF = response.data.UF;
              $public.tomador.Endereco.MunicipioID = response.data.MunicipioID;

              $public.BuscaMunicipios(false);
            })
            .catch(function (response) {
              $public.BuscaMunicipios(true);
            });

          if (response.data.logradouro === '') {
            $('#signup_form_Endereco').focus();
          } else {
            $('#signup_form_Numero').focus();
          }
        })
        .catch(function () {
          $public.CEPPendente = false;
          $public.tomador.Endereco.Logradouro = '';
          $public.tomador.Endereco.Bairro = '';
          $public.tomador.Endereco.Localidade = '';
          $public.tomador.Endereco.UF = '';
          $public.tomador.Endereco.CodIBGE = '';
          $public.tomador.Endereco.CodGIA = '';
          $public.tomador.Endereco.CodSIAFI = '';
          $('#signup_form_Endereco').focus();
          return;
        });
    };

    $public.buscarCNPJ = function () {
      if (!$public.tomador || !$public.tomador.CNPJ) {
        $public.CNPJPendente = false;
        return;
      }
      if ($public.tomador.CNPJ.length < 13) {
        $public.CNPJPendente = false;
        return;
      }

      $public.CNPJPendente = true;
      $public.BuscouCNPJOuCPF = false;

      tomadoresAPI
        .GetByCNPJorCPF($public.tomador.CNPJ)
        .then(function (response) {
          $public.BuscouCNPJOuCPF = true;
          if (response.data.erro) {
            $public.CNPJPendente = false;
            return;
          }
          if (response.data) {
            $public.tomador.TomadorID = response.data.TomadorID;
            $public.tomador.Ativo = response.data.Ativo;
            $public.tomador.Tipo = response.data.Tipo;
            $public.tomador.CNPJ = response.data.CNPJ;
            $public.tomador.InscricaoMunicipal =
              response.data.InscricaoMunicipal;
            $public.tomador.RetemISS = response.data.RetemISS;
            $public.tomador.Nome = response.data.Nome;
            $public.tomador.Email = response.data.Email;
            $public.tomador.Contato = response.data.Contato;
            $public.tomador.Telefone = response.data.Telefone;

            $public.tomador.Endereco = {};
            $public.tomador.Endereco.CEP = response.data.Endereco.CEP;
            $public.tomador.Endereco.Logradouro =
              response.data.Endereco.Logradouro;
            $public.tomador.Endereco.Numero = response.data.Endereco.Numero;
            $public.tomador.Endereco.Complemento =
              response.data.Endereco.Complemento;
            $public.tomador.Endereco.Bairro = response.data.Endereco.Bairro;

            $public.tomador.Endereco.Municipio = {};
            $public.tomador.Endereco.Municipio.UF =
              response.data.Endereco.Municipio.UF;

            $public.BuscaMunicipios(false);
            $public.tomador.Endereco.MunicipioID =
              response.data.Endereco.Municipio.MunicipioID;
            $public.tomador.Endereco.CodSIAFI =
              response.data.Endereco.Municipio.SIAFI;

            $public.CNPJPendente = false;
            $public.BuscouCNPJOuCPF = true;
            $('#signup_form_Nome').focus();
          }

          $public.CNPJPendente = false;
          $public.BuscouCNPJOuCPF = true;
          console.log(' chegou');
          $('#signup_form_Nome').focus();
        })
        .catch(function (err) {
          $public.CNPJPendente = false;
          $public.BuscouCNPJOuCPF = true;
          if (err.status === 409) {
            sweetAlert.swal(
              'Já existe um cadastrado para este CNPJ.',
              '',
              'info'
            );
            $public.tomador.CNPJ = '';
            $public.tomador.Nome = '';
            $public.tomador.Email = '';
            $public.tomador.Contato = '';
            $public.tomador.Telefone = '';
            $public.BuscouCNPJOuCPF = false;
            $('#signup_form_Nome').focus();
          }
          $public.tomador.InscricaoMunicipal = '';
          $public.tomador.RetemISS = false;
          $public.tomador.Nome = '';
          $public.tomador.Email = '';
          $public.tomador.Contato = '';
          $public.tomador.Telefone = '';
        });
    };

    $public.buscarCPF = function () {
      if (!$public.tomador || !$public.tomador.CPF) {
        $public.CPFPendente = false;
        return;
      }
      if ($public.tomador.CPF.length < 10) {
        $public.CPFPendente = false;
        return;
      }

      $public.CPFPendente = true;
      $public.BuscouCNPJOuCPF = false;

      tomadoresAPI
        .GetByCNPJorCPF($public.tomador.CPF)
        .then(function (response) {
          $public.BuscouCNPJOuCPF = true;
          if (response.data.erro) {
            $public.CPFPendente = false;
            return;
          }
          if (response.data) {
            $public.tomador.TomadorID = response.data.TomadorID;
            $public.tomador.Ativo = response.data.Ativo;
            $public.tomador.Tipo = response.data.Tipo;
            $public.tomador.CPF = response.data.CPF;
            $public.tomador.RG = response.data.RG;
            $public.tomador.Nome = response.data.Nome;
            $public.tomador.Email = response.data.Email;
            $public.tomador.Contato = response.data.Contato;
            $public.tomador.Telefone = response.data.Telefone;

            $public.tomador.Endereco = {};
            $public.tomador.Endereco.CEP = response.data.Endereco.CEP;
            $public.tomador.Endereco.Logradouro =
              response.data.Endereco.Logradouro;
            $public.tomador.Endereco.Numero = response.data.Endereco.Numero;
            $public.tomador.Endereco.Complemento =
              response.data.Endereco.Complemento;
            $public.tomador.Endereco.Bairro = response.data.Endereco.Bairro;

            $public.tomador.Endereco.Municipio = {};
            $public.tomador.Endereco.Municipio.UF =
              response.data.Endereco.Municipio.UF;

            $public.BuscaMunicipios(false);
            $public.tomador.Endereco.MunicipioID =
              response.data.Endereco.Municipio.MunicipioID;

            $public.tomador.Endereco.CodSIAFI =
              response.data.Endereco.Municipio.SIAFI;

            //$public.tomador.Endereco.Municipio.MunicipioID = response.data.tomador.Endereco.Municipio.MunicipioID;

            //$public.tomador.Endereco.CEP = response.data.Endereco.CEP;
            //$public.tomador.Endereco.Logradouro = response.data.Endereco.Logradouro;
            // $public.tomador.Endereco.Bairro = response.data.Endereco.bairro;
            //$public.tomador.Endereco.CodIBGE = response.data.Endereco.ibge;
            //$public.tomador.Endereco.CodGIA = response.data.Endereco.gia;
            $public.CPFPendente = false;
            $public.BuscouCNPJOuCPF = true;
            $('#signup_form_CPF').focus();
          }

          $public.CPFPendente = false;
          $public.BuscouCNPJOuCPF = true;
          $('#signup_form_CPF').focus();
        })
        .catch(function (err) {
          $public.CPFPendente = false;
          $public.BuscouCNPJOuCPF = true;
          if (err.status === 409) {
            sweetAlert.swal(
              'Já existe um cadastrado para este CPF.',
              '',
              'info'
            );
            $public.tomador.CPF = '';
            $public.tomador.RG = '';
            $public.tomador.Nome = '';
            $public.tomador.Email = '';
            $public.tomador.Contato = '';
            $public.tomador.Telefone = '';
            $public.BuscouCNPJOuCPF = false;
            $('#signup_form_Nome').focus();
          }

          //$public.tomador.Ativo = 1;
          //$public.tomador.Tipo = response.data.Tipo;
          //$public.tomador.CNPJ = response.data.CNPJ;
          $public.tomador.InscricaoMunicipal = response.data.InscricaoMunicipal;
          $public.tomador.RetemISS = false;
          $public.tomador.Nome = '';
          $public.tomador.Email = '';
          $public.tomador.Contato = '';
          $public.tomador.Telefone = '';

          //$("#signup_form_Endereco").focus();
        });
    };

    $public.BuscaMunicipios = function (limparModel) {
      // Sempre que trocar de estado, limpa os campos
      $public.Municipio = [];

      if (limparModel) {
        $public.tomador.Endereco.MunicipioID = undefined;
      }

      if (!$public.tomador.Endereco.Municipio.UF) {
        return;
      }

      municipiosAPI
        .GetByUF($public.tomador.Endereco.Municipio.UF)
        .then(function (response) {
          $public.Municipio = response.data;
        });
    };

    $public.Enviar = function () {
      var result = {
        OnSuccess: function () {
          var msg = tomadorSelecionado ? 'alterados' : 'adicionados';
          /**** Auditoria *****/
          /*************/
          AuditoriaFactory.CriarAuditoria(
            'Alterou um Tomador',
            'cadastro.tomador',
            'Alterou um cadastro de tomador',
            $public.tomador.TomadorID,
            $public.tomadorOriginal,
            $public.tomador
          );

          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close($public.tomador);
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O tomador não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um tomador com este CNPJ ou CPF: \n' +
                  $public.tomador.Nome,
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (tomadorSelecionado) {
        // EDITAR TOMADOR
        tomadoresAPI
          .Put($public.tomador)
          .then(result.OnSuccess)
          .catch(result.OnError);
      } else {
        // CADASTRAR TOMADOR
        tomadoresAPI
          .Post($public.tomador)
          .then(result.OnSuccess)
          .catch(result.OnError);
      }
    };

    $private.Init = function () {
      $public.tomador = tomadorSelecionado;
      $public.tomadorOriginal = angular.copy(tomadorSelecionado);

      console.log(tomadorSelecionado);
      console.log(camposObrigatorios);

      var Config = configuracoesDoUsuario;

      $public.Title = tomadorSelecionado
        ? Config.CadastroFacilitadoParaCliente
          ? 'Editar Cliente'
          : 'Editar Tomador'
        : Config.CadastroFacilitadoParaCliente
        ? 'Incluir Novo Cliente'
        : 'Incluir Novo Tomador';

      $public.Enuns = EnumFactory;

      $public.BuscouCNPJOuCPF = false;

      if (tomadorSelecionado) {
        $public.BuscouCNPJOuCPF = true;
        $public.BuscaMunicipios();
      }
      $public.camposObrigatorios =
        camposObrigatorios.CamposObrigatoriosDoTomador;
    };
    $private.Init();
  }
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .controller('usuariosCtrl', usuariosCtrl)
    .controller('modalNovoEditarUsuarioCtrl', modalNovoEditarUsuarioCtrl)
    .controller(
      'abrirModalImportarTomadoresDeOutroUsuarioCtrl',
      abrirModalImportarTomadoresDeOutroUsuarioCtrl
    )
    .controller('modalVincularNovoTomadorCtrl', modalVincularNovoTomadorCtrl);

  usuariosCtrl.$inject = [
    '$q',
    '$uibModal',
    'sweetAlert',
    'usuariosAPI',
    'usuarioAPI',
    'medicosAPI',
    'config',
    'SharedDataFactory',
    'AuditoriaFactory',
  ];

  modalNovoEditarUsuarioCtrl.$inject = [
    '$q',
    '$scope',
    'EnumFactory',
    '$uibModalInstance',
    '$uibModal',
    'sweetAlert',
    'usuariosAPI',
    'permissoesAPI',
    'clientesAPI',
    'tomadoresAPI',
    'municipiosAPI',
    'usuarioSelecionado',
    'medicos',
    'auditoriaAPI',
  ];

  abrirModalImportarTomadoresDeOutroUsuarioCtrl.$inject = [
    '$scope',
    'sweetAlert',
    '$uibModalInstance',
    'usuariosAPI',
    'tomadoresAPI',
  ];

  modalVincularNovoTomadorCtrl.$inject = [
    '$scope',
    'sweetAlert',
    '$uibModalInstance',
    'usuariosAPI',
    'tomadoresAPI',
    'UsuarioSelecionado',
    'ListaDeTomadores',
  ];

  function usuariosCtrl(
    $q,
    $uibModal,
    sweetAlert,
    usuariosAPI,
    usuarioAPI,
    medicosAPI,
    config,
    SharedDataFactory,
    AuditoriaFactory
  ) {
    var $public = this;
    var $private = {};

    $private.GetUsuarios = function () {
      usuariosAPI
        .Get($public.PaginationConfig.Paginacao)
        .then(function (response) {
          $public.usuarios = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Paginacao.SemCliente = $public.usuarioFilterVinculo;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    $public.filtrarSemCliente = function() {
      $public.PaginationConfig.Paginacao.SemCliente = $public.usuarioFilterVinculo
      $private.GetUsuarios()
    }

    //MODAL Incluir e Editar Usuario
    $public.abrirModalNovoEditarUsuario = function (itemSelecionado) {
      var modalNovoEditarUsuarioInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/usuarios/modals/modalNovoEditarUsuario.html',
        controller: 'modalNovoEditarUsuarioCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          usuarioSelecionado: function () {
            if (!itemSelecionado) {
              return undefined;
            }
            var deferred = $q.defer();

            usuariosAPI
              .GetByID(itemSelecionado.UsuarioID)
              .then(function (response) {
                response.data.DataNascimento = new Date(
                  response.data.DataNascimento
                );
                $public.loading = false;
                deferred.resolve(response.data);
              })
              .catch(function () {
                $public.loading = false;
                deferred.resolve(undefined);
              })
              .finally(function () {
                $public.loading = false;
              });
            return deferred.promise;
          },
          medicos: function () {
            if (!itemSelecionado) {
              return undefined;
            }

            var deferred = $q.defer();
            medicosAPI
              .Get()
              .then(function (response) {
                deferred.resolve(response.data.Registros);
              })
              .catch(function () {
                deferred.resolve(undefined);
              });

            return deferred.promise;
          },
        },
      });

      modalNovoEditarUsuarioInstance.result
        .then(function () {
          $private.GetUsuarios();
          $public.loading = false;
        })
        .catch(function () {
          $public.loading = false;
        });
    };

    //MODAL Incluir e Editar Usuario
    $public.abrirModalImportarTomadoresDeOutroUsuario = function () {
      $uibModal.open({
        templateUrl:
          '/views/cadastros/usuarios/modals/modalImportarTomadoresDeOutroUsuario.html',
        controller: 'abrirModalImportarTomadoresDeOutroUsuarioCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {},
      });
    };

    //POPUP Enviar E-mail de Confirmação de Cadastro
    $public.AbrirModalEmailDeConfirmacao = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Recuperar Senha',
          html:
            'Deseja enviar um e-mail com a senha para o usuário <strong><u>' +
            itemSelecionado.Nome +
            '</u></strong>?',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#5cb85c',
          confirmButtonText: 'Sim, enviar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return usuarioAPI
              .RecuperarSenha(itemSelecionado.Email)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'E-mail de confirmação enviado!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 503:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'O servidor de recuperação está fora do ar.',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao enviar o e-mail de confirmação.',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    //POPUP Enviar E-mail de Boas Vindas
    $public.AbrirModalEmailDeBoasVindas = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'E-mail de Boas-Vindas',
          html:
            'Deseja enviar um e-mail de boas-vindas para o usuário <strong><u>' +
            itemSelecionado.Nome +
            '</u></strong>?',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#5cb85c',
          confirmButtonText: 'Sim, enviar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return usuarioAPI
              .EnviarEmailBoasVindas(itemSelecionado.UsuarioID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'E-mail de boas-vindas enviado!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 503:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'O servidor de recuperação está fora do ar.',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao enviar o e-mail de confirmação.',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };


    $public.AbrirModalEmailDeOrientacao = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'E-mail de Orientação',
          html:
            'Deseja enviar um e-mail de orientação para o usuário <strong><u>' +
            itemSelecionado.Nome +
            '</u></strong>?',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#5cb85c',
          confirmButtonText: 'Sim, enviar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return usuarioAPI
              .EnviarEmailOrientacao(itemSelecionado.UsuarioID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'E-mail de orientação enviado!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 503:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'O servidor de recuperação está fora do ar.',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao enviar o e-mail de confirmação.',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.GerarLinkDeExterno = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Gerar Link de Acesso Externo',
          html:
            'Deseja gerar um link de acesso externo para o usuário <strong><u>' +
            itemSelecionado.Nome +
            '</u></strong>?',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#5cb85c',
          confirmButtonText: 'Sim, gerar!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return usuarioAPI
              .SolicitarLinkDeAcessoExterno(itemSelecionado.UsuarioID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Link de Acesso externo gerado!',
                  text:
                    'https://dev.solicitacao.mitfokus.com.br/linkid=' +
                    responde.LinkID,
                  type: 'success',
                  timer: 3500,
                });
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 503:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'O servidor de recuperação está fora do ar.',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao enviar o e-mail de confirmação.',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    //POPUP Excluir Médico
    $public.AbrirModalExcluirUsuario = function (itemSelecionado) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html:
            'Deseja remover o usuário <strong><u>' +
            itemSelecionado.Nome +
            '</u></strong>?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return usuariosAPI
              .Delete(itemSelecionado.UsuarioID)
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Usuário removido com sucesso!',
                  text: '',
                  type: 'success',
                  timer: 3500,
                });
                $private.GetUsuarios();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Usuário não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;

                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o registro, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $private.Init = function () {
      $public.loading = false;      
      $public.usuarioFilterVinculo = false;
      
      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Nome',
          OrderBy: '-UsuarioID',
          Max: 50,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,
          SemCliente: $public.usuarioFilterVinculo
        },
        Action: $private.GetUsuarios,
      };
      $private.GetUsuarios();
      $public.caminhoURL = config.baseProfilePictures;
      $public.Config = SharedDataFactory.Get().Configuracoes;

      /**** Auditoria *****/
      /*************/
      AuditoriaFactory.CriarAuditoria(
        'Acesso ao cadastro: Usuários',
        'cadastro.usuarios',
        'Acessou o menu de Usuários',
        null,
        null,
        null
      );
    };

    $private.Init();
  }

  function modalNovoEditarUsuarioCtrl(
    $q,
    $scope,
    EnumFactory,
    $uibModalInstance,
    $uibModal,
    sweetAlert,
    usuariosAPI,
    permissoesAPI,
    clientesAPI,
    tomadoresAPI,
    municipiosAPI,
    usuarioSelecionado,
    medicos,
    auditoriaAPI
  ) {
    var $public = this;
    var $private = {};
    $scope.viewby = 5;

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $public.BuscaMunicipios = function (limparModel) {
      // Sempre que trocar de estado, limpa os campos
      $public.Municipio = [];

      if (limparModel) {
        $public.usuario.Obrigatoriedade_MunicipioID = undefined;
      }

      // if (!$public.UF) {
      //   return;
      // }

      municipiosAPI.GetByUF($public.UF).then(function (response) {
        console.log(response.data);
        $public.Municipio = response.data;
        //console.log(response.data);
      });
    };

    $public.EscolherMunicipio = function () {
      $public.NFSe.MunicipioPrestacao = $public.MunicipioDaPrestacao.SIAFI;
      $public.NFSe.MunicipioPrestacaoDescricao =
        $public.MunicipioDaPrestacao.Nome;
    };

    $public.Enviar = function () {
      $public.loading = true;
      var result = {
        OnSuccess: function () {
          var msg = usuarioSelecionado ? 'alterados' : 'adicionados';
          sweetAlert.swal('Dados ' + msg + ' com sucesso!', '', 'success');
          $uibModalInstance.close($public.usuario);
        },
        OnError: function (response) {
          switch (response.status) {
            case 401:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.Message +
                  '</font>',
              });
              break;

            case 404:
              sweetAlert.swal(
                'Atenção',
                'O usuário não foi encontrado, verifique!!!',
                'error'
              );
              break;

            case 409:
              sweetAlert.swal(
                'Atenção',
                'Já existe um usuário com este CPF ou E-mail informado',
                'warning'
              );
              break;

            case 417:
              sweetAlert.swal({
                title: 'Atenção',
                type: 'error',
                html:
                  'Um erro inesperado impediu a relização do cadastro. Tente Novamente!<br><br><br><font size=2 color=#B22222>Detalhes do Erro:<br>' +
                  response.data.ExceptionMessage +
                  '</font>',
              });
              break;

            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      if (usuarioSelecionado) {
        // EDITAR Usuario
        usuariosAPI
          .Put($public.usuario)
          .then(result.OnSuccess)
          .catch(result.OnError)
          .finally(function () {
            $public.loading = false;
          });
      } else {
        // CADASTRAR Usuario
        usuariosAPI
          .Post($public.usuario)
          .then(result.OnSuccess)
          .catch(result.OnError)
          .finally(function () {
            $public.loading = false;
          });
      }
    };

    $public.ExcluirVinculoDoTomador = function (tomador) {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html: 'Deseja realmente excluir o vínculo com o tomador com este usuário?<br><br><strong style="color:red">Atenção: Excluir o vínculo não exclui o cadastro do Tomador do Sistema.</strong>',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, excluir!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return usuariosAPI
              .DesvincularTomadorDoUsuario(
                tomador.TomadorID,
                usuarioSelecionado.UsuarioID
              )
              .then(function (response) {
                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Excluído!',
                  text: 'Vínculo excluído com sucesso',
                  type: 'success',
                  timer: 2000,
                });

                $public.GetTomadoresWithUserId();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Tomador vinculado não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;
                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir o vínculo do tomador, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.LimparClientes = function () {
      $public.usuario.Clientes = [];
    };
    

    $public.AddOnboard = function () {
      console.log($public.Permissoes)
      let onboardPermissions = [35, 5, 2,18,1,33,41,30,16,38,15]; 
        $public.usuario.Permissoes = $public.Permissoes.filter(item => onboardPermissions.includes(item.AreaDeAcessoID));
    };


    $public.AddTodas = function () {
      $public.usuario.Clientes = [...$public.Clientes];
    };

    $public.AddTodasSimples = function () {
      $public.usuario.Clientes = [...$public.Clientes.filter(item => item.RegimeID === 3)];
    };

    $public.AddTodasLp = function () {
      $public.usuario.Clientes = [...$public.Clientes.filter(item => item.RegimeID === 2)];
    };


    $public.LimparTodosOsTomadoresDoUsuario = function () {
      sweetAlert.swal.queue([
        {
          title: 'Atenção!',
          html: 'Deseja realmente excluir o vínculo de todos os tomadores deste usuário?<br><br><strong style="color:red">Atenção: Esta opção é irreversível!</strong>',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F44336',
          confirmButtonText: 'Sim, excluir!',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          preConfirm: function () {
            $(sweetAlert.swal.getCancelButton()).hide(); //Esconde o botao cancelar.

            return usuariosAPI
              .DesvincularTodosOsTomadoresDoUsuario($public.usuario.UsuarioID)
              .then(function (response) {
                $public.usuario.Tomadores = [];
                $public.TomadoresDoUsuario = [];

                console.log('Success: %o', response);
                sweetAlert.swal.insertQueueStep({
                  title: 'Excluído!',
                  text: 'Vínculos excluídos com sucesso',
                  type: 'success',
                  timer: 2000,
                });

                $public.GetTomadoresWithUserId();
              })
              .catch(function (response) {
                console.log('Error: %o', response);
                switch (response.status) {
                  case 404:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Tomador vinculado não encontrado, Verifique!',
                      type: 'error',
                    });
                    break;
                  default:
                    sweetAlert.swal.insertQueueStep({
                      title: 'Atenção!',
                      text: 'Erro ao exluir os vínculos dos tomadores, verifique se o mesmo não esta vinculado com outra tabela',
                      type: 'error',
                    });
                    break;
                }
              });
          },
        },
      ]);
    };

    $public.LimparTomadores = function () {
      $public.TomadoresDoUsuario = [];
    };

    $public.GetTomadoresWithUserId = function () {
      console.log(usuarioSelecionado.UsuarioID);
      tomadoresAPI
        .GetTomadoresWithUserId(
          $public.PaginationConfig.Paginacao,
          usuarioSelecionado.UsuarioID
        )
        .then(function (response) {
          console.log(response.data.Registros);
          $public.TomadoresDoUsuario = response.data.Registros;
          $public.PaginationConfig.Paginacao.OrderBy = response.data.OrderBy;
          $public.PaginationConfig.Paginacao.Page = response.data.Page;
          $public.PaginationConfig.Paginacao.TotalRegistros =
            response.data.TotalRegistros;
          $public.PaginationConfig.Paginacao.Max = response.data.Max;
          $public.PaginationConfig.Paginacao.SemCliente = response.data.SemCliente;
          $public.PaginationConfig.Loading = false;
        })
        .catch(function () {
          $public.PaginationConfig.Loading = false;
        });
    };

    $public.abrirModalNovoTomador = function () {
      if ($public.loading === true) {
        return;
      }
      $public.loading = true;

      var modalVincularNovoTomadorInstance = $uibModal.open({
        templateUrl:
          '/views/cadastros/usuarios/modals/modalVincularTomador.html',
        controller: 'modalVincularNovoTomadorCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          UsuarioSelecionado: function () {
            return $public.usuario.UsuarioID;
          },
          ListaDeTomadores: function () {
            var deferred = $q.defer();
            deferred.resolve([]);
            return deferred.promise;
          },
        },
      });

      modalVincularNovoTomadorInstance.result
        .then(function () {
          // TODO: Pegar os usuarios do usuario após a atualização
          $public.GetTomadoresWithUserId();
          //$public.CarregarTomadoresDoUsuario();
          $public.loading = false;
        })
        .catch(function () {
          $public.loading = false;
        });
    };

    

    $private.Init = function () {
      $public.usuario = usuarioSelecionado;
      $public.medicos = medicos;

      $public.PaginationConfig = {
        Loading: false,
        Paginacao: {
          Search: '',
          SearchField: 'Nome',
          OrderBy: '-TomadorID',
          Max: 3,
          Page: 1,
          TotalRegistros: 0,
          NumPages: undefined,          
          SemCliente: false,
        },
        Action: $public.GetTomadoresWithUserId,
      };

      if (usuarioSelecionado) {
        $public.TomadoresDoUsuario = []; //$public.usuario.Tomadores;
        $public.GetTomadoresWithUserId();
        $public.UF = usuarioSelecionado.UFDaObrigatoriedade;
        $public.BuscaMunicipios();
      }

      $public.Enuns = EnumFactory;
      $public.Title = usuarioSelecionado
        ? 'Editar Usuário'
        : 'Incluir Novo Usuário';

      permissoesAPI.Get().then(function (response) {
        $public.Permissoes = response.data;
      });

      clientesAPI.GetAll().then(function (response) {
        $public.Clientes = response.data;
      });
    };

    $private.Init();
  }

  function abrirModalImportarTomadoresDeOutroUsuarioCtrl(
    $scope,
    sweetAlert,
    $uibModalInstance,
    usuariosAPI,
    tomadoresAPI
  ) {
    var $public = this;
    var $private = {};
    $public.Title = 'Importar Tomadores para um outro Usuário';
    $public.Usuarios = [];
    $public.Loading = false;

    $public.Enviar = function () {
      var result = {
        OnSuccess: function (response) {
          sweetAlert.swal(
            'Tomadores vinculado ao usuário de destino com sucesso!',
            '',
            'success'
          );
          $uibModalInstance.close();
        },
        OnError: function (response) {
          switch (response.status) {
            case 404:
              sweetAlert.swal(
                'Atenção',
                'O usuário não foi encontrado, verifique!!!',
                'error'
              );
              break;
            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      var vm = {
        registerUserId: $public.UsuarioPrincipal.UsuarioID,
        destinationUserId: $public.UsuarioDestino.UsuarioID,
        initDate: null,
        finalDate: null,
        include: true,
      };
      // VINCULAR TOMADORES DO OUTRO USUÁRIO
      tomadoresAPI
        .LinkTomadores(vm)
        .then(result.OnSuccess)
        .catch(result.OnError);
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.loadMore = function () {
      console.log($public.currentElement);
      $public.currentElement = $public.currentElement + 20;
    };

    $public.GetUISelectAsync = function (search) {
      $public.currentElement = 20;

      if (!search) {
        return;
      }

      $public.Loading = true;
      usuariosAPI
        .Get({
          Search: search,
          Max: 1000,
          Page: 1,
          SearchField: 'Nome',
          SemCliente: $public.usuarioFilterVinculo
        })
        .then(function (response) {
          $public.Usuarios = response.data.Registros;
          console.log(response.data.Registros);
          $public.Loading = false;
        })
        .catch(function () {
          $public.Loading = false;
        });
    };

    $private.Init = function () {};

    $private.Init();
  }

  function modalVincularNovoTomadorCtrl(
    $scope,
    sweetAlert,
    $uibModalInstance,
    usuariosAPI,
    tomadoresAPI,
    UsuarioSelecionado,
    ListaDeTomadores
  ) {
    var $public = this;
    var $private = {};
    $public.Title = 'Vincular Tomador';
    $public.Tomador = [];
    $public.Loading = false;

    $public.Enviar = function () {
      var result = {
        OnSuccess: function (response) {
          sweetAlert.swal('Tomador vinculado com sucesso!', '', 'success');
          $uibModalInstance.close();
        },
        OnError: function (response) {
          switch (response.status) {
            case 404:
              sweetAlert.swal(
                'Atenção',
                'O tomador não foi encontrado, verifique!!!',
                'error'
              );
              break;
            case 409:
              sweetAlert.swal('Tomador já vinculado.', '', 'warning');
              break;
            default:
              sweetAlert.swal(
                'Atenção',
                'Verifique o preenchimento do formulário',
                'warning'
              );
              break;
          }
        },
      };

      // CADASTRAR
      usuariosAPI
        .VinculaNovoTomadorAoUsuario($public.Tomador, UsuarioSelecionado)
        .then(result.OnSuccess)
        .catch(result.OnError);
    };

    $public.FecharModal = function () {
      $uibModalInstance.dismiss('Cancel');
    };

    $public.loadMore = function () {
      console.log($public.currentElement);
      $public.currentElement = $public.currentElement + 20;
    };

    $public.GetUISelectAsync = function (search) {
      $public.currentElement = 20;

      if (!search) {
        return;
      }

      $public.Loading = true;
      tomadoresAPI
        .GetAllWithSearch({
          Search: search,
          Max: 1000,
          Page: 1,
          SearchField: 'Nome',
        })
        .then(function (response) {
          $public.Tomadores = response.data.Registros;
          console.log(response.data.Registros);
          $public.Loading = false;
        })
        .catch(function () {
          $public.Loading = false;
        });
    };

    $private.Init = function () {
      $public.cliente = UsuarioSelecionado;
      $public.Tomadores = ListaDeTomadores;
    };

    $private.Init();
  }
})();

(function () {
  'use strict';

  angular.module('fokus').controller('appCtrl', appCtrl);

  appCtrl.$inject = [];

  function appCtrl() {
    // Injeta as Permissões do Usuário no SharedDataFactory
  }

})();

(function () {
  'use strict';

  angular.module('fokus').factory('RedirectInterceptor', RedirectInterceptor);

  RedirectInterceptor.$inject = ['$q', '$rootScope', 'LoadingScreenFactory'];

  function RedirectInterceptor($q, $rootScope, LoadingScreenFactory) {
    return {
      responseError: function (response) {
        LoadingScreenFactory.Hide();

        if (response.status === -1) {
          if ($rootScope.$state.current.url != '/logout') {
            window.location.href = "https://www.login.mitfokus.com.br/";
          }
        }

        if (response.status === 401) {
          if ($rootScope.$state.current.url != '/logout') {
            window.location.href = "https://www.login.mitfokus.com.br/";
          }
        }

        if (response.status === 404) {
        }

        if (response.status === 503) {
        }

        return $q.reject(response);
      },
    };
  }
})();

(function () {
  'use strict';

  angular.module('fokus').factory('noCacheInterceptor', noCacheInterceptor);

  noCacheInterceptor.$inject = [];

  function noCacheInterceptor() {
    return {
      request: function (config) {
        console.log(config.method);
        console.log(config.url);
        if (config.method == 'GET') {
          var separator = config.url.indexOf('?') === -1 ? '?' : '&';
          config.url =
            config.url + separator + 'datafactory=' + new Date().getTime();
        }
        console.log(config.method);
        console.log(config.url);
        return config;
      },
    };
  }
})();

/* eslint-disable no-console */
(function () {
  'use strict';

  var app = angular.module('fokus');

  app.factory('AuditoriaFactory', [
    '$window',
    '$http',
    'config',
    function ($window, $http, config) {
      var dataFactory = {
        GetObjectDiff: function (objA, objB) {
          return $window.recursiveDiff
            .getDiff(objA, objB, true)
            .reduce((acc, item) => {
              const GetAuditoria = (p) => ({
                Prop: (p.path || []).join('.'),
                Antes: p.oldVal,
                Depois: p.val,
              });

              const GetAuditoriaObjeto = (registro, dados) => {
                let itens = [];

                Object.keys(
                  registro.op === 'delete' ? registro.oldVal : registro.val
                ).forEach((key) => {
                  const data = {
                    op: registro.op,
                    path: [...(registro.path || ''), key],
                    oldVal:
                      registro.op === 'delete'
                        ? registro.oldVal[key]
                        : undefined,
                    val:
                      registro.op === 'delete' ? undefined : registro.val[key],
                  };

                  if (
                    (registro.op === 'add' &&
                      registro.val[key] &&
                      typeof registro.val[key] === 'object') ||
                    (registro.op === 'delete' &&
                      registro.oldVal[key] &&
                      typeof registro.oldVal[key] === 'object')
                  ) {
                    itens = [...itens, ...GetAuditoriaObjeto(data, dados)];
                  } else {
                    itens.push(GetAuditoria(data));
                  }
                });

                return [...dados, ...itens];
              };

              if (
                (item.op === 'add' &&
                  item.val &&
                  typeof item.val === 'object') ||
                (item.op === 'delete' &&
                  item.oldVal &&
                  typeof item.oldVal === 'object')
              ) {
                const dadosObj = GetAuditoriaObjeto(item, []);
                return [...acc, ...dadosObj];
              }

              return [...acc, GetAuditoria(item)];
            }, []);
        },
      };

      //POST {tenant}/api/Utilitarios/Auditoria
      dataFactory.CriarAuditoria = function (
        Operacao,
        Url,
        Descricao,
        ReferenciaID,
        DadosOriginais,
        DadosAtuais
      ) {
        var vm = {
          Operacao: Operacao,
          Url: Url,
          ReferenciaID: ReferenciaID,
          Descricao: Descricao,
          AlteracaoCadastral: dataFactory.GetObjectDiff(
            DadosOriginais || {},
            DadosAtuais || {}
          ),
        };
        return $http.post(config.baseUrl + '/api/auditoria', vm);
      };

      return dataFactory;
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').factory('AuthFactory', [
    '$http',
    '$q',
    '$localStorage',
    'config',
    'payloadAPI',
    'AuditoriaFactory',
    '$cookies',
    'GetTokenByCookie',
    function (
      $http,
      $q,
      $localStorage,
      config,
      payloadAPI,
      AuditoriaFactory,
      $cookies,
      GetTokenByCookie
    ) {
      var LocalStorageName = 'authToken';

      var dataFactory = {
        isAutenticated: false,
        Payload: { Roles: [] },
        Token: undefined,
        Expiration: undefined,
        ReadPopUpAlert: false,
      };

      //console.log('data factory init', { ...dataFactory });

      var userData = [];
      
      var loginObservables = [];
      var logoutObservables = [];
      

      var ConfigAuth = function () {
        var deferred = $q.defer();
        var storageData = '';

        GetTokenByCookie.GetToken().then(function(response) {
          storageData = response;
        }).finally(function(){
        

        if (!storageData) {
          storageData = $localStorage[LocalStorageName];
        }        

        dataFactory.isAutenticated = true;
        dataFactory.Token = storageData.accessToken;
        var expiration = new Date();
        dataFactory.Expiration = expiration.setSeconds(
          expiration.getSeconds() + storageData.expires_in
        );

        

        //console.log('dataFactory depois de pegar cookie', { ...dataFactory });
        //console.log('Token: ',dataFactory.Token);

        // add jwt token to auth header for all requests made by the $http service
        $http.defaults.headers.common.Authorization =
          'Bearer ' + dataFactory.Token;
        $http.defaults.headers.common['Content-Type'] = 'application/json';


        // set payload to dataFactory
        payloadAPI
          .Get()
          .then(function (response) {
            //console.log('payloadAPI dataFactory', { ...dataFactory });
            dataFactory.Payload = response.data[0];
            userData = response.data[0];
            //console.log('ESTE RESPONSE:', storageData);
            //console.log('payloadAPI response', response.data);
            //console.log('payloadAPI dataFactory updated', dataFactory);
            //console.log('payloadAPI userData', { ...userData });
            // Migração para novo ambiente Caso token vazio é enviado para a página de migração e é setado o localstorage                     
            //console.log('verifica token:', storageData.accessToken);
            //console.log('?email=', userData);
            deferred.resolve();
          })
          .catch(function (response) {
            deferred.reject();
          });
        });
        return deferred.promise;
      
      };
      
      if(window.MitfokusConfig.WhatsappBtn === 'true'){
        var buttonWhatsApp = document.querySelector(".btn-whatsapp");
        buttonWhatsApp.style.display = "";
        var msgWhatsApp = document.querySelector(".whatsapp-msg");
        msgWhatsApp.style.display = "";
        var msgWhatsAppClose = document.querySelector(".whatsapp-msg-close");
        msgWhatsAppClose.style.display = "";
      } else {
      (function (w, d, u) {
        var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 60000 | 0);
        var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
      })(window, document, 'https://cdn.bitrix24.com.br/b11065897/crm/site_button/loader_3_tyovgd.js');
      }

      var onLoginHandle = function () {
        //console.log('userData: ', userData);
        //console.log('dataFactory onLoginHandle', { ...dataFactory });
        // Raven.setUserContext({
        //   id: userData.UsuarioID,
        //   username: userData.Nome,
        //   email: userData.Email,
        //   Login: userData.Email,
        // });
        // Percorre todos os observadores informando a alteração
        for (var i = 0; i < loginObservables.length; i++) {
          var itemObservable = loginObservables[i];
          itemObservable(dataFactory.Payload);
        }        
      };

      var onLogoutHandle = function () {
        // Sentry: Remove as informações do usuário logado
        // Raven.setUserContext({});
        // Raven.clearContext();

        // Percorre todos os observadores informando a alteração
        for (var i = 0; i < logoutObservables.length; i++) {
          var itemObservable = logoutObservables[i];
          itemObservable(dataFactory.Payload);
        }
      };

      dataFactory.GetPayload = function () {
        //console.log('datafactory getpayload: ', { ...dataFactory });
        return dataFactory;
      };

      dataFactory.Login = function (vm) {
        var deferred = $q.defer();

        $http({
          method: 'POST',
          url: config.baseUrl + '/api/security/token',
          headers: { 'Content-Type': 'application/json' },
          data: vm,
        })
          .then(function (response) {
            // Migração usuário já migrado retorno 405 api Redirect para login SSO
            //if (response.status == '405') {
            //  window.location.href = config.loginSsoUrl;
            //  return;
            //}
            response.data.ReadPopUpAlert = false;
            $localStorage[LocalStorageName] = response.data;
            // $cookies.putObject('mf_sso', response.data)

            ConfigAuth()
              .then(function () {
                /**** Auditoria *****/
                /*************/
                /*AuditoriaFactory.CriarAuditoria(
                  'Login',
                  'login',
                  'Login no sistema',
                  null,
                  null,
                  null
                );*/
                onLoginHandle();

                deferred.resolve();
              })
              .catch(function () {
                deferred.reject();
              });
          })
          .catch(function (response) {
            deferred.reject(response);
          });

        return deferred.promise;
      };

      dataFactory.HasPermission = function (roles) {
        if (!roles || !dataFactory.Payload.Roles) {
          return false;
        }

        var final = [];
        for (var i = 0; i < roles.length; i++) {
          if (dataFactory.Payload.Roles.indexOf(roles[i]) > -1) {
            final.push(roles[i]);
          }
        }
        return final.length > 0;
      };

      dataFactory.Logout = function () {
        var deferred = $q.defer();

        /**** Auditoria *****/
        /*************/
        /*try {
          AuditoriaFactory.CriarAuditoria(
            'Logout',
            'logout',
            'Saiu do sistema',
            null,
            null,
            null
          );
        } catch (e) {}*/

        //dataFactory = { isAutenticated: false, Payload:{}, Token:  undefined, Expiration: undefined };
        dataFactory.isAutenticated = false;
        dataFactory.Payload = { Roles: [] };
        dataFactory.Token = undefined;
        dataFactory.Expiration = undefined;
        dataFactory.ReadPopUpAlert = false;

        // remove user from local storage and clear http auth header
        //delete $localStorage[LocalStorageName];
        $localStorage.$reset();
        // $cookies.remove('mf_sso');

        $http.defaults.headers.common.Authorization = '';
        //Correção do BUG de CORS do Google Chrome.
        $http.defaults.headers.common = {};
        $http.defaults.headers.post = {};
        $http.defaults.headers.put = {};
        $http.defaults.headers.patch = {};

        onLogoutHandle();

        deferred.resolve();
        return deferred.promise;
      };

      dataFactory.ChangePassword = function (vm) {
        var deferred = $q.defer();

        $http
          .post(config.baseUrl + '/auth/change-password', vm)
          .then(function (response) {
            $localStorage[LocalStorageName] = response.data;
            // $cookies.put('mf_sso', response.data)

            ConfigAuth().then(function () {
              deferred.resolve();
            });
          })
          .catch(function (response) {
            deferred.reject();
          });

        return deferred.promise;
      };

      dataFactory.OnLogin = function (fn) {
        loginObservables.push(fn);

        if (dataFactory.isAutenticated) {
          fn(dataFactory.Payload);
        }
      };

      dataFactory.OnLogout = function (fn) {
        logoutObservables.push(fn);
      };

      // Configura o login quando reload]

      var cookiesAll = document.cookie.split(';');
        var idToken = '';
        //console.log('cookies',test2.split('='));
        cookiesAll.forEach(element => {
          var key = element.split("=");   
          if(key[0].split('.').pop() === 'accessToken'){
            idToken = key[1];
            //console.log('idToken',key[1]); 
          }          
        });

        

        var tokens = { accessToken: idToken, expires_in: new Date(), Token: idToken }

        var storageData = tokens;
      if ($localStorage[LocalStorageName] || storageData) {
        //console.log('dataFactory before reload', { ...dataFactory });

        ConfigAuth().then(function () {          
          onLoginHandle();
        });
      }

      //console.log('dataFactory after reload', { ...dataFactory });

      return dataFactory;
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').factory('ControleNFSEsFactory', [
    function () {
      return {
        TempoDeAtualizacao: [
          // {Segundos: null, Nome: 'Tempo real'},
          {Segundos: 15, Nome: '15s'},
          {Segundos: 30, Nome: '30s'},
          {Segundos: 60, Nome: '1min'},
          {Segundos: 5*60, Nome: '5min'},
          {Segundos: 15*60, Nome: '15min'},
          {Segundos: 30*60, Nome: '30min'},
        ],
        StatusIDs: [
          'Sem Status',
          'Enviada para Prefeitura',
          'Aguardando Autorização',
          'Autorizada',
          'Rejeitada',
          'Cancelada',
          'Apagada',
          'Importada via XML',
          'Aguardando assinatura',
          'Geradas no sistema',
          'Recebida',
          'Aguardando Aceite da Prefeitura',
          'Reprocessando',
        ]
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').factory('DashboardFactory', [
    function () {
      return {
        Meses: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEV'],
        MesesCompletos: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        RenderChart: function ({categories = [], series = [], title = '', subtitle= '', yAxisTitle = '', xAxisVisible = true, currency = false, ...moreOptions}) {
          return {
              colors: ['#00BAA8', '#042c6c', '#42CA9F', '#6ED893', '#9BE585', '#C9F079', '#F9F871'],
              tooltip: {
                useHTML: true,
                backgroundColor: 'rgba(252, 255, 255, 1)',
                headerFormat: '<div class="chart-tooltip">',
                pointFormat: currency ? '<span>R$ {point.y}</span>' : '<span>{point.y}</span>',
                footerFormat: '</div>',
                valueDecimals: currency ? 2 : 0
              },
              plotOptions: {
                series: {
                  pointWidth: 35,
                  dataLabels: {
                    enabled: true,
                    allowOverlap: true,
                    borderRadius: 5,
                    padding: 5,
                    backgroundColor: 'rgba(252, 255, 255, 0.8)',
                    borderWidth: 0,
                    borderColor: '#AAA',
                    shadow: {
                      opacity: 0.1,
                    },
                    y: -10,
                    style: {
                      fontSize: '1.2rem',
                      color: '#042c6c',
                      fontWeight: 400,
                    }
                   }
                },
                column: {
                  borderRadius: 5,
                  dataLabels: {
                    enabled: true,
                  }
                },
                pie: {
                  allowPointSelect: false,
                  cursor: 'pointer',
                  shadow: false,
                  dataLabels: {
                    enabled: false,
                  },
                }
              },
              title: {
                text: title,
                align: "left",
                margin: 40
              },
              subtitle: {
                text: subtitle,
                align: "left",
              },
              yAxis: {
                title: {
                  text: yAxisTitle,
                },
                offset: 30,
                tickLength: 30, // Same value as offset
                tickPosition: "inside",
                tickWidth: 2,
                tickColor: "#00BAA8", // The same as your gridLine color
                labels: {
                  align: 'left',
                  x: 0,
                  y: -8 // Position labels above their gridLine/tickMark
                }
              },
              xAxis: {
                categories,
                visible: xAxisVisible,
              },
              series,
              credits: {
                enabled: false
              },
              ...moreOptions
          };
        },
        FormatarDinheiroSemCents: function (valor) {
          if(typeof valor === "number") {
            return Number(valor.toString().replace(/\.[0-9]+/g, '')).toLocaleString('pt-BR')
          }
        }
      };
    }
  ]);
})();

(function () {
  'use strict';

  angular
    .module('fokus')
    .factory('emptyDoubleClickSelectFactory', emptyDoubleClickSelectFactory);

  emptyDoubleClickSelectFactory.$inject = [];

  function emptyDoubleClickSelectFactory() {
    return {
      request: function () {
        if (window.getSelection) {
          if (window.getSelection().empty) {
            // Chrome
            window.getSelection().empty();
          } else if (window.getSelection().removeAllRanges) {
            // Firefox
            window.getSelection().removeAllRanges();
          }
        } else if (document.selection) {
          // IE?
          document.selection.empty();
        }
        return;
      },
    };
  }
})();

(function () {
  'use strict';

  angular.module('fokus').factory('EnumFactory', [
    function () {
      return {
        Ativo: [
          { Nome: 'Sim', Valor: true },
          { Nome: 'Não', Valor: false },
        ],
        Tipo: [
          { Nome: 'Física', Valor: 0 },
          { Nome: 'Juridica', Valor: 1 },
          { Nome: 'Estrangeiro', Valor: 2 },
        ],
        Sexo: [
          { Nome: 'Masculino', Valor: 1 },
          { Nome: 'Feminino', Valor: 0 },
        ],
        EstadoCivil: [
          { Nome: 'Solteiro', Valor: 0 },
          { Nome: 'Casado', Valor: 1 },
          { Nome: 'Divorciado', Valor: 2 },
        ],
        RegimeTributario: [
          { Nome: 'Sem Regime Tributário', Valor: 1 },
          { Nome: 'Lucro Presumido', Valor: 2 },
          { Nome: 'Simples Nacional', Valor: 3 },
        ],
        RetemISS: [
          { Nome: 'Sim', valor: 0 },
          { Nome: 'Não', valor: 1 },
        ],
        TipoDeRecolhimento: [
          { Nome: 'ISS Retido na Fonte pelo Tomador', valor: 0 },
        ],
        UF: [
          'AC',
          'AL',
          'AM',
          'AP',
          'BA',
          'CE',
          'DF',
          'ES',
          'GO',
          'MA',
          'MG',
          'MS',
          'MT',
          'PA',
          'PB',
          'PE',
          'PI',
          'PR',
          'RJ',
          'RN',
          'RO',
          'RR',
          'RS',
          'SC',
          'SE',
          'SP',
          'TO',
          'EX',
        ],
        SituacaoDaNFSe: [
          {
            Autorizada: 1,
            Cancelada: 2,
          },
        ],
        TipoEmissor: [
          { Nome: 'Emissor Próprio (A1)', Valor: 0 },
          { Nome: 'Assinador Externo', Valor: 1 },
          { Nome: 'E-notas', Valor: 2 },
          // { Nome: 'Plugnotas', Valor: 3 },
          { Nome: 'Focus', Valor: 4 },
          { Nome: 'Emissor Próprio (Kotlin)', Valor: 5 },
        ],
        PlugNotasRegimeEspecialTrib: [
          { Nome: 'Sem Regime Tributário Especial', Valor: 0 },
          { Nome: 'Micro Empresa Municipal', Valor: 1 },
          { Nome: 'Estimativa', Valor: 2 },
          { Nome: 'Sociedade de Profissionais', Valor: 3 },
          // { Nome: 'Cooperativa', Valor: 4 },
          // { Nome: 'Microempresário Individual - MEI', Valor: 5 },
          { Nome: 'Microempresa ou Pequeno Porte - ME EPP', Valor: 6 },
        ],
        EnderecoTipoLogradouro: [
          { Nome: 'Alameda', Valor: 'Alameda' },
          { Nome: 'AVENIDA', Valor: 'AVENIDA' },
          { Nome: 'Chácara', Valor: 'Chácara' },
          { Nome: 'Colônia', Valor: 'Colônia' },
          { Nome: 'Condomínio', Valor: 'Condomínio' },
          // { Nome: 'Eqnp', Valor: 'Eqnp'},
          { Nome: 'Estância', Valor: 'Estância' },
          { Nome: 'ESTRADA', Valor: 'ESTRADA' },
          { Nome: 'FAZENDA', Valor: 'FAZENDA' },
          { Nome: 'PRAÇA', Valor: 'PRAÇA' },
          { Nome: 'Prolongamento', Valor: 'Prolongamento' },
          { Nome: 'RODOVIA', Valor: 'RODOVIA' },
          { Nome: 'RUA', Valor: 'RUA' },
          { Nome: 'SÍTIO', Valor: 'SÍTIO' },
          { Nome: 'Travessa', Valor: 'Travessa' },
          { Nome: 'Vicinal', Valor: 'Vicinal' },
          { Nome: 'Viela', Valor: 'VIELA' },
        ],
        TipoDeTributacao: [
          { Nome: 'Isento de ISS', Valor: 1 },
          { Nome: 'Imune', Valor: 2 },
          { Nome: 'Não Incidência no Município', Valor: 3 },
          { Nome: 'Não Tributável', Valor: 4 },
          { Nome: 'Retido', Valor: 5 },
          { Nome: 'Tributável Dentro do Município', Valor: 6 },
          { Nome: 'Tributável Fora do Município', Valor: 7 },
        ],
        Exigibilidade: [
          { Nome: 'Exigível', Valor: 1 },
          { Nome: 'Não Incidência', Valor: 2 },
          { Nome: 'Isenção', Valor: 3 },
          { Nome: 'Exportação', Valor: 4 },
          { Nome: 'Imunidade', Valor: 5 },
          { Nome: 'Supenso por Ação Judicial', Valor: 6 },
          { Nome: ' Suspenso por Ação Administrativa', Valor: 7 },
        ],
        Intermediario: [
          { Nome: 'Cliente', Valor: 1 },
          // { Nome: 'Intermediário', Valor: 2 },
        ],
        MITPDFTemplate: [
          { Nome: 'Distribuição', Valor: 'planilha_distribuicao' },
          {
            Nome: 'Tributário',
            Valor: 'planilha_apuracao',
          },
          { Nome: 'Segregação', Valor: 'planilha_segregacao' },
        ],
        MITUploadTemplate: [
          { Nome: 'NFS-e', Valor: 'nfse' },
          { Nome: 'Empresa', Valor: 'cliente' },
          {
            Nome: 'Tomador',
            Valor: 'tomador',
          },
        ],
        MITQuestorTemplate: [
          { Nome: 'NFS-e', Valor: 'nfse' },
          {
            Nome: 'Cadastro de Tomador',
            Valor: 'tomador',
          },
        ],
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').factory('focus', [
    '$rootScope',
    '$timeout',
    function ($rootScope, $timeout) {
      return function (name) {
        $timeout(function () {
          $rootScope.$broadcast('focusOn', name);
        });
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').factory('LoadingScreenFactory', [
    '$window',
    function ($window) {
      return {
        Show: function () {
          if (!$window.loading_screen) {
            $window.loading_screen = pleaseWait({
              logo: '',
              backgroundColor: 'rgba(0, 0, 0, 0.40)',
              loadingHtml:
                '<div class="sk-spinner sk-spinner-wave"><div class="sk-rect1"></div><div class="sk-rect2"></div><div class="sk-rect3"></div><div class="sk-rect4"></div><div class="sk-rect5"></div></div>',
            });
          }
        },
        Hide: function () {
          if ($window.loading_screen) {
            $window.loading_screen.finish();
            $window.loading_screen = undefined;
          }
        },
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').factory('plupload', function () {
    // Return the global Plupload reference so that it can be injected,
    // proper-like, into our directives.
    return plupload;
  });

  angular.module('fokus').factory('mOxie', function () {
    // Return the global mOxie reference so that it can be injected,
    // proper-like, into our directives.
    return mOxie;
  });

  angular.module('fokus').factory('naturalSort', function () {
    // I sort the given collection on the given top-level property using a natural
    // sort that attempts to treat embedded numbers like real numbers.
    function sort(collection, property) {
      collection.sort(function (a, b) {
        // Normalize the file names with fixed-width numeric data.
        var aMixed = normalizeMixedDataValue(a[property]);
        var bMixed = normalizeMixedDataValue(b[property]);

        return aMixed < bMixed ? -1 : 1;
      });
    }

    // Return factory value.
    return sort;

    // ---
    // PRIVATE METHODS.
    // ---

    function normalizeMixedDataValue(value) {
      var padding = '000000000000000';

      // Loop over all numeric values in the string and replace them with a value
      // of a fixed-width for both leading (integer) and trailing (decimal) padded
      // zeroes.
      value = value.replace(
        /(\d+)((\.\d+)+)?/g,
        function ($0, integer, decimal, $3) {
          // If this numeric value has "multiple" decimal portions, then the
          // complexity is too high for this simple approach - just return the
          // padded integer.
          if (decimal !== $3) {
            return padding.slice(integer.length) + integer + decimal;
          }

          decimal = decimal || '.0';

          return (
            padding.slice(integer.length) +
            integer +
            decimal +
            padding.slice(decimal.length)
          );
        }
      );

      return value;
    }
  });
})();

(function (undefined) {
  'use strict';

  var app = angular.module('fokus');

  app.factory('SharedDataFactory', [
    function () {
      var _data = undefined;
      var dataFactory = {
        Get: function () {
          var result = angular.copy(_data);
          _data = undefined;

          return result;
        },
        Set: function (vm) {
          _data = vm;
        },
      };
      return dataFactory;
    },
  ]);
})();

(function (undefined) {
  // eslint-disable-line no-unused-vars
  'use strict';

  var app = angular.module('fokus');

  app.factory('SocketIO', [
    'socketFactory',
    'AuthFactory',
    function (socketFactory, AuthFactory) {
      // Mantem as ações solicitadas eqto o script do socket ainda nao estiver sido carregado
      var offlineActions = [];

      // Cria um objeto fake para armazenar as requisições offline
      var dataFactory = {
        on: function (name, fn) {
          offlineActions.push({ name: name, fn: fn });
        },
        addListener: function () {},
        removeListener: function (name) {
          for (var i = 0; i < offlineActions.length; i++) {
            if (offlineActions[i].name === name) {
              // Se o item foi adicionado pelo metodo On, remove da lista
              offlineActions.splice(i, 1);
              break;
            }
          }
        },
        removeAllListeners: function () {},
        emit: function (name, value) {}, // eslint-disable-line  no-unused-vars
        forward: function () {},
      };

      /**************************************************************************************
       * Adiciona o script do socket-io dinamicamente
       ***************************************************************************************/
      var socket_io_script = document.createElement('script');
      socket_io_script.setAttribute('src', window.MitfokusConfig.socketIOURL);

      /**************************************************************************************
       * Quando o script for adicionado
       ***************************************************************************************/
      socket_io_script.onload = function () {
        // Configura a conexão com o socket
        var socketConnection = window.io(window.MitfokusConfig.socketIOURL, {
          autoConnect: false,
        });

        // Quando o usuário logar no sistema
        // Adiciona as informaçoes do usuário logado ao socket e efetua a conexão
        AuthFactory.OnLogin(function (vm) {
          socketConnection.io.opts.query = vm;
          socketConnection.open();
        });

        // Quando o usuário sair do sistema
        // Remove as informaçoes do usuário e fecha a conexão
        AuthFactory.OnLogout(function () {
          socketConnection.close();
          socketConnection.io.opts.query = {};
          dataFactory.removeAllListeners();
        });

        // Altera o retorno do factory para o socket original
        var siof = socketFactory({ ioSocket: socketConnection });

        dataFactory.on = siof.on;
        dataFactory.addListener = siof.addListener;
        dataFactory.removeListener = siof.removeListener;
        dataFactory.removeAllListeners = siof.removeAllListeners;
        dataFactory.emit = siof.emit;
        dataFactory.forward = siof.forward;

        // Executa as ações offline
        for (var i = 0; i < offlineActions.length; i++) {
          dataFactory.on(offlineActions[i].name, offlineActions[i].fn);
        }
      };

      document.body.appendChild(socket_io_script);

      return dataFactory;
    },
  ]);
})();

(function () {
  "use strict";

  angular
    .module("fokus")
    .filter("comma2decimal", [
      function () {
        // should be altered to suit your needs
        return function (input) {
          //var ret=(input)?input.toString().trim().replace(",","."):null;

          return parseFloat(Math.round(input * 100) / 100).toFixed(2);
          //  return parseFloat(ret);
        };
      }
    ])
    .filter("sumByKey", [function () {
      return function (data, key) {
        if (typeof data === "undefined" || typeof key === "undefined") {
          return 0;
        }

        var sum = 0;
        for (var i = data.length - 1; i >= 0; i--) {
          sum += parseInt(data[i][key]);
        }

        return sum;
      };
    }]);

})();

(function () {
  'use strict';

  angular.module('fokus').service('NFSeAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Post = function (vm) {
        return $http.post(config.baseUrl + '/api/nfse', vm);
      };

      this.PostEspelhado = function (vm, descricao) {
        console.log(descricao);
        //vm.DescricaodaNFSe = angular.copy(descricao);
        vm.DadosComplementares = angular.copy(descricao);
        console.log(vm);
        return $http.post(config.baseUrl + '/api/nfse/espelho/', vm);
      };

      this.DeletarNFSeNaoAutorizada = function (NFSeID) {
        return $http.delete(config.baseUrl + '/api/nfse/' + NFSeID);
      };

      this.CancelarNFSe = function (vm, motivo) {
        console.log(vm);
        var obj = {};
        obj.vm = vm;
        obj.vm.MotivoCancelamento = motivo;
        return $http.post(config.baseUrl + '/api/nfse/cancelar', vm);
      };

      this.Get = function (vm) {
        return $http.get(config.baseUrl + '/api/nfse', { params: vm });
      };

      this.GetByID = function (NFSeID) {
        return $http.get(config.baseUrl + '/api/nfse/' + NFSeID);
      };

      this.GetClientes = function () {
        return $http.get(config.baseUrl + '/api/nfse/clientes');
      };

      this.GetAnexos = function () {
        return $http.get(config.baseUrl + '/api/nfse/anexos');
      };

      this.GetTomadores = function () {
        return $http.get(config.baseUrl + '/api/nfse/v1/tomadores');
      };

      this.GetTomadoresWithSearch = function (vm) {
        return $http.get(config.baseUrl + '/api/nfse/v1/tomadores/search', {
          params: vm,
        });
      };

      this.GetByClienteID = function (clienteID) {
        return $http.get(config.baseUrl + '/api/nfse/cliente/' + clienteID);
      };

      this.GetServicosByClienteID = function (clienteID) {
        return $http.get(
          config.baseUrl + '/api/nfse/' + clienteID + '/servicos'
        );
      };

      this.GetItensByClienteID = function (clienteID, filter) {
        console.log(config.baseUrl + '/api/nfse/' + clienteID + '/itens', {
          params: filter,
        });
        return $http.get(config.baseUrl + '/api/nfse/' + clienteID + '/itens', {
          params: filter,
        });
      };

      this.GetStatusDaNFSe = function (nfseID) {
        return $http.get(config.baseUrl + '/api/nfse/' + nfseID + '/status');
      };

      this.GetTipoDeRecolhimentoDaNFSe = function (nfseID) {
        return $http.get(
          config.baseUrl + '/api/nfse/' + nfseID + '/tipoderecolhimento'
        );
      };

      this.PostPayroll = function (vm) {
        return $http.post(
          config.baseUrl + 'api/clientes/folhadepagamento/add',
          vm
        );
      };

      this.RemovePayroll = function (FolhaDePagamentoID) {
        return $http.delete(
          config.baseUrl +
            'api/clientes/folhadepagamento/' +
            FolhaDePagamentoID +
            '/remove'
        );
      };

      this.PutToogleTipoDeRecolhimentoDaNFSe = function (nfseID) {
        return $http.put(
          config.baseUrl + '/api/nfse/' + nfseID + '/tipoderecolhimento'
        );
      };

      this.GetRPS = function (NFSeID) {
        return $http.get(config.baseUrl + '/api/nfse/' + NFSeID + '/draw');
      };

      this.GetBase64PDFString = function (vm) {
        return $http.post(config.baseUrl + '/api/nfse/pdf/base64', vm);
      };

      this.GetLogDeErros = function (NFSeID) {
        return $http.get(config.baseUrl + '/api/nfse/' + NFSeID + '/erroslog');
      };

      this.PostQuestorSync = function (clienteID) {
        return $http.post(
          config.baseUrl + '/api/mitquestor/migrate/' + clienteID
        );
      };

      this.GetQuestorSync = function (clienteID, page) {
        return $http.get(
          config.baseUrl +
            '/api/mitquestor/migrate/' +
            clienteID +
            '?page=' +
            page +
            '&pageSize=10'
        );
      };

      this.PostRfactor = function (clienteID, factor, comp) {
        return $http.post(config.baseUrl + '/api/fatorr', {
          ClienteID: clienteID,
          FatorR: factor,
          Competencia: comp,
        });
      };

      this.PutRfactor = function (factor, idfactor) {
        return $http.put(config.baseUrl + '/api/fatorr', {
          FatorRCacheID: idfactor,
          FatorR: factor,
        });
      };

      this.GetPayroll = function (clienteID, page) {
        return $http.get(
          config.baseUrl +
            '/api/clientes/folhadepagamento/' +
            clienteID +
            '/get',
          '?Max=10' + '&Page=' + page
        );
      };

      this.GetRfactor = function (clienteID, page, size) {
        return $http.get(
          config.baseUrl +
            '/api/fatorr/' +
            clienteID +
            '?page=' +
            page +
            '&pageSize=' +
            size
        );
      };

      this.EnviarRPSPorEmail = function (NFSeID, email, assunto) {
        var obj = {};
        obj.Assunto = assunto;
        obj.Destinatarios = [];

        email.forEach((item) => {
          obj.Destinatarios.push(item);
        });

        return $http({
          method: 'POST',
          url: config.baseUrl + '/api/nfse/' + NFSeID + '/send',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(obj),
        });
      };

      this.UpdateISSTomador = function (vm) {
        return $http.put(
          config.baseUrl + '/api/nfse/tomador/' + vm.TomadorID,
          vm
        );
      };

      this.PostInformarRecebimento = function (vm) {
        return $http.post(
          config.baseUrl + '/api/nfse/informarrecebimento/',
          vm
        );
      };

      this.PutInformarRecebimento = function (vm) {
        return $http.put(config.baseUrl + '/api/nfse/informarrecebimento/', vm);
      };

      this.PostAddNFSeStatus = function (vm, statusID) {
        return $http.post(
          config.baseUrl + '/api/nfse/addstatus/' + statusID,
          vm
        );
      };

      this.PutEditarNFSe = function (vm) {
        return $http.put(config.baseUrl + '/api/nfse', vm);
      };

      this.GetRecebimento = function (NFSeID) {
        return $http.get(
          config.baseUrl + '/api/nfse/' + NFSeID + '/recebimento'
        );
      };

      this.GetMedicosByClienteID = function (ClienteID) {
        return $http.get(config.baseUrl + '/api/clientes/' + ClienteID);
      };

      this.DeleteRPSPDFFile = function (filename) {
        return $http.delete(
          config.baseUrl +
            '/api/nfse/' +
            filename.substring(0, filename.length - 4)
        );
      };

      this.DeleteCotaMedica = function (itemID, medicoID) {
        return $http.delete(
          config.baseUrl + '/api/nfse/item/' + itemID + '/medico/' + medicoID
        );
      };

      this.UpdateEnotasID = function (NFSeID, EnotasID) {
        return $http.put(
          config.baseUrl +
            '/api/nfse/' +
            NFSeID +
            '/corrigirEnotasID/' +
            EnotasID
        );
      };

      this.GetNFSeOnHander = function () {
        return $http.get(config.baseUrl + '/api/nfse/onHandler');
      };

      this.GetXMLNFSeFile = function (vm) {
        return $http.post(config.baseUrl + '/api/nfse/getnfsexmlfile', vm);
      };

      this.InsertItemIntoNFSe = function (nfseID, vm) {
        return $http.post(
          config.baseUrl + '/api/nfse/' + nfseID + '/item/add',
          vm
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('NFSeDescricoesDoItensAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function () {
        return $http.get(config.baseUrl + '/api/nfsedescricoesdositens');
      };

      this.GetWithPagination = function (vm) {
        return $http.get(config.baseUrl + '/api/nfsedescricoesdositens/withpagination', { params: vm });
      };

      this.GetByID = function (itensID) {
        return $http.get(
          config.baseUrl + '/api/nfsedescricoesdositens/' + itensID
        );
      };

      this.Post = function (vm) {
        return $http.post(config.baseUrl + '/api/nfsedescricoesdositens', vm);
      };

      this.Put = function (vm) {
        return $http.put(config.baseUrl + '/api/nfsedescricoesdositens', vm);
      };

      this.Delete = function (itensID) {
        return $http.delete(
          config.baseUrl + '/api/nfsedescricoesdositens/' + itensID
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('NFSeItemCotaAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.PostCotaMedica = function (itemID, vm) {
        return $http.post(config.baseUrl + '/api/nfse/cota/item/' + itemID, vm);
      };

      this.DeleteCotaMedica = function (itemID, medicoID) {
        return $http.delete(
          config.baseUrl +
            '/api/nfse/cota/item/' +
            itemID +
            '/medico/' +
            medicoID
        );
      };

      this.ToogleServicoStatusNoItem = function (itemDescricaoID, situacao) {
        return $http.put(
          config.baseUrl +
            '/api/nfse/cota/item/' +
            itemDescricaoID +
            '/situacao/' +
            situacao
        );
      };

      this.DeleteItemDaNFSe = function (itemID) {
        return $http.delete(config.baseUrl + '/api/nfse/item/' + itemID);
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('anexosAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function (vm) {
        return $http.get(config.baseUrl + '/api/anexos', { params: vm });
      };

      this.GetByID = function (faixa) {
        return $http.get(config.baseUrl + '/api/anexos/' + faixa);
      };

      this.GetTabelaSimplesNacionalCliente = function (clienteID) {
        return $http.get(config.baseUrl + '/api/nfse/faturamento/' + clienteID);
      };

      this.PutFaixa = function (vm) {
        return $http.put(config.baseUrl + '/api/anexos/faixa', vm);
      };

      this.Put = function (vm) {
        return $http.put(config.baseUrl + '/api/anexos', vm);
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('auditoriaAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.GetAuditoriaByUsuarioID = function (usuarioID) {
        return $http.post(config.baseUrl + '/api/auditoria/user/' + usuarioID);
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('certificadoAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function (vm) {
        return $http.get(
          config.baseUrl + '/api/certificado', { params: vm });
      };

      this.GetExpirando = function () {
        return $http.get(
          config.baseUrl + '/api/certificado/expirando');
      };

      this.GetByFaixaID = function (faixaID) {
        return $http.get(config.baseUrl + '/api/faixas/' + faixaID);
      };

      this.DeleteCertificadoPelaHash = function (md5Hash) {
        return $http.delete(
          config.baseUrl + '/api/certificado/md5hash/' + md5Hash
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('clientesAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.GetAll = function () {
        return $http.get(config.baseUrl + '/api/clientes/all');
      };

      this.Get = function (vm) {
        return $http.get(config.baseUrl + '/api/clientes', { params: vm });
      };

      this.GetCompleto = function () {
        return $http.get(config.baseUrl + '/api/clientes/completo');
      };

      this.GetByID = function (clienteID) {
        return $http.get(config.baseUrl + '/api/clientes/' + clienteID);
      };

      this.Post = function (vm) {
        return $http.post(config.baseUrl + '/api/clientes', vm);
      };

      this.Put = function (vm) {
        return $http.put(config.baseUrl + '/api/clientes', vm);
      };

      this.Delete = function (clienteID) {
        return $http.delete(config.baseUrl + '/api/clientes/' + clienteID);
      };

      this.GetMedicos = function (clienteID) {
        return $http.get(
          config.baseUrl + '/api/clientes/' + clienteID + '/medicos'
        );
      };

      this.PostAdicionaMedicoNoCliente = function (clienteID, vm) {
        return $http.post(
          config.baseUrl + '/api/clientes/' + clienteID + '/medicos',
          vm
        );
      };

      this.DeleteRemoveMedicoDoCliente = function (clienteID, medicoID) {
        return $http.delete(
          config.baseUrl + '/api/clientes/' + clienteID + '/medicos/' + medicoID
        );
      };

      this.PostGestor = function (clienteID, Gestor) {
        return $http.post(
          config.baseUrl + '/api/clientes/' + clienteID + '/gestor',
          Gestor
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('configuracoesAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.GetConfiguracaoCampinas = function () {
        return $http.get(config.baseUrl + '/api/configuracao/nfsecampinas');
      };

      this.GetConfiguracoesXML = function () {
        return $http.get(config.baseUrl + '/api/configuracao/xml');
      };

      this.GetConfiguracoesImpostosFederais = function () {
        return $http.get(config.baseUrl + '/api/configuracao/impostosfederais');
      };

      this.GetConfiguracoesDashBoard = function () {
        return $http.get(config.baseUrl + '/api/configuracao/dashboard');
      };

      this.PutConfigNFSeCampinas = function (vm) {
        return $http.put(config.baseUrl + '/api/configuracao/nfsecampinas', vm);
      };

      this.PutConfigPadraoXML = function (vm) {
        return $http.put(config.baseUrl + '/api/configuracao/xml', vm);
      };

      this.PutConfigImpostosFederais = function (vm) {
        return $http.put(
          config.baseUrl + '/api/configuracao/impostosfederais',
          vm
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('ControleNFSEsAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.GetNotas = function (dataInicial, dataFinal) {
        return $http.get(
          config.baseUrl + '/api/monitoramento/nfses', {
            params: {dataInicial, dataFinal}
          });
      };

      this.GetTomadores = function (dataInicial, dataFinal, paginacaoObjeto) {
        return $http.get(
        config.baseUrl + '/api/monitoramento/tomadores', {
          params: {dataInicial, dataFinal, ...paginacaoObjeto}
        });

      };

      this.GetHistoricoDeStatus = function(id) {
        return $http.get(
          config.baseUrl + '/api/monitoramento/nfse/status/' + id
        );
      }
    },
  ]);
})();

(function () {
  'use strict';

  angular
  .module('fokus')
  .service('dashboardAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function (clienteID, mes, ano) {
        return $http.get(
          config.baseUrl + '/api/dashboard/' + clienteID + '/' + mes + '/' + ano
        );
      };

      this.GetChartData = function({
        EmpresaID,
        DataInicial,
        DataFinal,
        MedicoID = "",
        SomenteNFSeRecebidas = false,
        Consolidado,
        PorCompetencia,
    }) {
      return $http.post(config.baseUrl + '/api/dashboard/data', {
        EmpresaID,
        DataInicial,
        DataFinal,
        MedicoID,
        SomenteNFSeRecebidas,
        Consolidado,
        PorCompetencia
      })
    };

      this.GetNFSEs = function ({
        EmpresaID,
        MedicoID = -1,
        DataInicial,
        DataFinal,
        SomenteNFSeRecebidas = false,
        PorCompetencia
      }) {
        return $http.post(config.baseUrl + '/api/dashboard/nfses', {
          EmpresaID,
          MedicoID,
          DataInicial,
          DataFinal,
          SomenteNFSeRecebidas,
          PorCompetencia
        })
      };

      this.GetApuracao = function ({
        EmpresaID,
        DataInicial,
        DataFinal,
      }) {
        return $http.post(
          config.baseUrl + '/api/relatorios/resumoapuracaoirpjecslltributario/v1',
          {
            EmpresaID,
            DataInicial,
            DataFinal,
            OutrasReceitas: 0,
            RetencaoIRPJ: 0,
            RetencaoCSLL: 0,
            Honorarios: 0
          }
        );
      }

      this.GetClienteInfo = function (EmpresaID) {
        return $http.get(
          config.baseUrl + '/api/clientes/' + EmpresaID
        );
      }

      this.ExpiringCertificates = function () {
        return $http.get(
          config.baseUrl + '/api/dashboard/expiringcertificates/'
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('despesasRecorrentesAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function (vm) {
        return $http.get(config.baseUrl + '/api/despesasrecorrentes/', {
          params: vm,
        });
      };

      this.GetByID = function (DespesaExtraID) {
        return $http.get(
          config.baseUrl + '/api/despesasrecorrentes/' + DespesaExtraID
        );
      };

      this.GetByClienteID = function (ClienteID) {
        return $http.get(
          config.baseUrl + '/api/despesasrecorrentes/cliente/' + ClienteID
        );
      };

      this.GetByMedicoID = function (MedicoID) {
        return $http.get(
          config.baseUrl + '/api/despesasrecorrentes/medico/' + MedicoID
        );
      };

      this.Post = function (despesaRecorrente) {
        return $http.post(
          config.baseUrl + '/api/despesasrecorrentes/',
          despesaRecorrente
        );
      };

      this.PostDespesaExtra = function (despesaExtra) {
        return $http.post(
          config.baseUrl + '/api/despesasrecorrentes/despesaExtra',
          despesaExtra
        );
      };

      this.PostReajuste = function (reajuste) {
        return $http.post(
          config.baseUrl +
            '/api/despesasrecorrentes/' +
            reajuste.DespesaRecorrenteID +
            '/reajuste',
          reajuste
        );
      };

      this.Put = function (despesaExtra) {
        return $http.put(
          config.baseUrl + '/api/despesasrecorrentes/',
          despesaExtra
        );
      };

      this.PutDespesaRecorrenteMedico = function (vm) {
        return $http.put(
          config.baseUrl + '/api/despesasrecorrentes/despesarecorrentemedico/',
          vm
        );
      };

      this.Delete = function (DespesaExtraID) {
        return $http.delete(
          config.baseUrl + '/api/despesasrecorrentes/' + DespesaExtraID
        );
      };

      this.DeleteDespesasRecorrentesMedicos = function (
        MedicoID,
        ClienteID,
        DespesaRecorrenteID
      ) {
        return $http.delete(
          config.baseUrl +
            '/api/despesasrecorrentes/despesarecorrentesmedicos/' +
            MedicoID +
            '/' +
            ClienteID +
            '/' +
            DespesaRecorrenteID
        );
      };

      this.DeleteReajusteDespesasRecorrentesMedicos = function (
        DespesaRecorrenteID,
        MedicoID,
        ClienteID,
        MesDeVigencia,
        AnoDeVigencia
      ) {
        return $http.delete(
          config.baseUrl +
            '/api/despesasrecorrentes/despesarecorrentesmedicos/reajuste/' +
            DespesaRecorrenteID +
            '/' +
            MedicoID +
            '/' +
            ClienteID +
            '/' +
            MesDeVigencia +
            '/' +
            AnoDeVigencia
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('enotasAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.HabilitarEnotas = function (clienteID, mes, ano) {
        return $http.get(
          config.baseUrl + '/api/enotas/' + clienteID + '/' + mes + '/' + ano
        );
      };

      this.ConsultarEmpresa = function (razaoSocial) {
        return $http.get(
          config.baseUrl + '/api/enotas/consultar/empresa/' + razaoSocial
        );
      };

      this.ListarEmpresas = function () {
        return $http.get(config.baseUrl + '/api/enotas/listar/empresas');
      };

      this.IncluirEmpresa = function (vm) {
        return $http.post(config.baseUrl + '/api/enotas/empresa/incluir', vm);
      };

      this.ServiçosMunicipaisdaCidadeOLD = function (uf, cidade, descricao) {
        return $http.get(
          config.baseUrl +
            '/api/enotas/nfse/Servicosmunicipaisdacidade/uf/' +
            uf +
            '/cidade/' +
            cidade +
            '/descricao/' +
            descricao
        );
      };

      this.ServiçosMunicipaisdaCidade = function (uf, cidade, descricao) {
        return $http({
          method: 'POST',
          url:
            config.baseUrl +
            '/api/enotas/nfse/Servicosmunicipaisdacidade/uf/' +
            uf +
            '/cidade/' +
            cidade +
            '/descricao',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(descricao),
        });
      };

      this.GetRegimeEspecialDeTributacaoPorUFECidade = function (uf, cidade) {
        return $http.get(
          config.baseUrl +
            '/api/enotas/uf/' +
            uf +
            '/cidade/' +
            cidade +
            '/regimesEspecialTributacao'
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('faixasAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function () {
        return $http.get(config.baseUrl + '/api/faixas');
      };

      this.GetByFaixaID = function (faixaID) {
        return $http.get(config.baseUrl + '/api/faixas/' + faixaID);
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('faturamentoAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.GetByClienteID = function (clienteID) {
        return $http.get(config.baseUrl + '/api/faturamento/' + clienteID);
      };

      this.GetFaturamentoTotalByClienteID = function (clienteID) {
        return $http.get(
          config.baseUrl + '/api/faturamento/' + clienteID + '/total'
        );
      };

      this.GetFaixaByClienteIDAndFaixaID = function (clienteID, faixaID) {
        return $http.get(
          config.baseUrl + '/api/faturamento/' + clienteID + '/' + faixaID
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('filiaisAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.GetFiliaisDoCliente = function (empresaID) {
        return $http.get(config.baseUrl + '/api/filiais/' + empresaID);
      };

      this.Add = function (empresaID, filialID) {
        return $http.post(
          config.baseUrl + '/api/filiais/' + empresaID + '/filial/' + filialID
        );
      };

      this.Delete = function (empresaID, filialID) {
        return $http.delete(
          config.baseUrl + '/api/filiais/' + empresaID + '/filial/' + filialID
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('fornecedoresAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function (vm) {
        return $http.get(config.baseUrl + '/api/fornecedores', { params: vm });
      };

      this.GetByID = function (fornecedorID) {
        return $http.get(config.baseUrl + '/api/fornecedores/' + fornecedorID);
      };
      this.GetByClienteID = function (clienteID) {
        return $http.get(
          config.baseUrl + '/api/fornecedores/cliente/' + clienteID
        );
      };

      this.Post = function (vm) {
        return $http.post(config.baseUrl + '/api/fornecedores', vm);
      };

      this.Put = function (vm) {
        return $http.put(config.baseUrl + '/api/fornecedores', vm);
      };

      this.Delete = function (fornecedorID) {
        return $http.delete(
          config.baseUrl + '/api/fornecedores/' + fornecedorID
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('gestorAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function () {
        return $http.get(config.baseUrl + '/api/gestor');
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('medicosAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function (vm) {
        return $http.get(config.baseUrl + '/api/medicos', { params: vm });
      };

      this.GetByID = function (medicoID) {
        return $http.get(config.baseUrl + '/api/medicos/' + medicoID);
      };

      this.GetClientesByMedicoID = function (medicoID) {
        return $http.get(
          config.baseUrl + '/api/medicos/' + medicoID + '/clientes'
        );
      };

      this.Post = function (vm) {
        return $http.post(config.baseUrl + '/api/medicos', vm);
      };

      this.Put = function (vm) {
        return $http.put(config.baseUrl + '/api/medicos', vm);
      };

      this.Delete = function (medicoID) {
        return $http.delete(config.baseUrl + '/api/medicos/' + medicoID);
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('meusRelatoriosAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function (vm) {
        return $http.get(config.baseUrl + '/api/meusrelatorios', {
          params: vm,
        });
      };

      this.RequestMitQuestor = function (vm) {
        return $http({
          method: 'POST',
          url: config.baseUrl + '/api/meusrelatorios/request/mitquestor',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(vm),
        });
        // return $http.post(
        //   config.baseUrl + '/api/meusrelatorios/request/mitdominio',
        //   vm
        // );
      };

      this.RequestMitDominio = function (vm) {
        return $http({
          method: 'POST',
          url: config.baseUrl + '/api/meusrelatorios/request/mitdominio',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(vm),
        });
        // return $http.post(
        //   config.baseUrl + '/api/meusrelatorios/request/mitdominio',
        //   vm
        // );
      };

      this.RequestMitPDF = function (vm) {
        return $http({
          method: 'POST',
          url: config.baseUrl + '/api/meusrelatorios/request/mitpdf',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(vm),
        });
        // return $http.post(
        //   config.baseUrl + '/api/meusrelatorios/request/mitdominio',
        //   vm
        // );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('mitUploadAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.CheckHomologation = function (UF, city) {
        return $http.get(
          config.baseUrl +
            `/api/mitupload/verify/homologation/xml/nfse/${UF}/${city}`
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('municipiosAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function (vm) {
        return $http.get(config.baseUrl + '/api/municipios', { params: vm });
      };

      this.GetComAliquota = function () {
        return $http.get(config.baseUrl + '/api/municipios/comaliquota');
      };

      this.GetByID = function (municipioID) {
        return $http.get(config.baseUrl + '/api/municipios/' + municipioID);
      };

      this.GetByNome = function (municipioNome, UF) {
        // METODO DE BUSCA PARA CADASTROS
        return $http.get(
          config.baseUrl +
            '/api/municipios/' +
            UF +
            '/' +
            municipioNome +
            '/completo'
        );
      };

      this.GetByUF = function (UF) {
        return $http.get(config.baseUrl + '/api/municipios/uf/' + UF);
      };

      this.Put = function (vm) {

        return $http.put(config.baseUrl + '/api/municipios', vm);
      };

      this.PutCamposObrigatorios = function (vm) {
        return $http.put(config.baseUrl + '/api/municipios/obrigatoriedade', vm);
      };

      this.GetAtividadeVinculadaAoMunicipio = function (
        municipioID,
        atividadeID
      ) {
        return $http.get(
          config.baseUrl +
            '/api/municipios/' +
            municipioID +
            '/atividade/' +
            atividadeID
        );
      };

      this.PostAtividadeNoMunicipio = function (municipioID, vm) {
        return $http.post(
          config.baseUrl +
            '/api/municipios/' +
            municipioID +
            '/aliquotadomunicipio',
          vm
        );
      };

      this.PutAtividadeNoMunicipio = function (municipioID, vm) {
        return $http.put(
          config.baseUrl +
            '/api/municipios/' +
            municipioID +
            '/aliquotadomunicipio',
          vm
        );
      };

      this.DeleteAtividadeVinculadaAoServico = function (
        municipioID,
        atividadeID
      ) {
        return $http.delete(
          config.baseUrl +
            '/api/municipios/' +
            municipioID +
            '/atividade/' +
            atividadeID
        );
      };

      this.PutEhMultiServico = function(municipioID, ehMultiServico) {
        return $http.put(
          config.baseUrl +
            '/api/municipios/' +
            municipioID +
            '/' + ehMultiServico
        );
      }
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('navigationAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.SendUserMessage = function (mensagem) {
        return $http.get(config.baseUrl + '/api/teste/senduser/' + mensagem);
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('payloadAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function () {
        return $http.get(config.baseUrl + '/api/payload');
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('permissoesAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function () {
        return $http.get(config.baseUrl + '/api/permissoes');
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('imagesService', function ($http, $q) {
    // Return the public API.
    return {
      deleteImage: deleteImage,
      getAllImages: getAllImages,
    };

    // ---
    // PUBLIC METHODS.
    // ---

    // I delete the image with the given ID.
    function deleteImage(id) {
      var request = $http({
        method: 'post',
        url: 'api/index.cfm',
        params: {
          action: 'delete',
        },
        data: {
          id: id,
        },
      });

      return request.then(handleSuccess, handleError);
    }

    // I get all the images currently uploaded.
    function getAllImages() {
      var request = $http({
        method: 'get',
        url: 'api/index.cfm',
        params: {
          action: 'list',
        },
      });

      return request.then(handleSuccess, handleError);
    }

    // ---
    // PRIVATE METHODS.
    // ---

    // I transform the error response, unwrapping the application dta from the API
    // response payload.
    function handleError(response) {
      // The API response from the server should be returned in a nomralized
      // format. However, if the request was not handled by the server (or was
      // not handles properly - ex. server error), then we may have to normalize
      // it on our end, as best we can.
      if (!angular.isObject(response.data) || !response.data.message) {
        return $q.reject({
          code: -1,
          message: 'An unknown error occurred.',
        });
      }

      // Otherwise, use expected error message.
      return $q.reject(response.data);
    }

    // I transform the successful response, unwrapping the application data from the
    // API response payload.
    function handleSuccess(response) {
      return response.data;
    }
  });
})();

(function () {
  'use strict';

  angular.module('fokus').service('relatoriosAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.GetByClienteIDEPeriodo = function (clienteID, mes, ano, porcompetencia, consolidado) {
        return $http.get(
          config.baseUrl +
            '/api/relatorios/nfseemitidas/cliente/' +
            clienteID +
            '/mes/' +
            mes +
            '/ano/' +
            ano +
            '/porcompetencia/' +
            porcompetencia +
            '/unificado/' +
            consolidado
        );
      };

      this.GetRelatorioDeDistribuicao = function (
        clienteID,
        diainicial,
        mesinicial,
        anoinicial,
        diafinal,
        mesfinal,
        anofinal,
        medicoID,
        SomenteNFSeRecebidas,
        PorCompetencia
      ) {
        //   console.log(config.baseUrl + "/api/relatorios/distribuicao/"
        //   + "/" + clienteID + "/" + diainicial + "/" + mesinicial + "/" + anoinicial
        // + "/" + diafinal + "/" + mesfinal + "/" + anofinal);
        console.log(
          config.baseUrl +
            '/api/relatorios/distribuicao/' +
            clienteID +
            '/' +
            diainicial +
            '/' +
            mesinicial +
            '/' +
            anoinicial +
            '/' +
            diafinal +
            '/' +
            mesfinal +
            '/' +
            anofinal +
            '/' +
            medicoID +
            '/somenterecebidas/' +
            SomenteNFSeRecebidas +
            '/geracsv/true/' +
            'porcompetencia/' + PorCompetencia
        );
        return $http.get(
          config.baseUrl +
            '/api/relatorios/distribuicao/' +
            clienteID +
            '/' +
            diainicial +
            '/' +
            mesinicial +
            '/' +
            anoinicial +
            '/' +
            diafinal +
            '/' +
            mesfinal +
            '/' +
            anofinal +
            '/' +
            medicoID +
            '/somenterecebidas/' +
            SomenteNFSeRecebidas +
            '/geracsv/true/' +
            'porcompetencia/' + PorCompetencia
        );
      };

      this.GetCSVFileDeDistribuicaoUnificado = function (
        clienteID,
        diainicial,
        mesinicial,
        anoinicial,
        diafinal,
        mesfinal,
        anofinal,
        SomenteNFSeRecebidas,
        PorCompetencia
      ) {
        return $http.get(
          config.baseUrl +
            '/api/relatorios/distribuicao/' +
            clienteID +
            '/' +
            diainicial +
            '/' +
            mesinicial +
            '/' +
            anoinicial +
            '/' +
            diafinal +
            '/' +
            mesfinal +
            '/' +
            anofinal +
            '/' +
            0 +
            '/somenterecebidas/' +
            SomenteNFSeRecebidas +
            '/geracsv/true/porcompetencia/' +
            PorCompetencia
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('servicosAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function () {
        return $http.get(config.baseUrl + '/api/servicos');
      };

      this.GetPage = function (page, max, search) {
        return $http.get(
          config.baseUrl +
            '/api/servicos/filter?Page=' +
            page +
            '&Max=' +
            max +
            '&Search=' +
            encodeURIComponent(search)
        );
      };

      this.GetByID = function (servicoID) {
        return $http.get(config.baseUrl + '/api/servicos/' + servicoID);
      };

      this.Post = function (vm, ClienteID) {
        return $http.post(
          config.baseUrl + '/api/servicos/cliente/' + ClienteID,
          vm
        );
      };

      this.Put = function (vm) {
        return $http.put(config.baseUrl + '/api/servicos', vm);
      };

      this.Delete = function (servicoID, clienteID) {
        return $http.delete(
          config.baseUrl +
            '/api/servicos/' +
            servicoID +
            '/cliente/' +
            clienteID
        );
      };
    },
  ]);
})();

(function () {
    'use strict';

    angular.module('fokus').service('servicosplugNotasAPI', [
      '$http',
      'config',
      function ($http, config) {
        this.Get = function () {
          return $http.get(config.baseUrl + '/api/servicosplugnotas');
        };

        this.GetByID = function (servicoID) {
          return $http.get(config.baseUrl + '/api/servicosplugnotas/' + servicoID);
        };

        this.Post = function (vm, ClienteID) {
          return $http.post(
            config.baseUrl + '/api/servicosplugnotas/cliente/' + ClienteID,
            vm
          );
        };

        this.Put = function (vm) {
          return $http.put(config.baseUrl + '/api/servicosplugnotas', vm);
        };

        this.Delete = function (servicoID, clienteID) {
          return $http.delete(
            config.baseUrl +
              '/api/servicosplugnotas/' +
              servicoID +
              '/cliente/' +
              clienteID
          );
        };
      },
    ]);
  })();

(function () {
    'use strict';
  
    angular.module('fokus').service('GetTokenByCookie', [
      '$q',
      function ($q) {
        this.GetToken = function () {
            var deferred = $q.defer();
            var cookiesAll = document.cookie.split(';');
            var idToken = '';
            cookiesAll.forEach(element => {
              var key = element.split("=");
              if(key[0].split('.').pop() === 'accessToken'){
                idToken = key[1];
              }
            });
            var tokens = { accessToken: idToken, expires_in: new Date(), Token: idToken }
    
            deferred.resolve(tokens);
            return deferred.promise;
        }; 
      },
    ]);
  })();
(function () {
  'use strict';

  angular.module('fokus').service('tomadoresAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.GetAll = function () {
        return $http.get(config.baseUrl + '/api/tomadores/all');
      };

      this.GetAllWithSearch = function (vm) {
        return $http.get(config.baseUrl + '/api/tomadores/v1', { params: vm });
      };

      this.Get = function (vm) {
        return $http.get(config.baseUrl + '/api/tomadores', { params: vm });
      };

      this.GetCompleto = function (vm) {
        return $http.get(config.baseUrl + '/api/tomadores/completo');
      };

      this.GetByID = function (tomadorID) {
        return $http.get(config.baseUrl + '/api/tomadores/' + tomadorID);
      };

      this.GetByCNPJorCPF = function (CNPJOrCPF) {
        return $http.get(
          config.baseUrl + `/api/tomadores/cnpjorcpf=${CNPJOrCPF}`
        );
      };

      this.Post = function (vm) {
        return $http.post(config.baseUrl + '/api/tomadores', vm);
      };

      this.Put = function (vm) {
        return $http.put(config.baseUrl + '/api/tomadores', vm);
      };

      this.Delete = function (tomadorID) {
        return $http.delete(config.baseUrl + '/api/tomadores/' + tomadorID);
      };

      this.GetCamposObrigatoriosDoTomador = function () {
        return $http.get(config.baseUrl + '/api/tomadores/camposObrigatorios');
      };

      this.LinkTomadores = function (vm) {
        return $http.put(config.baseUrl + '/api/tomadores/link', vm);
      };

      this.GetTomadoresWithUserId = function (vm, userId) {
        return $http.get(
          config.baseUrl + '/api/tomadores/v1/userId/' + userId,
          { params: vm }
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('usuarioAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.TokenValidation = function () {
        return $http.get(config.baseUrl + '/api/usuario/tokenvalidation');
      };

      this.Get = function () {
        return $http.get(config.baseUrl + '/api/usuario');
      };

      this.RecuperarSenha = function (vm) {
        return $http({
          method: 'POST',
          url: config.baseUrl + '/api/email/recovery',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(vm),
        });
      };

      this.CheckValidToken = function (token) {
        return $http.get(config.baseUrl + '/api/email/recovery/' + token);
      };

      this.AlterarSenha = function (token, vm) {
        return $http({
          method: 'POST',
          url: config.baseUrl + '/api/email/recovery/' + token,
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(vm),
        });
      };

      this.EnviarEmailBoasVindas = function (usuarioID) {
        return $http.get(
          config.baseUrl + '/api/usuarios/' + usuarioID + '/email/boasvindas'
        );
      };

      this.EnviarEmailOrientacao = function (usuarioID) {
        return $http.get(
          config.baseUrl + '/api/usuarios/' + usuarioID + '/email/orientacao'
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('usuariosAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.Get = function (vm) {
        return $http.get(config.baseUrl + '/api/usuarios', { params: vm });
      };

      this.GetByID = function (usuarioID) {
        return $http.get(config.baseUrl + '/api/usuarios/' + usuarioID);
      };

      this.Post = function (vm) {
        return $http({
          method: 'POST',
          url: config.baseUrl + '/api/usuarios',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(vm),
        });
      };

      this.Put = function (vm) {
        return $http({
          method: 'PUT',
          url: config.baseUrl + '/api/usuarios',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(vm),
        });
      };

      this.Delete = function (usuarioID) {
        return $http.delete(config.baseUrl + '/api/usuarios/' + usuarioID);
      };

      this.VinculaNovoTomadorAoUsuario = function (vm, usuarioID) {
        console.log(vm);
        console.log(usuarioID);
        return $http({
          method: 'POST',
          url: config.baseUrl + '/api/usuarios/v1/tomadores/' + usuarioID,
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(vm),
        });
      };

      this.DesvincularTomadorDoUsuario = function (tomadorID, usuarioID) {
        return $http.delete(
          config.baseUrl +
            '/api/usuarios/v1/tomador/' +
            tomadorID +
            '/usuario/' +
            usuarioID
        );
      };

      this.DesvincularTodosOsTomadoresDoUsuario = function (usuarioID) {
        return $http.delete(
          config.baseUrl +
            '/api/usuarios/v1/tomador/' +
            '/usuario/limpartodosostomadores/' +
            usuarioID
        );
      };
    },
  ]);
})();

(function () {
  'use strict';

  angular.module('fokus').service('viaCepAPI', [
    '$http',
    'config',
    function ($http, config) {
      this.GetEndereco = function (cep) {
        return $http.get(config.baseUrl + '/api/enderecos/' + cep);
      };

      this.GetEnderecoViaCEP = function (cep) {
        return $http.get('https://viacep.com.br/ws/' + cep + '/json/');
      };
    },
  ]);
})();

/*
 *
 *   Right - Responsive Admin Template
 *   v 0.3.0
 *   http://adminbootstrap.com
 *
 */

$(document).ready(function() {
	quickmenu($('.quickmenu__item.active'));

	$('body').on('click', '.quickmenu__item', function() {
		quickmenu($(this))
	});

	function quickmenu(item) {
		var menu = $('.sidebar__menu');
		menu.removeClass('active').eq(item.index()).addClass('active');
		$('.quickmenu__item').removeClass('active');
		item.addClass('active');
		menu.eq(0).css('margin-left', '-'+item.index()*200+'px');
	}

	$('.sidebar li').on('click', function(e) {
		e.stopPropagation();
		var second_nav = $(this).find('.collapse').first();
		if (second_nav.length) {
			second_nav.collapse('toggle');
			$(this).toggleClass('opened');
		}
	});

	$('body.main-scrollable .main__scroll').scrollbar();
	$('.scrollable').scrollbar({'disableBodyScroll' : true});
	$(window).on('resize', function() {
		$('body.main-scrollable .main__scroll').scrollbar();
		$('.scrollable').scrollbar({'disableBodyScroll' : true});
	});

	$('.selectize-dropdown-content').addClass('scrollable scrollbar-macosx').scrollbar({'disableBodyScroll' : true});
	$('.nav-pills, .nav-tabs').tabdrop();

	$('body').on('click', '.header-navbar-mobile__menu button', function() {
		$('.dashboard').toggleClass('dashboard_menu');
	});

	$('.sidestat__chart.sparkline.bar').each(function() {
		$(this).sparkline(
			'html',
			{
				type: 'bar',
				height: '30px',
				barSpacing: 2,
				barColor: '#3D3A6D',
				negBarColor: '#ed4949'
			}
		);
	});

	$('.sidestat__chart.sparkline.area').each(function() {
		$(this).sparkline(
			'html',
			{
				width: '145px',
				height: '40px',
				type: 'line',
				lineColor: '#ed4949',
				lineWidth: 2,
				fillColor: 'rgba(237, 73, 73, 0.6)',
				spotColor: '#FF5722',
				minSpotColor: '#FF5722',
				maxSpotColor: '#FF5722',
				highlightSpotColor: '#FF5722',
				spotRadius: 2
			}
		);
	});

	$('.sidestat__chart.sparkline.bar_thin').each(function() {
		$(this).sparkline(
			'html',
			{
				type: 'bar',
				height: '30px',
				barSpacing: 1,
				barWidth: 2,
				barColor: '#FED42A',
				negBarColor: '#ed4949'
			}
		);
	});

	$('.sidestat__chart.sparkline.line').each(function() {
		$(this).sparkline(
			'html',
			{
				type: 'bar',
				height: '30px',
				barSpacing: 2,
				barWidth: 3,
				barColor: '#20c05c',
				negBarColor: '#ed4949'
			}
		);
	});

	$("input.bs-switch").bootstrapSwitch();

	$('.settings-slider').ionRangeSlider({
		decorate_both: false
	});

	if ($('input[type=number]').length) {
		$('input[type=number]').inputNumber({
			mobile: false
		});
	}
});
